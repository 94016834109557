import React from 'react'
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { Link } from 'react-router-dom'
import { formatCurrency } from 'wearslot-dev-utils';


const InvoiceCard = ({ invoice, store }) => {

    const Item = ({ id, item }) => {

        return (
            <tr>
                <th scope="row">{id + 1}</th>
                <td className="text-start">
                    <span className="fw-semibold">
                        {item.name}
                    </span>
                    <p className="text-muted mb-0">
                        {item.description}
                    </p>
                </td>
                <td>{getSymbolFromCurrency(invoice.currency) + formatCurrency(item.price)}</td>
                <td>{item.quantity}</td>
                <td className="text-end">{getSymbolFromCurrency(invoice.currency) + formatCurrency(item.price * item.quantity)}</td>
            </tr>
        )
    }


    return (
        <div>
            <div className="card-header border-bottom-dashed p-4">
                <div className="d-sm-flex">
                    <div className="flex-grow-1">
                        {store.logo !== null ? <img
                            src={store.logo}
                            className="card-logo"
                            style={{ height: "40px", width: "150px" }}
                        /> : store.name}
                    </div>
                    <div className="flex-shrink-0 mt-sm-0 mt-3">
                        <div>
                            <Link to={`/invoice/${invoice?.id}/edit`}
                                className="btn btn-light" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="Edit"><i
                                    className="ri-pencil-fill align-bottom"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body p-4">
                <div className="row g-3">
                    <div className="col-lg-3 col-6">
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                            Invoice No
                        </p>
                        <h5 className="fs-15 mb-0">{invoice && invoice.invoice_id}</h5>
                    </div>
                    <div className="col-lg-3 col-6">
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                            Due Date
                        </p>
                        <h5 className="fs-15 mb-0">
                            {invoice && moment(invoice.due_date).format('ddd, D MMM YYYY')}
                        </h5>
                    </div>
                    <div className="col-lg-3 col-6">
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                            Payment Status
                        </p>
                        {invoice && invoice.payment_status === "Unpaid" ? (
                            <span className="badge badge-soft-danger fs-11">
                                Unpaid
                            </span>
                        ) : null}
                        {invoice && invoice.payment_status === "Paid" ? (
                            <span className="badge badge-soft-success fs-11">
                                Paid
                            </span>
                        ) : null}
                        {invoice && invoice.payment_status === "Partially Paid" ? (
                            <span className="badge badge-soft-warning fs-11">
                                Partially Paid
                            </span>
                        ) : null}
                    </div>
                    <div className="col-lg-3 col-6">
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                            Total Amount
                        </p>
                        <h5 className="fs-15 mb-0">{invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.final_total)}</h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-4 border-top border-top-dashed">
                <div className="row g-3">
                    <div className="col-sm-6">
                        <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Billing Address
                        </h6>
                        <p className="fw-semibold mb-2">{invoice && invoice.billing_name}</p>
                        <p className="text-muted mb-1">{invoice && invoice.billing_address}</p>
                        <p className="text-muted mb-1">Phone: {invoice && invoice.billing_phone}</p>
                    </div>
                    <div className="col-sm-6">
                        <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Shipping Address
                        </h6>
                        <p className="fw-semibold mb-2">{invoice && invoice.shipping_name}</p>
                        <p className="text-muted mb-1">{invoice && invoice.shipping_address}</p>
                        <p className="text-muted mb-0">Phone: {invoice && invoice.shipping_phone}</p>
                    </div>
                </div>
            </div>
            <div className="card-body p-4">
                <div className="table-responsive">
                    <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                        <thead>
                            <tr className="table-active">
                                <th scope="col" style={{ width: "50px" }}>
                                    #
                                </th>
                                <th scope="col">Item</th>
                                <th scope="col">Rate</th>
                                <th scope="col">Quantity</th>
                                <th scope="col" className="text-end">
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoice && invoice.items.length > 0 ? (
                                    <>
                                        {invoice.items.map((item, index) => (
                                            <Item id={index} item={item} key={index} />
                                        ))}
                                    </>
                                ) : null
                            }
                            <tr className="border-top border-top-dashed mt-2">
                                <td colSpan="3"></td>
                                <td colSpan="2" className="fw-semibold p-0">
                                    <table className="table table-borderless text-start table-nowrap align-middle mb-0">
                                        <tbody>
                                            <tr>
                                                <td>Sub Total</td>
                                                <td className="text-end">{invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.sub_total)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Discount
                                                    {invoice && invoice.coupon_code ? <small className="text-muted">({invoice.coupon_code})</small> : ''}
                                                </td>
                                                <td className="text-end">- {invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.discount)}</td>
                                            </tr>
                                            <tr>
                                                <td>Shipping Charge</td>
                                                <td className="text-end">{invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.shipping_fee)}</td>
                                            </tr>
                                            <tr className="border-top border-top-dashed">
                                                <th scope="row">Total</th>
                                                <td className="text-end">{invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.final_total)}</td>
                                            </tr>
                                            <tr className="border-top border-top-dashed">
                                                <th scope="row">Amount Paid</th>
                                                <td className="text-end text-success">{invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.amount_paid)}</td>
                                            </tr>
                                            <tr className="">
                                                <th scope="row">Balance Due</th>
                                                <td className="text-end text-danger">{invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.final_total - invoice.amount_paid)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-3">
                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                        Payment Details:
                    </h6>
                    <p className="text-muted mb-1">
                        Payment Method: <span className="fw-semibold">{invoice && invoice.payment_method.name}</span>
                    </p>
                    <p className="text-muted mb-1">
                        Minimum Payment:  <span className="fw-semibold">{invoice && getSymbolFromCurrency(invoice.currency) + formatCurrency(invoice.minimum_payment_allowed)}</span>
                    </p>
                </div>
                <div className="mt-4">
                    {
                        invoice && invoice.note ? (
                            <div className="alert">
                                <p className="mb-0">
                                    <span className="fw-semibold">NOTE:</span> {invoice.note}
                                </p>
                            </div>
                        ) : null
                    }
                </div>

            </div>
        </div>
    )
}

export default InvoiceCard