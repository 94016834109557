import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import PayStack from './PayStack';
import Flutterwave from './Flutterwave';
import Kwik from './Kwik';


const Provider = () => {

    const { provider } = useParams();

    const getProvider = (provider) => {

        var screen = '';
        switch (provider) {
            case 'paystack':
                screen = <PayStack />
                break;

            case 'flutterwave':
                screen = <Flutterwave />
                break;

            case 'kwik':
                screen = <Kwik />
                break;

            default:
                window.history.back();
                break;
        }

        return screen;
    }

    return (
        <Fragment>{getProvider(provider)}</Fragment>
    )
}

export default Provider