import React, { useEffect, useState } from 'react'
import ModalBox from '../../../Components/ModalBox'
import SimpleBar from 'simplebar-react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../../Components/Loader/Loader'

const RegionForm = ({ region, saving, show, closeModal, validateZone, handleRegionChange, saveRegion }) => {

  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);

  const [forAllZones, setForAllZones] = useState(false);
  const [selectedZones, setSelectedZones] = useState({});

  useEffect(() => {
    getCountriesWithStates();
  }, []);

  useEffect(() => {
    if (region.locations === 'all') {
      return setForAllZones(true)
    }

    setSelectedZones(region.locations);
  }, [region])


  const getCountriesWithStates = async () => {
    try {
      var newList = [];
      for (var i = 1; i <= 3; i++) {
        const response = await axios.get(`/countries-with-states?page=${i}`);
        if (response.data.success) {
          newList = [...newList, ...response.data.data];
        }
      }
      setCountries(newList)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleCountryCheck = (e, country) => {
    if (e.target.checked === true) {
      var states = country.states.map(state => state.name);
      return setSelectedZones({ ...selectedZones, [country.name]: states });
    }

    var newZoneUpdate = { ...selectedZones };
    delete newZoneUpdate[country.name];

    return setSelectedZones(newZoneUpdate);
  }

  const handleStateCheck = (e, state, country) => {
    var newStateList = [];
    if (e.target.checked === true) {
      if (selectedZones[country.name] === undefined) {
        return setSelectedZones({ ...selectedZones, [country.name]: [state.name] });
      }

      newStateList = selectedZones[country.name];
      newStateList.push(state.name);

      return setSelectedZones({ ...selectedZones, [country.name]: newStateList });
    }

    newStateList = selectedZones[country.name].filter((st) => st !== state.name);
    if (newStateList.length === 0) {
      var newZoneUpdate = { ...selectedZones };
      delete newZoneUpdate[country.name];

      return setSelectedZones(newZoneUpdate);
    }

    return setSelectedZones({ ...selectedZones, [country.name]: newStateList });

  }

  const checkCountry = (country) => {
    if (selectedZones === null) {
      return false;
    }

    if (selectedZones[country.name] === undefined) {
      return false;
    }

    return selectedZones[country.name].length === country.states.length;
  }

  const checkState = (state, country) => {
    if (selectedZones === null) {
      return false;
    }

    if (selectedZones[country.name] === undefined) {
      return false;
    }

    return selectedZones[country.name].includes(state.name);
  }

  return (
    <ModalBox
      show={show}
      handleClose={closeModal}
      title={region && region.id !== undefined ? 'Update Region' : 'Create Region'}
      closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
      saveBtn={
        (<button
          type="button"
          disabled={saving}
          className={`btn btn-primary ${saving ? 'disabled' : ''}`}
          onClick={() => saveRegion(selectedZones, forAllZones)}>
          {saving
            ? <>
              <div className="spinner-border spinner-border-sm text-white" role="status">
                <span className="visually-hidden">Loading...</span>
              </div> Saving...
            </> : 'Save'}
        </button>)}
    >
      <form action="" method="POST" className="form" id="deliveryRegionForm" encType="multipart/form-data">
        <div className="card-body">
          <div className="form-group mb-3">
            <label className="col-xl-12 col-lg-12 col-form-label text-right">Name</label>
            <div className="col-lg-12 col-xl-12">
              <input type="text" className="form-control form-control-solid" name="name"
                value={region.name} placeholder="e.g Domestic Delivery, International Shipping"
                onChange={(e) => handleRegionChange(e)} />
            </div>
          </div>

          <div className="form-group mb-3">
            <div className="col-lg-12 col-xl-12">
              <div className="form-check form-switch form-switch-left form-switch-md">
                <label htmlFor="default-modal" className="form-label">Include all available zones</label>
                <input className="form-check-input code-switcher" type="checkbox" checked={forAllZones}
                  onChange={(e) => setForAllZones(!forAllZones)}
                />
              </div>
              <div className="alert alert-primary alert-dismissible fade show" role="alert">
                <b>Note:</b> If you include all available zones, only zones that doesn't exist in any of your existing regions would be included.
              </div>
            </div>
          </div>

          {!forAllZones &&
            <div className="form-group mb-3">
              <label className="col-xl-12 col-lg-12 col-form-label text-right">Select Zones</label>
              <SimpleBar style={{ maxHeight: 320 }} className='border px-2 rounded-3'>
                {loading && <div className="py-5"><Loader message={'Fetching data...'} /></div>}
                {countries.length > 0 &&
                  <ul className="navbar-nav" id="navbar-nav">
                    {countries.map((country) =>
                      <li className="nav-item mb-1" key={`country${country.id}`}>
                        <div
                          className="nav-link menu-link p-0 d-flex justify-content-between"
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              disabled={validateZone(country)}
                              name={`checkAllCountry${country.id}`}
                              checked={checkCountry(country)}
                              onChange={(e) => handleCountryCheck(e, country)}
                            />
                            <label>{country.name}</label> {validateZone(country) ? <small className='text-danger'><i>Already in another region</i></small> : null}
                          </div>
                          {country.states.length > 0 &&
                            <Link style={{ whiteSpace: "nowrap" }}
                              to={`#regionCountry${country.id}`}
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              aria-controls={`regionCountry${country.id}`}>
                              <small>{country.states.length} states</small>
                            </Link>}
                        </div>
                        <div className="collapse menu-dropdown px-3" id={`regionCountry${country.id}`}>
                          <ul className="nav nav-sm flex-column">
                            {country.states.map((state) =>
                              <li className="nav-item" key={`state${country.id}${state.id}`}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    disabled={validateZone(country)}
                                    name={`checkForState${country.id}${state.id}`}
                                    checked={checkCountry(country) || checkState(state, country)}
                                    onChange={(e) => handleStateCheck(e, state, country)}
                                  />
                                  <label>{state.name}</label>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </li>
                    )}
                  </ul>
                }
              </SimpleBar>
            </div>
          }
        </div>
      </form>
    </ModalBox>
  )
}

export default RegionForm