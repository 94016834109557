import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatCurrency } from 'wearslot-dev-utils';

const BestSellingTable = ({ best_selling, currency }) => {
    return (
        <div className="card-body">
            <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                    <tbody>
                        {best_selling.items.length > 0 ? (
                            <>
                                {best_selling.items.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-sm bg-light rounded p-1 me-2" style={{ overflow: 'hidden' }}>
                                                    <img
                                                        src={item.images.main}
                                                        alt={item.product.name}
                                                        className="img-fluid d-block"
                                                    />
                                                </div>
                                                <div>
                                                    <h5 className="fs-14 my-1">
                                                        <Link
                                                            to={`product/${item.product.id}/details`}
                                                            className="text-reset"
                                                        >
                                                            {item.product.name}
                                                        </Link>
                                                    </h5>
                                                    <span className="text-muted">
                                                        {item.created_date && moment(item.created_date).format("MMM D, y")}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <h5 className="fs-14 my-1 fw-normal">
                                                {currency}{item.product.price}
                                            </h5>
                                            <span className="text-muted">
                                                Price
                                            </span>
                                        </td>
                                        <td>
                                            <h5 className="fs-14 my-1 fw-normal">
                                                {item.orders}
                                            </h5>
                                            <span className="text-muted">
                                                Orders
                                            </span>
                                        </td>
                                        <td>
                                            {item.product.variations_quantity > 0 ? (
                                                <h5 className="fs-14 my-1 fw-normal">
                                                    {item.product.variations_quantity} from {item.product.variations_count} variant(s)
                                                </h5>
                                            ) : (
                                                <>
                                                    {item.product.quantity > 0 ? (
                                                        <h5 className="fs-14 my-1 fw-normal">
                                                            {item.product.quantity}
                                                        </h5>
                                                    ) : null}
                                                </>
                                            )}

                                            {item.product.variations_quantity === 0 &&
                                                item.product.quantity === 0 ? (
                                                <span className="badge badge-soft-danger">
                                                    Out of stock
                                                </span>
                                            ) : <span className="badge badge-soft-success">
                                                In Stock
                                            </span>}

                                        </td>
                                        <td>
                                            <h5 className="fs-14 my-1 fw-normal">
                                                {currency}{formatCurrency(item.total_amount)}
                                            </h5>
                                            <span className="text-muted">
                                                Total Sales
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td colSpan="5">
                                    <div className="text-center mt-3 align-items-center">
                                        <p> No item available! </p>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BestSellingTable