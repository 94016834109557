import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Link, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { errorResponse, responseMessage } from '../../libs/app';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';

import axios from 'axios';
import { AuthContext } from '../../Contexts/AuthContext';
import moment from 'moment';
import Placeholder from '../../Components/Placeholder/Placeholder';



export async function publishAction(product_id, status = 1) {

    try {
        const response = await axios.post(`/product/publish`, { id: product_id, status })
        if (response.data.success) {
            responseMessage(response.data.message, "success");
            return true;
        }

    } catch (error) {
        if (error.response) {
            return errorResponse(error.response);
        }
        return responseMessage("Something went wrong.", "error");
    }
}


const Stars = ({ rate = 0 }) => {

    return (
        <div className="flex-grow-1">
            <div
                className="fs-16 align-middle text-warning">
                {[...Array(5)].map((num, index) =>
                    <Star
                        key={index}
                        current={(rate - index)}
                    />
                )}
            </div>
        </div>
    )
}

const Star = ({ current }) => {

    return <>{
        current > 1
            ? <i className="ri-star-fill"></i>
            : (current > 0
                ? <i className="ri-star-half-fill"></i>
                : <i className="ri-star-fill text-muted"></i>)
    }</>
}

const Variation = ({ variation }) => {

    const Item = ({ type, value }) => {

        return (<>
            {type.name.toLowerCase() === "sizes"
                ? (<div data-bs-toggle="tooltip" data-bs-trigger="hover"
                    data-bs-placement="top" title="Out of Stock">
                    <input type="radio" className="btn-check"
                        name="productsize-radio" id="productsize-radio1"
                        disabled />
                    <label
                        className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"
                        htmlFor="productsize-radio1">{value.name}</label>
                </div>)
                : (
                    <>
                        {type.name.toLowerCase() === "colors"
                            ? (<div data-bs-toggle="tooltip" data-bs-trigger="hover"
                                data-bs-placement="top" title="Out of Stock">
                                <button type="button"
                                    className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20"
                                >
                                    <i className="ri-checkbox-blank-circle-fill" style={{ color: value.name.toLowerCase() }}></i>
                                </button>
                            </div>)
                            : (<></>)
                        }
                    </>
                )
            }
        </>)
    }

    return (
        <div className="col-xl-6">
            <div className=" mt-4">
                <h5 className="fs-14">{variation.type.name}:</h5>
                <div className="d-flex flex-wrap gap-2">
                    {
                        variation.values.map((value, index) => (
                            <Item type={variation.type} value={value} key={index} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

const RatingsByCount = ({ ratings }) => {

    const getClassStyle = (percentage) => {

        if (percentage <= 10) {
            return 'bg-danger';
        } else if (percentage <= 25) {
            return 'bg-warning'
        } else if (percentage <= 40) {
            return 'bg-info'
        } else {
            return 'bg-success'
        }
    }

    return (
        <>
            {
                Object.keys(ratings).map((key, index) => (
                    <div className="row align-items-center g-2" key={index}>
                        <div className="col-auto">
                            <div className="p-2">
                                <h6 className="mb-0">{ratings[key].rate} star</h6>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-2">
                                <div
                                    className="progress animated-progress progress-sm">
                                    <div className={"progress-bar " + getClassStyle(ratings[key].percentage)}
                                        role="progressbar"
                                        style={{ width: ratings[key].percentage }}
                                        aria-valuenow={ratings[key].percentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="p-2">
                                <h6 className="mb-0 text-muted">{ratings[key].count}</h6>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

const ImageSlideshow = ({ perView = 1, spaceBetween = 0, style, images }) => {

    return (
        <Swiper
            modules={[Navigation, Scrollbar, A11y]}
            className={style}
            navigation
            spaceBetween={spaceBetween}
            slidesPerView={perView}>
            {images.main ? (
                <SwiperSlide className="swiper-slide">
                    <img src={images.main} alt=""
                        className="img-fluid d-block" />
                </SwiperSlide>
            ) : null}

            {images.gallery.length ? (
                <>
                    {images.gallery.map((image, index) => (<>
                        <SwiperSlide className="swiper-slide" key={`slide-${index}`}>
                            <img src={image} alt=""
                                className="img-fluid d-block" />
                        </SwiperSlide>
                    </>))}
                </>
            ) : null}
        </Swiper>
    )
}



const ProductDetails = () => {

    document.title = 'Product Details | Taojaa - Store Manager App';

    const { product_id } = useParams();
    const { currency } = useContext(AuthContext)

    const [product, setProduct] = useState(null);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        getProductDetails(product_id);
    }, [])


    async function getProductDetails(id) {

        try {

            const response = await axios.get(`/product/details/${id}`);
            if (response.data.success) {
                setProduct(response.data.product)
                getProductReviews(product_id);
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function getProductReviews(id) {

        try {

            const response = await axios.get(`/product/reviews/${id}`)
            if (response.data.success) {
                setReviews(response.data.reviews);
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
        }
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    {product ?
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Product Details</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to="#" onClick={() => window.history.back()}>
                                                        <i className="ri-arrow-left-line align-bottom me-1"></i>
                                                        Back
                                                    </Link>
                                                </li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row gx-lg-5">
                                                <div className="col-xl-4 col-md-8 mx-auto">
                                                    <div className="product-img-slider sticky-side-div">

                                                        <ImageSlideshow
                                                            spaceBetween={10}
                                                            images={product.images}
                                                            style={'swiper product-thumbnail-slider rounded bg-light'} />

                                                        <div className='mt-3 text-start'>
                                                            {product.published.status === true ?
                                                                <button type="button" onClick={() => (publishAction(product_id, 0) ? getProductDetails() : null)} className="btn btn-danger w-sm">
                                                                    Unpublish
                                                                </button>
                                                                :
                                                                <button type="button" onClick={() => (publishAction(product_id, 1) ? getProductDetails() : null)} className="btn btn-primary w-sm">
                                                                    Publish
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-8">
                                                    <div className="mt-xl-0 mt-5">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <h4>{product.name}</h4>
                                                                <div className="hstack gap-3 flex-wrap">
                                                                    <div className="text-muted">Brand: <span
                                                                        className="text-body fw-medium">{product.brand}</span>
                                                                    </div>
                                                                    <div className="vr"></div>
                                                                    <div className="text-muted">Manufacturer: <span
                                                                        className="text-body fw-medium">{product.inventory.manufacturer}</span>
                                                                    </div>
                                                                    <div className="vr"></div>
                                                                    <div className="text-muted">Published: {product.published.status === true
                                                                        ? (<span className="text-body fw-medium">{product.published.date}</span>)
                                                                        : (<span className="text-body fw-medium">Not Published</span>)
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <div>
                                                                    <Link to={`/product/${product.id}/edit`}
                                                                        className="btn btn-light" data-bs-toggle="tooltip"
                                                                        data-bs-placement="top" title="Edit"><i
                                                                            className="ri-pencil-fill align-bottom"></i></Link>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                                                            <div className="text-muted fs-16">
                                                                <Stars rate={product.rating.rate} />
                                                            </div>
                                                            <div className="text-muted">( {product.rating.total_reviews} Customer Review )</div>
                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className="col-lg-3 col-sm-6">
                                                                <div className="p-2 border border-dashed rounded">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="avatar-sm me-2">
                                                                            <div
                                                                                className="avatar-title rounded bg-transparent text-info fs-24">
                                                                                <i className="ri-money-dollar-circle-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <p className="text-muted mb-1">Price:</p>
                                                                            <h5 className="mb-0">{currency + product.price}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-sm-6">
                                                                <div className="p-2 border border-dashed rounded">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="avatar-sm me-2">
                                                                            <div
                                                                                className="avatar-title rounded bg-transparent text-info fs-24">
                                                                                <i className="ri-file-copy-2-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <p className="text-muted mb-1">Orders:</p>
                                                                            <h5 className="mb-0">{product.orders}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-sm-6">
                                                                <div className="p-2 border border-dashed rounded">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="avatar-sm me-2">
                                                                            <div
                                                                                className="avatar-title rounded bg-transparent text-info fs-24">
                                                                                <i className="ri-stack-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <p className="text-muted mb-1">Stocks:</p>
                                                                            <h5 className="mb-0">{product.quantity}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-sm-6">
                                                                <div className="p-2 border border-dashed rounded">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="avatar-sm me-2">
                                                                            <div
                                                                                className="avatar-title rounded bg-transparent text-info fs-24">
                                                                                <i className="ri-inbox-archive-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <p className="text-muted mb-1">Revenue:</p>
                                                                            <h5 className="mb-0">{currency + product.revenue}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            {
                                                                product.variations.map((variation, index) => (
                                                                    <Variation key={index} variation={variation} />
                                                                ))
                                                            }
                                                        </div>

                                                        <div className="product-content mt-5">
                                                            <h5 className="fs-14 mb-3">Product Description:</h5>
                                                            <div className="tab-content border border-top-0 p-4"
                                                                id="nav-tabContent">
                                                                <div>
                                                                    <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                                                </div>
                                                                <div className="mt-5 text-muted">
                                                                    <h5 className="fs-14 fw-bold">Care Information:</h5>
                                                                    <p>{product.inventory.care_information}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mt-5">
                                                            <div>
                                                                <h5 className="fs-14 mb-3">Ratings & Reviews</h5>
                                                            </div>
                                                            <div className="row gy-4 gx-0">
                                                                <div className="col-lg-4">
                                                                    <div>
                                                                        <div className="pb-3">
                                                                            <div className="bg-light px-3 py-2 rounded-2 mb-2">
                                                                                <div className="d-flex align-items-center">
                                                                                    <Stars rate={product.rating.rate} />
                                                                                    <div className="flex-shrink-0">
                                                                                        <h6 className="mb-0">{product.rating.rate} out of 5</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <div className="text-muted">Total <span
                                                                                    className="fw-medium">{product.rating.total_reviews}</span> reviews
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mt-3">
                                                                            {product.rating.ratings_by_rate
                                                                                ? <RatingsByCount ratings={product.rating.ratings_by_rate} />
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-8">
                                                                    <div className="ps-lg-4">
                                                                        <div className="d-flex flex-wrap align-items-start gap-3">
                                                                            <h5 className="fs-14">Reviews: </h5>
                                                                        </div>

                                                                        <SimpleBar id="scrollbar" className="me-lg-n3 pe-lg-4" style={{ maxHeight: "225px" }}>
                                                                            <ul className="list-unstyled mb-0">
                                                                                {reviews ? (
                                                                                    <>
                                                                                        {reviews.map((review, index) => (
                                                                                            <>
                                                                                                <li className="py-2">
                                                                                                    <div
                                                                                                        className="border border-dashed rounded p-3">
                                                                                                        <div
                                                                                                            className="d-flex align-items-start mb-3">
                                                                                                            <div className="hstack gap-3">
                                                                                                                <div
                                                                                                                    className="badge rounded-pill bg-success mb-0">
                                                                                                                    <i className="mdi mdi-star"></i>{Number(review.rate).toFixed(1)}</div>
                                                                                                                <div className="vr"></div>
                                                                                                                <div className="flex-grow-1">
                                                                                                                    <p className="text-muted mb-0">{review.message}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="d-flex align-items-end">
                                                                                                            <div className="flex-grow-1">
                                                                                                                <h5 className="fs-14 mb-0">{review.fullname}
                                                                                                                </h5>
                                                                                                            </div>

                                                                                                            <div className="flex-shrink-0">
                                                                                                                <p className="text-muted fs-13 mb-0">{moment(review.created_date).format('DD MMM, YY')}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </>
                                                                                        ))}
                                                                                    </>
                                                                                ) : null}
                                                                            </ul>
                                                                        </SimpleBar>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </>
                        :
                        <>
                            <div className='mb-2'><Placeholder column="col-9" /></div>
                            <div className='mb-2'><Placeholder column="col-12" /></div>
                            <div className='mb-2'><Placeholder column="col-7" /></div>
                            <div className='mb-2'><Placeholder column="col-10" /></div>
                        </>
                    }
                </div>
            </div>
            <Outlet />
        </>
    );
};


export default ProductDetails;