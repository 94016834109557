import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom';
import ExpenseLogs from './ExpenseLogs';
import ExpenseTypes from './ExpenseTypes';
import { useEffect } from 'react';


const Expenses = () => {

    const [active, setActive] = useState("logs");

    useEffect(() => {
        
    }, [])

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Expense Management</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Expenses</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className='col-lg-12'>
                            <div className='card'>
                                <ul
                                    className="nav nav-tabs nav-tabs-custom nav-primary"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active ExpenseLogs py-2"
                                            data-bs-toggle="tab"
                                            id="ExpenseLogs"
                                            to="#all"
                                            role="tab"
                                            aria-selected="true"
                                            onClick={() => setActive("logs")}
                                        >
                                            Expense Logs
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link py-2 ExpenseTypes"
                                            data-bs-toggle="tab"
                                            id="ExpenseTypes"
                                            to="#published"
                                            role="tab"
                                            aria-selected="false"
                                            onClick={() => setActive('types')}
                                        >
                                            Expense Types
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {active === "logs" ? <ExpenseLogs /> : <ExpenseTypes />}
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default Expenses