import React, { Fragment, useEffect, useState } from 'react'
import ModalBox from '../../Components/ModalBox';
import moment from 'moment'
import { Form, InputGroup } from 'react-bootstrap'
import Flatpickr from "react-flatpickr";
import getSymbolFromCurrency from 'currency-symbol-map';

const ScheduleDelivery = ({ show = false, order, connectedProviders, closeModal, saving, createDeliverySchedule, getDeliveryFee }) => {

    const [delivery, setDelivery] = useState({
        pickup_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        delivery_provider_id: 0,
        note: null,
        rate: null
    })

    useEffect(() => {
        setDelivery({
            ...delivery,
            rate: order?.delivery_details?.provider_rate,
            delivery_provider_id: order?.delivery_details?.delivery_provider_id,
        })
    }, [order]);

    const setScheduleDate = (date) => {
        const pickup_date = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
        setDelivery({ ...delivery, pickup_date });
    }

    const handleChange = (e) => {
        setDelivery({ ...delivery, [e.target.name]: e.target.value });
    }

    const setDeliveryRate = (rate) => {
        setDelivery({ ...delivery, rate });
    }

    return (

        <ModalBox
            show={show}
            handleClose={closeModal}
            title={'Schedule Delivery'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={delivery.rate ?
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-success ${saving ? 'disabled' : ''}`}
                    onClick={() => createDeliverySchedule(delivery)}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Scheduling...
                        </> : 'Schedule Delivery'}
                </button>)
                :
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                    onClick={() => getDeliveryFee(delivery, setDeliveryRate)}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Fetching rate...
                        </> : 'Get Delivery Rate'}
                </button>)
            }
        >
            <div>
                <div className='mb-3'>
                    <Form.Group>
                        <Form.Label htmlFor="sendAt">Pickup Date</Form.Label>
                        <InputGroup>
                            <Flatpickr
                                type="text"
                                id="sendAt"
                                name="pickup_date"
                                options={{ enableTime: true, mode: 'single', minDate: moment().add(1, 'hour').format('YYYY-MM-DD HH:mm:ss') }}
                                value={delivery.pickup_date}
                                className="form-control bg-transparent shadow-0"
                                onChange={setScheduleDate}
                                required={true}
                            />
                            <InputGroup.Text as="label" role="button" htmlFor="sendAt" className="bg-primary border-primary text-white">
                                <i className="ri-calendar-2-line"></i>
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </div>

                <div class="alert alert-primary mt-2" role="alert">
                    <p className='m-0'><strong>Note:</strong> If you're using Taojaa's provider settings, delivery fee will be charged directly from your connected billing meyhod.</p>
                </div>

                <div className='mb-3 form-group'>
                    <label className="form-label m-0"><b>Delivery service provider</b></label>
                    {connectedProviders.length === 0 && <p className='m-0 p-0'><small className='text-danger'>You haven't connect any logistics service provider yet.</small></p>}
                    <select name='delivery_provider_id'
                        className="form-select"
                        disabled={order?.delivery_details?.delivery_provider_id ? true : false}
                        onChange={(e) => handleChange(e)} >
                        <option value={0}>{'Select logistic provider/carrier'}</option>
                        {connectedProviders.map((provider, index) => (
                            delivery?.delivery_provider_id === provider.id
                                ? <option key={index} selected value={provider.id}>{provider.name}</option>
                                : <option key={index} value={provider.id}>{provider.name}</option>
                        ))}
                    </select>
                </div>

                <div className='mb-4'>
                    {delivery.rate &&
                        <Fragment>
                            <h6 className='text-success'>Delivery Fee: {getSymbolFromCurrency(delivery.rate.selected_currency)}{delivery.rate?.amount_due}</h6>
                        </Fragment>}
                </div>

                <div className='send-email-box'>
                    <div className='delivery-note mt-2'>
                        <label className="form-label form-group"><b>Delivery Note </b><small>(optional)</small></label>
                        <textarea className="form-control form-control-solid" rows="3" name="note" value={delivery.note}
                            onChange={(e) => handleChange(e)}></textarea>
                    </div>
                </div>
            </div>
        </ModalBox>
    )
}

export default ScheduleDelivery