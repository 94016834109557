import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import BlankPage from '../../Components/BlankPage';
import axios from 'axios';
import { errorResponse, responseMessage } from '../../libs/app';
import { AuthContext } from '../../Contexts/AuthContext';

const PayStack = () => {

    const { subscription, handleUpgradeModalShow } = useContext(AuthContext);

    const [saving, setSaving] = useState(false);
    const [provider, setProvider] = useState(null);
    const [vendorSettings, setVendorSettings] = useState({
        vendor_id: null,
        name: 'Pay with Paystack',
        type: "online",
        provider_id: 0,
        use_taojaa_settings: true,
        provider_settings: {
            mode: "live",
            test_public_key: null,
            test_secret_key: null,
            live_public_key: null,
            live_secret_key: null
        },
        status: "enabled"
    });

    const getProvider = useCallback(async () => {
        try {
            const response = await axios.get(`/settings/payment/providers/paystack`);
            if (response.data.success) {
                setProvider(response.data.provider);
                setVendorSettings({
                    ...vendorSettings, provider_id: response.data.provider.id
                });
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }, []);

    useEffect(() => {
        getProvider();
        getVendorSettings();
    }, []);


    const getVendorSettings = async () => {
        try {
            const response = await axios.get(`/settings/payment/methods/settings/paystack`);
            if (response.data.success) {
                setVendorSettings(response.data.settings);
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    const saveProviderSettings = async () => {
        try {
            setSaving(true)
            const response = await axios.post(`/settings/payment/methods`, vendorSettings);
            if (response.data.success) {
                responseMessage(response.data.message, 'success');
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        } finally {
            setSaving(false)
        }
    }

    const switchSettings = () => {

        if(vendorSettings.use_taojaa_settings === true) {
            if(subscription.plan.group_name === 'Free') {
                return handleUpgradeModalShow(
                    'Upgrade Plan',
                    'Your current plan does not enable access to use personal provider details, kindly upgrade to use payment provider details.'
                )
            }
        }

        setVendorSettings({
            ...vendorSettings, use_taojaa_settings: !vendorSettings.use_taojaa_settings
        });
    }

    const switchStatus = () => {
        setVendorSettings({
            ...vendorSettings, status: vendorSettings.status === "enabled" ? 'disabled' : 'enabled'
        });
    }

    const switchMode = () => {

        if(vendorSettings.provider_settings.mode === 'live') {
            if(subscription.plan.group_name === 'Free') {
                return handleUpgradeModalShow(
                    'Upgrade Plan',
                    'Oops! Your current plan does not enable access to use test mode, kindly upgrade to use payment provider test mode.'
                )
            }
        }

        setVendorSettings({
            ...vendorSettings, provider_settings: {
                ...vendorSettings.provider_settings, mode: vendorSettings.provider_settings.mode === 'test' ? 'live' : 'test'
            }
        });
    }

    const handleChange = (e) => {
        setVendorSettings({
            ...vendorSettings, name: e.target.value
        });
    }

    const handleProviderSettingsChange = (e) => {
        setVendorSettings({
            ...vendorSettings, provider_settings: {
                ...vendorSettings.provider_settings, [e.target.name]: e.target.value
            }
        });
    }


    return (
        <BlankPage title='Paystack Setup' titleColumSize={12}>
            <div className="col-xxl-9">
                <div className="card card-height-100">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Paystack Provider</h4>
                        <div className="flex-shrink-0">
                            <div className="form-check form-switch form-switch-right form-switch-md">
                                <label htmlFor="default-modal" className="form-label text-muted">{vendorSettings?.provider_settings.mode === "live" ? "Live mode" : "Test mode"}</label>
                                <input className="form-check-input code-switcher" type="checkbox" checked={vendorSettings?.provider_settings.mode === "live"} onChange={switchMode} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className=''>
                            <div className='d-flex gap-3 mb-2'>
                                <img src={provider?.logo} width={'60'} />
                                <p>{provider?.description}</p>
                            </div>
                            <p className="text-white text-end">
                                Add your paystack keys. <a href='https://dashboard.paystack.com/#/login' target='_blank' className='text-decoration-underline'>Visit your paystack dashboard to get details <i className='ri-external-link-line'></i></a>
                            </p>
                        </div>
                        <div className=''>
                            <div className="form-group row mb-3">
                                <label className="col-xl-3 col-lg-3 col-form-label text-right">Payment Name</label>
                                <div className="col-lg-9 col-xl-9">
                                    <input className="form-control form-control-solid" name="name" type="text"
                                        placeholder="Paystack online with Card or Bank" value={vendorSettings.name} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            <div className="form-group row mb-3">
                                <label className="col-xl-3 col-lg-3 col-form-label text-right">Use Taojaa's Paystack Provider Settings</label>
                                <div className="col-lg-9 col-xl-9">
                                    <div className="form-check form-switch form-switch-right form-switch-md">
                                        <label htmlFor="default-modal" className="form-label text-muted">{vendorSettings.use_taojaa_settings ? "Enabled" : "Disabled"}</label>
                                        <input className="form-check-input code-switcher" type="checkbox" checked={vendorSettings.use_taojaa_settings} onChange={switchSettings} />
                                    </div>
                                    <div className=''>
                                        <small>All payments handled by Taojaa will be credited directly into your Taojaa wallet.</small>
                                    </div>
                                </div>
                            </div>

                            {!vendorSettings.use_taojaa_settings &&
                                <Fragment>
                                    {vendorSettings.provider_settings.mode === "test" ?
                                        <Fragment>
                                            <div className="form-group row mb-3">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-right">Test Public Key</label>
                                                <div className="col-lg-9 col-xl-9">
                                                    <input className="form-control form-control-solid" name="test_public_key" type="text"
                                                        placeholder="Paystack test public key" value={vendorSettings.provider_settings.test_public_key} onChange={(e) => handleProviderSettingsChange(e)} />
                                                </div>
                                            </div>
                                            <div className="form-group row mb-3">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-right">Test Secret Key</label>
                                                <div className="col-lg-9 col-xl-9">
                                                    <input className="form-control form-control-solid" name="test_secret_key" type="text"
                                                        placeholder="Paystack test secret key" value={vendorSettings.provider_settings.test_secret_key} onChange={(e) => handleProviderSettingsChange(e)} />
                                                </div>
                                            </div>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <div className="form-group row mb-3">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-right">Live Public Key</label>
                                                <div className="col-lg-9 col-xl-9">
                                                    <input className="form-control form-control-solid" name="live_public_key" type="text"
                                                        placeholder="Paystack live public key" value={vendorSettings.provider_settings.live_public_key} onChange={(e) => handleProviderSettingsChange(e)} />
                                                </div>
                                            </div>
                                            <div className="form-group row mb-3">
                                                <label className="col-xl-3 col-lg-3 col-form-label text-right">Live Secret Key</label>
                                                <div className="col-lg-9 col-xl-9">
                                                    <input className="form-control form-control-solid" name="live_secret_key" type="text"
                                                        placeholder="Paystack live secret key" value={vendorSettings.provider_settings.live_secret_key} onChange={(e) => handleProviderSettingsChange(e)} />
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </Fragment>
                            }

                            <div className="form-group row mb-3 pt-3 border-top">
                                <label className="col-xl-3 col-lg-3 col-form-label text-right">Enable on Checkout</label>
                                <div className="col-lg-9 col-xl-9 d-flex align-items-center">
                                    <div className="form-check form-switch form-switch-right form-switch-md">
                                        <label htmlFor="default-modal" className="form-label text-muted">{vendorSettings.status === "enabled" ? "Enabled" : "Disabled"}</label>
                                        <input className="form-check-input code-switcher" type="checkbox" checked={vendorSettings.status === "enabled"} onChange={switchStatus} />
                                    </div>
                                </div>
                            </div>
                            <div className='text-end'>
                                <button
                                    type="button"
                                    disabled={saving}
                                    className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                                    onClick={() => saveProviderSettings()}>
                                    {saving
                                        ? <Fragment>
                                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> Saving...
                                        </Fragment> : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlankPage>
    )
}

export default PayStack