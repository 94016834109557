import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import '../styles/trialnotice.css'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

const TrialNotice = ({ settings, subscription }) => {

    const [showDetail, setShowDetail] = useState(true);

    const getEndDays = useMemo(() => {

        var current = moment();
        var end_date = moment(subscription.end_date);

        return end_date.diff(current, 'days');
    }, []);

    return (
        <div className={`position-fixed bg-${settings.theme} trial-card p-2 rounded-3`}>
            <div className='position-relative'>
                <button className='trial-close btn btn-sm p-0 position-absolute end-0' onClick={() => setShowDetail(!showDetail)}>
                    {showDetail ? <FiChevronDown size={18} /> : <FiChevronUp size={18} />}
                </button>
                <h6>
                    {getEndDays > 0 ? <>Trial ends in {getEndDays} {getEndDays > 1 ? 'days' : 'day'}</> : <>Your free trial ends today, upgrade now!</>}
                </h6>
                {showDetail && <>
                    <p className='mt-3'>Your 7 days {subscription.plan.group_name} trial ends on <b>{moment(subscription.end_date).format('D MMMM')}</b></p>
                    <p>Upgrade to any plan and get 20% OFF your first subscription.</p>
                    <Link to={'/settings/billing-and-subscription'} className='btn btn-sm d-block fw-bold'>Select a Plan</Link>
                </>}
            </div>
        </div>
    )
}

export default TrialNotice