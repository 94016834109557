import React from 'react';
import { OverlayTrigger } from "react-bootstrap"
import { RenderTooltip } from "../helpers"

const IconWithTooltip = ({ children, message }) => {
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={RenderTooltip(message)}
        >
            {children}
        </OverlayTrigger>
    )
}

export default IconWithTooltip;