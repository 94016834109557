import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import $ from "jquery";

const MyDropzone = ({
	endpoint,
	message,
	size = "big",
	entries = null,
	beforeUpload,
	onUploadResponse,
	onUploadError,
	max_files = 5,
	type 
}) => {

	const [files, setFiles] = useState([]);

	const onDrop = useCallback((acceptedFiles) => {
		var data = new FormData();

		if (entries != null) {
			for (const [key, value] of Object.entries(entries)) {
				data.append(key, value);
			}
		}

		acceptedFiles.forEach((file) => {
			if (max_files > 1) {
				return data.append("images[]", file);
			}
			return data.append("image", file);
		});

		setFiles(acceptedFiles.map(file => Object.assign(file, {
			preview: URL.createObjectURL(file)
		})));

		$.ajax({
			xhr: function () {
				var xhr = new window.XMLHttpRequest();
				xhr.upload.addEventListener(
					"progress",
					function (evt) {
						if (evt.lengthComputable) {
							var percentComplete = (evt.loaded / evt.total) * 100;
							$(".progress-bar").width(Math.floor(percentComplete) + "%");
							$(".progress-bar").html(Math.floor(percentComplete) + "%");
						}
					},
					false
				);
				return xhr;
			},
			type: "POST",
			url: process.env.REACT_APP_API_ENDPOINT + '/api/v1' + endpoint,
			data,
			contentType: false,
			cache: false,
			processData: false,
			beforeSend: beforeUpload,
			error: onUploadError,
			success: function(response) {
				onUploadResponse(response)
				setFiles([]);
			}
		});
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		maxFiles: max_files,
		onDrop,
	});

	const BigImagesPreview = ({ files }) => {
		return (
			<>
				{files.map(file => (
					<div className="d-flex" key={file.name}>
						<div className="d-flex justify-content-center align-items-center" style={{ overflow: 'hidden', height: 300 }}>
							<img
								src={file.preview}
								alt={''}
								width={'100%'}
								className='img-fluid mx-auto'
								onLoad={() => { URL.revokeObjectURL(file.preview) }}
							/>
						</div>
					</div>
				))}
			</>
		)
	}

	const DisplayMessage = ({ size, message }) => {

		return (
			<div className="dz-message text-center needsclick">
				{size === "small" ? (
					<>
						<div className="mb-0">
							<i className="display-6 text-muted ri-upload-cloud-2-fill"></i>
						</div>
						<span className="text-muted" style={{ fontSize: 14 }}>
							{message}
						</span>
					</>
				) : (
					<>
						<div className="">
							<i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
						</div>
						<h6>{message}</h6>
					</>
				)}
			</div>
		)
	}

	return (
		<div
			className={"dropzone d-flex justify-content-center align-items-center " + (size === "small" ? "dropzone-small" : '')}
			{...getRootProps()}
		>
			<div className="fallback">
				<input
					type="file"
					multiple="multiple"
					{...getInputProps()}
				/> 
			</div>
			{files.length > 0 && size === "big" ?<BigImagesPreview files={files} /> : <DisplayMessage message={message} size={size} />}
		</div>
	);
};

export default MyDropzone;
