import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const CreatableInputOnly = ({ id, extractOptions, defaultValues = [] }) => {

  const [inputValue, setInputValue] = useState("");

  const handleChange = (value, actionMeta) => {
    extractOptions(id, value);
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleExtraction = (inputValue) => {
    setInputValue("");

    var exist = defaultValues.find(option => {
      if (option.value === inputValue) {
        return true;
      }
      return false
    });

    if (!exist) {
      extractOptions(id, [...defaultValues, createOption(inputValue)]);
    }
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        handleExtraction(inputValue);
        event.preventDefault();
    }
  };
  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Enter option and press enter..."
      value={defaultValues}
    />
  );
};

export default CreatableInputOnly;
