import axios from "axios";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  errorResponse,
  responseMessage,
  uploadSelectedFiles,
} from "../../libs/app";
import { AuthContext } from "../../Contexts/AuthContext";
import FileUploader from "../../Components/FileUploader";
import ModalBox from "../../Components/ModalBox";
import { saveSettings } from "./Storefront";
import CodeBlock from "../../Components/CopyText/CopyText";

const ApiWebHooks = () => {
  document.title = "Store Details | Taojaa - Store Manager App";

  const { store, subscription } = React.useContext(AuthContext);






  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">API Keys</h4>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-9 mb-4">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Secret key</h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg">
                      <div className="d-flex justify-content-between"></div>
                    
                      <CodeBlock code={store.secret_key} />
                    </div>
                  </div>
             
                </div>
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Access Token</h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg">
                      <div className="d-flex justify-content-between"></div>

                      <CodeBlock code={store.access_token} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default ApiWebHooks;
