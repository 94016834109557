import React from 'react'
import Select from "react-select";
import ModalBox from '../../Components/ModalBox';
import getSymbolFromCurrency from 'currency-symbol-map';

const AddItemForm = ({
    show,
    closeModal,
    product,
    variation,
    price,
    saving,
    quantity,
    order,
    products,
    productOptions,
    variations,
    selectVariation,
    selectedOption,
    setSelectedOption,
    handleChange,
    saveItem
}) => {

    return (
        <ModalBox
            show={show}
            handleClose={closeModal}
            title={'Add Item'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-success ${saving ? 'disabled' : ''}`}
                    onClick={() => saveItem()}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Add Item'}
                </button>)}
        >
            {products ? (
                <div className='p-3'>
                    <div className="mb-4 form-group">
                        <label
                            htmlFor="productname-field"
                            className="form-label"
                        >
                            Product
                        </label>
                        <Select
                            isMulti={false}
                            isClearable={true}
                            isDisabled={false}
                            isLoading={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            options={productOptions}
                            value={selectedOption}
                            onChange={setSelectedOption}
                        >
                            Select Product
                        </Select>
                    </div>

                    {variations.length ? (
                        <div className="mb-3">
                            <label className="form-label"><b>Select Variation</b></label>
                            <div className="form-group">
                                {variations.map((option, index) => (
                                    <button
                                        key={index} className={`btn mb-1 me-1 btn-sm rounded-pill ${variation && variation.id === option.id ? 'btn-primary' : 'btn-light'}`}
                                        onClick={() => selectVariation(option)}>
                                        {option.variant}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    <div className='mb-3 form-group'>
                        <label className="form-label"><b>Quantity</b></label>
                        <p className="text-primary">
                            {variation ? variation.quantity + ' available in stock.'
                                : (product) ? product.quantity + ' available in stock.' : null}
                        </p>
                        <input type="number" placeholder="quantity" name='quantity'
                            min={1} max={`${variation ? variation.quantity : (selectedOption ? selectedOption.quantity : 1)}`}
                            className="form-control" value={quantity} onChange={(e) => handleChange(e)} />
                    </div>

                    <div className='mb-3'>
                        <label className="form-label form-group"><b>Price</b></label>
                        <div className='input-group'>
                            <span className='input-group-text'>{getSymbolFromCurrency(order?.currency)}</span>
                            <div className=''>
                                <input type="text" placeholder="price" name='price'
                                    className="form-control" value={price} onChange={(e) => handleChange(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </ModalBox>
    )
}

export default AddItemForm