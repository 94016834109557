import React, { useEffect, useRef } from "react";
import { AdvancedVideo } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import axios from "axios";
// Import required actions and qualifiers.
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { AppContext } from "../Contexts/AppContext";

import { toast } from "react-toastify";



const UploadWidget = () => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  const { video, setVideo, setVideoUrl } = React.useContext(AppContext);
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;

    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dp7quxpsq",
        uploadPreset: "f9jiv7he",
        sources: ["local", "url", "camera"], // Specify the sources you want to use
        apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
        resourceType: "video",
        folder: "videos"
      },
      (error, result) => {
        if (error) {
          console.error(error);
        } else {
          if (result && result.info && result.info.files) {
            let newVideo = result.info.files[0].uploadInfo;
            setVideo(newVideo.public_id);
            setVideoUrl(newVideo.secure_url);
          }
        }
      }
    );
  }, [video]);

  const openWidget = () => {
    if (widgetRef.current) {
      widgetRef.current.open();
    }
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dp7quxpsq",
    },
  });

  const deleteVideo = () => {
    const payload = {
      publicId: video
    }
    axios
      .post(`/remove-video`, payload)
      .then((response) => {
        console.log(response)
        if (response?.data?.success) {
          toast.success(response?.data?.message)
          setVideo("")
          setVideoUrl("")
        }
      })
      .catch((error) => {
        toast.error(error.message)
        console.log(error)
      });
  };


  const RenderVideo = (publicId) => {
    const video = cld.video(publicId);
    // Apply the transformation.
    video
      .resize(fill().width(300).height(200)) // Crop the video, focusing on the faces.
      .roundCorners(byRadius(20)); // Round the corners.

    return (
      <div className="d-flex flex-column">
        <div>
          <AdvancedVideo
            cldVid={video}
            controls
            cldPoster="auto"
            playsInline
            loop
          />
        </div>
        <div onClick={() => deleteVideo()} className="text-danger">
          <i className="ri-delete-bin-5-fill fs-16 link"></i>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="">
        {video ? (
          <div>{RenderVideo(video)}</div>
        ) : (
          <button
            className="dropzone upload_video fs-6 d-flex flex-column justify-content-center align-items-center w-100 "
            type="button"
            onClick={openWidget}
          >
            <div className="mb-0">
              <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
            </div>
            Select Video
          </button>
        )}
      </div>
    </div>
  );
};

export default UploadWidget;
