import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import {
  Navigate,
  Outlet,
  Link,
} from "react-router-dom";
import CreateAbleSelect from "react-select/creatable";
import { AuthContext } from "../../Contexts/AuthContext";
import Flatpickr from "react-flatpickr";
import { errorResponse, responseMessage } from "../../libs/app";
import { AppContext } from "../../Contexts/AppContext";
import Pagination from "../../Components/Pagination";
import Loader from "../../Components/Loader/Loader";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import DeleteModal from "../../Components/DeleteModal";
import ModalBox from "../../Components/ModalBox";
import OrderTabs from "./OrderTabs";
import { addFiltersToEndpoint, dateChange } from "../../helpers";
import OrderTableItem from "./OrderTableItem";
import CustomerForm from "../Customers/CustomerForm";
import ProductFormModal from "../Products/ProductFormModal";

export const OrderStatus = ({ status }) => {

  if (status === 0) {
    return <span className="badge badge-soft-danger text-uppercase">Cancelled</span>
  } else if (status === 1) {
    return <span className="badge badge-soft-warning text-uppercase">Pending</span>
  } else if (status === 2) {
    return <span className="badge badge-soft-primary text-uppercase">Confirmed</span>
  } else if (status === 3) {
    return <span className="badge badge-soft-success text-uppercase">Processed</span>
  } else if (status === 4) {
    return <span className="badge bg-primary text-uppercase">Shipped</span>
  } else if (status === 5) {
    return <span className="badge bg-success text-uppercase">Delivered</span>
  } else if (status === 6) {
    return <span className="badge badge-dark text-uppercase">Returned</span>
  }
}

export const OrderPaymentStatus = ({ status }) => {

  if (status === 111) {
    return <span className="badge bg-success text-uppercase">Paid</span>
  } else if (status === 316) {
    return <span className="badge badge-soft-warning text-uppercase">Pending</span>
  } else if (status === 901) {
    return <span className="badge badge-soft-danger text-uppercase">Failed</span>
  }
}

const Orders = () => {

  document.title = 'Sales & Orders | Taojaa - Business Management App'

  const { paymentMethods, paymentStatuses, orderStatus, salesChannel } = useContext(AppContext);
  const { wallets, store, subscription, recordCounts, handleUpgradeModalShow } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [productModal, setProductModal] = useState(false);

  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    name: '',
    price: 0,
    cost_price: 0,
    availability: 1
  });
  const [selectedOption, setSelectedOption] = useState([]);
  const [data, setData] = useState({
    customer_id: "",
    vendor_id: "",
    products: "",
    order_date: "",
    shipping_fee: "",
    payment_method: "",
    payment_status: "",
    status: "",
    channel: "",
    quantities: "",
    variant_ids: "",
    coupon: "",
    currency: store.wallet.currency.abbr
  });
  const [order, setOrder] = useState(null);
  const [customer, setcustomer] = useState({
    name: "",
    email: "",
    phone: "",
    created_date: "",
    country_id: 140,
    status: 1,
  });
  const [customers, setCustomers] = useState([]);
  const [selectedForDelete, setSelectedForDelete] = useState(null);

  const [recordsCount, setRecordsCount] = useState({
    cancelledCounts: 0,
    confirmedCounts: 0,
    deliveredCounts: 0,
    packedCounts: 0,
    pendingCounts: 0,
    returnedCounts: 0,
    shippedCounts: 0
  });


  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [pagination, setPagination] = useState(null);
  const [filters, setFilters] = useState({
    status: "",
    channel: "",
    payment_method: 0,
    keywords: "",
    start_date: null,
    end_date: null,
  });

  const productOptions =
    products.length > 0 &&
    products.map(({ id, name, price, variation_options }) => {
      return { value: id, label: name, quantity: 1, price: price, variants: variation_options };
    });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const applyFilter = () => {
    getOrders(filters);
  };

  const resetFilter = () => {
    setFilters({
      status: "",
      channel: "",
      payment_method: 0,
      keywords: "",
      start_date: null,
      end_date: null,
    });
    getOrders();
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 2000);
  }, [copied])

  useEffect(() => {
    getOrders();
  }, [currentPage])

  useEffect(() => {

    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getProducts();
    getCustomers();
  }, [store, subscription]);

  if (order !== null) {
    return <Navigate to={`/order/${order.id}`} />;
  }

  async function getCustomers() {
    try {
      const response = await axios.get(`/customers`);
      if (response.data.success) {
        setCustomers(response.data.customers);
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
    }
  }

  async function getProducts() {
    try {

      const response = await axios.get(`/products`);
      if (response.data.success) {
        setProducts(response.data.data);
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
    }
  }

  async function getOrders(filters = null) {

    setLoading(true)

    let endpoint = `/orders?page=${currentPage}&perpage=${perPage}`;

    if (filters) {
      endpoint = addFiltersToEndpoint(filters, endpoint)
    }

    try {
      const response = await axios.get(endpoint);

      if (response.data.success) {
        setOrders(response.data.data);
        setPagination(response.data.pagination)
        setRecordsCount(response.data.recordsCount)
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setLoading(false)
  }

  async function deleteOrder() {
    try {
      if (selectedForDelete !== null) {
        const response = await axios.delete(`/order/delete/${selectedForDelete}`);

        if (response.data.success) {
          responseMessage(response.data.message, "success");
          getOrders();
        }
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    closeDeleteModal()
  }

  async function handleSubmit(e) {

    if (store.store_type === 'live' && subscription?.settings.bookeepingAndRecords !== 'unlimited') {
      if ((Number(recordCounts.orders) + 1) > subscription.settings.bookeepingAndRecords) {
        return handleUpgradeModalShow(
          'Upgrade to Professional Plan',
          'Oops! You have reached the maxiumun number of records allowed for your current plan, kindly upgrade now to add more records.'
        )
      }
    }

    // Validated selected options
    var error = validateSelectecOptions()
    if (error) {
      return responseMessage(`Select an option for ${error.label}`, 'error');
    }

    setSaving(true)

    e.preventDefault();
    const order = {
      customer_id: parseInt(data.customer_id),
      vendor_id: store.id,
      products: selectedOption,
      order_date: data.order_date,
      shipping_fee: parseInt(data.shipping_fee).toFixed(2),
      payment_method: data.payment_method,
      status: parseInt(data.status),
      quantities: data.quantities,
      variant_ids: "",
      coupon: data.coupon,
      channel: data.channel,
      currency: data.currency,
      payment_status: data.payment_status
    };

    axios
      .post("/order/create", order)
      .then((res) => {
        if (res) {

          closeModal()
          responseMessage(res.data.message, "success");
          setOrder({ ...res.data.order });
        }

        setSaving(false)
      })
      .catch((error) => {
        if (error.response)
          errorResponse(error.response);
        else
          responseMessage("Something went wrong.", "error");

        setSaving(false)
      });
  }

  function validateSelectecOptions() {

    const error = selectedOption.find((option) => {
      if (option.variants.length > 0) {
        if (!option.selected_variant) {
          return true
        }
      }

      return false;
    })

    return error;
  }

  function handleProductChange(e) {
    setProduct({ ...product, [e.target.name]: e.target.value });
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setData({
      ...data,
      [evt.target.name]: value,
    });
  }

  const handleTabClick = (status) => {

    var tabfilter = { ...filters, status };
    delete tabfilter.start_date;
    delete tabfilter.end_date;

    if (status !== null) {
      return getOrders(tabfilter)
    }

    getOrders();
  };

  const handleCreateProduct = (value) => {
    setProduct({ ...product, name: value });
    toggleProductModal(true);
  }

  const toggleCustomerModal = (status = true) => {
    setCustomerModal(status);
    if (status === false) {
      setcustomer({
        name: "",
        email: "",
        phone: "",
        created_date: "",
        country_id: 140,
        status: 1,
      });
    }
  }

  const toggleProductModal = (status) => {
    setProductModal(status);
    if (status === false) {
      setProduct({
        name: "",
        price: 0,
        cost_price: 0,
        availability: 1
      });
    }
  }

  const openModal = () => {

    if (subscription?.settings.allowManualOrderRecording === false) {
      return handleUpgradeModalShow(
        'Upgrade Your Plan',
        'Oops! Your current plan does not enable access to record offline sales, kindly upgrade to record offline sales.'
      )
    }

    setModal(true);
  }
  const closeModal = () => {
    setModal(false);

    setData({
      customer_id: "",
      vendor_id: "",
      products: "",
      order_date: "",
      shipping_fee: 0.00,
      payment_method: "",
      status: "",
      channel: "",
      quantities: "",
      variant_ids: "",
      coupon: "",
    })
  }

  const closeDeleteModal = () => {
    setSelectedForDelete(null)
  }


  const increaseQuantity = (index) => {
    var target = selectedOption[index];
    target.quantity += 1;

    if (target.selected_variant) {
      const variant = target.variants.find((v) => v.id === target.selected_variant)
      if (variant?.quantity < target.quantity) {
        return responseMessage('Quantity not available', 'info');
      }
    }

    updateOptionQuantity(target, index);
  }

  const decreaseQuantity = (index) => {
    var target = selectedOption[index];
    target.quantity -= 1;
    if (target.quantity < 1) {
      target.quantity = 1;
    }

    updateOptionQuantity(target, index)
  }

  const setOptionPrice = (value, index) => {
    var target = selectedOption[index];
    target.price = value;

    var newOptionList = [...selectedOption];
    newOptionList[index] = target;

    setSelectedOption(newOptionList);
  }

  const setTargetVariant = (value, index) => {
    var target = selectedOption[index];

    const variant = target.variants.find((v) => v.id === Number(value))
    if (variant) {
      if (variant.quantity < 1) {
        return responseMessage('Item is out of stock', 'info');
      }
    }

    target['selected_variant'] = Number(value);
  }

  const updateOptionQuantity = (target, index) => {
    var newOptionList = [...selectedOption];
    newOptionList[index] = target;
    setSelectedOption(newOptionList)
  }


  const customerFormProps = {
    modal: customerModal,
    saving,
    customer,
    setSaving,
    closeModal: () => toggleCustomerModal(false),
    setcustomer,
    getCustomers,
  }

  const productModalProps = {
    product,
    productModal,
    saving,
    setSaving,
    handleProductChange,
    getProducts,
    toggleProductModal,
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Sales & Orders</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Sales & Orders</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="orderList">
                <div className="card-header  border-0">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="btn btn-success add-btn me-1"
                        onClick={openModal}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>
                        Create Order/Record Sales
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-7 col-sm-6">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            name="keywords"
                            placeholder="Search for order ID, customer, order status or something..."
                            value={filters.keywords}
                            onChange={handleFilterChange}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>

                      <div className="col-xxl-5 col-sm-6">
                        <Flatpickr
                          className="form-control"
                          id="dash-filter-picker"
                          placeholder="start date to end date"
                          options={{ mode: "range" }}
                          defaultValue={(filters.start_date && filters.end_date) ? filters.start_date + " to " + filters.end_date : ''}
                          onChange={(selectedDates) => dateChange(selectedDates, filters, setFilters)}
                        />
                      </div>

                      <div className="col-xxl-2 col-sm-3">
                        <div>
                          <select
                            className="form-control"
                            data-choices
                            data-choices-search-false
                            name="channel"
                            id="idStatus"
                            value={filters.channel}
                            onChange={handleFilterChange}
                          >
                            <option defaultValue="" value={""} defaultChecked>
                              Select Channel
                            </option>
                            {salesChannel.length > 0 &&
                              salesChannel.map(({ id, name }) => {
                                return (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="col-xxl-2 col-sm-3">
                        <div>
                          <select
                            className="form-control"
                            data-choices
                            data-choices-search-false
                            name="status"
                            id="idStatus"
                            value={filters.status}
                            onChange={handleFilterChange}
                          >
                            <option defaultValue="" value={""} defaultChecked>
                              Select Status
                            </option>
                            {orderStatus.length > 0 &&
                              orderStatus.map(({ id, name }) => {
                                return (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="col-xxl-2 col-sm-3">
                        <div>
                          <select
                            className="form-control"
                            data-choices
                            data-choices-search-false
                            name="payment_method"
                            id="idPayment"
                            value={filters.payment_method}
                            onChange={handleFilterChange}
                          >
                            <option defaultValue="" value={0} defaultChecked>
                              Select Payment
                            </option>
                            {paymentMethods.length > 0 &&
                              paymentMethods.map(({ id, name }) => {
                                return (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="col-xxl-2 col-sm-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary w-100 filter-btn"
                            onClick={applyFilter}
                          >
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Filter
                          </button>
                        </div>
                      </div>

                      <div className="col-xxl-2 col-sm-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-danger w-100 filter-btn"
                            onClick={resetFilter}
                          >
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-body pt-0">
                  <div>

                    <OrderTabs handleTabClick={handleTabClick} recordsCount={recordsCount} />

                    <div className="table-responsive table-card mb-1">
                      {orders.length > 0 ? (
                        <table
                          className="table table-nowrap align-middle"
                          id="orderTable"
                        >
                          <thead className="text-muted table-light">
                            <tr className="text-uppercase">
                              <th scope="col" style={{ width: "25px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th className="sort" data-sort="id">Order ID</th>
                              <th className="sort" data-sort="customer_name">Customer</th>
                              <th className="sort" data-sort="product">Product</th>
                              <th className="sort" data-sort="sub_total_amount">Amount</th>
                              <th className="sort" data-sort="amount">Shipping Fee</th>
                              <th className="sort" data-sort="payment_status">Payment Status</th>
                              <th className="sort" data-sort="date">Order Date</th>
                              <th className="sort" data-sort="channel">Sales Channel</th>
                              <th className="sort" data-sort="status">Status</th>
                              <th className="sort" data-sort="action">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {orders.map((order, index) => (
                              <OrderTableItem
                                key={index}
                                index={index}
                                order={order}
                                store={store}
                                copied={copied}
                                setCopied={setCopied}
                                setSelectedForDelete={setSelectedForDelete}
                              />
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {loading
                            ? <div className="py-5"><Loader message={<p className="text-muted">Fetching sales/order records...</p>} /></div>
                            : <SearchResponse title={"No Order Available"} message={"You don't have any order yet"} />
                          }
                        </>
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Pagination pagination={pagination} onPageChange={onPageChange} />
                      </div>
                    </div>
                  </div>

                </div>

                <ModalBox
                  show={modal}
                  handleClose={closeModal}
                  title={'Create Order/Record Sales'}
                  closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                  saveBtn={
                    (<button
                      type="button"
                      disabled={saving || (customerModal || productModal)}
                      className={`btn btn-success ${saving ? 'disabled' : ''}`}
                      onClick={(e) => handleSubmit(e)}
                    >
                      {saving && (!customerModal && !productModal)
                        ? <>
                          <div className="spinner-border spinner-border-sm text-white" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div> Saving...
                        </> : 'Save and Continue'}
                    </button>)}
                >
                  <form>
                    <input type="hidden" id="id-field" />

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label
                          htmlFor="currency-field"
                          className="form-label"
                        >
                          Currency
                        </label>
                      </div>
                      <select
                        className="form-control"
                        name="currency"
                        onChange={handleChange}
                        value={data.currency}
                      >
                        <option defaultChecked>Select currency</option>
                        {wallets.map((wallet, index) =>
                          <option key={index} value={wallet.currency.abbr} selected={data.currency === wallet.currency.abbr}>{wallet.currency.name} ({wallet.currency.abbr}) </option>
                        )}
                      </select>
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label
                          htmlFor="customername-field"
                          className="form-label"
                        >
                          Customer
                        </label>
                        <Link to={"#"} className="text-primary" onClick={() => toggleCustomerModal(true)}>+ Add New Customer</Link>
                      </div>
                      <select
                        className="form-control"
                        name="customer_id"
                        onChange={handleChange}
                        value={data.customer_id}
                      >
                        <option defaultChecked>
                          Select customer
                        </option>
                        {customers.length > 0 &&
                          customers.map(({ id, name, email }) => {
                            return (
                              <option key={id} value={id}>
                                {name} - {email}
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label
                          htmlFor="productname-field"
                          className="form-label"
                        >
                          Select Items
                        </label>
                        <Link to={"#"} className="text-primary" onClick={() => toggleProductModal(true)}>+ Add New Product</Link>
                      </div>
                      <CreateAbleSelect
                        isMulti
                        isLoading={false}
                        isClearable={true}
                        isDisabled={false}
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        value={selectedOption}
                        options={productOptions}
                        onChange={setSelectedOption}
                        onCreateOption={handleCreateProduct}
                      />

                      {selectedOption.length > 0 &&
                        <div className="col-md-12 border-top pt-1 mt-2">
                          {selectedOption.map((option, index) =>
                            <div className="col-md-12 pb-1 mb-1 border-bottom">
                              <div className="row align-item-center">
                                <div className="col-5">
                                  <h6 className="m-0">{option.label}</h6>
                                  {/* Show Available variants */}
                                  {option.variants.length > 0
                                    &&
                                    <select name="variant" style={{ height: 25, padding: '0px 5px' }} className="form-control" onChange={(e) => setTargetVariant(e.target.value, index)}>
                                      <option defaultChecked value={''}>Select variant</option>
                                      {option.variants.map((variant, i) => <option key={i} value={variant.id}>{variant.variant}</option>)}
                                    </select>
                                  }
                                </div>
                                <div className="col-3">
                                  <input type="number" name="price" className="form-control" value={option.price} onChange={(e) => setOptionPrice(e.target.value, index)} />
                                </div>
                                <div className="col-4 text-end">
                                  <div class="input-step">
                                    <button type="button" class="minus" onClick={() => decreaseQuantity(index)}>–</button>
                                    <input type="number" class="product-quantity" value={option.quantity} min="1" />
                                    <button type="button" class="plus" onClick={() => increaseQuantity(index)}>+</button>
                                  </div>
                                </div>
                              </div>
                            </div>)}
                        </div>}
                    </div>

                    <div className="row gy-4 mb-3">
                      <div className="col-md-6">
                        <div>
                          <label
                            htmlFor="date-field"
                            className="form-label"
                          >
                            Order Date
                          </label>
                          <input
                            type="date"
                            id="date-field"
                            className="form-control"
                            placeholder="Select date"
                            name="order_date"
                            value={data.order_date}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label
                            htmlFor="payment-field"
                            className="form-label"
                          >
                            Payment Method
                          </label>
                          <select
                            className="form-control"
                            name="payment_method"
                            value={data.payment_method}
                            onChange={handleChange}
                            id="payment-field"
                          >
                            <option defaultValue="">
                              Payment Method
                            </option>
                            {paymentMethods.length > 0 &&
                              paymentMethods.map(({ id, name }) => {
                                return (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row gy-4 mb-3">
                      <div className="col-md-6">
                        <div>
                          <label
                            htmlFor="amount-field"
                            className="form-label"
                          >
                            Shipping Fee
                          </label>
                          <input
                            type="number"
                            min={1}
                            id="amount-field"
                            className="form-control"
                            placeholder="Shipping fee"
                            name="shipping_fee"
                            value={data.shipping_fee}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label
                            htmlFor="payment-field"
                            className="form-label"
                          >
                            Payment Status
                          </label>
                          <select
                            className="form-control"
                            name="payment_status"
                            value={data.payment_status}
                            onChange={handleChange}
                            id="payment-field"
                          >
                            <option defaultValue="">
                              Payment Status
                            </option>
                            {paymentStatuses.length > 0 &&
                              paymentStatuses.map(({ id, name }) => {
                                return (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row gy-4 mb-3">
                      <div className="col-md-6">
                        <label
                          htmlFor="delivered-status"
                          className="form-label"
                        >
                          Order Status
                        </label>
                        <select
                          className="form-control"
                          name="status"
                          id="delivered-status"
                          value={data.status}
                          onChange={handleChange}
                        >
                          <option defaultChecked>Select status</option>
                          {orderStatus.length > 0 &&
                            orderStatus.map(({ id, name }) => {
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="delivered-status"
                          className="form-label"
                        >
                          Sales Channel
                        </label>
                        <select
                          className="form-control"
                          name="channel"
                          id="sales-channel"
                          value={data.channel}
                          onChange={handleChange}
                        >
                          <option defaultChecked>Select Channel</option>
                          {salesChannel.length > 0 &&
                            salesChannel.map(({ id, name }) => {
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div>
                        <label
                          htmlFor="amount-field"
                          className="form-label"
                        >
                          Coupon (letters and numbers only, no
                          punctuation or special characters)
                        </label>
                        <input
                          type="text"
                          id="amount-field"
                          className="form-control"
                          placeholder="Coupon"
                          name="coupon"
                          value={data.coupon}
                          pattern="[A-Za-z0-9]+"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>
                </ModalBox>

                <CustomerForm {...customerFormProps} />

                <ProductFormModal {...productModalProps} />

                <DeleteModal
                  show={selectedForDelete ? true : false}
                  title={'Delete confirmation'}
                  message={'Are you sure you want to delete this order?'}
                  handleDelete={deleteOrder}
                  handleClose={closeDeleteModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Orders;
