import axios from 'axios'
import React from 'react'
import { errorResponse, responseMessage } from '../../libs/app';
import { useState } from 'react';
import ModalBox from '../../Components/ModalBox';

const SendInvoiceModal = ({ show, closeModal, invoice_id }) => {

    const [sending, setSending] = useState(false)

    async function sendInvoice() {

        try {
            setSending(true);

            const response = await axios.post(`/invoice/send/`, { invoice_id });
            if (response.data.success) {
                responseMessage(response.data.message, 'success');
                closeModal()
            }

        } catch (error) {
            if (error.response) {
                errorResponse(error.response)
            } else {
                responseMessage("Something went wrong.", "error");
            }
        }

        setSending(false);
    }

    return (
        <ModalBox
            show={show}
            centered
            className={"zoomIn text-center"}
            footerClass={'justify-content-center'}
            handleClose={closeModal}
            closeBtn={<button
                className="btn btn-link link-success fw-medium text-decoration-none"
                onClick={closeModal}
            >
                <i className="ri-close-line me-1 align-middle"></i>
                Close
            </button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={sending}
                    className={`btn btn-success ${sending ? 'disabled' : ''}`}
                    onClick={() => sendInvoice()}
                >
                    {sending ? <>
                        <div className="spinner-border spinner-border-sm text-white" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> Sending...
                    </> : <><i className="ri-send-plane-fill align-bottom me-1"></i> Yes, Send It</>
                    }
                </button>)}
        >
            <lord-icon src="https://cdn.lordicon.com/pithnlch.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}></lord-icon>
            <div className="mt-4 text-center">
                <h4>You are about to send an invoice</h4>
                <p className="text-muted fs-15 mb-4">
                    Click the send button below to confirm you want to send this invoice. <br />
                    Kindly note that invoice will no longer be valid if edited.
                </p>
            </div>
        </ModalBox>
    )
}

export default SendInvoiceModal