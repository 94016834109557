import axios from 'axios';
import React from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { errorResponse, responseMessage } from '../libs/app';

const ImagePreview = ({ image, query = null, onRemove = null }) => {


    const removeHandler = async () => {
        try {
            var endpoint = `/settings/storefront/remove/banner?image=${image}`;
            if(query !== null) {
                Object.keys(query).map(key => {
                    if (query[key]) {
                        return endpoint += `&${key}=${query[key]}`;
                    }
                    return null;
                })
            }

            const response = await axios.delete(endpoint);
            if (response.data.success) {
                responseMessage(response.data.message, "success");

                if (onRemove !== undefined) {
                    onRemove(response.data)
                }
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    return (
        <div className="bg-light rounded img-display">
            <div className="img-display__overlay">
                <span
                    className="img-display__overlay__remove-btn"
                    onClick={removeHandler}
                >
                    <FiTrash2 size={15} />
                </span>
            </div>
            <img
                className="img-fluid rounded d-block"
                src={image}
                alt="Product-Image"
            />
        </div>
    )
}

export default ImagePreview