import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom"
import { AuthContext } from "../../Contexts/AuthContext";
import Flatpickr from "react-flatpickr";
import { addFiltersToEndpoint, dateChange } from "../../helpers";
import axios from "axios";
import { errorResponse, responseMessage } from "../../libs/app";
import Loader from "../../Components/Loader/Loader";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import IconWithTooltip from "../../Components/IconWithTooltip";
import DeleteModal from "../../Components/DeleteModal";
import { Button, Form, Pagination } from "react-bootstrap";
import ModalBox from "../../Components/ModalBox";

const SubscriberAction = ({ subscriber, signal, editAction }) => {

    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    const deleteSubscriber = () => {
        setDeleteConfirmation(true)
    }

    const confirmDelete = () => {
        axios
            .delete(`/subscribers/delete/${subscriber.id}`)
            .then((response) => {
                if (response.data.success) {
                    signal(true);
                    closeDeleteModal();
                    responseMessage(response.data.message, "success");
                }
            })
            .catch((error) => {
                if (error.response) errorResponse(error.response);
                else responseMessage("Something went wrong.", "error");
            });
    };

    const closeDeleteModal = () => {
        setDeleteConfirmation(false);
    };

    const removeItem = () => {
        return (
            <DeleteModal
                show={deleteConfirmation}
                title={"Delete confirmation"}
                message={"Are you sure you want to delete this subscriber?"}
                handleDelete={confirmDelete}
                handleClose={closeDeleteModal}
            />
        );
    }

    return (
        <>
            {deleteConfirmation ? removeItem() : null}
            <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item edit" title="Edit">
                    <IconWithTooltip message={"Edit Subscriber"}>
                        <Button
                            variant="link"
                            className="text-primary d-inline-block edit-item-btn"
                            onClick={() => editAction(subscriber)}
                        >
                            <i className="ri-pencil-fill fs-16"></i>
                        </Button>
                    </IconWithTooltip>
                </li>
                <li className="list-inline-item" title="Remove">
                    <IconWithTooltip message={"Delete Subscriber"}>
                        <Link
                            className="text-danger d-inline-block remove-item-btn"
                            onClick={() => deleteSubscriber()}
                        >
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Link>
                    </IconWithTooltip>
                </li>
            </ul>
        </>
    );
}


const Subscribers = () => {
    document.title = "Marketing - Subscribers | Taojaa - Store Manager App";
    const initialFilters = {
        status: "",
        keywords: "",
        start_date: null,
        end_date: null,
    };

    const initialSubscriber = {
        name: "",
        email: "",
        phone: "",
        status: null
    }

    const { getSubscription } = useContext(AuthContext);


    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState(initialFilters);

    const [perPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);

    const [pagination, setPagination] = useState(null);

    const [subscribers, setSubscribers] = useState([]);

    const [subscriber, setSubscriber] = useState(initialSubscriber);

    const [refresh, setRefresh] = useState(false);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevState) => ({ ...prevState, [name]: value }));
    };

    const applyFilter = () => {
        getSubscribers(filters);
    };

    const resetFilter = () => {
        setFilters(initialFilters);
        getSubscribers();
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const [modal, setModal] = useState(false);
    const [busy, setBusy] = useState(false);

    const closeModal = () => {
        setModal(false);
    }

    const createNewSubscriber = () => {
        setModal(true);
        setSubscriber(initialSubscriber);
    }


    const editSubscriber = data => {
        setModal(true);
        setSubscriber(data);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSubscriber({ ...subscriber, [name]: value });
    }


    async function handleSubmit(e) {

        e.preventDefault();
        try {
            setBusy(true);
            let response;
            if (subscriber.id !== undefined) {
                response = await axios.put(`/subscribers/update/${subscriber.id}`, subscriber);
            } else {
                response = await axios.post('/subscribers/create', subscriber);
            }

            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getSubscribers();

                closeModal()
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        } finally {
            setBusy(false);
        }
    }

    async function getSubscribers(filters = null) {
        setLoading(true);

        let endpoint = `subscribers?page=${currentPage}&perpage=${perPage}`;

        if (filters) {
            endpoint = addFiltersToEndpoint(filters, endpoint);
        }

        try {
            const response = await axios.get(endpoint);

            if (response.data.success) {
                setSubscribers(response.data.data);
                setPagination(response.data.pagination);
                getSubscription();
            }

        } catch (error) {
            if (error.response) errorResponse(error.response);
            else responseMessage("Something went wrong.", "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSubscribers();
        
    }, [refresh]);

    return (
        <>
            <section className="page-content">
                <div className="container-fluid">
                    <header className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Marketing - Subscribers</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item">Marketing</li>
                                        <li className="breadcrumb-item active">Subscribers</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div>

                                <div className="card">
                                    <header className="card-header  border-0">
                                        <div className="d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">
                                                All Subscribers
                                            </h5>
                                            <div className="flex-shrink-0">
                                                <Button
                                                    variant="success"
                                                    className="add-btn me-1"
                                                    onClick={createNewSubscriber}
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i>
                                                    New Subscriber
                                                </Button>
                                            </div>
                                        </div>
                                    </header>

                                    <div className="card-body border border-dashed border-end-0 border-start-0">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-xxl-6 col-sm-6">
                                                    <div className="search-box">
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            name="keywords"
                                                            placeholder="Search for name, email, phone number, or something..."
                                                            onChange={handleFilterChange}
                                                        />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-6 col-sm-6">
                                                    <Flatpickr
                                                        className="form-control"
                                                        id="dash-filter-picker"
                                                        placeholder="start date to end date"
                                                        options={{ mode: "range" }}
                                                        defaultValue={
                                                            filters.start_date && filters.end_date !== null
                                                                ? filters.start_date + " to " + filters.end_date
                                                                : ""
                                                        }
                                                        onChange={(selectedDates) =>
                                                            dateChange(selectedDates, filters, setFilters)
                                                        }
                                                    />
                                                </div>

                                                <div className="col-xxl-2 col-sm-4">
                                                    <div>
                                                        <select
                                                            className="form-control"
                                                            name="status"
                                                            id="idStatus"
                                                            onChange={handleFilterChange}
                                                        >
                                                            <option defaultValue="">Select Status</option>
                                                            <option value="all">All</option>
                                                            <option value="subscribed">Subscribed</option>
                                                            <option value="unsubscribed">Unsubscribed</option>=
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-2 col-sm-4">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary w-100 filter-brn"
                                                            onClick={applyFilter}
                                                        >
                                                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                                            Filter
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-sm-4">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger w-100 filter-btn"
                                                            onClick={resetFilter}
                                                        >
                                                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                    <div className="card-body">
                                        <div>
                                            <div className="table-responsive table-card mb-1">
                                                {subscribers.length > 0 ?
                                                    <>
                                                        <table
                                                            className="table table-nowrap align-middle"
                                                            id="subscribersTable"
                                                        >
                                                            <thead className="text-muted table-light">
                                                                <tr className="text-uppercase">
                                                                    <th scope="col" style={{ width: "25px" }}>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="checkAll"
                                                                                value="option"
                                                                            />
                                                                        </div>
                                                                    </th>
                                                                    <th className="sort" data-sort="id">
                                                                        ID
                                                                    </th>
                                                                    <th
                                                                        className="sort"
                                                                        data-sort="name"
                                                                    >
                                                                        Name
                                                                    </th>
                                                                    <th className="sort" data-sort="email">
                                                                        Email
                                                                    </th>
                                                                    <th className="sort" data-sort="phone">
                                                                        Phone
                                                                    </th>
                                                                    <th className="sort" data-sort="status">
                                                                        Status
                                                                    </th>
                                                                    <th className="sort" data-sort="action">
                                                                        Actions
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="list form-check-all">
                                                                {
                                                                    subscribers.map((subscriber, index) => (
                                                                        <tr key={index}>
                                                                            <th scope="row">
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        name="checkAll"
                                                                                        value="option1"
                                                                                    />
                                                                                </div>
                                                                            </th>
                                                                            <td className="id">
                                                                                <Link
                                                                                    to={`subscribers/${subscriber.id}/details`}
                                                                                    className="fw-semibold link-dark"
                                                                                >
                                                                                    #{subscriber.id || "N/A"}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="subscriber_name">
                                                                                {subscriber.name}
                                                                            </td>
                                                                            <td className="subscriber_email">
                                                                                {subscriber.email}
                                                                            </td>
                                                                            <td className="subscriber_phone">
                                                                                {subscriber.phone}
                                                                            </td>
                                                                            <td className="subscriber_status">
                                                                                <span className={`badge badge-soft-${subscriber.status === 'subscribed' ? 'success' : 'warning'} text-uppercase`}>
                                                                                    {subscriber.status}
                                                                                </span>
                                                                            </td>
                                                                            <td className="subscriber_actions">
                                                                                <SubscriberAction subscriber={subscriber} signal={setRefresh} editAction={editSubscriber} />
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    : (
                                                        <>
                                                            {loading ? (
                                                                <div className="py-5">
                                                                    <Loader
                                                                        message={
                                                                            <p className="text-muted">
                                                                                Fetching subscribers...
                                                                            </p>
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <SearchResponse
                                                                    title={"No subscriber Available"}
                                                                    message={"You don't have any subscriber yet"}
                                                                />
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Pagination
                                                    pagination={pagination}
                                                    onPageChange={onPageChange}
                                                />
                                            </div>
                                        </div>

                                        <ModalBox
                                            show={modal}
                                            title={subscriber && subscriber.id !== undefined ? 'Edit Subscriber' : 'Create Subscriber'}
                                            handleClose={closeModal}
                                            allowClose={!busy}
                                            closeBtn={
                                                <Button variant="light" disabled={busy} onClick={closeModal}>Cancel</Button>
                                            }
                                            saveBtn={
                                                <Button type="submit"
                                                    onClick={handleSubmit}
                                                    variant="success"
                                                    className={`${busy ? 'disabled' : ''}`}

                                                    disabled={busy}
                                                >
                                                    {busy ?
                                                        <>
                                                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                                                <span className="visually-hidden">Saving...</span>
                                                            </div> Saving...
                                                        </> :
                                                        (subscriber.id !== undefined ? "Save changes" : "Save subscriber")
                                                    }
                                                </Button>
                                            }
                                            as={Form}
                                        >

                                            <Form.Group className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control name="name" onChange={handleChange} value={subscriber.name} />
                                            </Form.Group>


                                            <Form.Group className="mb-3">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control name="email" type="email" onChange={handleChange} value={subscriber.email} />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Phone number</Form.Label>
                                                <Form.Control name="phone" onChange={handleChange} type="tel" value={subscriber.phone} />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Subscription status</Form.Label>
                                                <Form.Select name="status" onChange={handleChange} >
                                                    <option defaultChecked>Select status</option>
                                                    <option value="subscribed" selected={subscriber.status === "subscribed"}>Subscribed</option>
                                                    <option value="unsubscribed" selected={subscriber.status === "unsubscribed"}>Unsubscribed</option>
                                                </Form.Select>
                                            </Form.Group>

                                        </ModalBox>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Outlet />
        </>
    )
}

export default Subscribers