import React, { useContext } from 'react'
import ModalBox from '../../Components/ModalBox';
import { AppContext } from '../../Contexts/AppContext';

const OrderAddressForm = ({ show, closeModal, saving, address, asOther, type, handleChange, setAsOther, saveAddress }) => {

    const { countries, states, getStates } = useContext(AppContext)

    return (
        <ModalBox
            show={show}
            handleClose={closeModal}
            title={'Update Address'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-success ${saving ? 'disabled' : ''}`}
                    onClick={() => saveAddress(type)}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Save'}
                </button>)}
        >
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>First Name</b></label>
                            <input type="text" name='firstname'
                                placeholder="Enter Firstname"
                                className="form-control"
                                validatefield="true"
                                validationoutput="#firstnameError"
                                validationmessage="Firstname cannot be empty!*"
                                value={address?.firstname} onChange={(e) => handleChange(e)} />
                            <span id="firstnameError"></span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>Last Name</b></label>
                            <input type="text" name='lastname' placeholder="Enter Lastname"
                                className="form-control"
                                validatefield="true"
                                validationoutput="#lastnameError"
                                validationmessage="Lastname cannot be empty!*"
                                value={address?.lastname} onChange={(e) => handleChange(e)} />
                            <span id="lastnameError"></span>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>Phone</b></label>
                            <input type="text" name='phone' placeholder="Enter Phone"
                                className="form-control"
                                validatefield="true"
                                validationoutput="#phoneError"
                                validationmessage="Phone cannot be empty!*"
                                value={address?.phone} onChange={(e) => handleChange(e)} />
                            <span id="phoneError"></span>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>Street</b></label>
                            <input type="text" name='street' placeholder="Enter Street"
                                className="form-control"
                                validatefield="true"
                                validationoutput="#streetError"
                                validationmessage="Street cannot be empty!*"
                                value={address?.street} onChange={(e) => handleChange(e)} />
                            <span id="streetError"></span>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>Additional Address</b></label>
                            <input type="text" name='additional_address' placeholder="Enter Additional Address"
                                className="form-control" value={address?.additional_address} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>City</b></label>
                            <input type="text" name='city' placeholder="Enter City"
                                className="form-control"
                                validatefield="true"
                                validationoutput="#cityError"
                                validationmessage="City cannot be empty!*"
                                value={address?.city} onChange={(e) => handleChange(e)} />
                            <span id="cityError"></span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>Postal Code</b></label>
                            <input type="text" name='zip' placeholder="Enter Postal Code"
                                className="form-control" value={address?.zip} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>Country</b></label>
                            <select name='country'
                                className="form-select"
                                validatefield="true"
                                validationoutput="#countryError"
                                validationmessage="Country is required!*"
                                onChange={(e) => { handleChange(e); getStates(e.target.value) }} >
                                <option>Select country</option>
                                {
                                    countries.map((country, index) => (
                                        address.country === country.name
                                            ? <option key={index} selected value={country.name}>{country.name}</option>
                                            : <option key={index} value={country.name}>{country.name}</option>
                                    ))
                                }
                            </select>
                            <span id="countryError"></span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='mb-3 form-group'>
                            <label className="form-label"><b>State</b></label>
                            <select className="form-select" name='state'
                                validatefield="true"
                                validationoutput="#stateError"
                                validationmessage="State is required!*"
                                onChange={e => handleChange(e)}>
                                <option disabled selected>Select state</option>
                                {
                                    states.map((state, index) => (
                                        address.state === state.name
                                            ? <option key={index} selected value={state.name}>{state.name}</option>
                                            : <option key={index} value={state.name}>{state.name}</option>
                                    ))
                                }
                            </select>
                            <span id="stateError"></span>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='mb-3 form-group'>
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" id="formCheck6"
                                    checked={asOther}
                                    onChange={(e) => { e.target.checked === true ? setAsOther(true) : setAsOther(false) }} />
                                <label className="form-check-label" htmlFor="formCheck6" >
                                    {type === "billing-address" ? 'Use address as shipping address?' : 'Use address as billing address?'}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBox>
    )
}

export default OrderAddressForm