import moment from "moment";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { errorResponse, responseMessage } from "../../libs/app";
import Flatpickr from "react-flatpickr";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import Loader from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination";
import ModalBox from "../../Components/ModalBox";
import getSymbolFromCurrency from "currency-symbol-map";
import { formatCurrency } from "wearslot-dev-utils";
import { addFiltersToEndpoint, dateChange } from "../../helpers";

const Transactions = () => {
  document.title = "Transactions | Taojaa - Store Manager App";

  const { selected } = useParams();

  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState(null);

  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);

  const [filters, setFilters] = useState({
    status: null,
    keywords: "",
    start_date: null,
    end_date: null,
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const applyFilter = () => {
    getTransactions(filters);
  };

  const resetFilter = () => {
    setFilters({
      status: null,
      keywords: "",
      start_date: null,
      end_date: null,
    });
    getTransactions();
  };

  useEffect(() => {
    getTransactions();
    if (selected) {
      viewTransaction(selected);
    }
  }, []);

  async function getTransactions(filters = null) {
    try {
      let endpoint = `/transactions?page=${currentPage}&perpage=${perPage}`;
      if (filters) {
        endpoint = addFiltersToEndpoint(filters, endpoint);
      }

      const response = await axios.get(endpoint);
      if (response.data.success) {
        setTransactions(response.data.data);
        setPagination(response.data.pagination);
      }
    } catch (error) {
      if (error.response) {
        return errorResponse(error.response);
      }

      return responseMessage("Something went wrong.", "error");
    }

    setLoading(false);
  }

  async function viewTransaction(id) {
    try {
      const response = await axios.get(`/transactions/${id}`);
      if (response.data.success) {
        return setTransaction(response.data.data);
      }
    } catch (error) {
      if (error.response) {
        return errorResponse(error.response);
      }

      return responseMessage("Something went wrong.", "error");
    }
  }

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Transactions</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Payments</a>
                    </li>
                    <li className="breadcrumb-item active">Transactions</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="customerList">
                <div className="card-header border-bottom-dashed">
                  <div className="row g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Transaction History</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto"></div>
                  </div>
                </div>
                <div className="card-body border-bottom-dashed border-bottom">
                  <form>
                    <div className="row g-3">
                      <div className="col-xl-5">
                        <div className="search-box">
                          <input
                            type="text"
                            name="keywords"
                            value={filters.keywords}
                            className="form-control search"
                            placeholder="Search for transaction reference, status or something..."
                            onChange={handleFilterChange}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>

                      <div className="col-xl-7">
                        <div className="row g-3">
                          <div className="col-sm-4">
                            <Flatpickr
                              className="form-control"
                              id="dash-filter-picker"
                              placeholder="start date to end date"
                              options={{ mode: "range" }}
                              defaultValue={
                                filters.start_date && filters.end_date !== null
                                  ? filters.start_date +
                                    " to " +
                                    filters.end_date
                                  : ""
                              }
                              onChange={(selectedDates) =>
                                dateChange(selectedDates, filters, setFilters)
                              }
                            />
                          </div>

                          <div className="col-sm-4">
                            <div>
                              <select
                                className="form-control"
                                name="status"
                                id="idStatus"
                                onChange={handleFilterChange}
                              >
                                <option value="">Status</option>
                                <option value="316">Pending</option>
                                <option value="111">Successful</option>
                                <option value="901">Failed</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={() => applyFilter()}
                              >
                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                Filters
                              </button>
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-danger w-100"
                                onClick={() => resetFilter()}
                              >
                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-body">
                  <div>
                    <div className="table-responsive table-card mb-1">
                      {transactions.length > 0 ? (
                        <table
                          className="table align-middle"
                          id="customerTable"
                        >
                          <thead className="table-light text-muted">
                            <tr className="text-uppercase">
                              <th scope="col" style={{ width: "50" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>

                              <th className="sort" data-sort="reference">
                                Reference
                              </th>
                              <th className="sort" data-sort="email">
                                Email
                              </th>
                              <th className="sort" data-sort="amount">
                                Amount
                              </th>
                              <th className="sort" data-sort="method">
                                Payment Method
                              </th>
                              <th className="sort" data-sort="date">
                                Date
                              </th>
                              <th className="sort" data-sort="status">
                                Status
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {transactions.map((transaction, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="checkAll"
                                      value="option1"
                                    />
                                  </div>
                                </th>
                                <td className="id" style={{ display: "none" }}>
                                  <a
                                    href="#"
                                    className="fw-medium link-primary"
                                  >
                                    #{transaction.order_no}
                                  </a>
                                </td>
                                <td className="reference">
                                  {transaction.reference}
                                </td>
                                <td className="email">{transaction.email}</td>
                                <td className="amount">
                                  {getSymbolFromCurrency(transaction.currency) +
                                    formatCurrency(transaction.amount)}
                                </td>
                                <td className="method">
                                  {transaction.payment_method}
                                </td>
                                <td className="date">
                                  {moment(transaction.date).format(
                                    "DD MMM, Y hh:m:ss a"
                                  )}
                                </td>
                                <td className="status">
                                  {transaction.status === 111 ? (
                                    <span className="badge badge-soft-success text-uppercase">
                                      Successful
                                    </span>
                                  ) : (
                                    <>
                                      {transaction.status === 316 ? (
                                        <span className="badge badge-soft-warning text-uppercase">
                                          Pending
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-danger text-uppercase">
                                          Failed
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li
                                      className="list-inline-item edit"
                                      title="Edit"
                                    >
                                      <a
                                        href="#showModal"
                                        data-bs-toggle="modal"
                                        className="text-primary d-inline-block edit-item-btn"
                                        onClick={() =>
                                          viewTransaction(transaction.id)
                                        }
                                      >
                                        <i className="ri-eye-fill fs-16"></i>{" "}
                                        View
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {loading ? (
                            <div className="py-5">
                              <Loader
                                message={
                                  <p className="text-muted">
                                    Fetching transactions...
                                  </p>
                                }
                              />
                            </div>
                          ) : (
                            <SearchResponse
                              title={"No Transaction Available"}
                              message={
                                <p>You don't have any transactions yet.</p>
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Pagination
                          pagination={pagination}
                          previous={goToPreviousPage}
                          next={goToNextPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ModalBox
            show={transaction ? true : false}
            handleClose={() => setTransaction(null)}
            title={"Transaction Details"}
            closeBtn={
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setTransaction(null)}
              >
                {" "}
                Close
              </button>
            }
            saveBtn={null}
          >
            {transaction && (
              <div>
                <div className="d-flex gap-3">
                  <h6>Reference:</h6>
                  <p>{transaction.reference}</p>
                </div>
                <div className="d-flex gap-3">
                  <h6>Customer Details:</h6>
                  <p>
                    {transaction.customer?.name}{" "}
                    <small className="text-muted">
                      {transaction.customer?.email}
                    </small>{" "}
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <h6>Amount:</h6>
                  <p>
                    {getSymbolFromCurrency(transaction.currency) +
                      formatCurrency(transaction.amount)}
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <h6>Payment Status:</h6>
                  <p>
                    {transaction.status === 111 ? (
                      <span className="badge badge-soft-success text-uppercase">
                        Successful
                      </span>
                    ) : (
                      <>
                        {transaction.status === 316 ? (
                          <span className="badge badge-soft-warning text-uppercase">
                            Pending
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger text-uppercase">
                            Failed
                          </span>
                        )}
                      </>
                    )}
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <h6>Payment Method:</h6>
                  <p>{transaction.payment_method}</p>
                </div>
                <div className="d-flex gap-3">
                  <h6>Transaction Date:</h6>
                  <p>
                    {moment(transaction.created_date).format(
                      "DD MMM, Y hh:m:ss a"
                    )}
                  </p>
                </div>
              </div>
            )}
          </ModalBox>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Transactions;
