import React, { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import Placeholder from "../../Components/Placeholder/Placeholder";
import { Button, ButtonGroup, Card, Col, Dropdown, Form, InputGroup, Row, Table } from "react-bootstrap";
import Async from 'react-select/async';
import axios from "axios";
import ModalBox from "../../Components/ModalBox";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { errorResponse, responseMessage } from "../../libs/app";
import { AppContext } from "../../Contexts/AppContext";
import { AuthContext } from "../../Contexts/AuthContext";

const CampaignForm = (props) => {

  if (props.mode === "new")
    document.title = "Create Campaign | Taojaa - Store Manager App";
  if (props.mode === "edit")
    document.title = "Edit Campaign | Taojaa - Store Manager App";

  const { store, subscription } = useContext(AuthContext);
  const { emailTemplate, setEmailTemplate, campaign, setCampaign } = useContext(AppContext);

  const initialCampaignData = {
    title: "",
    subject: "",
    channel: "",
    text: "",
    email_template: null,
    extra_vars: '',
    recipients: "",
    exclude: [],
    send_at: ""
  };

  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(false);

  const varNameRef = useRef(null);
  const varValueRef = useRef(null);
  const { campaign_id } = useParams();
  const navigate = useNavigate();

  const [sendAt, setSendAt] = useState(null)

  const [searching, setSearching] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    setCampaign(props.mode === 'new' ? initialCampaignData : campaign);
  }, [props.mode, store, subscription])

  const confirmSend = () => {
    setConfirmModal(true);
  }

  const cancelConfirm = () => {
    setConfirmModal(false);
  }

  const createOption = ({ id, name, email, phone }) => {
    return {
      value: id,
      label: `${name} - ${email} - ${phone}`,
    };
  }

  const handleExclusionChange = (newValue) => {
    const options = newValue.map(({ value, label }) => {
      const recipient = label.split('-').map((value) => value.trim());

      return { name: recipient[0], email: recipient[1], phone: recipient[2] };
    })

    setCampaign({
      ...campaign,
      exclude: options,
      exclude_opt: newValue
    })
  }

  const handleChange = (e) => {
    setCampaign({
      ...campaign,
      [e.target.name]: e.target.value,
    });
  };

  const getRecipientsOptions = async (inputValue) => {
    try {
      setSearching(true);
      const response = await axios.get(`/campaigns/recipients/search?search=${inputValue}`);
      // console.log(response);
      if (response.data.success) {
        console.log('Recipients Loaded:', response.data);
        const { customers, subscribers } = response.data;

        const options = customers.map(createOption);
        subscribers.map(subscriber => options.push(createOption(subscriber)));

        return options;
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    } finally {
      setSearching(false);
    }
  }

  const handleExtraVar = () => {
    if (varNameRef.current?.value && varValueRef.current?.value) {
      setCampaign({
        ...campaign,
        extra_vars: {
          ...campaign.extra_vars,
          [varNameRef.current?.value]: varValueRef.current?.value,
        },
      });
      varNameRef.current.value = "";
      varValueRef.current.value = "";
    } else {
      responseMessage("Variable name and value are required.", "error");
    }
  };

  async function createCampaign(status, send_at) {
    try {
      let data = {
        title: campaign.title,
        channel: campaign.channel,
        subject: campaign.subject,
        status: status,
        text: campaign.text,
        email_template: emailTemplate,
        extra_vars: campaign.extra_vars,
        recipients: campaign.recipients,
        exclude: campaign.exclude,
        send_at: send_at
      };

      const response = await axios.post(props.mode === 'new' ? '/campaigns/create' : `/campaign/update/${campaign_id}`, data);

      if (response.data.success) {
        responseMessage(response.data.message, "success");
        setEmailTemplate({ html: null, design: null });
        setCampaign({});
        navigate('/marketing/campaigns')
      }
    } catch (error) {
      console.log("Error: ", error);
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    } finally {
      setBusy(false)
    }
  }

  async function getCampaign() {
    setLoading(true);
    if (props.mode === 'edit') {
      axios
        .get(`/campaign/details/${campaign_id}`)
        .then((response) => {
          if (response.data.success) {
            const data = response.data;
            const c = {
              title: data.campaign.title,
              subject: data.campaign.subject,
              channel: data.campaign.channel,
              text: data.campaign.text,
              email_template: data.campaign.email_template,
              extra_vars: data.campaign.extra_vars ?? {},
              recipients: data.campaign.recipient_type,
              exclude: data.campaign.exclude,
              send_at: data.campaign.send_at,
              status: data.campaign.status,
            }
            c.exclude_opt = c.exclude.map(recipient => createOption(recipient));
            setCampaign(c);

            if (emailTemplate.html === null) {
              if (data.campaign.channel === 'email' && data.campaign.email_template !== null) {
                setEmailTemplate(data.campaign.email_template);
              }
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            errorResponse(error.response);
          } else {
            responseMessage("Something went wrong.", "error");
          }

          setTimeout(() => navigate('/marketing/campaigns'), [2000]);
        }).finally(() => {
          setLoading(false);
        })
    } else {
      setLoading(false);
    }
  }

  const cleanup = () => {
    if (scheduleModal) {
      closeScheduleModal()
    }

    if (confirmModal) {
      setConfirmModal(false);
    }

    setBusy(false);
  }

  const saveCampaign = (status = "draft", send_at = null) => {
    if (formRef.current.checkValidity()) {
      setBusy(true);
      createCampaign(status, send_at);

      cleanup();
    } else {
      setBusy(false);
      formRef.current.reportValidity()
    }
  }

  const openScheduleForm = () => {
    if (sendAt === false || sendAt === '') {
      setSendAt(moment().add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'));
    }
    setScheduleModal(true);
  }

  function closeScheduleModal() {
    setScheduleModal(false)
  }

  const setScheduleDate = (date) => {
    const send_date = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
    setSendAt(send_date);
  }

  const gotoEmailBuilder = () => {
    navigate('/marketing/campaign/editor')
  }

  useEffect(() => {
    getCampaign();
  }, []);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {!loading ? (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    {props.mode === "new" ? (
                      <h4 className="mb-sm-0">Create Campaign</h4>
                    ) : (
                      <h4 className="mb-sm-0">Edit Campaign</h4>
                    )}

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="#" onClick={() => window.history.back()}>
                            <i className="ri-arrow-left-line align-bottom me-1"></i>
                            Back
                          </Link>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <form className="row" ref={formRef}>
                <div className="col-lg-8">
                  <div className="card">
                    <div className="card-body">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="campaign_title">
                          Campaign Title
                        </Form.Label>
                        <Form.Control
                          id="campaign_title"
                          name="title"
                          value={campaign.title}
                          placeholder="Enter campaign title"
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Campaign Channel</Form.Label>
                        <div>
                          <Form.Check
                            id="sms_campaign_channel"
                            type="radio"
                            value="sms"
                            name="channel"
                            required
                            onChange={handleChange}
                            checked={campaign.channel === "sms"}
                            inline
                            label="SMS"
                          />
                          <Form.Check
                            id="email_campaign_channel"
                            type="radio"
                            value="email"
                            name="channel"
                            required
                            onChange={handleChange}
                            checked={campaign.channel === "email"}
                            inline
                            label="Email"
                          />
                        </div>
                      </Form.Group>
                      {campaign.channel === "sms" ? (
                        <Form.Group className="mb-3">
                          <Form.Label>Campaign Message</Form.Label>
                          <Form.Control
                            id="sms_message"
                            as="textarea"
                            value={campaign.text}
                            name="text"
                            maxLength={120}
                            minLength={15}
                            onChange={handleChange}
                            required
                          />
                          <Form.Text>Max character length (120).</Form.Text>
                        </Form.Group>
                      ) : (
                        <>
                          <Form.Group>
                            <Form.Label htmlFor="campaign_subject">
                              Campaign Subject
                            </Form.Label>
                            <Form.Control
                              id="campaign_subject"
                              name="subject"
                              value={campaign.subject}
                              placeholder="Enter campaign subject"
                              onChange={handleChange}
                              maxLength={250}
                              minLength={5}
                              required
                            />
                            <Form.Text>Max character length (250).</Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row className="gap-2 d-flex justify-content-center">
                              <Col md={3} className="rounded-3 position-relative border" style={{ height: 250, overflow: 'hidden' }}>
                                <div className="table-responsive" dangerouslySetInnerHTML={{ __html: emailTemplate.html || '' }}></div>
                                <div className='position-absolute d-flex justify-content-center align-items-center top-0 start-0 end-0 bottom-0 em-overlay'>
                                  <Button className="btn btn-sm btn-primary" onClick={gotoEmailBuilder}>Edit Content</Button>
                                </div>
                              </Col>
                            </Row>
                          </Form.Group>
                        </>
                      )}
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Select Campaign Recipients
                        </Form.Label>
                        <Form.Check type="radio" label="Select All (customers & subscribers)" className="mb-3" onChange={handleChange} checked={campaign.recipients === 'all'} id="select-all" name="recipients" value="all" />
                        <Form.Check type="radio" label="Select Subscribers" className="mb-3" onChange={handleChange} checked={campaign.recipients === 'subscribers'} id="select-subscribers" name="recipients" value="subscribers" />
                        <Form.Check type="radio" label="Select Customers" className="mb-3" onChange={handleChange} checked={campaign.recipients === 'customers'} id="select-customers" name="recipients" value="customers" />
                        <Form.Label>
                          Select Recipients To Exclude
                        </Form.Label>
                        <Async
                          name="exclude"
                          controlShouldRenderValue
                          isClearable
                          isLoading={searching}
                          isMulti
                          loadOptions={getRecipientsOptions}
                          onChange={handleExclusionChange}
                          noOptionsMessage={(props) => "Search for recipients to exclude."}
                          value={campaign.exclude_opt}
                        />
                        {/* //Add support for uploading recipients via csv & provide a template */}
                      </Form.Group>
                      <div className="d-flex justify-content-end mt-3">
                        <Dropdown as={ButtonGroup}>
                          <Button variant="success" onClick={() => confirmSend()} className={`w-sm ${busy ? "disabled" : ""
                            }`}>
                            {busy ? (
                              <>
                                <div
                                  className="spinner-border spinner-border-sm text-white"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>{" "}
                                Saving...
                              </>
                            ) : (
                              "Send now"
                            )}
                          </Button>

                          <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => saveCampaign()}>
                              {props.mode === "edit" ? "Save changes to draft" : "Save to draft"}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={openScheduleForm}>Schedule send</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <Card>
                    <Card.Body>
                      <legend className="fs-6">Extra Variables</legend>
                      <Row className="mb-2">
                        <Col sm={5}>
                          <Form.Control
                            placeholder="name"
                            size="sm"
                            name="var_name"
                            ref={varNameRef}
                          />
                        </Col>
                        <Col sm={5}>
                          <Form.Control
                            placeholder="value"
                            size="sm"
                            name="var_value"
                            ref={varValueRef}
                          />
                        </Col>
                        <Col sm={2}>
                          <Button
                            variant="primary"
                            className="float-end"
                            size="sm"
                            onClick={handleExtraVar}
                            type="button"
                          >
                            Add
                          </Button>
                        </Col>
                      </Row>
                      {campaign?.extra_vars && JSON.stringify(campaign.extra_vars) !== "{}" && (
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(campaign.extra_vars).map(
                              (key, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <code>##{key}##</code>
                                  </td>
                                  <td>{campaign.extra_vars[key]}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      )}
                    </Card.Body>
                    <Card.Header className="pb-">
                      Default Variables & Syntax
                    </Card.Header>
                    <Card.Body>
                      <ul>
                        <li>
                          <code>##FName##</code> - First Name.
                        </li>
                        <li>
                          <code>##LName##</code> - Last Name.
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </div>
              </form>
              <ModalBox
                show={confirmModal}
                handleClose={cancelConfirm}
                onHide={cancelConfirm}
                title=" "
                backdrop="static"
                keyboard={false}
                closeBtn={
                  <Button variant="light" disabled={busy} onClick={cancelConfirm}>Cancel</Button>
                }
                saveBtn={
                  <Button variant="success" type="button" onClick={() => saveCampaign('pending', null)}>
                    {busy ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm text-white"
                          role="status"
                        >
                          <span className="visually-hidden">
                            Loading...
                          </span>
                        </div>{" "}
                        Saving...
                      </>
                    ) : (
                      "Yes, Continue."
                    )}
                  </Button>
                }
              >
                <div className="mt-2 text-center">
                  <lord-icon
                    src="https://cdn.lordicon.com/aycieyht.json"
                    trigger="loop"
                    colors="primary:#f7b84b,secondary:#f06548"
                    style={{ width: "80px", height: "80px" }}
                  ></lord-icon>
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Confirm you want to send now?</h4>
                    <div className="text-muted mx-4 mb-0">Are you sure you want to continue too send out this campaign?</div>
                  </div>
                </div>
              </ModalBox>
              <ModalBox
                show={scheduleModal}
                handleClose={closeScheduleModal}
                onHide={closeScheduleModal}
                title="Schedule campaign"
                closeBtn={
                  <Button variant="light" disabled={busy} onClick={closeScheduleModal}>Cancel</Button>
                }
                saveBtn={
                  <Button variant="success" type="button" onClick={() => saveCampaign('scheduled', sendAt)} disabled={sendAt === false || sendAt === '' || busy}>
                    {busy ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm text-white"
                          role="status"
                        >
                          <span className="visually-hidden">
                            Loading...
                          </span>
                        </div>{" "}
                        Scheduling...
                      </>
                    ) : (
                      "Schedule"
                    )}
                  </Button>
                }
              >
                <Form.Group>
                  <Form.Label htmlFor="sendAt">Send At</Form.Label>
                  <InputGroup>
                    <Flatpickr
                      type="text"
                      id="sendAt"
                      options={{ enableTime: true, mode: 'single', minDate: moment().add(1, 'hour').format('YYYY-MM-DD HH:mm:ss') }}
                      value={sendAt}
                      className="form-control bg-transparent shadow-0"
                      onChange={setScheduleDate}
                      required={true}
                    />
                    <InputGroup.Text as="label" role="button" htmlFor="sendAt" className="bg-primary border-primary text-white">
                      <i className="ri-calendar-2-line"></i>
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </ModalBox>
            </>
          ) : (
            <>
              <div className="mb-2">
                <Placeholder column="col-9" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-12" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-7" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-10" />
              </div>
            </>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};

CampaignForm.propTypes = {
  mode: PropTypes.oneOf(["new", "edit"]),
};



const CampaignFormPage = () => {
  const { mode } = useParams();

  return <CampaignForm mode={mode} />;
};

export default CampaignFormPage;