import React from 'react';
import { Modal } from 'react-bootstrap'

const ModalBox = (props) => {

    return (
        <Modal
            {...props}
            show={props.show}
            onHide={props.handleClose}
        >
            <Modal.Header closeButton={props.allowClose || Boolean(props.closeBtn)}>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer className={props.footerClass}>
                {props.closeBtn}
                {props.saveBtn}
            </Modal.Footer>
        </Modal>
    )
}

export default ModalBox