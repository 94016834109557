import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../../Contexts/AppContext';
import ValidateSubmit from 'wearslot-dev-utils';
import axios from 'axios'
import { errorResponse, responseMessage } from '../../../libs/app';
import ModalBox from '../../../Components/ModalBox';

const StationForm = ({ show, closeModal, defaultStation, getStations }) => {

    const { statuses, countries, states, getStates, saving, setSaving } = useContext(AppContext)

    const [station, setStation] = useState({
        name: '',
        phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: '',
        is_default: false,
        status: 1,
        zip_code: ''
    });

    useEffect(() => {
        if (defaultStation) { setStation(defaultStation) };
    }, [defaultStation])


    async function saveStation() {
        try {
            if (!ValidateSubmit()) {
                return;
            }

            setSaving(true)
            if (station?.id !== undefined) {
                var response = await axios.put(`/settings/pickups`, station);
            } else {
                var response = await axios.post(`/settings/pickups`, station);
            }
            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getStations();

                closeModal()
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
        setSaving(false)
    }

    const handleStationChange = (e) => {
        setStation({ ...station, [e.target.name]: e.target.value });
    }

    const switchDefault = () => {
        setStation({ ...station, is_default: !station.is_default });
    }

    return (
        <ModalBox
            show={show}
            handleClose={closeModal}
            title={station && station.id !== undefined ? 'Edit Pickup Location' : 'Add Pickup Location'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                    onClick={() => saveStation()}>
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Save'}
                </button>)}
        >
            <form action="" method="POST" className="form" id="pickupStationForm" encType="multipart/form-data">
                <div className="card-body">
                    <div className="form-group row mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Name</label>
                        <div className="col-lg-12 col-xl-12">
                            <input type="text" className="form-control form-control-solid" name="name"
                                validatefield="true"
                                validationoutput="#nameError"
                                validationmessage="Station name cannot be empty!*"
                                value={station.name} placeholder="Enter name e.g Ajah Pick-Up Station"
                                onChange={handleStationChange} />
                            <span id="nameError"></span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='mb-3 form-group'>
                                <label className="form-label"><b>Phone</b></label>
                                <input type="text" name='phone' placeholder="Enter Phone"
                                    className="form-control"
                                    validatefield="true"
                                    validationoutput="#phoneError"
                                    validationmessage="Phone cannot be empty!*"
                                    value={station.phone} onChange={handleStationChange} />
                                <span id="phoneError"></span>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div className="flex-shrink-0">
                                <div className="form-check form-switch form-switch-left form-switch-md">
                                    <input className="form-check-input code-switcher" type="checkbox" checked={station.is_default === true}
                                        onChange={switchDefault}
                                    />
                                    <label htmlFor="default-switch" className="form-label text-muted">Set as Default Station</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='mb-3 form-group'>
                                <label className="form-label"><b>Street</b></label>
                                <input type="text" name='address_line_1' placeholder="Enter Street"
                                    className="form-control"
                                    validatefield="true"
                                    validationoutput="#streetError"
                                    validationmessage="Street cannot be empty!*"
                                    value={station.address_line_1} onChange={handleStationChange} />
                                <span id="streetError"></span>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='mb-3 form-group'>
                                <label className="form-label"><b>Additional Address</b></label>
                                <input type="text" name='address_line_2' placeholder="Enter Additional Address"
                                    className="form-control" value={station?.address_line_2} onChange={handleStationChange} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='mb-3 form-group'>
                                <label className="form-label"><b>City</b></label>
                                <input type="text" name='city' placeholder="Enter City"
                                    className="form-control"
                                    validatefield="true"
                                    validationoutput="#cityError"
                                    validationmessage="City cannot be empty!*"
                                    value={station.city} onChange={handleStationChange} />
                                <span id="cityError"></span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='mb-3 form-group'>
                                <label className="form-label"><b>Postal Code</b></label>
                                <input type="text" name='zip_code' placeholder="Enter Postal Code"
                                    className="form-control" value={station.zip_code} onChange={handleStationChange} />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='mb-3 form-group'>
                                <label className="form-label"><b>Country</b></label>
                                <select name='country'
                                    className="form-select"
                                    validatefield="true"
                                    validationoutput="#countryError"
                                    validationmessage="Country is required!*"
                                    onChange={(e) => { handleStationChange(e); getStates(e.target.value) }} >
                                    <option>Select country</option>
                                    {
                                        countries.map((country, index) => (
                                            station.country === country.name
                                                ? <option key={`country-${index}`} selected value={country.name}>{country.name}</option>
                                                : <option key={`country-${index}`} value={country.name}>{country.name}</option>
                                        ))
                                    }
                                </select>
                                <span id="countryError"></span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='mb-3 form-group'>
                                <label className="form-label"><b>State</b></label>
                                <select className="form-select" name='state'
                                    validatefield="true"
                                    validationoutput="#stateError"
                                    validationmessage="State is required!*"
                                    onChange={e => handleStationChange(e)}>
                                    <option disabled selected>Select state</option>
                                    {
                                        states.map((state, index) => (
                                            station.state === state.name
                                                ? <option key={`state-${index}`} selected value={state.name}>{state.name}</option>
                                                : <option key={`state-${index}`} value={state.name}>{state.name}</option>
                                        ))
                                    }
                                </select>
                                <span id="stateError"></span>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Status</label>
                        <div className="col-lg-12 col-xl-12">
                            <select
                                name="status"
                                className="form-control"
                                value={station.status}
                                onChange={handleStationChange}
                            >
                                {statuses.map(({ id, name }) => {
                                    return (
                                        <option key={id} selected={station.status == id ? true : false} value={id}>{name}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>

                </div>
            </form>
        </ModalBox>
    )
}

export default StationForm