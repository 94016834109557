import React, { useContext } from 'react'
import { AuthContext } from '../../Contexts/AuthContext'
import { Outlet } from 'react-router-dom';

const DomainIntegration = () => {

    document.title = 'Custom Domain Integration | Taojaa - Store Manager App';

    const { store, subscription, handleUpgradeModalShow } = useContext(AuthContext)

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Domain Integration</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xxl-9">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Use Custom Domain</h4>
                                    <div className="flex-shrink-0">
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className=''>
                                        <div className='bank-details'>
                                            <p className="text-muted">Your prefered bank account details for earning withdrawal and receiving direct bank transfer on checkout.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Outlet />
        </>
    )
}

export default DomainIntegration