import axios from 'axios'
import React, { useEffect, useState, useContext, Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { errorResponse, responseMessage } from '../../libs/app'
import { AuthContext } from '../../Contexts/AuthContext'
import ModalBox from '../../Components/ModalBox'
import { Col, Row } from 'react-bootstrap';
import TextEditor from '../../Components/TextEditor'
import BlankPage from '../../Components/BlankPage'


const PaymentSetup = () => {

    document.title = 'Payment Setup | Taojaa - Store Manager App';

    const navigator = useNavigate();
    const { wallets, getWallets, currencies, store, subscription, handleUpgradeModalShow } = useContext(AuthContext)

    const [modal, setModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [provider, setProvider] = useState(null);
    const [providers, setProviders] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState({
        name: null,
        type: 'manual',
        status: 'enabled',
        provider_id: null
    });
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankDetails, setBankDetails] = useState({
        account_name: '',
        account_number: '',
        bank_name: '',
        currency: currencies[0].abbr
    });

    const [currency, setCurrency] = useState(null);

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }

        getProviders()
        getBankAccounts()
        getPaymentMethods()
    }, [store, subscription]);


    async function getBankAccounts() {
        try {
            const response = await axios.get(`/settings/payment-information`);
            if (response.data.success) {
                setBankAccounts(response.data.data);
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function getProviders() {
        try {
            const response = await axios.get('/settings/payment/providers');
            if (response.data.success) {
                setProviders(response.data.providers);
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function getPaymentMethods() {
        try {
            const response = await axios.get("/settings/payment/methods");
            if (response.data.success) {
                setPaymentMethods(response.data.data);
            }
        } catch (error) {
        }
    }

    async function saveBankInformation() {
        try {
            setSaving(true)
            const response = await axios.put('/settings/update/payment-information', bankDetails);
            if (response.data.success) {
                responseMessage(response.data.message, 'success')
                getBankAccounts();
            }

            closeModal();

        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function savePaymentMethod() {
        try {
            setSaving(true)
            if (paymentMethod?.id) {
                var response = await axios.put('/settings/payment/methods', paymentMethod);
            } else {
                var response = await axios.post('/settings/payment/methods', paymentMethod);
            }

            if (response.data.success) {
                responseMessage(response.data.message, 'success')
                getPaymentMethods();
            }

            closeModal();
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function addNewCurrency() {
        try {
            setSaving(true)
            const response = await axios.post('/wallets/add', { currency });
            if (response.data.success) {
                responseMessage(response.data.message, 'success')
                getWallets();
            }

            closeModal();
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        } finally {
            setSaving(false)
        }
    }

    async function setDefaultCurrency(id) {
        try {
            const response = await axios.get(`/wallets/default?id=${id}`);
            if (response.data.success) {
                responseMessage(response.data.message, 'success')
                getWallets();
            }
        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    const goToProviderSetupPage = (provider) => {
        if (provider != null) {
            return navigator(`/provider/${provider?.slug}`)
        }

        return responseMessage("Kindly select a provider to continue", "info");
    }

    const handleBankDetailsChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setBankDetails({ ...bankDetails, [name]: value });
    }

    const handlePaymentDetailChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setPaymentMethod({ ...paymentMethod, [name]: value });
    }

    const handleTextfieldChange = (value) => {
        setPaymentMethod({ ...paymentMethod, instruction: value });
    }

    const editAccount = (account) => {
        setBankDetails(account);
        openModal("bank")
    }

    const editPaymentMethod = (paymentMethod) => {
        if (subscription?.settings.allowManualPaymentOption === false) {
            return handleUpgradeModalShow(
                'Upgrade Plan',
                'Oops! this feature is not available on your current plan, upgrade your plan to setup/enable manual payments.'
            )
        }

        setPaymentMethod(paymentMethod);
        setModal("manual-payments");
    }

    const switchMethodStatus = () => {
        setPaymentMethod({
            ...paymentMethod, status: paymentMethod.status === "enabled" ? "disabled" : "enabled"
        });
    }

    const openModal = (type) => {
        if (type === "currency") {
            if (subscription?.settings.enableMultipleCurrencies === false) {
                return handleUpgradeModalShow(
                    'Upgrade Your Plan',
                    'Oops! Multi-currency feature is not available on your current plan, upgrade now to receive payments in other currencies.'
                )
            }
        }

        setModal(type);
    }

    const closeModal = () => {
        setModal(false);
        setCurrency(null);
        setProvider(null);
        setBankDetails({
            account_name: '',
            account_number: '',
            bank_name: '',
            currency: currencies[0].abbr
        });
    }

    return (
        <BlankPage title='Payment Setup' titleColumSize={12}>
            <div className="col-xxl-9 mb-4">
                <div className="card h-100">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Online Payment Methods</h4>
                    </div>
                    <div className="card-body">
                        <div className='d-flex justify-content-between gap-3'>
                            <div className='bank-details'>
                                <p className="text-muted">Connect payment providers for online payments and checkouts.</p>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => openModal("providers")}>
                                    <i className="ri-plug-fill"></i> Connect Provider
                                </button>
                            </div>
                        </div>
                        <div className='px-3 mt-3'>
                            {paymentMethods.map((paymentMethod, index) =>
                                <Fragment>
                                    {paymentMethod.type === "online" &&
                                        <div className='d-flex align-items-center gap-3 border rounded-2 p-2 mb-2' key={`online-${index}`}>
                                            <div className='provider-logo'>
                                                <img src={paymentMethod.provider.logo} width={50} alt='Provider logo' />
                                            </div>
                                            <div className='provider-details flex-fill'>
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        <h5 className='m-0'>{paymentMethod.provider.name}</h5>
                                                        <p className='m-0'>Name: {paymentMethod.name}</p>
                                                    </div>
                                                    <div className='p-2'>
                                                        <Link to={`/provider/${paymentMethod.provider.slug}`} className="text-danger" style={{ whiteSpace: 'nowrap' }}>
                                                            <i className="ri-pencil-line"></i> Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-9 mb-4">
                <div className="card h-100">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Manual Payment Methods</h4>
                    </div>
                    <div className="card-body">
                        <div className='d-flex justify-content-between gap-3'>
                            <div className='bank-details'>
                                <p className="text-muted">Setup manaual payment methods for your customers on checkouts.</p>
                            </div>
                            <div>
                                {subscription?.settings.allowManualPaymentOption === false ?
                                    <Link to="/settings/billing-and-subscription" className='btn btn-success btn-sm'>Upgrade Now</Link>
                                    :
                                    <button type="button" className="btn btn-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => openModal("manual-payments")}>
                                        <i className="ri-add-circle-line"></i> Add Payment Method
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='px-3 mt-3'>
                            {paymentMethods.map((paymentMethod, index) =>
                                <Fragment>
                                    {paymentMethod.type === "manual" &&
                                        <div className='d-flex align-items-center gap-3 border rounded-2 p-2 mb-3' key={`manual-${index}`}>
                                            <div className='provider-logo'>
                                                {/* <i className='ri-wallet-3-fill text-muted' style={{ fontSize: 40 }}></i> */}
                                            </div>
                                            <div className='provider-details flex-fill'>
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        <h5 className='m-0'>{paymentMethod.name}</h5>
                                                        <p className='m-0'>{paymentMethod.description}</p>
                                                        <p className='m-0'><b>Status:</b>
                                                            {paymentMethod.status === "enabled" && subscription?.settings.allowManualPaymentOption !== false
                                                                ? <small className='text-success fw-bold ms-1'>Enabled</small>
                                                                : <small className='text-danger fw-bold ms-1'>Disabled</small>}
                                                        </p>
                                                    </div>
                                                    <div className='p-2'>
                                                        <Link to={`#`} className="text-danger" style={{ whiteSpace: 'nowrap' }}
                                                            onClick={() => editPaymentMethod(paymentMethod)}>
                                                            <i className="ri-pencil-line"></i> Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-9 mb-4">
                <div className="card h-100">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Currency Settings</h4>
                    </div>
                    <div className="card-body">
                        <div className='d-flex justify-content-between gap-3'>
                            <div className='bank-details'>
                                <p className="text-muted">Set preferred currencies for your business, to transact and receive payments.</p>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => openModal("currency")}>
                                    <i className="ri-add-circle-line"></i> Add Currency
                                </button>
                            </div>
                        </div>
                        <div>
                            {wallets.length > 0 &&
                                (<table
                                    className="table align-middle"
                                    id="currencyTable"
                                >
                                    <tbody className="list form-check-all">
                                        {wallets.map((wallet, index) => (
                                            <tr key={index}>
                                                <td className="name">{wallet.currency.abbr} - {wallet.currency.name}</td>
                                                <td className='text-end'>
                                                    {!wallet.is_default ?
                                                        <a
                                                            href="#currency"
                                                            className="text-primary d-inline-block set-defult"
                                                            onClick={() => setDefaultCurrency(wallet.id)}>
                                                            Set as default
                                                        </a>
                                                        :
                                                        <span className="badge badge-soft-success text-uppercase">Default</span>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-9 mb-4">
                <div className="card h-100">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Bank Accounts</h4>
                        <div className="flex-shrink-0">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='d-flex justify-content-between gap-3'>
                            <div className='bank-details'>
                                <p className="text-muted">Your preferred bank account details for withdrawals and settlements.</p>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => openModal("bank")}>
                                    <i className="ri-add-circle-line"></i> Add Account
                                </button>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            {bankAccounts.length > 0 &&
                                (<table
                                    className="table align-middle"
                                    id="currencyTable"
                                >
                                    <thead className="table-light text-muted">
                                        <tr className="text-uppercase">
                                            <th className="sort" data-sort="account_name">Account Name</th>
                                            <th className="sort" data-sort="account_no">Account No.</th>
                                            <th className="sort" data-sort="bank_name">Bank Name</th>
                                            <th className="sort" data-sort="currency">Currency</th>
                                            <th className="sort" data-sort="action">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {bankAccounts.map((account, index) => (
                                            <tr key={index}>
                                                <td className="account_name">{account.account_name}</td>
                                                <td className="account_no">{account.account_number}</td>
                                                <td className="name">{account.bank_name}</td>
                                                <td className="name">{account.currency}</td>
                                                <td>
                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                        <li className="list-inline-item edit" title="Edit account">
                                                            <a
                                                                href="#bankaccount"
                                                                className="text-primary d-inline-block edit-item-btn"
                                                                onClick={() => editAccount(account)}
                                                            >
                                                                <i className="ri-pencil-fill fs-16"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>)}
                        </div>
                    </div>
                </div>
            </div>

            <ModalBox
                show={modal === "bank"}
                handleClose={closeModal}
                title={'Bank Information'}
                closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                saveBtn={
                    (<button
                        type="button"
                        disabled={saving}
                        className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                        onClick={() => saveBankInformation()}>
                        {saving
                            ? <>
                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> Saving...
                            </> : 'Save'}
                    </button>)}
            >
                <form action="" method="POST" className="form" id="bankAccountForm" encType="multipart/form-data">
                    <div className="card-body">
                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Account Name</label>
                            <div className="col-lg-12 col-xl-12">
                                <input type="text" className="form-control form-control-solid" name="account_name"
                                    value={bankDetails.account_name} placeholder="Enter Account Name"
                                    onChange={(e) => handleBankDetailsChange(e)} />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Account Number</label>
                            <div className="col-lg-12 col-xl-12">
                                <input className="form-control form-control-solid" name="account_number" type="text" value={bankDetails.account_number}
                                    onChange={(e) => handleBankDetailsChange(e)} />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Bank Name</label>
                            <div className="col-lg-12 col-xl-12">
                                <input className="form-control form-control-solid" name="bank_name" type="text"
                                    placeholder="Enter Bank Name" value={bankDetails.bank_name} onChange={(e) => handleBankDetailsChange(e)} />
                            </div>
                        </div>

                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Currency</label>
                            <div className="col-lg-12 col-xl-12">
                                <select
                                    name="currency"
                                    className="form-control"
                                    value={bankDetails.currency}
                                    onChange={(e) => handleBankDetailsChange(e)}
                                >
                                    {currencies.map((currency, index) => {
                                        return (
                                            <option key={index} selected={currency.abbr === bankDetails.currency ? true : false} value={currency.abbr}>{currency.name} - ({currency.abbr})</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        {bankDetails.currency !== 'NGN' &&
                            <>

                            </>
                        }
                    </div>
                </form>
            </ModalBox>

            <ModalBox
                show={modal === "currency"}
                handleClose={closeModal}
                title={'Add Currency'}
                closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                saveBtn={
                    (<button
                        type="button"
                        disabled={saving}
                        className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                        onClick={() => addNewCurrency()}>
                        {saving
                            ? <>
                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> Saving...
                            </> : 'Save'}
                    </button>)}
            >
                <form action="" method="POST" className="form" id="CurrencyForm" encType="multipart/form-data">
                    <div className="card-body">
                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Select Currency</label>
                            <div className="col-lg-12 col-xl-12">
                                <select
                                    name="currency"
                                    className="form-control"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    {currencies.map((cy, index) => {
                                        return (
                                            <option key={index} selected={cy.abbr === currency ? true : false} value={cy.abbr}>{cy.name} - ({cy.abbr})</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                    </div>
                </form>
            </ModalBox>

            <ModalBox
                show={modal === "providers"}
                handleClose={closeModal}
                title={'Select Provider'}
                closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>}
                saveBtn={
                    (<button
                        type="button"
                        disabled={saving}
                        className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                        onClick={() => goToProviderSetupPage(provider)}>Connect Provider
                    </button>)}
            >
                <form action="" method="POST" className="form" id="ProviderForm" encType="multipart/form-data">
                    <div className="card-body">
                        {providers.map((prd) =>
                            <Fragment>
                                <Col lg={12} className={`mb-3 border rounded-2 p-3 ${prd.id === provider?.id ? 'selected' : ''} provider-card`} onClick={() => setProvider(prd)}>
                                    <Row className='align-items-center'>
                                        <Col lg={3} xs={3}>
                                            <div className='provider-logo'>
                                                <img src={prd.logo} width={'100%'} alt={prd.name} />
                                            </div>
                                        </Col>
                                        <Col lg={9} xs={9}>
                                            <div className='provider-details'>
                                                <h5>{prd.name}</h5>
                                                <small>{prd.description}</small>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Fragment>)}
                    </div>
                </form>
            </ModalBox>

            <ModalBox
                size={'lg'}
                show={modal === "manual-payments"}
                handleClose={closeModal}
                title={paymentMethod ? 'Edit Payment Method' : 'Add Payment Method'}
                closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                saveBtn={
                    (<button
                        type="button"
                        disabled={saving}
                        className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                        onClick={() => savePaymentMethod()}>
                        {saving
                            ? <>
                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> Saving...
                            </> : 'Save'}
                    </button>)}
            >
                <form action="" method="POST" className="form" id="paymentMethodForm" encType="multipart/form-data">
                    <div className="card-body">
                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Name</label>
                            <div className="col-lg-12 col-xl-12">
                                <input type="text" className="form-control form-control-solid" name="name"
                                    value={paymentMethod?.name} placeholder="Enter Method Name"
                                    onChange={(e) => handlePaymentDetailChange(e)} />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Description</label>
                            <div className="col-lg-12 col-xl-12">
                                <input className="form-control form-control-solid" name="description" type="text" value={paymentMethod?.description}
                                    onChange={(e) => handlePaymentDetailChange(e)} />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Payment Instructions <span></span></label>
                            <div className="col-lg-12 col-xl-12">
                                <TextEditor
                                    value={paymentMethod?.instruction}
                                    setValue={handleTextfieldChange} />
                            </div>
                        </div>

                        <div className="form-group row mb-3">
                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Payment Method Status<span></span></label>
                            <div className="col-lg-12 col-xl-12">
                                <div className="form-check form-switch form-switch-right form-switch-md">
                                    <label htmlFor="default-modal" className="form-label text-muted">{paymentMethod?.status === "enabled" ? "Enabled" : "Disabled"}</label>
                                    <input className="form-check-input code-switcher" type="checkbox" checked={paymentMethod?.status === "enabled"} onChange={switchMethodStatus} />
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </ModalBox>
        </BlankPage>



    )
}

export default PaymentSetup