import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import {
  errorResponse,
  responseMessage,
} from "../../libs/app";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import Pagination from "../../Components/Pagination";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import Loader from "../../Components/Loader/Loader";
import DeleteModal from "../../Components/DeleteModal";
import { addFiltersToEndpoint, dateChange } from "../../helpers";
import CustomerForm from "./CustomerForm";
import { AuthContext } from "../../Contexts/AuthContext";
import { formatCurrency } from 'wearslot-dev-utils';

const Customers = () => {

  document.title = 'Customers | Taojaa - Store Manager App'

  const { selected } = useParams();
  const { store, displayCurrency, subscription, handleUpgradeModalShow } = useContext(AuthContext);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setcustomer] = useState({
    name: "",
    email: "",
    phone: "",
    created_date: "",
    country_id: 140,
    status: 1,
  });

  const [selectedForDelete, setSelectedForDelete] = useState(null);

  var end = moment();
  var start = moment().subtract(7, "days");
  const [initialFrom, setInitialFrom] = useState(start.format("YYYY-MM-DD"));
  const [initialTo, setInitialTo] = useState(end.format("YYYY-MM-DD"));

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [pagination, setPagination] = useState(null);
  const [filters, setFilters] = useState({
    status: '',
    keywords: "",
    start_date: initialFrom,
    end_date: initialTo,
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const applyFilter = () => {
    getCustomers(filters);
  };

  const resetFilter = () => {
    setFilters({
      keywords: "",
      start_date: "",
      end_date: "",
      status: ""
    });
    getCustomers();
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }
    
    getCustomers();

    if (selected !== undefined) {
      getCustomerDetails(selected);
    }

  }, [currentPage, store, subscription]);

  async function getCustomers(filters = null) {
    try {

      let endpoint = `/customers?page=${currentPage}&perpage=${perPage}`;
      if (filters) {
        endpoint = addFiltersToEndpoint(filters, endpoint);
      }

      const response = await axios.get(endpoint);
      if (response.data.success) {
        setCustomers(response.data.customers);
        setPagination(response.data.pagination)
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setLoading(false)
  }


  async function getCustomerDetails(selected) {
    try {

      const response = await axios.get(`/customer/details/${selected}`);
      if (response.data.success) {
        setcustomer(response.data.customer);
        openModal("update")
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function deleteCustomer() {
    try {
      if (selectedForDelete === null) {
        return responseMessage("Something went wrong, invalid action attempted", "error");
      }

      const response = await axios.delete(`/customers/delete/${selectedForDelete}`);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getCustomers();

        closeDeleteModal();
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  function editCustomer(customer) {
    setcustomer(customer);
    openModal("update")
  }

  const openModal = (mode = "add") => {
    if (mode === "add") {
      if (subscription?.settings.allowManualOrderRecording === false) {
        return handleUpgradeModalShow(
          'Upgrade Your Plan',
          'Oops! Your current plan does not enable access to add customers manually, kindly upgrade to add customers.'
        )
      }
    }
    setModal(true)
  }

  const closeModal = () => {
    setModal(false);
    setcustomer({
      name: "",
      email: "",
      phone: "",
      created_date: "",
      country_id: 140,
      status: 1,
    })
  }

  const closeDeleteModal = () => {
    setSelectedForDelete(null)
  }


  const customerFormProps = {
    modal,
    saving,
    customer,
    setSaving,
    closeModal,
    setcustomer,
    getCustomers,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Customers</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Customers</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="customerList">
                <div className="card-header border-bottom-dashed">
                  <div className="row g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Customers</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success me-1"
                          onClick={() => openModal()}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add Customer
                        </button>
                        <button
                          type="button"
                          className="btn btn-info"
                          style={{ display: "none" }}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>
                          Import
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body border-bottom-dashed border-bottom">
                  <form>
                    <div className="row g-3">
                      <div className="col-xl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            name="keywords"
                            placeholder="Search for customers using name, email or phone..."
                            onChange={(e) => handleFilterChange(e)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>

                      <div className="col-xl-8">
                        <div className="row g-3">
                          <div className="col-sm-4">
                            <Flatpickr
                              className="form-control border-0 shadow"
                              id="dash-filter-picker"
                              placeholder="start date to end date"
                              options={{ mode: "range" }}
                              defaultValue={initialFrom + " to " + initialTo}
                              onChange={(selectedDates) => dateChange(selectedDates, setInitialFrom, setInitialTo)}
                            />
                          </div>

                          <div className="col-sm-4">
                            <div>
                              <select
                                className="form-control"
                                name="status"
                                id="idStatus"
                                onChange={(e) => handleFilterChange(e)}
                              >
                                <option value="">Status</option>
                                <option value="1">Active</option>
                                <option value="0">Blocked</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-xxl-2 col-sm-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={applyFilter}
                              >
                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                Filters
                              </button>
                            </div>
                          </div>

                          <div className="col-xxl-2 col-sm-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-danger w-100 filter-btn"
                                onClick={resetFilter}
                              >
                                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-body">
                  <div>
                    <div className="table-responsive table-card mb-1">
                      {customers.length > 0 ? (
                        <table
                          className="table align-middle"
                          id="customerTable"
                        >
                          <thead className="table-light text-muted">
                            <tr className="text-uppercase">
                              <th scope="col" style={{ width: "50" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th className="sort" data-sort="customer_name">Name</th>
                              <th className="sort" data-sort="email">Email</th>
                              <th className="sort" data-sort="phone">Phone</th>
                              <th className="sort" data-sort="spent">Spent</th>
                              <th className="sort" data-sort="orders">Orders</th>
                              <th className="sort" data-sort="date">Date</th>
                              <th className="sort" data-sort="status">Status</th>
                              <th className="sort" data-sort="action">Action</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {customers.map((customer, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="checkAll"
                                      value="option1"
                                    />
                                  </div>
                                </th>
                                <td className="customer_name">{customer.name}</td>
                                <td className="email">{customer.email}</td>
                                <td className="phone">{customer.phone}</td>
                                <td className="spent">{displayCurrency}{formatCurrency(customer.total_spent)}</td>
                                <td className="orders">{customer.orders}</td>
                                <td className="date">{moment(customer.created_date).format("DD MMM, Y")}</td>
                                <td className="status">
                                  {customer.status === "1" ? (
                                    <span className="badge badge-soft-success text-uppercase">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-soft-danger text-uppercase">
                                      Blocked
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li
                                      className="list-inline-item edit"
                                      data-bs-toggle="tooltip"
                                      data-bs-trigger="hover"
                                      data-bs-placement="top"
                                      title="Edit"
                                    >
                                      <a
                                        className="text-primary d-inline-block"
                                        onClick={() =>
                                          editCustomer(customer)
                                        }
                                      >
                                        <i className="ri-pencil-fill fs-16"></i>
                                      </a>
                                    </li>
                                    <li
                                      className="list-inline-item"
                                      data-bs-toggle="tooltip"
                                      data-bs-trigger="hover"
                                      data-bs-placement="top"
                                      title="Remove"
                                    >
                                      <a
                                        className="text-danger d-inline-block"
                                        onClick={() =>
                                          setSelectedForDelete(customer.id)
                                        }
                                      >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {loading
                            ? <div className="py-5"><Loader message={<p className="text-muted">Fetching customers...</p>} /></div>
                            : <SearchResponse title={"No Customer Available"}
                              message={<p>You don't have any customer yet.
                                <Link to={"#"} type="button" className='text-primary' onClick={openModal}> Add customer</Link>
                              </p>}
                            />
                          }
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Pagination pagination={pagination} onPageChange={onPageChange} />
                      </div>
                    </div>
                  </div>

                  <CustomerForm {...customerFormProps} />

                  <DeleteModal
                    show={selectedForDelete ? true : false}
                    title={'Confirm delete?'}
                    message={'Are you sure you want to remove this customer?'}
                    handleDelete={deleteCustomer}
                    handleClose={closeDeleteModal}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Outlet />
    </>
  );
};

export default Customers;
