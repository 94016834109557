import React from 'react';
import { Fragment } from "react";
import { Link } from "react-router-dom";

const QuickActions = () => {

    return (
        <Fragment>
            <div className="py-4">
                <h6 className="fw-bold mb-3">Quick Actions</h6>
                <div className="row">
                    <div className="col-3">
                        <div className="text-center">
                            <Link to={"/products/create"} className="btn btn-icon btn-soft-primary rounded-circle">
                                <i class="ri-price-tag-3-line fs-22"></i>
                            </Link>
                            <p className="p-0 m-0">
                                <small>Add Product</small>
                            </p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="text-center">
                            <Link to={"/sales-and-orders"} className="btn btn-icon btn-soft-danger rounded-circle">
                                <i class="ri-shopping-bag-3-line fs-22"></i>
                            </Link>
                            <p className="p-0 m-0">
                                <small>Record Sales</small>
                            </p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="text-center">
                            <Link to={"/payments/links"} className="btn btn-icon btn-soft-dark rounded-circle">
                                <i class="ri-links-line fs-22"></i>
                            </Link>
                            <p className="p-0 m-0">
                                <small>Payment Links</small>
                            </p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="text-center">
                            <Link to={"/invoices"} className="btn btn-icon btn-soft-success rounded-circle">
                                <i class="ri-file-list-2-line fs-22"></i>
                            </Link>
                            <p className="p-0 m-0">
                                <small>Invoice & Receipts</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default QuickActions;