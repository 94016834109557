import { toast } from "react-toastify";
import $ from "jquery";
import { logoutUser } from "../Contexts/AuthContext";

export function isLoggedIn() {

	const { loggedInUser, storeDetails } = authData();
	if (loggedInUser === null || storeDetails === null) {
		return false;
	}

	return true;
}

export function authData() {

	const user = localStorage.getItem("user");
	const store = localStorage.getItem("store");

	if (user !== null && store != null) {
		return { loggedInUser: JSON.parse(user), storeDetails: JSON.parse(store) };
	}

	return { loggedInUser: null, storeDetails: null };
}

export const responseMessage = (
	message,
	type = 'default',
	position = "top-right"
) => {

	const options = {
		position,
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	}

	if (type === "success") {
		toast.success(message, { ...options });
	} else if (type === "error") {
		toast.error(message, { ...options });
	} else if (type === "warning") {
		toast.warn(message, { ...options });
	} else if (type === "info") {
		toast.info(message, { ...options });
	} else {
		toast(message, { ...options });
	}
};

export const errorResponse = (response) => {

	if (response.data.message === 'Unauthorized request') {
		logoutUser(true);
	}

	if(response.data.message === 'No active subscription, kindly upgrade to continue.') {
		window.location = "/dashboard/access/choose/plan";
	} 

	responseMessage(response.data.message, "error");
	if (response.data.errors !== undefined) {
		Object.keys(response.data.errors).map((key) => responseMessage(response.data.errors[key], 'error'))
	}
}

export function uploadSelectedFiles(acceptedFiles, config = {}) {

	var upload_location = config.url;

	var form = new FormData();

	if (config.data !== undefined) {
		var data = config.data;
		for (var key in data) {
			form.append(key, data[key]);
		}
	}

	acceptedFiles.forEach((file) => {
		if (config.field !== undefined) {
			form.append(config.field, file);
		} else {
			form.append("file", file);
		}
	});

	return $.ajax({
		xhr: function () {
			var xhr = new window.XMLHttpRequest();
			xhr.upload.addEventListener(
				"progress",
				function (evt) {
					if (evt.lengthComputable) {
						var percentComplete = (evt.loaded / evt.total) * 100;
						$(".progress-bar").width(Math.floor(percentComplete) + "%");
						$(".progress-bar").html(Math.floor(percentComplete) + "%");
					}
				},
				false
			);
			return xhr;
		},
		type: "POST",
		url: upload_location,
		data: form,
		contentType: false,
		cache: false,
		processData: false,
		beforeSend: function () {

			$(".progress").removeClass("hide");
			$(".progress-bar").width("0%");
		},
		success: function (resp) {

			$(".progress").addClass("hide");
			$(".progress-bar").width("0%");
			return resp;

		},
		error: function (error) {

			$(".progress").addClass("hide");
			$(".progress-bar").width("0%");

			return error;
		}
	});
}
