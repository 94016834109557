import React, { useState, useEffect } from "react";
import ModalBox from "../../Components/ModalBox";
import { useNavigate, useParams, Link } from "react-router-dom";
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { errorResponse, responseMessage } from "../../libs/app";
import axios from "axios";
import DeleteModal from "../../Components/DeleteModal";
import { Form } from "react-bootstrap";
import Async from "react-select/async";

const AddMenu = () => {
  const [modal, setModal] = useState(null);
  const [saving, setSaving] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [menu, setMenu] = useState({
    nav_title: "",
    data: [],
  });
  const [name, setName] = useState("");
  const [forDelete, setForDelete] = useState(null);
  const [itemtobeEdited, setItemToBeEdited] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const getmenuItem = () => {
    // setLoading(true);
    axios
      .get(`/settings/navigations/${id}`)
      .then((response) => {
        const menuItemsFromId = JSON.parse(response?.data?.data?.data);

        setMenu((prev) => {
          return {
            ...prev,
            nav_title: response?.data?.data?.nav_title,
            data: [...menuItemsFromId],
          };
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getmenuItem();
    }
  }, [id]);
  const closeModal = () => {
    setModal(null);
  };
  const deafultNavigations = [
    { value: "/", label: "Home" },
    { value: "/shop", label: "Shop" },
    { value: "/collections", label: "Collections" },
    { value: "/contact", label: "Contact" },
  ];
  const openModal = (type, editData) => {
    setModal(type);
    if (type === "editMenu" && editData) {
      const itemToeditset = deafultNavigations?.find(
        (element) => element?.value === editData?.url
      );
      setSelectedSearch(itemToeditset);
      setName(editData?.name);
      setItemToBeEdited(editData);
    } else {
      setModal(type);
      setSelectedSearch();
      setName("");
    }
  };

  const handleMenu = () => {
    if (modal === "addMenu") {
      setMenu((prev) => {
        const newId = prev?.data?.length || 0; // Calculate the new id based on the current length of the data array
        const item = {
          id: newId,
          name: name,
          url: selectedSearch?.value,
        };
        return {
          ...prev,
          data: [...prev?.data, item],
        };
      });
      closeModal();
    } else if (modal === "editMenu") {
      const newItems = {
        id: itemtobeEdited?.id,
        name: name,
        url: selectedSearch?.value,
      };
      setMenu((prev) => ({
        ...prev,
        data: prev?.data?.map((item, index) =>
          item.id === itemtobeEdited?.id // Use the index to find and update the item
            ? newItems
            : item
        ),
      }));
      closeModal();
    }
  };

  const handleSave = async () => {
    // e.preventDefault();
    setSaving(true);
    try {
      if (menu.nav_title === "") {
        return responseMessage("menu title is empty", "warning");
      }

      if (menu?.data?.length === 0) {
        return responseMessage("Add menu Items", "warning");
      }


      if (id) {
        const data = { id: id, ...menu };
        const response = await axios.put("/settings/navigations/update", data);
        if (response.data.success) {
          responseMessage(response.data.message);

          closeModal();
          window.history.back()
        }
      } else {
        const data = { ...menu };
        const response = await axios.post("/settings/navigations/create", data);
        if (response.data.success) {
          responseMessage(response.data.message);
          closeModal();
          window.history.back()
        }
      }
    } catch (error) {

      if (error.response) {
        return errorResponse(error.response);
      }
      return responseMessage("Something went wrong.", "error");
    } finally {
      setSaving(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the state of the object based on the input field
    setMenu((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  const openDeleteModal = (id) => {
    setItemToDelete(id);
    setForDelete("delete");
  };
  const closeDeleteModal = () => {
    setForDelete(null);
  };



  const deleteMenuItem = (number) => {
    let newArray = menu?.data?.filter((item) => {
      return item.id !== itemToDelete;
    });

    setMenu((prev) => ({
      ...prev,
      data: [...newArray],
    }));
    closeDeleteModal();
  };



  const createOption = ({ name, url, }) => {
    return {
      value: url,
      label: `${name}`,
    };
  }


  const getUrlOptions = async (inputValue) => {
    try {
      var navigationList = inputValue ? deafultNavigations.filter(navigation =>
        navigation.label.indexOf(inputValue) > -1 || navigation.value.indexOf(inputValue) > -1) : deafultNavigations;

      const response = await axios.get(
        `/settings/navigations/search?keywords=${inputValue}`
      );
      if (response.data.success) {

        const option = response.data.data;
        const options = option.map(createOption);

        navigationList = [...navigationList, ...options];
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    } finally {
      return navigationList;
    }
  };


  const handleUrlChange = (newValue) => {
    setSelectedSearch(newValue)
  };



  return (
    <>
      <div className="page-content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Navigation</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="#" onClick={() => window.history.back()}>
                        <i className="ri-arrow-left-line align-bottom me-1"></i>
                        Back
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">

            <div className="col-xxl-9 mb-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Navigation Title</h4>
                </div>
                <div className="card-body">
                  <div className='mt-2'>
                    <input
                      type="text"
                      className="form-control w-100 rounded-4  border-dark"
                      id="nav_title"
                      name="nav_title"
                      placeholder="e.g. Header Menu "
                      value={menu?.nav_title}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="alert alert-primary mt-2">
                    This is used to reference the navigation menu on the storefront.
                    e.g. a navigation with the title “Header menu” would be accessible via the `store.navigation` object using  `header_menu`.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-9 mb-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Menu Items</h4>
                </div>
                <div className="card-body">
                  <div className="mt-2">
                    {menu?.data?.length > 0 ? (
                      <>
                        {menu?.data?.map((item, index) => {
                          return (
                            <div key={index} className=" w-100 p-2 text-lg">
                              <div className="col d-flex align-items-center justify-content-between">
                                <div className="d-flex gap-2 align-items-center">
                                  <PiDotsSixVerticalBold color="black" />
                                  <div>{item?.name}</div>
                                </div>
                                <div className="d-flex rounded">
                                  <div
                                    className="p-2 text-primary link"
                                    onClick={() => {
                                      openModal("editMenu", item);
                                    }}
                                  >
                                    <i className="ri-pencil-fill fs-16"></i>
                                  </div>
                                  <div
                                    onClick={() => {
                                      openDeleteModal(item?.id);
                                    }}
                                    className="p-2 text-danger link"
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <p className=" text-center fs-5 bg-light border rounded-2 border-2 h-100 p-5 m-4">
                        This menu doesn't have any items
                      </p>
                    )}
                  </div>
                </div>
                <div
                  onClick={() => {
                    openModal("addMenu");
                  }}
                  className="d-flex align-item-center text-primary bg-light fs-5 gap-4 p-4 link"
                >
                  <div className="cursor-pointer">+</div>
                  <div className="cursor-pointer"> Add menu item</div>
                </div>
              </div>
            </div>

            <div className="col-xxl-9 gap-2 mb-5 text-end">
              <button
                className="btn ms-1 btn-light w-sm "
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <button
                onClick={() => handleSave()}
                className="btn ms-1 btn-primary w-sm "
              >
                {saving ? (
                  <>
                    <div
                      className="spinner-border spinner-border-sm "
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>{" "}
                  </>
                ) : (
                  "Save Menu"
                )}
              </button>
            </div>
          </div>

        </div>
      </div>
      <ModalBox
        show={modal}
        handleClose={closeModal}
        title={"Add Menu"}
        closeBtn={
          <button type="button" className="btn btn-light" onClick={closeModal}>
            Close
          </button>
        }
        saveBtn={
          <button
            type="button"
            disabled={saving}
            className={`btn btn-success ${saving ? "disabled" : ""}`}
            onClick={() => handleMenu()}
          >
            {saving ? (
              <>
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                Proccessing...
              </>
            ) : (
              <div>{modal === "editMenu" ? "Edit" : "Add item"}</div>
            )}
          </button>
        }
      >
        <form>
          <div className="mb-3"></div>

          <div className="mb-3 flex flex-column gap-2">
            <div>
              <label htmlFor="amount-field" className="form-label">
                Name
              </label>
              <input
                type="text"
                id="name-field"
                className="form-control  rounded-2"
                placeholder="e.g. About us "
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mt-4">
              <Form.Label>Search or paste a link</Form.Label>
              <Async
                name="exclude"
                controlShouldRenderValue
                isClearable
                defaultOptions={deafultNavigations}
                loadOptions={getUrlOptions}
                onChange={handleUrlChange}
                noOptionsMessage={(props) =>
                  "Search for urls, pages, products, collections"
                }
                value={selectedSearch}
                onFocus={getUrlOptions}
              />
            </div>
          </div>
        </form>
      </ModalBox>
      <DeleteModal
        show={forDelete ? true : false}
        title={"Delete confirmation"}
        message={"Are you sure you want to delete this menuitem?"}
        handleDelete={deleteMenuItem}
        handleClose={closeDeleteModal}
      />
    </>
  );
};

export default AddMenu;
