import React from 'react'

const Placeholder = ({column = 'col-12', height = '15px', radius = '0'}) => {
  return (
    <p className="placeholder-wave">
        <span className={`placeholder ${column}`} style={{ minHeight: `${height}`, borderRadius: `${radius}` }}></span>
    </p>
  )
}

export default Placeholder