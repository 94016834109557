import React, { useState } from "react";

const CodeBlock = ({ code }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = async () => {
    setCopySuccess(true);

    try {
      await navigator.clipboard.writeText(code);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <div className="position-relative">
      <pre className="bg-light rounded p-3 overflow-auto">
        <code className="text-monospace">{code}</code>
      </pre>
      <button
        onClick={handleCopy}
        className="btn position-absolute top-0 end-0 m-2 btn btn-primary btn-sm "
      >
        {copySuccess ? "Copied!" : "Copy Code"}
      </button>
    </div>
  );
};

export default CodeBlock;
