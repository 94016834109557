import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthContext";
import { responseMessage } from "../../libs/app";
import SendInvoiceModal from "./SendInvoiceModal";

import InvoiceCard from "./InvoiceCard";
import { Button } from "react-bootstrap";

const Invoice = () => {

  document.title = 'Invoice Details | Taojaa - Store Manager App'

  const { invoice_id } = useParams();
  const { store, subscription } = useContext(AuthContext);

  const [invoice, setInvoice] = useState(null);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getInvoice();
  }, [store, subscription]);


  async function getInvoice() {

    try {

      const response = await axios.get(`/invoice/details/${invoice_id}`)
      if (response.data.success) {
        setInvoice(response.data.data);
      }

    } catch (error) {
      responseMessage("Something went wrong!!", "error");
    }
  }

  const openModal = (target) => {
    setModal(target)
  }

  const closeModal = () => {
    setModal(null)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Invoice Details</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="#" onClick={() => window.history.back()} >
                        <i className="ri-arrow-left-line align-bottom me-1"></i>
                        Back to List
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-9">
              <div className="card" id="demo">
                <InvoiceCard invoice={invoice} store={store} />
                <div className="card-body p-4">
                  <div className="hstack gap-2 justify-content-end d-print-none">
                    {invoice &&
                      <Button type="button" className="btn btn-success" onClick={() => openModal('send-invoice')}>
                        <i className="ri-send-plane-fill align-bottom me-1"></i> Send
                      </Button>}
                    <Button className="btn btn-primary" onClick={() => {
                      window.location = `${process.env.REACT_APP_API_ENDPOINT}/download/invoice/${invoice.invoice_id}`
                    }}>
                      <i className="ri-download-2-line align-bottom me-1"></i> Download
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SendInvoiceModal show={modal === 'send-invoice' ? true : false} closeModal={closeModal} invoice_id={invoice && invoice.invoice_id} />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Invoice;
