
import axios from "axios";
import React, { useState } from "react";

export const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [settings, setSettings] = React.useState({
    layout: "detached",
    theme: "light",
    sidebar: "close",
  });
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [currencies, setCurrencies] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [saving, setSaving] = React.useState(false);

  const [emailTemplate, setEmailTemplate] = React.useState({
    html: null,
    design: null,
  });
  const [campaign, setCampaign] = React.useState({});

  const [video, setVideo] = useState("");
  const [videoUrl , setVideoUrl] = useState("")

  // const [collectionSlugs, setCollectionSlugs] = React.useState([]);
  const [blogFiles , setBlogFiles] =  useState([])
  const [ blogFilesPreview , setBlogFilesPreview ] = useState("")
  const [fileSent , setFileSent] =  useState(null)


  const orderStatus = [
    { id: 1, name: "Pending" },
    { id: 2, name: "Confirmed" },
    { id: 3, name: "Packed" },
    { id: 4, name: "Shipped" },
    { id: 5, name: "Delivered" },
    { id: 6, name: "Returned" },
    { id: 0, name: "Cancelled" },
  ];

  const salesChannel = [
    { id: "Storefront", name: "Storefront" },
    { id: "Physical Store", name: "Physical Store" },
    { id: "WhatsApp", name: "WhatsApp" },
    { id: "Instagram", name: "Instagram" },
    { id: "Facebook Marketplace", name: "Facebook Marketplace" },
    { id: "Jumia", name: "Jumia" },
    { id: "Konga", name: "Konga" },
    { id: "Jiji", name: "Jiji" },
    { id: "Twitter", name: "Twitter" },
    { id: "PayStack", name: "PayStack" },
    { id: "Flutterwave", name: "Flutterwave" },
    { id: "Snapchat", name: "Snapchat" },
    { id: "Others", name: "Others" },
  ];

  const availability_types = [
    { id: 1, label: "Available in store" },
    { id: 2, label: "Made on request" },
    { id: 3, label: "Not available" },
  ];

  const statuses = [
    { id: 1, name: "Active" },
    { id: 0, name: "Inactive" },
  ];

  React.useEffect(() => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("data-layout-style", settings.layout);
    document
      .getElementsByTagName("html")[0]
      .setAttribute("data-layout-mode", settings.theme);
    document
      .getElementsByTagName("html")[0]
      .setAttribute("data-sidebar", settings.theme);

    if (settings.sidebar === "open") {
      document
        .getElementsByTagName("body")[0]
        .setAttribute("class", "twocolumn-panel vertical-sidebar-enable");
    } else {
      document
        .getElementsByTagName("body")[0]
        .setAttribute("class", "twocolumn-panel");
    }
  }, [settings]);

  React.useEffect(() => {
    getPaymentMethods();
    getCurrencies();
    getCountries();
  }, []);

  const changeTheme = () => {
    let theme = settings.theme === "light" ? "dark" : "light";
    setSettings({ ...settings, theme });

    updateUserSettings({ name: "theme", value: theme });
    localStorage.setItem("settings", JSON.stringify({ ...settings, theme }));
  };

  const changeLayout = () => {
    let layout = settings.layout === "detached" ? "default" : "detached";
    setSettings({ ...settings, layout });

    updateUserSettings({ name: "layout", value: layout });
    localStorage.setItem("settings", JSON.stringify({ ...settings, layout }));
  };

  const toggleSidebar = () => {
    let sidebar = settings.sidebar === "open" ? "close" : "open";
    setSettings({ ...settings, sidebar });

    localStorage.setItem("settings", JSON.stringify({ ...settings, sidebar }));
  };

  async function updateUserSettings(data) {
    try {
      const response = await axios.put("/settings/update/user-settings", data);
      if (response.data.success) {
      }
    } catch (error) {}
  }

  async function getPaymentMethods() {
    try {
      let response = await axios.get("/settings/payment/methods");
      if (response.data.success) {
        setPaymentMethods(response.data.data);
      }
    } catch (error) {}
  }

  async function getCurrencies() {
    try {
      let response = await axios.get("/currencies");
      if (response.data.success) {
        setCurrencies(response.data.currencies);
      }
    } catch (error) {}
  }

  async function getCountries() {
    try {
      var request = axios.get("/countries");
      var response = (await request).data;

      if (response.success) {
        setCountries(response.data);
      }
    } catch (error) {}
  }

  async function getStates(country_id) {
    try {
      var request = axios.get(`/states/${country_id}`);
      var response = (await request).data;

      if (response.success) {
        setStates(response.data);
      }
    } catch (error) {}
  }

  const paymentStatuses = [
    { id: 111, name: "Paid" },
    { id: 100, name: "Partially Paid" },
    { id: 316, name: "Unpaid" },
  ];

  return (
    <AppContext.Provider
      value={{
        changeTheme,
        changeLayout,
        toggleSidebar,
        setSettings,
        getStates,
        setSaving,
        setEmailTemplate,
        setCampaign,

        setVideo,
        setVideoUrl,

        // setCollectionSlugs,
        setBlogFiles,
        setFileSent,
        setBlogFilesPreview,
        fileSent,
        blogFiles,
        blogFilesPreview,


        settings,
        orderStatus,
        salesChannel,
        paymentMethods,
        paymentStatuses,
        statuses,
        saving,
        emailTemplate,
        campaign,
        currencies,
        availability_types,
        countries,
        states,
        video, 
        videoUrl,
        // collectionSlugs,
        

      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
