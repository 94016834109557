import React from 'react'
import { Modal } from 'react-bootstrap'

const DeleteModal = (props) => {

    return (
        <Modal
            centered
            show={props.show}
            onHide={props.handleClose}
            className="zoomIn"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-2 text-center">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="loop"
                        colors="primary:#f7b84b,secondary:#f06548"
                        style={{ width: "80px", height: "80px" }}
                    ></lord-icon>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>{props.title}</h4>
                        <div className="text-muted mx-4 mb-0">{props.message}</div>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" className="btn w-sm btn-light">
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn w-sm btn-danger"
                        onClick={props.handleDelete}
                    >
                        {props.deleting
                            ? <>
                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </> : 'Yes, Delete It!'}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const CloseConfirmation = (props) => {

    return (<Modal
        centered
        show={props.show}
        onHide={props.handleClose}
        className="zoomIn"
        aria-labelledby="contained-modal-title-vcenter"
    >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="mt-2 text-center">
                <lord-icon src="https://cdn.lordicon.com/tdrtiskw.json"
                    trigger="loop"
                    colors="primary:#f7b84b,secondary:#405189"
                    style={{ width: "80px", height: "80px" }}>
                </lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>{props.title}</h4>
                    <div className="text-muted mx-4 mb-0">{props.message}</div>
                </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button type="button" className="btn w-sm btn-light">
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn w-sm btn-danger"
                    onClick={props.handleConfirm}
                >
                    {props.deleting
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </> : 'Yes, Continue!'}
                </button>
            </div>
        </Modal.Body>
    </Modal>)
}

export default DeleteModal