import axios from 'axios';
import moment from 'moment';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import { errorResponse, responseMessage } from '../../libs/app';
import SearchResponse from '../../Components/SearchResponse/SearchResponse';
import Loader from '../../Components/Loader/Loader';
import ModalBox from '../../Components/ModalBox';
import DeleteModal from '../../Components/DeleteModal';
import MyDropzone from '../../Components/MyDropZone';
import $ from "jquery";
import IconWithTooltip from '../../Components/IconWithTooltip';
import ImagePreview from '../../Components/ImagePreview';
import { AuthContext } from '../../Contexts/AuthContext';

const Collections = () => {

    document.title = 'Collections | Taojaa - Store Manager App'

    const { store, subscription } = useContext(AuthContext);

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({
        keyword: '',
        status: ''
    });

    const [collection, setCollection] = useState({
        name: '',
        image: '',
        parent_id: null,
        featured: false,
        status: null
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [collections, setCollections] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [forDelete, setForDelete] = useState(null);
    const [modal, setModal] = useState(false);

    const collectionStatus = [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" }
    ];

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }

        getCollections();
    }, [store, subscription]);

    const bannerUploadResponse = (response) => {
        if (response.success) {
            setCollection({ ...collection, image: response.image });
            localStorage.setItem("temporary_collection_image", response.image);

            $("#uploadStatus").html(
                '<p style="color:#28A74B;">File uploaded successfully!</p>'
            );

            responseMessage(response.message, "success");
        }
        else if (response.success == false) {

            $("#uploadStatus").html(
                `<p style="color:#EA4335;">${response.message}</p>`
            );
        }

        $(".progress").addClass("hide");
        $(".progress-bar").width("0%");
    }

    const onRemove = () => {
        setCollection({ ...collection, image: null });
        localStorage.setItem("temporary_collection_image", null);
    }

    async function getCollections() {

        try {

            const response = await axios.get(`/collections`);
            if (response.data.success === true) {
                setCollections(response.data.data);
                setPagination(response.data.pagination)
            }

        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setLoading(false)
    }

    async function handleSubmit(e) {

        e.preventDefault();

        try {
            setSaving(true)
            var response;
            if (collection.id !== undefined) {
                response = await axios.put('/collection/update', collection)
            } else {
                response = await axios.post('/collection/create', collection)
            }

            if (response.data.success) {
                responseMessage(response.data.message, "success");
                localStorage.setItem("temporary_collection_image", null);
                getCollections();

                closeModal()
            }

        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function deleteCollection() {

        try {

            const response = await axios.delete(`/collection/delete/${forDelete}`);

            if (response.data.success) {
                responseMessage(response.data.message, "success");

                getCollections();
                closeDeleteModal();
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    function editCollection(collection) {
        setCollection(collection);
        openModal()
    }

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const CollectionStatus = ({ status }) => {
        if (status === 0) {
            return <span className="badge badge-soft-warning text-uppercase">Inactive</span>
        } else if (status === 1) {
            return <span className="badge badge-soft-success text-uppercase">Active</span>
        }
    }

    const filterTable = () => {

    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setCollection({ ...collection, [name]: value });
    }

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
        setCollection({
            name: '',
            status: null
        })
    }

    const closeDeleteModal = () => {
        setForDelete(null)
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Collections</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="#">Inventory</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Collections</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card" id="orderList">
                                <div className="card-header border-bottom-dashed">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">
                                            Collections
                                        </h5>
                                        <div className="flex-shrink-0">
                                            <button
                                                type="button"
                                                className="btn btn-success me-1"
                                                onClick={openModal}
                                            >
                                                <i className="ri-add-line align-bottom me-1"></i> Add Collection
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body border-bottom-dashed border-bottom">
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-xl-8">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Search for collection..."
                                                        name="keyword"
                                                        value={filter.keyword}
                                                        onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="row g-3">
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <select
                                                                className="form-control"
                                                                name="choices-single-default"
                                                                id="idStatus"
                                                                onChange={(e) => {
                                                                    if (
                                                                        e.target.value.toLowerCase() == "active"
                                                                    ) {
                                                                        setFilter({ ...filter, status: 1 })
                                                                    } else if (
                                                                        e.target.value.toLowerCase() == "inactive"
                                                                    ) {
                                                                        setFilter({ ...filter, status: 0 })
                                                                    } else {
                                                                        setFilter({ ...filter, status: '' })
                                                                    }
                                                                }}
                                                            >
                                                                <option value="">All</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary w-100"
                                                                onClick={filterTable}
                                                            >
                                                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                                                Filters
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body">
                                    <div>

                                        <div className="table-responsive table-card mb-1">
                                            {collections.length > 0 ? (
                                                <table
                                                    className="table table-nowrap align-middle"
                                                    id="orderTable"
                                                >
                                                    <thead className="text-muted table-light">
                                                        <tr className='text-uppercase'>
                                                            <th scope="col" style={{ width: "25px" }}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="checkAll"
                                                                        value="option"
                                                                    />
                                                                </div>
                                                            </th>
                                                            <th className="sort" data-sort="name">
                                                                Name
                                                            </th>
                                                            <th className="sort" data-sort="status">
                                                                Status
                                                            </th>
                                                            <th className="sort" data-sort="created_date">
                                                                Created Date
                                                            </th>
                                                            <th className="sort" data-sort="updated_date">
                                                                Updated Date
                                                            </th>
                                                            <th className="sort" data-sort="actions">
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {collections.map((collection, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="checkAll"
                                                                            value="option1"
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <td className="customer_name">
                                                                    {collection.name}
                                                                </td>
                                                                <td className="status">
                                                                    <CollectionStatus status={Number(collection.status)} />
                                                                </td>
                                                                <td className="created_date">
                                                                    {moment(collection.created_date).format('ddd, D MMM YYYY')}
                                                                </td>
                                                                <td className="updated_date">
                                                                    {moment(collection.updated_date).format('ddd, D MMM YYYY')}
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li
                                                                            className="list-inline-item edit"
                                                                            title="Edit"
                                                                        >
                                                                            <Link
                                                                                to="#"
                                                                                className="text-primary d-inline-block edit-item-btn"
                                                                                onClick={() => editCollection(collection)}
                                                                            >
                                                                                <i className="ri-pencil-fill fs-16"></i>
                                                                            </Link>
                                                                        </li>
                                                                        <li
                                                                            className="list-inline-item"
                                                                            title="Remove"
                                                                        >
                                                                            <Link
                                                                                className="text-danger d-inline-block"
                                                                                to="#deleteCollection"
                                                                                onClick={() =>
                                                                                    setForDelete(collection.id)
                                                                                }
                                                                            >
                                                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <>
                                                    {loading
                                                        ? <div className="py-5"><Loader message={<p className="text-muted">Fetching collections...</p>} /></div>
                                                        : <SearchResponse title={"No Collection Available"}
                                                            message={<p>You haven't create any collection yet.
                                                                <Link to={"#"} type="button" className='text-primary' onClick={openModal}> Create collection</Link>
                                                            </p>}
                                                        />
                                                    }
                                                </>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Pagination pagination={pagination} previous={goToPreviousPage} next={goToNextPage} />
                                            </div>
                                        </div>
                                    </div>

                                    <ModalBox
                                        show={modal}
                                        handleClose={closeModal}
                                        title={collection && collection.id !== undefined ? 'Edit Collection' : 'Add Collection'}
                                        closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                                        saveBtn={
                                            (<button
                                                type="submit"
                                                disabled={saving}
                                                className={`btn btn-success ${saving ? 'disabled' : ''}`}
                                                onClick={(e) => handleSubmit(e)}>
                                                {saving
                                                    ? <>
                                                        <div className="spinner-border spinner-border-sm text-white" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div> Saving...
                                                    </> : 'Save Collection'}
                                            </button>)}
                                    >
                                        <form action="#">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="collection-name"
                                                    className="form-label"
                                                >
                                                    Collection Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="collection-name"
                                                    className="form-control"
                                                    name="name"
                                                    value={collection.name}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label
                                                    htmlFor="parent_collection"
                                                    className="form-label"
                                                >
                                                    Parent Collection
                                                    <IconWithTooltip message={'This applies when the collection belongs to an exiting collection. e.g Dress Shoes, which belongs to a Shoe collection'}>
                                                        <small className='text-danger'><i className='ri-information-line'></i></small>
                                                    </IconWithTooltip>
                                                </label>
                                                <select
                                                    className="form-control"
                                                    name="parent_id"
                                                    id="parent_collection"
                                                    value={collection.parent_id}
                                                    onChange={handleChange}
                                                >
                                                    <option defaultChecked>Select collection</option>
                                                    {collections.map(({ id, name }) => {
                                                        return (
                                                            <option key={id} selected={collection.parent_id == id ? true : false} value={id}>{name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label
                                                    htmlFor="delivered-status"
                                                    className="form-label"
                                                >
                                                    Collection Status
                                                </label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    id="delivered-status"
                                                    value={collection.status}
                                                    onChange={handleChange}
                                                >
                                                    <option defaultChecked>Select status</option>
                                                    {collectionStatus.map(({ id, name }) => {
                                                        return (
                                                            <option key={id} selected={collection.status == id ? true : false} value={id}>{name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div>
                                                <div className='col-md-12'>
                                                    {collection.image ? (
                                                        <ImagePreview
                                                            image={collection.image}
                                                            query={collection?.id ? { 'collection': collection.id } : null}
                                                            onRemove={onRemove}
                                                        />
                                                    ) : (
                                                        <MyDropzone
                                                            endpoint={"/settings/storefront/upload-banner"}
                                                            message="Select Image"
                                                            max_files={1}
                                                            beforeUpload={() => {
                                                                $("#uploadStatus").html("");
                                                                $(".progress").removeClass("hide");
                                                                $(".progress-bar").width("0%");
                                                            }}
                                                            onUploadResponse={bannerUploadResponse}
                                                            onUploadError={() => {
                                                                $("#uploadStatus").html(
                                                                    '<p style="color:#EA4335;">File upload failed, please try again.</p>'
                                                                );
                                                                $(".progress").addClass("hide");
                                                                $(".progress-bar").width("0%");
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div className="progress mt-2 hide">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: 0 }}
                                                        aria-valuenow="0"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        0%
                                                    </div>
                                                </div>
                                                <div id="uploadStatus" className="mt-2 text-center"></div>
                                            </div>
                                            <div className="mt-3">
                                                <div className="form-check">
                                                    <input
                                                        onChange={() => setCollection({ ...collection, featured: !collection.featured })}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="formCheck2"
                                                        checked={collection.featured}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="formCheck2"
                                                    >
                                                        Feature Collection on Storefront
                                                    </label>
                                                </div>
                                            </div>
                                        </form>
                                    </ModalBox>

                                    <DeleteModal
                                        show={forDelete ? true : false}
                                        title={'Delete confirmation'}
                                        message={'Are you sure you want to delete this collection?'}
                                        handleDelete={deleteCollection}
                                        handleClose={closeDeleteModal}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default Collections