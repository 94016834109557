import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Chart from "react-apexcharts";
import Flatpickr from "react-flatpickr";
import RecentActivity from "../Shared/Components/RecentActivity";
import { AuthContext } from "../Contexts/AuthContext";
import { OrderStatus } from "./Orders/Orders";
import Loader from "../Components/Loader/Loader";
import { errorResponse, responseMessage } from "../libs/app";
import { formatCurrency } from "wearslot-dev-utils";
import { copyLink } from "../helpers";
import IconWithTooltip from "../Components/IconWithTooltip";
import getSymbolFromCurrency from "currency-symbol-map";
import BestSellingTable from "../Components/BestSellingTable";
import TodoTask from "../Components/TodoTask";
import QuickActions from "../Components/QuickActions";

const Dashboard = () => {
  document.title = "Dashboard | Taojaa - Business Management App";

  const {
    user,
    store,
    currency,
    subscription,
    selectedWallet,
    onboardingMode,
    setOnboardingMode,
    displayCurrency,
  } = React.useContext(AuthContext);

  const referModalState = localStorage.getItem("refer_modal") != "false";
  const [referModal, setReferModal] = useState(referModalState);
  const [taskBarDisplay, setTaskBarDisplay] = useState(true);
  const taskBarDisplayCheck = useCallback(() => {
    if (
      store.store_setup_status &&
      store.has_products &&
      store.payment_setup_status &&
      store.shipping_setup_status &&
      user?.verified
    ) {
      setOnboardingMode(false);
      return setTaskBarDisplay(false);
    }

    setOnboardingMode(true);
    return setTaskBarDisplay(true);
  }, [store]);

  const referModalClose = useCallback(() => {
    localStorage.setItem("refer_modal", "false");
    setReferModal(false);
  }, []);

  var linechartcustomerColors = ["#73dce9", "#695eef", "#ff7f41", "#11d1b7"];
  var storeOverviewChart = {
    series: [
      {
        name: "Orders",
        type: "area",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Est. Earnings",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Customers",
        type: "line",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Revenue",
        type: "area",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: { height: 377, type: "line", toolbar: { show: !1 } },
      stroke: { curve: "straight", dashArray: [0, 0, 8], width: [2, 0, 2.2] },
      fill: { opacity: [0.1, 0.9, 1] },
      markers: { size: [0, 0, 0], strokeWidth: 2, hover: { size: 4 } },
      xaxis: {
        categories: [
          moment().subtract(6, "days").format("MMM DD, YY"),
          moment().subtract(5, "days").format("MMM DD, YY"),
          moment().subtract(4, "days").format("MMM DD, YY"),
          moment().subtract(3, "days").format("MMM DD, YY"),
          moment().subtract(2, "days").format("MMM DD, YY"),
          moment().subtract(1, "days").format("MMM DD, YY"),
          moment().format("MMM DD, YY"),
        ],
        axisTicks: { show: !1 },
        axisBorder: { show: !1 },
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      grid: {
        show: !0,
        xaxis: { lines: { show: !0 } },
        yaxis: { lines: { show: !1 } },
        padding: { top: 0, right: -2, bottom: 15, left: 10 },
      },
      legend: {
        show: !0,
        horizontalAlign: "center",
        offsetX: 0,
        offsetY: -5,
        markers: { width: 9, height: 9, radius: 6 },
        itemMargin: { horizontal: 10, vertical: 0 },
      },
      plotOptions: { bar: { columnWidth: "30%", barHeight: "70%" } },
      colors: linechartcustomerColors,
      tooltip: {
        shared: !0,
        y: [
          {
            formatter: function (e) {
              return e;
            },
          },
          {
            formatter: function (e) {
              var formated = displayCurrency + formatCurrency(e);
              return formated;
            },
          },
          {
            formatter: function (e) {
              return e;
            },
          },
          {
            formatter: function (e) {
              var formated = displayCurrency + formatCurrency(e);
              return formated;
            },
          },
        ],
      },
    },
  };

  var chartDonutBasicColors = [
    "#695eef",
    "rgba(80, 195, 230,0.85)",
    "rgba(237, 94, 94,0.70)",
    "rgba(19, 197, 107,0.60)",
    "rgba(232, 188, 82,0.45)",
  ];
  var storeVisitsBySource = {
    options: {
      labels: ["Direct", "Google", "Social", "Email", "Referrals"],
      legend: { position: "bottom" },
      stroke: { show: !1 },
      dataLabels: {
        dropShadow: {
          enabled: !1,
        },
      },
      colors: chartDonutBasicColors,
    },
    series: [44, 55, 41, 17, 15],
  };

  const [overviewChart, setOverviewChart] = useState({ ...storeOverviewChart });
  const [visitors, setVisitors] = useState({ today: 0, total: 0 });
  const [storeVisitsPie, setStoreVisitsPie] = useState({
    ...storeVisitsBySource,
  });
  const [reports, setReports] = useState({
    total_earnings: 0,
    total_earnings_percentage: 0,
    orders: 0,
    total_orders_percentage: 0,
    customers: 0,
    total_customers_percentage: 0,
    total_balance: 0,
  });
  const [best_selling, setBestSelling] = useState({
    items: [],
    pagination: {},
  });
  const [orders, setOrders] = useState([]);

  var start = moment().subtract(6, "days");
  var end = moment();
  const [initialFrom, setInitialFrom] = useState(start.format("YYYY-MM-DD"));
  const [initialTo, setInitialTo] = useState(end.format("YYYY-MM-DD"));

  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [activeChartRange, setActiveChartRange] = useState("Last 7 days");

  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    if (!onboardingMode) {
      getData(initialFrom, initialTo);
      getChart(initialFrom, initialTo);
      getStoreVisits(initialFrom, initialTo);
    } else {
      setLoading(false);
    }

    taskBarDisplayCheck();
  }, [initialFrom, initialTo, selectedWallet, store, subscription]);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  function getData(from, to) {
    var _best_selling_products = axios.get(`/best-selling-items`);
    var _analytics = axios.get(
      `/reports?${
        selectedWallet ? `currency=${selectedWallet.currency.abbr}` : ""
      }`
    );
    var _recent_orders = axios.get(`/orders?perpage=5&store_id=${store.id}`);

    axios
      .all([_best_selling_products, _analytics, _recent_orders])
      .then(
        axios.spread((...response) => {
          if (response[0].data.success === true) {
            setBestSelling({
              items: response[0].data.items,
              pagination: response[0].data.pagination,
            });
          }

          if (response[1].data.success === true) {
            setReports(response[1].data.reports);
          }

          if (response[2].data) {
            setOrders(response[2].data.data);
          }

          setLoading(false);
        })
      )
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      });
  }

  function getChart(from, to) {
    axios
      .get(
        `/charts?start_date=${from}&end_date=${to}${
          selectedWallet ? `&currency=${selectedWallet.currency.abbr}` : ""
        }`
      )
      .then((response) => {
        if (response.data) {
          var _charts = response.data;
          storeOverviewChart.series[0].data = _charts.orders;
          storeOverviewChart.series[1].data = _charts.sales;
          storeOverviewChart.series[2].data = _charts.customers;
          storeOverviewChart.series[3].data = _charts.revenues;
          storeOverviewChart.options.xaxis.categories = _charts.ticks;
          setOverviewChart({ ...storeOverviewChart });
        }
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      });
  }

  async function getStoreVisits(from, to) {
    try {
      const response = await axios.get(
        `/store-visits-by-source?start_date=${from}&end_date=${to}`
      );
      if (response.data.success) {
        storeVisitsBySource.options.labels = response.data.data.labels;
        storeVisitsBySource.series = response.data.data.series;
        setStoreVisitsPie({ ...storeVisitsBySource });
        setVisitors({
          today: response.data.data.today,
          total: response.data.data.total,
        });
      }
    } catch (error) {}
  }

  const onDateSelected = (selectedDates) => {
    if (selectedDates.length === 2) {
      var start = moment(selectedDates[0]);
      var end = moment(selectedDates[1]);

      setInitialFrom(start.format("YYYY-MM-DD"));
      setInitialTo(end.format("YYYY-MM-DD"));
    }
  };

  const Percantage = ({ value }) => {
    return (
      <div className="flex-shrink-0">
        {value < 0 ? (
          <h5
            className="text-danger fs-14 mb-0"
            style={{ whiteSpace: "nowrap" }}
          >
            <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
            {value}%
          </h5>
        ) : (
          <>
            {value > 0 ? (
              <h5
                className="text-success fs-14 mb-0"
                style={{ whiteSpace: "nowrap" }}
              >
                <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                {value}%
              </h5>
            ) : (
              <h5
                className="text-muted fs-14 mb-0"
                style={{ whiteSpace: "nowrap" }}
              >
                +0.00%
              </h5>
            )}
          </>
        )}
      </div>
    );
  };

  const chartRangeHandler = (name, from, to) => {
    setActiveChartRange(name);
    getChart(from, to);
  };

  const resendVerification = async () => {
    setSending(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_ENDPOINT}/account/resend-verification`
      );
      if (response.data.success) {
        responseMessage(response.data.message, "success");
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }
    setSending(false);
  };

  function getGreetingTime(m) {
    var g = null; //return g

    if (!m || !m.isValid()) {
      return;
    } //if we can't find a valid or filled moment, we return.

    var split_afternoon = 12; //24hr time to split the afternoon
    var split_evening = 17; //24hr time to split the evening
    var currentHour = parseFloat(m.format("HH"));

    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      g = "Good afternoon";
    } else if (currentHour >= split_evening) {
      g = "Good evening";
    } else {
      g = "Good morning";
    }

    return g;
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {!loading ? (
            <div className="row">
              <div className="col">
                <div className="h-100">
                  {user?.verified != 1 && (
                    <div
                      className="alert alert-primary alert-dismissible fade show"
                      role="alert"
                    >
                      <strong> Hi {user?.firstname}! </strong> kindly verify
                      your email address! 
                      <button
                        className="btn btn-primary btn-sm me-3"
                        disabled={sending}
                        onClick={() => resendVerification()}
                        style={{
                          position: "absolute",
                          right: 50,
                          textDecoration: "underline",
                        }}
                      >
                        {sending ? "Sending.." : "Resend verification"}
                      </button>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}
                  <div className="row mb-3 pb-1">
                    <div className="col-12">
                      <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                          <h4 className="fs-16 mb-1">
                            {getGreetingTime(moment())}, {user.firstname}!
                          </h4>
                          <p className="text-muted mb-0">
                            See what's happening on <b>{store.name}</b> today.
                          </p>
                        </div>
                        {!onboardingMode && (
                          <div className="mt-3 mt-lg-0">
                            <form action="">
                              <div className="row g-3 mb-0 align-items-center">
                                <div className="col-auto">
                                  <IconWithTooltip
                                    message={
                                      copied
                                        ? "Link copied!"
                                        : "Copy website link"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-soft-primary btn-icon waves-effect waves-light layout-rightside-btn"
                                      style={{
                                        padding: "0.5rem 0.9rem",
                                        width: "auto",
                                      }}
                                      onClick={() => {
                                        copyLink(
                                          `https://${store.slug}.${process.env.REACT_APP_STOREFRONT_DOMAIN}`,
                                          setCopied
                                        );
                                      }}
                                    >
                                      <i className="ri-link"></i>
                                      <span className="ms-1">
                                        {store.slug}.
                                        {
                                          process.env
                                            .REACT_APP_STOREFRONT_DOMAIN
                                        }
                                      </span>
                                    </button>
                                  </IconWithTooltip>
                                </div>

                                <div className="col-sm-auto">
                                  <div className="input-group">
                                    <Flatpickr
                                      type="text"
                                      className="form-control border-0 shadow"
                                      id="dash-filter-picker"
                                      defaultValue={
                                        initialFrom + " to " + initialTo
                                      }
                                      options={{ mode: "range" }}
                                      onChange={onDateSelected}
                                    />
                                    <div className="input-group-text bg-primary border-primary text-white">
                                      <i className="ri-calendar-2-line"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {window.innerWidth <= 1024 && <QuickActions />}

                  {onboardingMode ? (
                    <TodoTask taskBarDisplay={taskBarDisplay} />
                  ) : (
                    <Fragment>
                      <div className="row statistics">
                        <div className="col-xl-3 col-md-6 col-6">
                          <div className="card card-animate">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Total Sales
                                  </p>
                                </div>
                                <Percantage
                                  value={reports.total_earnings_percentage}
                                />
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    {displayCurrency}
                                    <span className="counter-value">
                                      {formatCurrency(reports.total_earnings)}
                                    </span>
                                  </h4>
                                  <Link
                                    to="/payments/transactions"
                                    className="text-decoration-underline"
                                  >
                                    Transactions
                                  </Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0 desktop">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    <i className="bx bx-dollar-circle text-primary"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-3 col-md-6 col-6">
                          <div className="card card-animate">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Orders
                                  </p>
                                </div>
                                <Percantage
                                  value={reports.total_orders_percentage}
                                />
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    <span className="counter-value">
                                      {reports.orders}
                                    </span>
                                  </h4>
                                  <Link
                                    to="/sales-and-orders"
                                    style={{ whiteSpace: "nowrap" }}
                                    className="text-decoration-underline"
                                  >
                                    All orders
                                  </Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0 desktop">
                                  <span className="avatar-title bg-soft-info rounded fs-3">
                                    <i className="bx bx-shopping-bag text-info"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-3 col-md-6 col-6">
                          <div className="card card-animate">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Customers
                                  </p>
                                </div>
                                <Percantage
                                  value={reports.total_customers_percentage}
                                />
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    <span className="counter-value">
                                      {reports.customers}
                                    </span>
                                  </h4>
                                  <Link
                                    to="/customers"
                                    className="text-decoration-underline"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    All Customers
                                  </Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0 desktop">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    <i className="bx bx-user-circle text-primary"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-3 col-md-6 col-6">
                          <div className="card card-animate">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    My Balance
                                  </p>
                                </div>
                                <Percantage value={0} />
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    {displayCurrency}
                                    <span className="counter-value">
                                      {selectedWallet
                                        ? formatCurrency(
                                            selectedWallet?.balance
                                          )
                                        : formatCurrency(reports.total_balance)}
                                    </span>
                                  </h4>
                                  <Link
                                    to="/payments/wallets"
                                    className="text-decoration-underline"
                                  >
                                    My Wallets
                                  </Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0 desktop">
                                  <span className="avatar-title bg-soft-info rounded fs-3">
                                    <i className="bx bx-wallet text-info"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-8">
                          <div className="card">
                            <div className="card-header border-0 d-flex flex-sm-column">
                              <h4 className="card-title flex-grow-1">
                                Overview
                              </h4>
                              <div>
                                <button
                                  type="button"
                                  className={`btn ${
                                    activeChartRange === "Today"
                                      ? "btn-soft-primary"
                                      : "btn-soft-secondary"
                                  } btn-sm me-1`}
                                  onClick={() =>
                                    chartRangeHandler(
                                      "Today",
                                      moment().format("YYYY-MM-DD"),
                                      moment()
                                        .add(1, "day")
                                        .format("YYYY-MM-DD")
                                    )
                                  }
                                >
                                  Today
                                </button>
                                <button
                                  type="button"
                                  className={`btn ${
                                    activeChartRange === "Last 7 days"
                                      ? "btn-soft-primary"
                                      : "btn-soft-secondary"
                                  } btn-sm me-1`}
                                  onClick={() =>
                                    chartRangeHandler(
                                      "Last 7 days",
                                      moment()
                                        .subtract(6, "days")
                                        .format("YYYY-MM-DD"),
                                      moment().format("YYYY-MM-DD")
                                    )
                                  }
                                >
                                  Last 7 days
                                </button>
                                <button
                                  type="button"
                                  className={`btn ${
                                    activeChartRange === "30 days"
                                      ? "btn-soft-primary"
                                      : "btn-soft-secondary"
                                  } btn-sm me-1`}
                                  onClick={() =>
                                    chartRangeHandler(
                                      "30 days",
                                      moment()
                                        .subtract(29, "days")
                                        .format("YYYY-MM-DD"),
                                      moment().format("YYYY-MM-DD")
                                    )
                                  }
                                >
                                  30 days
                                </button>
                                <button
                                  type="button"
                                  className={`btn ${
                                    activeChartRange === "1 year"
                                      ? "btn-soft-primary"
                                      : "btn-soft-secondary"
                                  } btn-sm me-1`}
                                  onClick={() =>
                                    chartRangeHandler(
                                      "1 year",
                                      moment()
                                        .subtract(1, "year")
                                        .format("YYYY-MM-DD"),
                                      moment().format("YYYY-MM-DD")
                                    )
                                  }
                                >
                                  1 year
                                </button>
                              </div>
                            </div>

                            <div className="card-header p-0 border-0 bg-soft-light">
                              <div className="row g-0 text-center">
                                <div className="col-6 col-sm-3">
                                  <div className="p-3 border border-dashed border-start-0">
                                    <h5 className="mb-1">
                                      <span className="counter-value">
                                        {overviewChart.series[0].data.reduce(
                                          (a, b) => a + b,
                                          0
                                        )}
                                      </span>
                                    </h5>
                                    <p className="text-muted mb-0">Orders</p>
                                  </div>
                                </div>

                                <div className="col-6 col-sm-3">
                                  <div className="p-3 border border-dashed border-start-0">
                                    <h5 className="mb-1">
                                      {displayCurrency}
                                      <span className="counter-value">
                                        {formatCurrency(
                                          overviewChart.series[1].data.reduce(
                                            (a, b) => a + b,
                                            0
                                          )
                                        )}
                                      </span>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      Est. Earnings
                                    </p>
                                  </div>
                                </div>

                                <div className="col-6 col-sm-3">
                                  <div className="p-3 border border-dashed border-start-0">
                                    <h5 className="mb-1">
                                      <span className="counter-value">
                                        {overviewChart.series[2].data.reduce(
                                          (a, b) => a + b,
                                          0
                                        )}
                                      </span>
                                    </h5>
                                    <p className="text-muted mb-0">Customers</p>
                                  </div>
                                </div>

                                <div className="col-6 col-sm-3">
                                  <div className="p-3 border border-dashed border-start-0 border-end-0">
                                    <h5 className="mb-1 text-success">
                                      {displayCurrency}
                                      <span className="counter-value">
                                        {formatCurrency(
                                          overviewChart.series[3].data.reduce(
                                            (a, b) => a + b,
                                            0
                                          )
                                        )}
                                      </span>
                                    </h5>
                                    <p className="text-muted mb-0">Revenue</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card-body p-0 pb-2">
                              <div className="w-100">
                                <div
                                  id="customer_impression_charts"
                                  data-colors='["--vz-info", "--vz-indigo", "--vz-danger"]'
                                  className="apex-charts"
                                  dir="ltr"
                                  style={{ minHeight: 355 }}
                                >
                                  {overviewChart ? (
                                    <Chart
                                      options={overviewChart.options}
                                      series={overviewChart.series}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-4">
                          <div className="card mb-3" style={{ height: "43%" }}>
                            <div className="card-header align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                Visitors
                              </h4>
                            </div>

                            <div className="card-body justify-content-center align-items-center d-flex">
                              <div className="card-content">
                                <div className="text-center mb-4">
                                  <h1 className="m-0" style={{ fontSize: 80 }}>
                                    {visitors.today}
                                  </h1>
                                  <p>
                                    <span>Visitors Today</span>
                                  </p>
                                </div>
                                <div className="text-center">
                                  <h3 style={{ fontSize: 17 }}>
                                    Total visitors: {visitors.total}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card mb-4">
                            <div className="card-header align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                Store visits by source
                              </h4>
                            </div>

                            <div className="card-body px-1">
                              <Chart
                                dir="ltr"
                                className="apex-charts"
                                type="donut"
                                height={270}
                                options={storeVisitsPie.options}
                                series={storeVisitsPie.series}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="card card-height-100">
                            <div className="card-header align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                Best Selling Items
                              </h4>
                            </div>

                            <BestSellingTable
                              best_selling={best_selling}
                              currency={currency}
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="card card-height-100">
                            <div className="card-header align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                Recent Orders
                              </h4>
                              <div className="flex-shrink-0">
                                <Link
                                  to="/sales-and-orders"
                                  className="btn btn-primary btn-sm"
                                >
                                  View All
                                </Link>
                              </div>
                            </div>

                            <div className="card-body">
                              {orders.length > 0 ? (
                                <Fragment>
                                  <div className="table-responsive table-card">
                                    <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                      <thead className="text-muted text-uppercase table-light">
                                        <tr>
                                          <th scope="col">Order ID</th>
                                          <th scope="col">Items</th>
                                          <th scope="col">Amount</th>
                                          <th scope="col">Status</th>
                                          <th scope="col">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {orders.map((order, index) => (
                                          <tr key={index}>
                                            <td>
                                              <Link
                                                to={`/order/${order.id}/details`}
                                                className="fw-medium link-primary"
                                              >
                                                #{order.order_no}
                                              </Link>
                                            </td>
                                            <td>
                                              {order.first_item ? (
                                                <div>
                                                  <p className="m-0">
                                                    {String(
                                                      order.first_item
                                                    ).substring(0, 15) + "..."}
                                                  </p>
                                                  {order.additional_items_count >
                                                  0 ? (
                                                    <small className="text-muted">
                                                      {" "}
                                                      +{" "}
                                                      {
                                                        order.additional_items_count
                                                      }{" "}
                                                      other item(s)
                                                    </small>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              ) : (
                                                <span className="text-muted">
                                                  No Item
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              {getSymbolFromCurrency(
                                                order.currency
                                              ) +
                                                formatCurrency(order.sub_total)}
                                            </td>
                                            <td>
                                              <OrderStatus
                                                status={order.status}
                                              />
                                            </td>
                                            <td>
                                              <li
                                                className="list-inline-item"
                                                title="View"
                                              >
                                                <Link
                                                  to={`/order/${order.id}/details`}
                                                  className="text-primary d-inline-block"
                                                >
                                                  <i className="ri-eye-fill fs-16"></i>
                                                </Link>
                                              </li>
                                              <li
                                                className="list-inline-item edit"
                                                title="Edit"
                                              >
                                                <Link
                                                  to={`/order/${order.id}`}
                                                  className="text-primary d-inline-block edit-item-btn"
                                                >
                                                  <i className="ri-pencil-fill fs-16"></i>
                                                </Link>
                                              </li>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </Fragment>
                              ) : (
                                <div className="text-center align-items-center">
                                  <p> No order available! </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <RecentActivity />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "75vh" }}
            >
              {" "}
              <Loader message={"Fetching data..."} />{" "}
            </div>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Dashboard;
