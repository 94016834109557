import React from 'react'

import './loader.css'

const Loader = ({message}) => {
    return (
        <div className='loader-component'>
            <div className='loader-wrapper d-flex justify-content-center'>
                <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                </div>
            </div>
            <div className='text-center'>{message}</div>
        </div>
    )
}

export default Loader