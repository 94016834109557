import React from 'react'
import OrderItemAction from './OrderItemAction'
import Placeholder from '../../Components/Placeholder/Placeholder'
import { formatCurrency } from 'wearslot-dev-utils'
import getSymbolFromCurrency from 'currency-symbol-map'

const OrderItem = ({ item, order, update = false, editOrderItem, getOrderItems, setItemForDelete }) => {

    return (
        <tr>
            <td>
                <div className="row">
                    <div className="col-md-3 mb-3 avatar-md bg-light rounded p-1" style={{ overflow: 'hidden' }}>
                        <img
                            alt={item.name}
                            src={item.image}
                            className="img-fluid d-block"
                        />
                    </div>
                    <div className="col-md-7">
                        <h5 className="fs-15" style={{ maxWidth: "250px", whiteSpace: "break-spaces" }}>
                            <a
                                href="#"
                                className="link-primary"
                            >
                                {item.name}
                            </a>
                        </h5>
                        {
                            item.variation ? (
                                <>
                                    <p className="text-muted mb-0">
                                        <span className="fw-medium">{item.variation.variant}</span>
                                    </p>
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </td>
            <td>{getSymbolFromCurrency(order.currency) + formatCurrency(item.price)}</td>
            <td>{item.quantity}</td>
            <td className="fw-medium text-end">{getSymbolFromCurrency(order.currency) + formatCurrency(item.quantity * item.price)}</td>
            {
                update === true ? (
                    <td>
                        <OrderItemAction
                            item={item}
                            editItem={editOrderItem}
                            getOrderItems={getOrderItems}
                            deleteItem={setItemForDelete}
                        />
                    </td>
                ) : null
            }
        </tr>
    )
}

export const OrderItemPlaceholder = ({ update }) => {
    return (
        <tr>
            <td>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Placeholder height={'60px'} />
                        </div>
                        <div className='col-md-7'>
                            <Placeholder height={'15px'} column={'col-8 mb-1'} />
                            <Placeholder height={'15px'} column={'col-12 mt-1'} />
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <Placeholder column={'col-8 mb-1'} />
            </td>
            <td>
                <Placeholder column={'col-2 mb-1'} />
            </td>
            <td className='fw-medium text-end'>
                <Placeholder column={'col-6 mb-1'} />
            </td>
            {
                update === true ? (
                    <td>
                        <div className='row'>
                            <div className='col-md-6'> <Placeholder column={'col-12 mb-1'} /> </div>
                            <div className='col-md-6'> <Placeholder column={'col-12 mb-1'} /> </div>
                        </div>
                    </td>
                ) : null
            }

        </tr>
    );
}

export default OrderItem