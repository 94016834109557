import React from 'react'
import Select from 'react-select'
import CreateAbleSelect from "react-select/creatable";

const InvoiceItem = ({ 
    id, item, 
    options = [], 
    increase, 
    decrease, 
    onSelect, 
    removeItem,
    handleItemPrice,
    onVariationSelect,
    enableSelect = false, 
    handleCreateProduct
}) => {


    const ProductSelect = props => <CreateAbleSelect {...props}
        isMulti={false}
        isSearchable={true}
        closeMenuOnSelect={true}
        placeholder={'Select Product'}
        styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                border: 'none',
                backgroundColor: '#eff2f7',
                outline: 'none',
                boxShadow: 'none'
            }),
            indicatorsContainer: (baseStyles, state) => ({
                ...baseStyles,
                display: 'none'
            })
        }}
        isLoading={true}
        options={options}
        onChange={(selected) => onSelect(selected, id)}
        onCreateOption={handleCreateProduct}
    />

    const VariationSelect = props => <Select {...props}
        isMulti={false}
        isSearchable={true}
        closeMenuOnSelect={true}
        placeholder={'Select Variation'}
        styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                border: 'none',
                backgroundColor: '#eff2f7',
                outline: 'none',
                boxShadow: 'none'
            }),
            indicatorsContainer: (baseStyles, state) => ({
                ...baseStyles,
                display: 'none'
            })
        }}
        isLoading={true}
        options={item.options}
        onChange={(selected) => onVariationSelect(selected, id)}
    />

    return (
        <tr id={item.id} className="product">
            <th scope="row" className="product-id">
                {id + 1}
            </th>
            <td className="text-start">
                <div className="mb-2">
                    {enableSelect ?
                        <ProductSelect /> :
                        <>
                            <div className='mb-2'>
                                <input
                                    type="text"
                                    placeholder='Product Name'
                                    value={item.name}
                                    className="form-control bg-light border-0"
                                    readOnly={true}
                                />
                            </div>
                            {
                                (item.hasVariations && item.description === null) ? <VariationSelect /> 
                                : item.description ? <textarea className="form-control bg-light border-0" rows="1" 
                                    placeholder="Item Details" value={item.description} readOnly={true}></textarea> : null
                            }
                        </>
                    }
                </div>
            </td>
            <td>
                <input
                    type="number"
                    className="form-control product-price bg-light border-0"
                    value={item.price}
                    onChange={(e) => handleItemPrice(id, e)}
                    readOnly={false}
                />
            </td>
            <td>
                <div className="input-step">
                    <button type="button" className="minus" onClick={() => decrease(id)}>
                        –
                    </button>
                    <input
                        type="number"
                        className="product-quantity"
                        value={item.quantity}
                        onChange={() => { }}
                        readOnly={true}
                    />
                    <button type="button" className="plus" onClick={() => increase(id)}>
                        +
                    </button>
                </div>
            </td>
            <td className="text-end">
                <div>
                    <input
                        type="text"
                        className="form-control bg-light border-0 product-line-price"
                        value={(item.quantity * item.price)}
                        readOnly={true}
                    />
                </div>
            </td>
            <td className="product-removal">
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={removeItem}
                >
                    Delete
                </button>
            </td>
        </tr>
    )
}

export default InvoiceItem