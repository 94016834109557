import React from "react";

import icon from '../../../logos/taojaa/icon-color.png';

const Icon = () => {
  return (
    <span className="logo-sm">
      <img src={icon} alt="" height="30" />
    </span>
  );
};

export default Icon;
