import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { errorResponse, responseMessage } from "../../libs/app";
import Loader from "../../Components/Loader/Loader";
import DeleteModal from "../../Components/DeleteModal";
import { toast } from "react-toastify";
import PageItem from "../../Components/PageItem";
import { AuthContext } from "../../Contexts/AuthContext";


export async function saveSettings(settings, showResponseMessage = false) {
  try {
    const response = await axios.put("/settings/storefront/save", {
      settings: JSON.stringify(settings),
    });
    if (response.data.success) {
      showResponseMessage && responseMessage(response.data.message, "success");
    }
  } catch (error) {
    if (error.response) errorResponse(error.response);
    else responseMessage("Something went wrong.", "error");
  }
}


const Pages = () => {
  document.title = "Storefront Settings | Taojaa - Store Manager App";

  const { store, subscription } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [forDelete, setForDelete] = useState(null);
  const [pageToDelete, setPageToDelete] = useState();

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getPages();
  }, [store, subscription]);


  const getPages = () => {
    setLoading(true);
    axios
      .get(`/settings/pages`)
      .then((response) => {

        setPages(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.message)

      })
      .finally(() => {
        setLoading(false);
      });
  };
  const openDeleteModal = (id) => {

    setPageToDelete(id);
    setForDelete("delete");
  };

  const deletePageItem = () => {
    setLoading(true);
    axios
      .delete(`/settings/pages/${pageToDelete}`)
      .then((response) => {

        if (response.data.success) {
          getPages();
          toast.success(response?.data?.message);
        }
        setForDelete(null);
      })
      .catch((error) => {
        toast.error(error?.messsage)
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const closeDeleteModal = () => {
    setForDelete(null);
  };
  
 return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Pages</h4>
              </div>
            </div>
          </div>

          <div className="row mt-5 justify-content-center mx-auto">
            <div className="col-xxl-9">
              <div className="row">
                <div className="card card-height-100">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Pages</h4>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <p className="text-muted text-muted">

                      </p>
                      <div className="c-actions">
                        <Link to="addPage">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="ri-add-circle-line"></i>
                            <span className="desktop">Add Page </span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center p-3 border-bottom">
                      <div className="col-2">Page Title</div>
                      <div className="col">Description</div>
                    </div>
                    {pages.length > 0 ? (
                      <div className="w-100 p-2">
                        {pages.map((item, index) => {
                          return (
                            <PageItem
                              title={item?.name}
                              description={item?.description}
                              key={index}
                              id={item.id}
                              openDeleteModal={openDeleteModal}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {loading ? (
                          <div className="col-xxl py-5">
                            <Loader message={<p className="text-muted"></p>} />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="live-preview">
          <div></div>
        </div>
      </div>
      <DeleteModal
        show={forDelete ? true : false}
        title={"Delete confirmation"}
        message={"Are you sure you want to delete this?"}
        handleDelete={deletePageItem}
        handleClose={closeDeleteModal}
        deleting={loading}
      />

      <Outlet />
    </>
  );
};

export default Pages;
