import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { errorResponse, responseMessage } from '../../libs/app'
import { AuthContext } from '../../Contexts/AuthContext'
import ModalBox from '../../Components/ModalBox'
import DeleteModal from '../../Components/DeleteModal'


const AnnouncementsSetup = () => {

    document.title = 'Notice Setup | Taojaa - Store Manager App';

    const { store, subscription } = useContext(AuthContext)
    const [saving, setSaving] = useState(false);
    const [modal, setModal] = useState(false);
    const [notices, setNotices] = useState([]);
    const [notice, setNotice] = useState({
        message: '',
        status: 1,
    });
    const [selectedForDelete, setSelectedForDelete] = useState(null);

    const statuses = [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" }
    ];

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }

        getNotices()
    }, [store, subscription]);

    async function getNotices() {
        try {
            const response = await axios.get(`/settings/notices`);
            if (response.data.success) {
                setNotices(response.data.data);
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function saveNotice() {

        try {
            setSaving(true)

            const data = { ...notice, store_id: store.id }
            var response;

            if (notice.id !== undefined) {
                response = await axios.put('/settings/update/notice', data);
            } else {
                response = await axios.post('/settings/create/notice', data);
            }

            if (response.data.success) {
                responseMessage(response.data.message, 'success')
                getNotices()
            }

            closeModal();

        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function deleteNotice() {

        try {
            if (selectedForDelete === null) {
                return responseMessage("Something went wrong, invalid action attempted", "error");
            }

            const response = await axios.delete(`/settings/notice/delete/${selectedForDelete}`);

            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getNotices();

                closeDeleteModal()
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    const editNotice = (notice) => {
        setNotice(notice);
        openModal()
    }

    const handleNoticeDetailChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setNotice({ ...notice, [name]: value });
    }

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
        setNotice({
            message: '',
            status: 1,
        })
    }

    const closeDeleteModal = () => {
        setSelectedForDelete(null)
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Marketing - Announcement</h4>
                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col-xxl-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">All Announcements</h4>
                                </div>
                                <div className="card-body">
                                    <div className='d-flex justify-content-between'>
                                        <div className='bank-details'>
                                            <p className="text-muted">Add announcement & pop-up messages for storefront.</p>
                                        </div>
                                        <div className=''>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={openModal}>
                                                <i className="bx bxs-megaphone"></i> <span className='desktop'>Add Announcement</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className=''>
                                        {notices.length > 0 && (
                                            <table
                                                className="table align-middle"
                                                id="noticesTable"
                                            >
                                                <thead className="table-light text-muted">
                                                    <tr className="text-uppercase">
                                                        <th scope="col" style={{ width: "50" }}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="notice"
                                                                />
                                                            </div>
                                                        </th>

                                                        <th className="sort" data-sort="name">
                                                            Message
                                                        </th>
                                                        <th className="sort" data-sort="status">
                                                            Status
                                                        </th>
                                                        <th className="sort" data-sort="action">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {notices.map((notice, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="checkAll"
                                                                        value="notice1"
                                                                    />
                                                                </div>
                                                            </th>
                                                            <td className="message">
                                                                {notice.message}
                                                            </td>
                                                            <td className="status">
                                                                {notice.status === 1 ? (
                                                                    <span className="badge badge-soft-success text-uppercase">
                                                                        Active
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-soft-danger text-uppercase">
                                                                        Inactive
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li
                                                                        className="list-inline-item edit"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-trigger="hover"
                                                                        data-bs-placement="top"
                                                                        title="Edit"
                                                                    >
                                                                        <a
                                                                            href="#notice"
                                                                            className="text-primary d-inline-block edit-item-btn"
                                                                            onClick={() =>
                                                                                editNotice(notice)
                                                                            }
                                                                        >
                                                                            <i className="ri-pencil-fill fs-16"></i>
                                                                        </a>
                                                                    </li>
                                                                    <li
                                                                        className="list-inline-item"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-trigger="hover"
                                                                        data-bs-placement="top"
                                                                        title="Remove"
                                                                    >
                                                                        <a
                                                                            className="text-danger d-inline-block remove-item-btn"
                                                                            href="#deleteShippingOption"
                                                                            onClick={() =>
                                                                                setSelectedForDelete(notice.id)
                                                                            }
                                                                        >
                                                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )
                                        }
                                    </div>
                                    <div className="live-preview">
                                        <div>
                                            <ModalBox
                                                show={modal}
                                                handleClose={closeModal}
                                                title={notice && notice.id !== undefined ? 'Edit Notice' : 'Add Notice'}
                                                closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                                                saveBtn={
                                                    (<button
                                                        type="button"
                                                        disabled={saving}
                                                        className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                                                        onClick={() => saveNotice()}>
                                                        {saving
                                                            ? <>
                                                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div> Saving...
                                                            </> : 'Save'}
                                                    </button>)}
                                            >
                                                <form action="" method="POST" className="form" id="noticeForm" encType="multipart/form-data">
                                                    <div className="card-body">
                                                        <div className="form-group row mb-3">
                                                            <label className="col-xl-3 col-lg-3 col-form-label text-right">Message</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <input type="text" className="form-control form-control-solid" name="message"
                                                                    value={notice.message} placeholder="Enter message e.g Delivery takes 2 - 4 days"
                                                                    onChange={(e) => handleNoticeDetailChange(e)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mb-3">
                                                            <label className="col-xl-3 col-lg-3 col-form-label text-right">Status</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <select
                                                                    name="status"
                                                                    className="form-control"
                                                                    value={notice.status}
                                                                    onChange={handleNoticeDetailChange}
                                                                >
                                                                    {statuses.map(({ id, name }) => {
                                                                        return (
                                                                            <option key={id} selected={notice.status == id ? true : false} value={id}>{name}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </form>
                                            </ModalBox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <DeleteModal
                    show={selectedForDelete ? true : false}
                    title={'Delete confirmation'}
                    message={'Are you sure you want to delete this notice?'}
                    handleDelete={deleteNotice}
                    handleClose={closeDeleteModal}
                />
            </div>
            <Outlet />
        </>
    )
}

export default AnnouncementsSetup