import React, { useState, useEffect, useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Loader from "../../Components/Loader/Loader";
import { errorResponse, responseMessage } from "../../libs/app";
import { formatCurrency } from "wearslot-dev-utils";

import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import Pagination from "../../Components/Pagination";
import ModalBox from "../../Components/ModalBox";
import Select from "react-select";
import getSymbolFromCurrency from "currency-symbol-map";
import { IoWalletOutline } from "react-icons/io5";
import Flatpickr from "react-flatpickr";
import { dateChange } from "../../helpers";
import { AuthContext } from "../../Contexts/AuthContext";

const UsersWallet = () => {
  document.title = "Payment - Wallet | Taojaa - Business Management App";

  const { store, subscription } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [allWallets, setAllWallet] = useState([]);
  const [modal, setModal] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState([]);
  const [amount, setAmount] = useState(0.0);
  const [withdrawal, setWithdrawal] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [walletId, setWalletId] = useState("");
  const [saving, setSaving] = useState(false);
  const [walletsDropdown, setWalletsDropdown] = useState([]);
  const [walletInfo, setWalletInfo] = useState(null);
  const [filters, setFilters] = useState({
    status: null,
    keywords: "",
    start_date: null,
    end_date: null,
  });
  const [transactionInfo, setTransactionInfo] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getWallets();
  }, [store, subscription]);
  useEffect(() => {
    getTransaction(walletId);
    getAnalytics(walletId);
  }, [walletId]);

  function getWallets() {
    setLoading(true);
    axios
      .get("/wallets")
      .then((response) => {
        setAllWallet(response?.data?.data);

        setWalletId(response.data.data[0].id);
        setWalletInfo(response.data.data[0]);
        if (response.data.success) {
          var walletOptions = response.data.data.map((wallet) => {
            return {
              label:
                wallet.currency.abbr +
                " - Balance: " +
                getSymbolFromCurrency(wallet.currency.abbr) +
                formatCurrency(wallet.balance),
              value: wallet.id,
            };
          });

          setWalletsDropdown(walletOptions);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const getTransaction = (walletId) => {
    setLoading(true);
    axios
      .get(`/wallet/transactions/${walletId}`)
      .then((response) => {
        setTransactions(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getAnalytics = (walletId) => {
    setLoading(true);
    axios
      .get(`/wallet/analytics/${walletId}`)
      .then((response) => {
        setAnalytics(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      if (Number(amount) <= 0) {
        return responseMessage("Enter a valid withdrawal amount", "warning");
      }

      if (selectedWallet.length === 0) {
        return responseMessage(
          "Select a wallet to perform withrawal",
          "warning"
        );
      }

      const data = { wallet_id: selectedWallet.value, amount };
      const response = await axios.post("/withdraw", data);

      if (response.data.success) {
        responseMessage(response.data.message);
        closeModal();
      }
    } catch (error) {
      if (error.response) {
        return errorResponse(error.response);
      }
      return responseMessage("Something went wrong.", "error");
    } finally {
      setSaving(false);
    }
  };
  const openModal = (type) => {
    setModal(type);
  };
  const closeModal = () => {
    setModal(null);
    setAmount(0.0);
    setSelectedWallet([]);
    setWithdrawal(null);
  };

  const handleWalletIdChange = (e) => {
    const selectedWallet = e.target.value;

    const selectedCurrency = allWallets.find(
      (wallet) => wallet?.id == selectedWallet
    );

    setWalletInfo(selectedCurrency);

    setWalletId(e.target.value);
    getTransaction(e.target.value);
    getAnalytics(e.target.value);
  };
  const resetFilter = () => {
    setFilters({
      status: null,
      keywords: "",
      start_date: null,
      end_date: null,
    });
    // getTransactions();
  };
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };
  const applyFilter = () => {
    // getTransactions(filters);
  };
  const viewTransaction = (data) => {
    console.log(data);
    setTransactionInfo(data);
  };
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">My Wallets</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Wallets</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {allWallets.length > 0 ? (
              <>
                <div className="col-xl-3 col-md-6 col-6">
                  <div className="card card-animate">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="d-flex  gap-2 w-100 position-relative">
                          <p className="text-uppercase fw-semibold text-muted mb-0">
                            Balance
                          </p>
                          <p className="text-uppercase fw-semibold text-muted mb-0 position-absolute top-0 end-0">
                            <IoWalletOutline size={20} color="#695eef" />
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          {/* <h5 className="text-success fs-14 mb-0">
                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                  +7.55 %
                </h5> */}
                        </div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                          <h4 className=" d-flex fs-22 fw-bold ff-secondary mb-4">
                            <span>
                              {walletInfo?.currency?.abbr === "USD"
                                ? "$"
                                : walletInfo?.currency?.abbr === "NGN"
                                  ? "₦"
                                  : walletInfo?.currency?.abbr === "GBP"
                                    ? "£"
                                    : ""}
                            </span>
                            <span className="counter-value">
                              {walletInfo?.balance}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-6">
                  <div className="card card-animate">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="d-flex w-100 position-relative">
                          <p className="text-uppercase fw-semibold text-muted mb-0">
                            Inflow
                          </p>
                          <p className="text-uppercase fw-semibold text-muted mb-0 position-absolute top-0 end-0">
                            {/* <IoWalletOutline size={20} color="#695eef" /> */}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          {/* <h5 className="text-success fs-14 mb-0">
                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                  +7.55 %
                </h5> */}
                        </div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                          <h4 className=" d-flex  fs-22 fw-bold ff-secondary mb-4">
                            <span>
                              {walletInfo?.currency?.abbr === "USD"
                                ? "$"
                                : walletInfo?.currency?.abbr === "NGN"
                                  ? "₦"
                                  : walletInfo?.currency?.abbr === "GBP"
                                    ? "£"
                                    : ""}
                            </span>
                            <span className="counter-value">
                              {analytics?.inflows}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-6">
                  <div className="card card-animate">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="d-flex  gap-2 w-100 position-relative">
                          <p className="text-uppercase fw-semibold text-muted mb-0">
                            Outflow
                          </p>
                          <p className="text-uppercase fw-semibold text-muted mb-0 position-absolute top-0 end-0"></p>
                        </div>
                        <div className="flex-shrink-0"></div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                          <h4 className=" d-flex  fs-22 fw-bold ff-secondary mb-4">
                            <span>
                              {walletInfo?.currency?.abbr === "USD"
                                ? "$"
                                : walletInfo?.currency?.abbr === "NGN"
                                  ? "₦"
                                  : walletInfo?.currency?.abbr === "GBP"
                                    ? "£"
                                    : ""}
                            </span>
                            <span className="counter-value">
                              {analytics?.outflows}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="invoiceList">
                <div className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Transactions
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="col-sm-auto">
                        <button
                          type="button"
                          className="btn btn-success me-1"
                          onClick={() => openModal("withdraw")}
                        >
                          Withdraw Funds
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-3 col-sm-4">
                        <div className="input-light">
                          <select
                            className="form-control"
                            name="walletId"
                            value={walletId}
                            onChange={handleWalletIdChange}
                          >
                            <option value="">Select Wallet</option>
                            {allWallets?.map((item, index) => (
                              <option
                                // selected={filters.payment_status === name ? true : false}
                                key={index}
                                value={item?.id}
                              >
                                {item?.currency?.abbr}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-7">
                        <div className="row g-3">
                          <div className="col-sm-4">
                            <Flatpickr
                              className="form-control"
                              id="dash-filter-picker"
                              placeholder="start date to end date"
                              options={{ mode: "range" }}
                              defaultValue={
                                filters.start_date && filters.end_date !== null
                                  ? filters.start_date +
                                  " to " +
                                  filters.end_date
                                  : ""
                              }
                              onChange={(selectedDates) =>
                                dateChange(selectedDates, filters, setFilters)
                              }
                            />
                          </div>

                          <div className="col-sm-4">
                            <div>
                              <select
                                className="form-control"
                                name="status"
                                id="idStatus"
                                onChange={handleFilterChange}
                              >
                                <option value="">Status</option>
                                <option value="316">Pending</option>
                                <option value="111">Successful</option>
                                <option value="901">Failed</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={() => applyFilter()}
                              >
                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                Filters
                              </button>
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-danger w-100"
                                onClick={() => resetFilter()}
                              >
                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-body">
                  <div>
                    <div className="table-responsive table-card">
                      {transactions.length > 0 ? (
                        <table
                          className="table align-middle table-nowrap"
                          id="invoiceTable"
                        >
                          <thead className="table-light text-muted">
                            <tr className="text-uppercase">
                              <th className="sort" data-sort="reference">
                                Reference
                              </th>
                              <th className="sort" data-sort="amount">
                                Amount
                              </th>
                              <th className="sort" data-sort="balance">
                                Balance
                              </th>
                              <th className="sort" data-sort="date">
                                Date
                              </th>
                              <th className="sort" data-sort="status">
                                Status
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            <>
                              {transactions.map((transaction, index) => (
                                <tr key={index}>
                                  {/* <th scope="row">
                                   <div className="form-check">
                                     <input
                                       className="form-check-input"
                                       type="checkbox"
                                       name="checkAll"
                                       value="option1"
                                     />
                                   </div>
                                 </th> */}
                                  <td className="reference">
                                    {transaction?.reference}
                                  </td>

                                  <td className="amount ">
                                    {transaction.debit ? (
                                      <>
                                        <p className="text-danger fw-bold">
                                          -
                                          {getSymbolFromCurrency(
                                            walletInfo?.currency?.abbr
                                          )}
                                          {formatCurrency(transaction?.debit)}
                                        </p>
                                      </>
                                    ) : transaction?.credit ? (
                                      <>
                                        <p className="balance text-success fw-bold">
                                          +
                                          {getSymbolFromCurrency(
                                            walletInfo?.currency?.abbr
                                          )}
                                          {formatCurrency(transaction?.credit)}
                                        </p>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className="balance text-black fw-bold">
                                    {getSymbolFromCurrency(
                                      walletInfo?.currency?.abbr
                                    )}
                                    {formatCurrency(transaction?.balance)}
                                  </td>
                                  <td className="date">
                                    {transaction?.created_date
                                      ? moment(
                                        transaction?.created_date
                                      ).format("Y-MM-DD H:mm:ss ")
                                      : ""}
                                  </td>
                                  <td className="status">
                                    {transaction?.status === 111 ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Successful
                                      </span>
                                    ) : (
                                      <>
                                        {transaction?.status === 316 ? (
                                          <span className="badge badge-soft-warning text-uppercase">
                                            Pending
                                          </span>
                                        ) : (
                                          <span className="badge badge-soft-danger text-uppercase">
                                            Failed
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <li
                                        className="list-inline-item edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <a
                                          className="text-primary d-inline-block edit-item-btn"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            viewTransaction(transaction)
                                          }
                                        >
                                          <i className="ri-eye-fill fs-16"></i>{" "}
                                          View
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                            </>
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {loading ? (
                            <div className="py-5">
                              <Loader
                                message={
                                  <p className="text-muted">
                                    Fetching Transactions...
                                  </p>
                                }
                              />
                            </div>
                          ) : (
                            <SearchResponse
                              title={"No Transaction Found"}
                              message={<p>Can't find any Transactions</p>}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Pagination
                          pagination={pagination}
                          previous={goToPreviousPage}
                          next={goToNextPage}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <DeleteModal
                    show={selectedForDelete ? true : false}
                    title={'Delete Notice'}
                    message={<div>
                      <p>Deleting this invoice would make you lost some information that are vital to your business performance.</p>
                      <p>Are you sure you want to delete this in option?</p>
                    </div>}
                    deleting={deleting}
                    handleDelete={deleteInvoice}
                    handleClose={closeDeleteModal}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalBox
          show={modal === "withdraw"}
          handleClose={closeModal}
          title={"Withdrawal"}
          closeBtn={
            <button
              type="button"
              className="btn btn-light"
              onClick={closeModal}
            >
              {" "}
              Close
            </button>
          }
          saveBtn={
            <button
              type="button"
              disabled={saving}
              className={`btn btn-success ${saving ? "disabled" : ""}`}
              onClick={(e) => handleSubmit(e)}
            >
              {saving ? (
                <>
                  <div
                    className="spinner-border spinner-border-sm text-white"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>{" "}
                  Proccessing...
                </>
              ) : (
                "Withdraw"
              )}
            </button>
          }
        >
          <form>
            <div className="mb-3">
              <label htmlFor="wallet-field" className="form-label">
                Wallets
              </label>
              <Select
                isMulti={false}
                isClearable={true}
                isDisabled={false}
                isLoading={false}
                isSearchable={true}
                closeMenuOnSelect={true}
                options={walletsDropdown}
                value={selectedWallet}
                onChange={setSelectedWallet}
                styles={{ backgroundColor: "red" }}
              >
                Select Wallet
              </Select>
            </div>

            <div className="mb-3">
              <div>
                <label htmlFor="amount-field" className="form-label">
                  Amount
                </label>
                <input
                  type="number"
                  id="amount-field"
                  className="form-control"
                  placeholder="0.00"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>
            </div>
          </form>
        </ModalBox>
        <ModalBox
          show={transactionInfo ? true : false}
          handleClose={() => setTransactionInfo(null)}
          title={"Transaction Details"}
          closeBtn={
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setTransactionInfo(null)}
            >
              {" "}
              Close
            </button>
          }
          saveBtn={null}
        >
          {transactionInfo && (
            <div>
              <div className="d-flex gap-3">
                <p>Reference:</p>
                <p>{transactionInfo?.reference}</p>
              </div>
              <div className="d-flex gap-3">
                <p>Description:</p>
                <p>{transactionInfo.description} </p>
              </div>
              <div className="d-flex gap-3">
                <p>Balance:</p>
                <p>
                  {getSymbolFromCurrency(walletInfo?.currency?.abbr) +
                    formatCurrency(transactionInfo.balance)}
                </p>
              </div>
              <div className="d-flex gap-3">
                <p>Status:</p>
                <p>
                  {transactionInfo.status === 111 ? (
                    <span className="badge badge-soft-success text-uppercase">
                      Successful
                    </span>
                  ) : (
                    <>
                      {transactionInfo.status === 316 ? (
                        <span className="badge badge-soft-warning text-uppercase">
                          Pending
                        </span>
                      ) : (
                        <span className="badge badge-soft-danger text-uppercase">
                          Failed
                        </span>
                      )}
                    </>
                  )}
                </p>
              </div>
              <div className="d-flex gap-3">
                <p>Credit:</p>
                <p>
                  {transactionInfo?.credit
                    ? getSymbolFromCurrency(walletInfo?.currency?.abbr) +
                    transactionInfo?.credit
                    : ""}
                </p>
              </div>
              <div className="d-flex gap-3">
                <p>Dedit:</p>
                <p>
                  {" "}
                  {transactionInfo?.debit
                    ? getSymbolFromCurrency(walletInfo?.currency?.abbr) +
                    transactionInfo?.debit
                    : ""}
                </p>
              </div>
              <div className="d-flex gap-3">
                <p>Created Date:</p>
                <p>
                  {transactionInfo?.created_date
                    ? moment(transactionInfo?.created_date).format(
                      "DD MMM, Y hh:m:ss a"
                    )
                    : ""}
                </p>
              </div>
            </div>
          )}
        </ModalBox>
      </div>
      <Outlet />
    </>
  );
};

export default UsersWallet;
