import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { errorResponse, responseMessage } from "../../libs/app";
import Loader from "../../Components/Loader/Loader";

import MenuItems from "../../Components/MenuItems";
import DeleteModal from "../../Components/DeleteModal";
import { toast } from "react-toastify";
import { AuthContext } from "../../Contexts/AuthContext";

export async function saveSettings(settings, showResponseMessage = false) {
  try {
    const response = await axios.put("/settings/storefront/save", {
      settings: JSON.stringify(settings),
    });
    if (response.data.success) {
      showResponseMessage && responseMessage(response.data.message, "success");
    }
  } catch (error) {
    if (error.response) errorResponse(error.response);
    else responseMessage("Something went wrong.", "error");
  }
}

const Navigations = () => {
  document.title = "Online Store - Navigation | Taojaa - Store Manager App";

  const { store, subscription } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [navigations, setNavigation] = useState([]);
  const [forDelete, setForDelete] = useState(null);
  const [navToDelete, setNavToDelete] = useState();

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getNavigations();
  }, [store, subscription]);

  const getNavigations = () => {
    setLoading(true);
    axios
      .get(`/settings/navigations`)
      .then((response) => {
        setNavigation(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const openDeleteModal = (id) => {
 
    setNavToDelete(id);
    setForDelete("delete");
  };

  const deleteMenuItem = () => {
    setLoading(true);
    axios
      .delete(`/settings/navigations/delete/${navToDelete}`)
      .then((response) => {

        if (response.data.success) {
          getNavigations();
          toast.success(response?.data?.message);
        }
        setForDelete(null);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const closeDeleteModal = () => {
    setForDelete(null);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Navigation Setup</h4>
              </div>
            </div>
          </div>

          <div className="row mt-5 justify-content-center mx-auto">
            <div className="col-xxl-9">
              <div className="row">
                <div className="card card-height-100">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Navigations</h4>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <p className="text-muted text-muted">
                        This helps your customers navigate around your website
                        pages.
                      </p>
                      <div className="c-actions">
                        <Link to="addMenu">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="ri-add-circle-line"></i>
                            <span className="desktop">Add Menu</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center p-3 border-bottom">
                      <div className="col-2">Title</div>
                      <div className="col">Menu Items</div>
                    </div>
                    {navigations.length > 0 ? (
                      <div className="w-100 p-2">
                        {navigations.map((item, index) => {
                          return (
                            <MenuItems
                              title={item?.nav_title}
                              menuItem={item?.data}
                              key={index}
                              id={item.id}
                              openDeleteModal={openDeleteModal}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {loading ? (
                          <div className="col-xxl py-5">
                            <Loader message={<p className="text-muted"></p>} />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-5 justify-content-center">
            <div className="col-xxl-9">
              <div className="row">
                <div className="col-xxl-4">
                  <h4>Colections and Search filters</h4>
                  <div>
                    Allow your customers to filter collections and search
                    results by product availability, price, color, and more.
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className=""></div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl">
                  <div className="card card-height-100">
                    <div className="card-header  d-flex flex-column">
                      <h4 className="card-title mb-0 flex-grow-1">Filter</h4>
                      <p>
                        Filter settings are now available in the Shopify Search
                        & Discovery app. Make changes to these settings in the
                        app.
                      </p>
                    </div>

                    <div className="card-body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="live-preview">
          <div></div>
        </div>
      </div>
      <DeleteModal
        show={forDelete ? true : false}
        title={"Delete confirmation"}
        message={"Are you sure you want to delete this?"}
        handleDelete={deleteMenuItem}
        handleClose={closeDeleteModal}
        deleting={loading}
      />

      <Outlet />
    </>
  );
};

export default Navigations;
