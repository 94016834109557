import React from "react";
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <div className="d-flex gap-2 align-item-center">
              <a href="https://taojaa.com/resources/how-to-guides" className="text-underline" rel="noopener noreferrer" target="_blank">Guides</a>
              <span>.</span>
              <a href="https://taojaa.com/resources/faqs" className="text-underline" rel="noopener noreferrer" target="_blank">FAQs</a>
              <span>.</span>
              <a href="mailto:support@taojaa.com" className="text-underline" rel="noopener noreferrer" target="_blank">Support</a>
              <span>.</span>
              <Link to="/referrals" className="text-underline">Refer and earn</Link>
            </div>
          </div>
          <div className="col-sm-6 text-end">
            © {(new Date().getFullYear())} Taojaa.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
