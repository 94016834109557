import React from 'react'
import { Link } from 'react-router-dom'

const Pagination = ({ pagination, onPageChange }) => {
    return (
        <>
            {pagination != null ? (
                <div className="pagination-wrap hstack gap-2">
                    <Link
                        className={`page-item pagination-prev ${Number(pagination.page) === 1 ? 'disabled' : ''}`}
                        to={`${Number(pagination.page) === 1 ? '#' : `?page=${Number(pagination.page) - 1}`} `}
                        onClick={(e) => { Number(pagination.page) > 1 && onPageChange(Number(pagination.page) - 1) }}>
                        Previous
                    </Link>
                    <ul className="pagination listjs-pagination mb-0">
                        {[...Array(pagination.pages)].map((page, index) =>
                        (index <= 5 && (
                            <li className={(Number(pagination.page) === (index + 1)) ? 'active' : ''} key={index}>
                                <Link className="page" to={(Number(pagination.page) !== (index + 1)) && `?page=${(index + 1)}`} onClick={(e) => onPageChange(index + 1)}>{index + 1}</Link>
                            </li>
                        )))}
                    </ul>
                    <Link
                        className={`page-item pagination-next ${Number(pagination.page) === Number(pagination.pages) ? 'disabled' : ''}`}
                        to={`${Number(pagination.page) === Number(pagination.pages) ? '#' : `?page=${Number(pagination.page) + 1}`} `}
                        onClick={(e) => { Number(pagination.page) < Number(pagination.pages) && onPageChange(Number(pagination.page) + 1) }}>
                        Next
                    </Link>
                </div>
            ) : null}</>
    )
}

export default Pagination