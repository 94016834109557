import React from 'react'
import ModalBox from '../../../Components/ModalBox';

const RateForm = ({ rate, saving, show, saveRate, closeModal, handleRateChange, connectedProviders }) => {

    const statuses = [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" }
    ];

    return (
        <ModalBox
            show={show}
            handleClose={closeModal}
            title={rate && rate.id !== undefined ? 'Update Rate' : 'Add Rate'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                    onClick={() => saveRate()}>
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Save'}
                </button>)}
        >
            <form action="" method="POST" className="form" id="deliverRatesForm" encType="multipart/form-data">
                <div className="card-body">
                    <div className="form-group mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Name</label>
                        <div className="col-lg-12 col-xl-12">
                            <input type="text" className="form-control form-control-solid" name="name"
                                value={rate.name} placeholder="Enter name e.g Shipping to Lagos Mainland"
                                onChange={(e) => handleRateChange(e)} />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Description</label>
                        <div className="col-lg-12 col-xl-12">
                            <input type="text" className="form-control form-control-solid" name="description"
                                value={rate.description} placeholder="Enter description"
                                onChange={(e) => handleRateChange(e)} />
                        </div>
                    </div>
                    <div className='mb-3 form-group'>
                        <label className="form-label m-0"><b>Use logistics service provider rate</b></label>
                        {connectedProviders.length === 0 && <p className='m-0 p-0'><small className='text-danger'>You haven't connect any logistics service provider yet.</small></p>}
                        <select name='delivery_provider_id'
                            className="form-select"
                            onChange={(e) => handleRateChange(e)} >
                            <option value={0}>{rate.delivery_provider_id > 0 ? 'Clear selection' : 'Select connected provider/carrier'}</option>
                            {connectedProviders.map((provider, index) => (
                                rate.delivery_provider_id === provider.id
                                    ? <option key={index} selected value={provider.id}>{provider.name}</option>
                                    : <option key={index} value={provider.id}>{provider.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right m-0 p-0">Price</label>
                        {rate.delivery_provider_id > 0 && <small className='text-primary'>Rate will be calculated by provder on checkout</small>}
                        <div className="col-lg-12 col-xl-12">
                            <input className="form-control form-control-solid" disabled={rate.delivery_provider_id > 0} name="price" type="number" value={rate.price}
                                onChange={(e) => handleRateChange(e)} placeholder="Enter Price" />
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Estimated Delivery Date</label>
                        <div className="col-lg-12 col-xl-12">
                            <input type="text" className="form-control form-control-solid" name="estimated_delivery_date"
                                value={rate.estimated_delivery_date} placeholder="Estimated Delivery Date"
                                onChange={(e) => handleRateChange(e)} />
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Status</label>
                        <div className="col-lg-12 col-xl-12">
                            <select
                                name="status"
                                className="form-control"
                                value={rate.status}
                                onChange={handleRateChange}
                            >
                                {statuses.map(({ id, name }) => {
                                    return (
                                        <option key={id} selected={rate.status == id ? true : false} value={id}>{name}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>

                </div>
            </form>
        </ModalBox>
    )
}

export default RateForm