import axios from 'axios'
import React, { useEffect, useState, useContext, Fragment } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { responseMessage } from '../../libs/app'
import { AuthContext } from '../../Contexts/AuthContext'
import { usePaystackPayment } from 'react-paystack';
import { generateNewRefCode } from '../../helpers'
import ModalBox from '../../Components/ModalBox'
import Loader from '../../Components/Loader/Loader'


const Subscribe = () => {

    document.title = 'Subscription | Taojaa - Store Manager App';

    const { group } = useParams();
    const navigate = useNavigate();

    const { user, currency, setSubscription } = useContext(AuthContext);
    const [plans, setPlans] = useState([]);
    const [selectedBillingCycle, setSelectedBillingCycle] = useState(null)
    const [verifying, setVerifying] = useState(false);
    const [loading, setLoading] = useState(true);

    const [reference, setReference] = useState(`TAC${Date.now()}tYrt2y`);
    const [config, setConfig] = useState({
        reference,
        email: user.email,
        amount: 0, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    });

    const paystackPop = usePaystackPayment(config);

    const getPrice = (selectedBillingCycle) => {
        if (selectedBillingCycle) {
            if (selectedBillingCycle.discount > 0) {
                var discount = selectedBillingCycle.discount / 100 * selectedBillingCycle.price;
                return selectedBillingCycle.price - discount;
            }

            return selectedBillingCycle.price;
        }

        return 0
    }

    const handleModalClose = () => setSelectedBillingCycle(null);

    useEffect(() => {
        plans.length === 0 && getPlans();
        if (selectedBillingCycle !== null) {
            setConfig({ ...config, amount: getPrice(selectedBillingCycle) * 100 });
        }
    }, [selectedBillingCycle]);

    async function getPlans() {
        try {
            const response = await axios.get(`/plans/${group}`);

            if (response.data.success) {
                setPlans(response.data.data);
            }
        } catch (error) {
        }
        setLoading(false);
    }

    function handleSuccess(transaction) {
        generateNewRefCode(setReference)
        verifyTransaction({ plan_id: selectedBillingCycle.id, reference: transaction.reference })
    }

    function handleClose(resp) {
        window.location.reload();
    }

    async function verifyTransaction(data) {

        try {
            setVerifying(true);
            const response = await axios.post('/subscription/verify/transaction', data);
            if (response.data.success) {
                setSubscription(response.data.subscription)
                navigate(`/subscription/successful`)
            }
        } catch (error) {
            if (error.response) {
                return navigate(`/subscription/failed`)
            }

            responseMessage("Something went wrong, please contact support.", "error");
        } finally {
            setSelectedBillingCycle(null);
            setVerifying(false);
        }
    }

    async function startSubscription() {

        try {
            if (!selectedBillingCycle || config.amount === 0) {
                return responseMessage("Kindly select a billing option", 'info');
            }

            paystackPop(handleSuccess, handleClose);

        } catch (error) {
            console.log(error)
            if (error.response) {
                responseMessage(error.response.data.message, "error");
            } else {
                responseMessage("Something went wrong.", "error");
            }
        }
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row justify-content-center">
                        <div className="col-xxl-8">

                            <div className='mb-3'>
                                <div className="text-center mb-4 pb-2">
                                    <h4 className="fw-semibold fs-22">Select a Billing Cycle.</h4>
                                    <p className="mb-4 fs-15">Choose how you want to be billed and get started today.</p>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    {loading
                                        ? <div className="py-5"><Loader message={<p className="text-muted">Loading...</p>} /></div>
                                        : <Fragment>
                                            {plans.length > 0 &&
                                                <>
                                                    {
                                                        plans.map((plan, index) => (
                                                            <div key={index} className="card ribbon-box border shadow-none right mt-1 mb-lg-0 plan-cards"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => setSelectedBillingCycle(plan)}
                                                            >
                                                                <div className="card-body">
                                                                    {plan.discount > 0 && <div className="ribbon ribbon-primary round-shape">{plan.discount}% Off</div>}
                                                                    <h5 className="fs-14 text-start">{plan.name} {plan.group_name} Plan</h5>
                                                                    <div className="ribbon-content d-flex gap-2">
                                                                        <p className="mb-0">
                                                                            <span className={`${plan.discount > 0 ? 'text-decoration-line-through' : ''}`}>{currency}{plan.price}</span>
                                                                            {plan.discount > 0 ? <span className='ms-2'>{currency}{(plan.price - (plan.discount / 100 * plan.price))}</span> : null}
                                                                        </p>
                                                                        <p className='mb-0 text-muted'>- Billed {plan.name !== "Monthly" && plan.name !== "Annually" ? 'every' : ''} {plan.name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>}
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalBox
                        show={selectedBillingCycle}
                        handleClose={handleModalClose}
                        title={''}
                        closeBtn={''}
                        saveBtn={''}
                    >
                        {verifying ?
                            <div className="text-center">
                                <div className="mb-4">
                                    <lord-icon
                                        src="https://cdn.lordicon.com/xjovhxra.json"
                                        trigger="loop"
                                        colors="primary:#695eef,secondary:#18151e"
                                        style={{ width: "120px", height: "120px" }}>
                                    </lord-icon>
                                    <h5>Completing Request</h5>
                                    <p className="text-muted">
                                        Please hold on a minute while completing your request.
                                    </p>
                                </div>
                            </div>
                            : <div className="text-center">
                                {selectedBillingCycle && (
                                    <div>
                                        <lord-icon src="https://cdn.lordicon.com/pithnlch.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
                                        </lord-icon>
                                        <div className="mt-4">
                                            <h4 className="mb-3">You're about to subscribe for {selectedBillingCycle.name} {selectedBillingCycle.group_name} Plan.</h4>
                                            <p className="text-muted"> You will be billed {selectedBillingCycle.name !== "Monthly" && selectedBillingCycle.name !== "Annually" ? 'every' : ''} {selectedBillingCycle.name}</p>
                                            <p className="text-muted mb-4">You can opt-out or unsubscribe anytime.</p>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <button onClick={() => setSelectedBillingCycle(null)} className="btn btn-link link-success fw-medium" data-bs-dismiss="modal">
                                                <i className="ri-close-line me-1 align-middle"></i> Cancel
                                            </button>
                                            <button onClick={startSubscription} disabled={verifying} className="btn btn-success">
                                                Subscribe
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </ModalBox>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default Subscribe