import axios from 'axios'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { errorResponse, responseMessage } from '../../libs/app'
import { AuthContext } from '../../Contexts/AuthContext'
import DeleteModal from '../../Components/DeleteModal'
import { saveSettings } from './Storefront'
import BlankPage from '../../Components/BlankPage'
import { Col, Row } from 'react-bootstrap'
import RegionForm from './Modals/RegionForm'
import RateForm from './Modals/RateForm'
import StationForm from './Modals/StationForm'
import { AppContext } from '../../Contexts/AppContext'
import Loader from '../../Components/Loader/Loader'


const DeliverySetup = () => {

  document.title = 'Shipping | Taojaa - Store Manager App';

  const { store, subscription, currency, connectedProviders, getConnectedDeliveryProviders } = useContext(AuthContext)
  const { saving, setSaving } = useContext(AppContext);

  const [loadingRegions, setLoadingRegions] = useState(true);
  const [loadingStations, setLoadingStations] = useState(true);
  const [loadingProviders, setLoadingProviders] = useState(true);

  const [modal, setModal] = useState(null);
  const [providers, setProviders] = useState([]);
  const [regions, setRegions] = useState([]);
  const [rate, setRate] = useState({
    name: null,
    description: null,
    estimated_delivery_date: null,
    price: 0,
    status: 1,
    delivery_region_id: 0,
    delivery_provider_id: 0,
  });
  const [region, setRegion] = useState({
    name: '',
    locations: {},
  });
  const [pickupStations, setPickupStations] = useState([]);
  const [station, setStation] = useState(null);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [selectedStationForDelete, setSelectedStationForDelete] = useState(null);
  const [storefrontSettings, setStorefrontSettings] = useState({
    allow_pickups: false,
    show_rates_on_checkout: true
  });

  const navigator = useNavigate();

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getStations();
    getProviders();
    getRegions();
    getStorefrontSettings();
    getConnectedDeliveryProviders();
  }, [store, subscription]);


  const goToProviderSetupPage = (provider) => {
    return navigator(`/provider/${provider.slug}`)
  }

  async function getProviders() {
    try {
      const response = await axios.get('/settings/delivery/providers');
      if (response.data.success) {
        setProviders(response.data.providers);
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    } finally {
      setLoadingProviders(false);
    }
  }

  async function getStorefrontSettings() {
    try {
      const settings = 'show_rates_on_checkout,allow_pickups'
      const response = await axios.get(`/settings/storefront?names=${settings}`);
      if (response.data.success) {
        setStorefrontSettings(response.data.data)
      }
    } catch (error) {
    }
  }

  async function getRegions() {
    try {
      const response = await axios.get(`/settings/delivery-regions`);
      if (response.data.success) {
        setRegions(response.data.data);
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    } finally {
      setLoadingRegions(false);
    }
  }

  async function saveRegion(zones, forAllZones) {
    try {
      setSaving(true)

      const data = { ...region, zones, forAllZones }
      var response;

      if (region.id !== undefined) {
        response = await axios.put('/settings/update/delivery-region', data);
      } else {
        response = await axios.post('/settings/create/delivery-region', data);
      }

      if (response.data.success) {
        responseMessage(response.data.message, 'success')
        getRegions()
      }
      closeModal();
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
    setSaving(false)
  }

  async function saveRate() {
    try {
      setSaving(true)

      var response;

      if (rate.id !== undefined) {
        response = await axios.put('/settings/update/delivery-rate', rate);
      } else {
        response = await axios.post('/settings/create/delivery-rate', rate);
      }

      if (response.data.success) {
        responseMessage(response.data.message, 'success')
        getRegions()
      }
      closeModal();
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
    setSaving(false)
  }

  async function getStations() {
    try {
      const response = await axios.get(`/settings/pickups`);
      if (response.data.success) {
        setPickupStations(response.data.stations);
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    } finally {
      setLoadingStations(false);
    }
  }

  async function deleteRate() {
    try {
      if (selectedForDelete === null) {
        return responseMessage("Something went wrong, invalid action attempted", "error");
      }

      const response = await axios.delete(`/settings/delivery-rates/delete/${selectedForDelete}`);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getRegions();

        closeDeleteModal()
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function deleteStation() {
    try {
      if (selectedStationForDelete === null) {
        return responseMessage("Something went wrong, invalid action attempted", "error");
      }

      const response = await axios.delete(`/settings/pickups/delete/${selectedStationForDelete}`);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getStations();

        closeDeleteModal()
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  function updateSettings(e, setting) {
    var value = e.target.checked;
    setStorefrontSettings({ ...storefrontSettings, [setting]: value });
    saveSettings({ ...storefrontSettings, [setting]: value })
  }

  const validateZone = (country) => {
    const search = regions.find((region) => region.locations !== null && typeof region.locations[country.name] !== 'undefined');
    if (search && region.id !== search.id) {
      return true;
    }
    return false;
  }

  const editRegion = (region) => {
    setRegion(region);
    openModal("region")
  }

  const editRate = (rate, region) => {
    setRate({ ...rate, delivery_region_id: region });
    openModal("rate")
  }

  const handleRateChange = (e) => {
    setRate({ ...rate, [e.target.name]: e.target.value });
  }

  const handleRegionChange = (e) => {
    setRegion({ ...region, [e.target.name]: e.target.value });
  }

  const editStation = (station) => {
    setStation(station);
    openModal("pickup")
  }

  const openRateForm = (region) => {
    setRate({ ...rate, delivery_region_id: region })
    openModal("rate")
  }

  const openModal = (target) => {
    setModal(target);
  }

  const closeModal = () => {
    setModal(null);
    setRate({
      name: null,
      description: null,
      estimated_delivery_date: null,
      price: 0,
      status: 1,
      delivery_region_id: 0,
      delivery_provider_id: 0,
    })
    setStation({
      name: '',
      phone: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      country: '',
      is_default: false,
      status: 1,
    });
    setRegion({
      name: '',
      locations: {}
    });
  }

  const checkProviderStatus = (prd) => {
    const search = connectedProviders.find((provider) => provider.provider_id === prd.id);
    if (search) {
      return true
    }

    return false;
  }

  const closeDeleteModal = () => {
    setSelectedForDelete(null)
    setSelectedStationForDelete(null)
  }

  return (
    <BlankPage title='Delivery Setup' titleColumSize={12}>
      <div className="col-xxl-9 mb-4">
        <div className="card h-100">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Regions & Rates</h4>
            <div className="flex-shrink-0">
              <div className="form-check form-switch form-switch-right form-switch-md">
                <label htmlFor="default-modal" className="form-label text-muted">Show rates on checkout</label>
                <input className="form-check-input code-switcher" type="checkbox" checked={Boolean(storefrontSettings.show_rates_on_checkout) === true}
                  onChange={(e) => updateSettings(e, 'show_rates_on_checkout')}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            {loadingRegions
              ? <div className="py-5"><Loader message={''} /></div>
              : <Fragment>
                <div className='d-flex justify-content-between'>
                  <div className='shipping-options'>
                    <p className="text-muted">Add delivery regions and rates, customer will select on checkout.</p>
                  </div>
                  <div className=''>
                    <button type="button" className="btn btn-primary btn-sm" onClick={() => openModal("region")}>
                      <i className="ri-add-circle-line"></i> <span className='desktop'>Create Region</span>
                    </button>
                  </div>
                </div>

                <div className='accordion' id="accordion">
                  {regions.map((region) =>
                    <div className='accordion-item mb-3' key={`region${region.id}`}>
                      <div className='accordion-header' id="region">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#region${region.id}Body`} aria-expanded="true" aria-controls={`region${region.id}Body`}>
                          <div className="row w-100">
                            <div className="col-md-8">
                              {region.name}
                            </div>
                            <div className="col-md-4">
                              <Link className='text-primary' to={'#'} onClick={() => editRegion(region)}>Edit Region</Link>
                              <Link className='text-primary ms-3' to={'#'} onClick={() => openRateForm(region.id)}>Add Rate</Link>
                            </div>
                          </div>
                        </button>
                      </div>
                      <div id={`region${region.id}Body`} className="accordion-collapse collapse" aria-labelledby="region" data-bs-parent="#accordion">
                        <div className="accordion-body">
                          <div className='table-responsive'>
                            {region.rates.length > 0 ?
                              (<table
                                className="table align-middle"
                                id="regionsTable"
                              >
                                <tbody className="list form-check-all">
                                  {region.rates.map((rate, index) => (
                                    <tr key={`rate${region.id}${index}`}>
                                      <td className="name" nowrap={"nowrap"}>
                                        {rate.name}
                                      </td>
                                      <td className="price" nowrap={"nowrap"}>
                                        {rate.provider !== null ?
                                          <Fragment>
                                            <p className='m-0 p-0'>{rate.provider.name}</p>
                                            <small>Price will be calculated by provider on checkout</small>
                                          </Fragment>
                                          :
                                          <>{currency}{rate.price}</>
                                        }
                                      </td>
                                      <td className="status">
                                        {rate.status === 1 ? (
                                          <span className="badge badge-soft-success text-uppercase">Active</span>)
                                          : (<span className="badge badge-soft-danger text-uppercase">Inactive</span>)}
                                      </td>
                                      <td>
                                        <ul className="list-inline hstack gap-2 mb-0">
                                          <li className="list-inline-item edit" title="Edit">
                                            <a href="#shippingOption" className="text-primary d-inline-block edit-item-btn"
                                              onClick={() => editRate(rate, region.id)}>
                                              <i className="ri-pencil-fill fs-16"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item" title="Remove">
                                            <a className="text-danger d-inline-block remove-item-btn" href="#deleteShippingRate"
                                              onClick={() => setSelectedForDelete(rate.id)}>
                                              <i className="ri-delete-bin-5-fill fs-16"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>)
                              : <div className='text-center'>
                                <p>No rates found. Add delivery rates for this region.</p>
                                <Link to={'#'} className='ms-2 btn btn-primary btn-sm' onClick={() => openRateForm(region.id)}>Add Rate</Link>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="live-preview">
                  <div>
                    <RateForm
                      show={modal === "rate"}
                      rate={rate}
                      saving={saving}
                      saveRate={saveRate}
                      closeModal={closeModal}
                      handleRateChange={handleRateChange}
                      connectedProviders={connectedProviders}
                    />

                    <RegionForm
                      show={modal === "region"}
                      region={region}
                      saving={saving}
                      saveRegion={saveRegion}
                      closeModal={closeModal}
                      validateZone={validateZone}
                      handleRegionChange={handleRegionChange}
                    />
                  </div>
                </div>
              </Fragment>}
          </div>
        </div>
      </div>

      <div className="col-xxl-9 mb-4">
        <div className="card h-100">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Local Pickup</h4>
            <div className="flex-shrink-0">
              <div className="form-check form-switch form-switch-right form-switch-md">
                <label htmlFor="default-modal" className="form-label text-muted">Enable pickup on checkout</label>
                <input className="form-check-input code-switcher" type="checkbox" checked={storefrontSettings.allow_pickups === true}
                  onChange={(e) => updateSettings(e, 'allow_pickups')}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            {loadingStations
              ? <div className="py-5"><Loader message={''} /></div>
              : <Fragment>
                <div className='d-flex justify-content-between gap-3'>
                  <div className='bank-details'>
                    <p className="text-muted">Add pickup locations for local pickup fulfillment.</p>
                  </div>
                  <div className=''>
                    <button type="button" className="btn btn-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => openModal("pickup")}>
                      <i className="ri-add-circle-line"></i> Add Pickup Location
                    </button>
                  </div>
                </div>
                <div className='table-responsive'>
                  {pickupStations.length > 0 &&
                    (<table
                      className="table align-middle"
                      id="currencyTable"
                    >
                      <thead className="table-light text-muted">
                        <tr className="text-uppercase">
                          <th scope="col" style={{ width: "50" }}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="checkAll"
                                value="option"
                              />
                            </div>
                          </th>
                          <th className="sort" nowrap="nowrap" data-sort="station_name">Name</th>
                          <th className="sort" data-sort="location">Location.</th>
                          <th className="sort" data-sort="status">Status</th>
                          <th className="sort" data-sort="default"></th>
                          <th className="sort" data-sort="action">Action</th>
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {pickupStations.map((station) => (
                          <tr key={`station${station.id}`}>
                            <th scope="row">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkAll"
                                  value="option1"
                                />
                              </div>
                            </th>
                            <td className="station_name" nowrap="nowrap">{station.name}</td>
                            <td className="location">{station.city}, {station.state}, {station.country}</td>
                            <td className="status">
                              {station.status === 1 ? (
                                <span className="badge badge-soft-success text-uppercase">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-soft-danger text-uppercase">
                                  Inactive
                                </span>
                              )}
                            </td>
                            <td className="default">
                              {station.is_default &&
                                <span className="badge badge-soft-success text-uppercase">Default</span>}
                            </td>
                            <td>
                              <ul className="list-inline hstack gap-2 mb-0">
                                <li className="list-inline-item edit" title="Edit Station">
                                  <a
                                    href="#station"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={() => editStation(station)}
                                  >
                                    <i className="ri-pencil-fill fs-16"></i>
                                  </a>
                                </li>
                                <li
                                  className="list-inline-item"
                                  title="Remove"
                                >
                                  <a
                                    className="text-danger d-inline-block remove-item-btn"
                                    href="#deleteStation"
                                    onClick={() =>
                                      setSelectedStationForDelete(station.id)
                                    }
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>)}
                </div>
                <div className="live-preview">
                  <div>
                    <StationForm
                      defaultStation={station}
                      show={modal === 'pickup'}
                      closeModal={closeModal}
                      getStations={getStations}
                    />
                  </div>
                </div>
              </Fragment>}
          </div>
        </div>
      </div>

      <div className="col-xxl-9 mb-4">
        <div className="card h-100">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Use Integrated Logistics Provider Services</h4>
          </div>
          <div className="card-body">
            {loadingProviders
              ? <div className="py-5"><Loader message={''} /></div>
              : <Fragment>
                <div className='d-flex justify-content-between'>
                  <div className='shipping-options'>
                    <p className="text-muted">You can automatically schedule pickups and delivery once the order is ready.</p>
                  </div>
                </div>
                <div className='mt-3 px-3'>
                  <Col lg={12}>
                    <div className='row'>
                      {providers.map((prd) =>
                        <Col lg={6} key={`prd-${prd.id}`} className={`mb-3 border rounded-2 p-3 provider-card`} onClick={() => goToProviderSetupPage(prd)}>
                          <Row className='align-items-center'>
                            <Col lg={3} xs={3}>
                              <div className='provider-logo'>
                                <img src={prd.logo} width={'100%'} />
                              </div>
                            </Col>
                            <Col lg={9} xs={9}>
                              <div className='provider-details'>
                                <div className='d-flex justify-content-between'>
                                  <h5>{prd.name}</h5>
                                  <div>
                                    {checkProviderStatus(prd) && <span className='badge badge-soft-success'>Connected</span>}
                                  </div>
                                </div>
                                <small>{prd.description}</small>
                              </div>
                            </Col>
                          </Row>
                        </Col>)}
                    </div>
                  </Col>
                </div>
              </Fragment>
            }
          </div>
        </div>
      </div>


      <DeleteModal
        show={selectedForDelete ? true : false}
        title={'Delete confirmation'}
        message={'Are you sure you want to delete this shipping option?'}
        handleDelete={deleteRate}
        handleClose={closeDeleteModal}
      />

      <DeleteModal
        show={selectedStationForDelete ? true : false}
        title={'Delete confirmation'}
        message={'Are you sure you want to delete this pickup station?'}
        handleDelete={deleteStation}
        handleClose={closeDeleteModal}
      />
    </BlankPage>
  )
}

export default DeliverySetup