import React, { useContext } from 'react'
import { AppContext } from '../../Contexts/AppContext';
import ModalBox from '../../Components/ModalBox';

const StatusForm = ({ show = false, closeModal, saving, status, sendMail, setSendMail, updateStatus, handleChange, subscription }) => {

    const { orderStatus } = useContext(AppContext)

    return (

        <ModalBox
            show={show}
            handleClose={closeModal}
            title={status.status === 0 ? 'Cancel Order' : 'Update Status'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-success ${saving ? 'disabled' : ''}`}
                    onClick={() => updateStatus()}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Save'}
                </button>)}
        >
            <div>
                {status.status !== 0 &&
                    <div className='mb-3'>
                        <label className="form-label form-group"><b>Status</b></label>
                        <select
                            className="form-control"
                            name="status"
                            id="idStatus"
                            value={status.status}
                            onChange={(e) => handleChange(e)}
                        >
                            <option defaultValue="" defaultChecked>
                                Select Status
                            </option>
                            {orderStatus.length > 0 &&
                                orderStatus.map((status, index) => {
                                    return ((status.id !== 1 && status.id !== 0) ? <option key={index} value={status.id}>{status.name}</option> : null);
                                })}
                        </select>
                    </div>
                }

                <div className='mb-3'>
                    <label className="form-label form-group"><b>Date</b></label>
                    <input type="date" placeholder="date" name="date" value={status.date}
                        className="form-control" onChange={(e) => handleChange(e)} />
                </div>

                <div className='send-email-box'>
                    <div>
                        <div className="form-check form-switch form-switch-md">
                            <input className="form-check-input code-switcher" type="checkbox" disabled={!subscription?.settings?.sendOrderStatusEmail} checked={sendMail} onChange={() => setSendMail(!sendMail)} />
                            <label className="form-check-label text-muted">Send order status email to customer</label>
                        </div>
                    </div>

                    {sendMail &&
                        <div className='status-note mt-2'>
                            <label className="form-label form-group"><b>Add Note </b><small>(optional)</small></label>
                            <textarea className="form-control form-control-solid" rows="3" name="note" value={status.note}
                                onChange={(e) => handleChange(e)}></textarea>
                        </div>
                    }


                    {!subscription?.settings?.sendOrderStatusEmail &&
                        <div class="alert alert-primary mt-2 d-flex justify-content-between" role="alert">
                            <p className='m-0'><strong> Oops! </strong> This feature is only available on paid plans.</p>
                            <a href='/settings/billing-and-subscription' target='_blank' className="btn btn-primary btn-sm me-3">Upgrade Now </a>
                        </div>}
                </div>
            </div>
        </ModalBox>


    )
}

export default StatusForm