import React from 'react'
import ModalBox from '../../Components/ModalBox'
import getSymbolFromCurrency from 'currency-symbol-map'

const EditItemForm = ({
    show,
    closeModal,
    item,
    order,
    product,
    variation,
    price,
    saving,
    quantity,
    variations,
    selectVariation,
    handleChange,
    orderItems,
    updateItem
}) => {
    return (
        <ModalBox
            show={show}
            handleClose={closeModal}
            title={'Edit Item'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-success ${saving ? 'disabled' : ''}`}
                    onClick={() => updateItem(orderItems)}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Save'}
                </button>)}
        >
            {item ? (
                <div className='p-3'>
                    <div className="mb-4 form-group">
                        <div className="d-flex">
                            <div className="flex-shrink-0 avatar-md bg-light rounded p-1" style={{ overflow: 'hidden' }}>
                                <img
                                    src={item.image}
                                    className="img-fluid d-block"
                                />
                            </div>
                            <div className="ms-2">
                                <label htmlFor="productname-field" className="form-label">
                                    {item.name}
                                </label>
                            </div>
                        </div>
                    </div>

                    {variations.length ? (
                        <div className="mb-3">
                            <label className="form-label"><b>Select Variation</b></label>
                            <div className="form-group">
                                {variations.map((option, index) => (
                                    <button
                                        key={index} className={`btn mb-1 me-1 btn-sm rounded-pill ${variation && variation.id === option.id ? 'btn-primary' : 'btn-light'}`}
                                        onClick={() => selectVariation(option)}>
                                        {option.variant}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    <div className='mb-3 form-group'>
                        <label className="form-label"><b>Quantity</b></label>
                        <p className="text-primary">
                            {variation ? variation.quantity + ' available in stock.'
                                : (product) ? item.product.quantity + ' available in stock.' : null}
                        </p>
                        <input type="number" name='quantity' placeholder="quantity"
                            min={1} max={`${variation ? variation.quantity : item.product.quantity}`}
                            className="form-control" value={quantity} onChange={(e) => handleChange(e)} />
                    </div>

                    <div className='mb-3'>
                        <label className="form-label form-group"><b>Price</b></label>
                        <div className='input-group'>
                            <span className='input-group-text'>{getSymbolFromCurrency(order?.currency)}</span>
                            <div className=''>
                                <input type="text" placeholder="price" name='price'
                                    className="form-control" value={price} onChange={(e) => handleChange(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </ModalBox>
    )
}

export default EditItemForm