import React, { useContext } from 'react'
import ModalBox from '../../Components/ModalBox'
import { AppContext } from '../../Contexts/AppContext';
import { AuthContext } from '../../Contexts/AuthContext';
import axios from 'axios';
import { errorResponse, responseMessage } from '../../libs/app';


const ProductFormModal = ({ product, getProducts, saving, setSaving, handleProductChange, productModal, toggleProductModal }) => {

    const { availability_types } = useContext(AppContext);
    const { store, subscription, recordCounts, handleUpgradeModalShow } = useContext(AuthContext);

    async function createProduct(e) {

        e.preventDefault();

        if (store.store_type === 'live' && subscription.settings.maxProductsAllowed !== 'unlimited') {
            if ((Number(recordCounts.products) + 1) > subscription.settings.maxProductsAllowed) {
                return handleUpgradeModalShow(
                    'Upgrade to Professional Plan',
                    'You have reached the maxiumun number of products allowed for your current plan, kindly upgrade now to add more products.'
                )
            }
        }

        try {

            setSaving(true);
            const response = await axios.post('/product/create', product);
            if (response.data.success) {
                responseMessage(response.data.message, "success");

                toggleProductModal(false);
                getProducts();
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        } finally {
            setSaving(false)
        }
    }

    return (
        <ModalBox
            show={productModal}
            handleClose={() => toggleProductModal(false)}
            title={'Create Product'}
            closeBtn={<button type="button" className="btn btn-light" onClick={() => toggleProductModal(false)}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-success ${saving ? 'disabled' : ''}`}
                    onClick={(e) => createProduct(e)}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Save Product'}
                </button>)}
        >
            <div className="row gy-4 mb-3">
                <div className="col-md-12">
                    <div>
                        <label
                            htmlFor="product-field"
                            className="form-label"
                        >
                            Product Name
                        </label>
                        <input
                            type="text"
                            id="product-field"
                            className="form-control"
                            placeholder="Product Name"
                            name="name"
                            value={product.name}
                            onChange={handleProductChange}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <div>
                        <label
                            htmlFor="price-field"
                            className="form-label"
                        >
                            Price
                        </label>
                        <input
                            type="number"
                            id="price-field"
                            className="form-control"
                            placeholder="Price"
                            name="price"
                            value={product.price}
                            onChange={handleProductChange}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <div>
                        <label
                            htmlFor="cost-field"
                            className="form-label"
                        >
                            Cost Price
                        </label>
                        <input
                            type="number"
                            id="cost-field"
                            className="form-control"
                            placeholder="Cost Price"
                            name="cost_price"
                            value={product.cost_price}
                            onChange={handleProductChange}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div>
                        <label htmlFor="availability" className="form-label">
                            Availability
                        </label>
                        <select
                            className="form-select"
                            name="availability"
                            id="availability"
                            onChange={handleProductChange}
                        >
                            {availability_types.map((data, index) => (
                                product.availability === data.id ? (
                                    <option value={data.id} key={index} selected={data.id}>{data.label}</option>
                                ) : (
                                    <option value={data.id} key={index}>{data.label}</option>
                                )
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </ModalBox>
    )
}

export default ProductFormModal