import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { errorResponse, responseMessage } from '../../libs/app'
import { AuthContext } from '../../Contexts/AuthContext'
import ModalBox from '../../Components/ModalBox'
import DeleteModal from '../../Components/DeleteModal'



function TableHeader({ columns, handleCheckAll }) {
  return (<thead className="table-light text-muted">
    <tr className="text-uppercase">
      <th scope="col" style={{
        width: "50"
      }}>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" onChange={handleCheckAll} id="checkAll" value="option" />
        </div>
      </th>
      {
        columns.map((column, index) => (
          <th key={index} className="sort" data-sort={column}>
            {column}
          </th>
        ))
      }
    </tr>
  </thead>);
}


const StaffAccount = () => {

  document.title = 'Staff Accounts | Taojaa - Store Manager App';

  const { store, subscription, recordCounts, getSubscription, handleUpgradeModalShow } = useContext(AuthContext);
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(false);
  const [staffAccounts, setStaffAccounts] = useState([]);
  const [accountDetails, setAccountDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    role_id: 5
  });
  const [selectedForDelete, setSelectedForDelete] = useState(null);

  const roles = [
    { id: 5, name: "Member" },
    { id: 4, name: "Admin" }
  ];

  const tableHeaderColumns = [
    "firstname",
    "lastname",
    "email",
    "verified",
    "role",
    "last_seen",
    "status",
    "action",
  ];

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    if (subscription?.settings.staffsAccount === 0) {
      return handleUpgradeModalShow(
        'Upgrade Your Plan',
        'Oops! we\'re sorry this feature is not available on your current plan, upgrade to now to manage staffs.'
      )
    }
  }, [store, subscription]);

  useEffect(() => {
    getStaffAccounts();
  }, []);

  async function getStaffAccounts() {
    try {
      const response = await axios.get(`/settings/staff/accounts`);
      if (response.data.success) {
        setStaffAccounts(response.data.data);
        getSubscription();
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function saveStaffAccount() {

    if (subscription?.settings.staffsAccount !== 'unlimited') {
      if ((Number(recordCounts.staffs) + 1) > subscription?.settings.staffsAccount) {
        return handleUpgradeModalShow(
          'Upgrade to Professional Plan',
          'Oops! we\'re sorry, You have reached the maxiumun number of staff accounts for this plan, kindly upgrade now to add more staffs account.'
        )
      }
    }

    try {
      setSaving(true)

      const data = { ...accountDetails }
      var response;

      if (accountDetails.id !== undefined) {
        response = await axios.put('/settings/update/staff/account', data);
      } else {
        response = await axios.post('/settings/create/staff/account', data);
      }

      if (response.data.success) {
        responseMessage(response.data.message, 'success')
        getStaffAccounts()
      }

      closeModal();

    } catch (error) {

      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(false)
  }

  async function removeAccount() {

    try {
      if (selectedForDelete === null) {
        return responseMessage("Something went wrong, invalid action attempted", "error");
      }

      const response = await axios.delete(`/settings/staff/account/remove/${selectedForDelete}`);

      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getStaffAccounts();

        closeDeleteModal()
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  const handleAccountDetailChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setAccountDetails({ ...accountDetails, [name]: value });
  }

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setModal(false);
    setAccountDetails({
      firstname: '',
      lastname: '',
      email: '',
    })
  }

  const closeDeleteModal = () => {
    setSelectedForDelete(null)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">

          <div className="row justify-content-center">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Manage Staffs</h4>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Staff Accounts</h4>
                </div>
                <div className="card-body">
                  <div className='d-flex justify-content-between'>
                    <div className='bank-details'>
                      <p className="text-muted">Add team/staffs that can help manage your business.</p>
                    </div>
                    <div className=''>
                      {subscription?.settings.staffsAccount === 0 ?
                        <Link to="/settings/billing-and-subscription" className='btn btn-success btn-sm'>Upgrade Your Plan</Link>
                        : <button type="button" className="btn btn-primary btn-sm" onClick={openModal}>
                          <i className="ri-add-circle-line"></i> <span className='desktop'>Create Staff</span>
                        </button>}
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {staffAccounts.length > 0 && (
                      <table
                        className="table align-middle"
                        id="staffAccountsTable"
                      >
                        <TableHeader columns={tableHeaderColumns} />

                        <tbody className="list form-check-all">
                          {staffAccounts.map((account, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkAll"
                                    value="account1"
                                  />
                                </div>
                              </th>
                              <td className="firstname">{account.firstname}</td>
                              <td className="lastname">{account.lastname}</td>
                              <td className="email">{account.email}</td>
                              <td className="verified">
                                {account.verified
                                  ? <span className="badge badge-soft-success text-uppercase">Active</span>
                                  : <span className="badge badge-soft-danger text-uppercase">Not Verified</span>
                                }
                              </td>
                              <td className="role">{account.role_id === 5 ? 'Member' : 'Admin'}</td>
                              <td className='last_seen'>{account.last_login_date}</td>
                              <td className="status">
                                {account.blocked === 0
                                  ? <span className="badge badge-soft-success text-uppercase">Active</span>
                                  : <span className="badge badge-soft-danger text-uppercase">Blocked</span>
                                }
                              </td>
                              <td>
                                <ul className="list-inline hstack gap-2 mb-0">

                                  <li
                                    className="list-inline-item"
                                    title="Remove"
                                  >
                                    <a
                                      className="text-danger d-inline-block d-flex align-items-center remove-item-btn"
                                      href="#removeAccount"
                                      onClick={() =>
                                        setSelectedForDelete(account.id)
                                      }
                                    >
                                      <i className="ri-delete-bin-5-fill fs-16"></i> <small>Remove</small>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                    }
                  </div>
                  <div className="live-preview">
                    <div>
                      <ModalBox
                        show={modal}
                        handleClose={closeModal}
                        title={accountDetails && accountDetails.id !== undefined ? 'Edit Account' : 'Add Staff'}
                        closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                        saveBtn={
                          (<button
                            type="button"
                            disabled={saving}
                            className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                            onClick={() => saveStaffAccount()}>
                            {saving
                              ? <>
                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div> Saving...
                              </> : 'Save'}
                          </button>)}
                      >
                        <form action="" method="POST" className="form" id="storeAddressForm" encType="multipart/form-data">
                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-lg-6 col-xl-6">
                                <div className='form-group'>
                                  <label
                                    htmlFor="firstname"
                                    className="form-label"
                                  >
                                    First Name
                                  </label>
                                  <input type="text" className="form-control form-control-solid" name="firstname"
                                    value={accountDetails.name} placeholder="Enter firstname"
                                    onChange={(e) => handleAccountDetailChange(e)} />
                                </div>
                              </div>

                              <div className="col-lg-6 col-xl-6">
                                <div className='form-group'>
                                  <label
                                    htmlFor="lastname"
                                    className="form-label"
                                  >
                                    Last Name
                                  </label>
                                  <input type="text" className="form-control form-control-solid" name="lastname"
                                    value={accountDetails.name} placeholder="Enter lastname"
                                    onChange={(e) => handleAccountDetailChange(e)} />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-xl-12 mb-3">
                              <div className="form-group ">
                                <label className="form-label">Email Address</label>
                                <input className="form-control form-control-solid" name="email" type="text" value={accountDetails.email}
                                  onChange={(e) => handleAccountDetailChange(e)} placeholder="Enter email address" />
                              </div>
                            </div>
                            <div className='col-lg-12 col-xl-12 mb-3'>
                              <div className='mb-3 form-group'>
                                <label className="form-label"><b>Role</b></label>
                                <select className="form-select" name='role_id'
                                  validatefield="true"
                                  validationoutput="#roleError"
                                  validationmessage="Role is required!*"
                                  onChange={e => handleAccountDetailChange(e)}>
                                  <option disabled selected>Select Role</option>
                                  {
                                    roles.map((role, index) => (
                                      accountDetails.role_id === role.id
                                        ? <option key={index} selected value={role.id}>{role.name}</option>
                                        : <option key={index} value={role.id}>{role.name}</option>
                                    ))
                                  }
                                </select>
                                <span id="roleError"></span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ModalBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          show={selectedForDelete ? true : false}
          title={'Remove Account'}
          message={'Are you sure you want to remove this staff account?'}
          handleDelete={removeAccount}
          handleClose={closeDeleteModal}
        />
      </div>
      <Outlet />
    </>
  )
}

export default StaffAccount