import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from 'wearslot-dev-utils'
import IconWithTooltip from '../../Components/IconWithTooltip'
import { copyLink } from '../../helpers'
import getSymbolFromCurrency from 'currency-symbol-map'

const InvoiceTableItem = ({ invoice, currency, index, setForDelete, store, copied, setCopied }) => {
    return (
        <tr key={index}>
            <th scope="row">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkAll"
                        value="option1"
                    />
                </div>
            </th>
            <td className="id">
                <Link
                    to={`/invoice/${invoice.id}`}
                    className="fw-semibold link-dark"
                >
                    {invoice.invoice_id}
                </Link>
            </td>
            <td className="customer_name">
                <div className="d-flex align-items-center">
                    {invoice.customer && invoice.customer.name}
                </div>
            </td>
            <td className="invoice_amount">
                {getSymbolFromCurrency(invoice.currency)}{formatCurrency(invoice.final_total)}
            </td>
            <td className="payment_method">
                {invoice.payment_method && (
                    <>{invoice.payment_method.name}</>
                )}
            </td>
            <td className="date">
                {moment(invoice.due_date).format('ddd, D MMM YYYY')}
            </td>
            <td className="status">
                {invoice.payment_status === "Unpaid" ? (
                    <span className="badge badge-soft-danger text-uppercase">
                        Unpaid
                    </span>
                ) : null}
                {invoice.payment_status === "Paid" ? (
                    <span className="badge badge-soft-success text-uppercase">
                        Paid
                    </span>
                ) : null}
                {invoice.payment_status === "Partially Paid" ? (
                    <span className="badge badge-soft-warning text-uppercase">
                        Partially Paid
                    </span>
                ) : null}
            </td>
            <td className="status">
                {invoice.is_sent === 1 ? (
                    <span className="badge badge-soft-success text-uppercase">Sent</span>
                ) : <span className="badge badge-soft-danger text-uppercase">Not Sent</span>}

            </td>
            <td>
                <ul className="list-inline hstack gap-2 mb-0">
                    <li
                        className="list-inline-item"
                        title="Copy Link"
                    >
                        <IconWithTooltip message={copied ? 'Link copied!' : "Copy invoice link"}>
                            <Link
                                type="button"
                                className="btn-sm btn btn-dark d-flex align-items-center gap-1"
                                onClick={() => { copyLink(`https://${store.slug}.${process.env.REACT_APP_STOREFRONT_DOMAIN}/invoice/${invoice.invoice_id}`, setCopied) }}
                            >
                                <i className="ri-link fs-16"></i> Copy
                            </Link>
                        </IconWithTooltip>
                    </li>
                    <li
                        className="list-inline-item"
                        title="View"
                    >
                        <IconWithTooltip message={'View Invoice'}>
                            <Link
                                to={`/invoice/${invoice.id}`}
                                className="text-primary d-inline-block"
                            >
                                <i className="ri-eye-fill fs-16"></i>
                            </Link>
                        </IconWithTooltip>
                    </li>
                    <li
                        className="list-inline-item edit"
                        title="Edit"
                    >
                        <IconWithTooltip message={'Edit Invoice'}>
                            <Link
                                to={`/invoice/${invoice.id}/edit`}
                                className="text-primary d-inline-block edit-invoice-btn"
                            >
                                <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </IconWithTooltip>
                    </li>
                    <li
                        className="list-inline-item download"
                        title="Download">
                        <IconWithTooltip message={'Download Invoice'}>
                            <Link
                                to={`#`}
                                className="text-primary d-inline-block download-invoice-btn"
                                onClick={() => {
                                    window.location = `${process.env.REACT_APP_API_ENDPOINT}/download/invoice/${invoice.invoice_id}`
                                }}
                            >
                                <i className="ri-download-2-line align-bottom me-1"></i>
                            </Link>
                        </IconWithTooltip>
                    </li>
                    <li
                        className="list-inline-item"
                        title="Delete"
                    >
                        <IconWithTooltip message={'Delete Invoice'}>
                            <Link
                                className="text-danger d-inline-block remove-item-btn"
                                to="#deleteInvoice"
                                onClick={() => setForDelete(invoice.id)}
                            >
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Link>
                        </IconWithTooltip>
                    </li>
                </ul>
            </td>
        </tr>
    )
}

export default InvoiceTableItem