import axios from "axios";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  errorResponse,
  responseMessage,
  uploadSelectedFiles,
} from "../../libs/app";
import { AuthContext } from "../../Contexts/AuthContext";
import FileUploader from "../../Components/FileUploader";
import ModalBox from "../../Components/ModalBox";
import { saveSettings } from "./Storefront";

const StoreSetup = () => {
  document.title = "Store Details | Taojaa - Store Manager App";

  const { store, subscription } = React.useContext(AuthContext);
  const [saving, setSaving] = useState(false);
  const [storeInformation, setStoreInformation] = useState(store);
  const [storeAddress, setStoreAddress] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [storeModal, setStoreModal] = useState(null);

  const [storefrontSettings, setStorefrontSettings] = useState({
    show_store_information: false,
    show_address_information: false,
    show_store_logo: true,
    favicon: null
  });

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getStorefrontSettings();
    getAddressInformation();
    getCountries();
  }, [store, subscription]);

  async function getStorefrontSettings() {
    try {
      const settings =
        "show_store_information,show_address_information,show_store_logo,favicon";
      const response = await axios.get(
        `/settings/storefront?names=${settings}`
      );
      if (response.data.success) {
        setStorefrontSettings(response.data.data);
      }
    } catch (error) { }
  }

  async function getCountries() {
    try {
      const response = await axios.get("/countries");

      if (response.data.success) {
        setCountries(response.data.data);
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
    }
  }

  async function getStates(country_id) {
    try {
      const response = await axios.get(`/states/${country_id}`);

      if (response.data.success) {
        setStates(response.data.data);
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
    }
  }

  async function getAddressInformation() {
    try {
      const response = await axios.get(`/settings/address-information`);
      if (response.data.success) {
        setStoreAddress(response.data.address);
      }
    } catch (error) { }
  }

  async function uploadStoreLogo(selectedFiles) {
    var response = await uploadSelectedFiles(selectedFiles, {
      field: "image",
      url:
        process.env.REACT_APP_API_ENDPOINT +
        "/api/v1/settings/update/store-logo",
      data: {
        store_id: store.id,
        type: "logo"
      },
    });
    if (response.success) {
      var newdata = { ...store };
      newdata["logo"] = response.image;
      localStorage.setItem("store", JSON.stringify(newdata));
      responseMessage(response?.message, "success");
    } else {
      responseMessage("Unable to upload image, please try again", "error");
    }
  }
  async function uploadStoreFavicon(selectedFiles) {
    var response = await uploadSelectedFiles(selectedFiles, {
      field: "image",
      url:
        process.env.REACT_APP_API_ENDPOINT +
        "/api/v1/settings/update/store-logo",
      data: {
        store_id: store.id,
        type: "favicon"
      },
    });
    if (response.success) {
      var newdata = { ...store };
      newdata["favicon"] = response.image;
      localStorage.setItem("store", JSON.stringify(newdata));
      responseMessage(response?.message, "success");
    } else {
      responseMessage("Unable to upload image, please try again", "error");
    }
  }

  async function saveStoreInformation() {
    try {
      setSaving(true);

      const response = await axios.put(
        "/settings/update/store-infomation",
        storeInformation
      );
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        localStorage.setItem("store", JSON.stringify(response.data.store));
      }

      closeStoreModal();
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }

    setSaving(false);
  }

  async function saveStoreAddress() {
    try {
      setSaving(true);

      const data = { ...storeAddress, store_id: store.id };
      const response = await axios.put(
        "/settings/update/address-infomation",
        data
      );

      if (response.data.success) {
        responseMessage(response.data.message, "success");
      }

      closeStoreModal();
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }

    setSaving(false);
  }

  function updateSettings(name) {
    var status = storefrontSettings[name] ? false : true;
    saveSettings({ [name]: status });

    setStorefrontSettings({ ...storefrontSettings, [name]: status });
  }

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setStoreInformation({ ...storeInformation, [name]: value });
  };

  const handleAddressFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setStoreAddress({ ...storeAddress, [name]: value });
  };


  const openStoreModal = (target, title = '') => {
    setStoreModal(target);
  };

  const closeStoreModal = () => {
    setStoreModal(null);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Store Settings</h4>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-9 mb-4">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Logo & Favicon</h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <label
                        htmlFor="default-modal"
                        className="form-label text-muted"
                      >
                        Show on website
                      </label>
                      <input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        checked={storefrontSettings.show_store_logo}
                        onChange={() => updateSettings("show_store_logo")}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-between">
                        <p className="text-muted text-muted">
                          Set your brand logo.
                        </p>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => openStoreModal("store-logo")}
                          >
                            <i className="ri-upload-cloud-2-line"></i>{" "}
                            <span className="desktop">Upload Logo</span>
                          </button>
                        </div>
                      </div>

                      <div>
                        <div className="imagePath">
                          <div>
                            {store.logo ? (
                              <img
                                className="tj-upload-img"
                                style={{ width: "200px" }}
                                src={store.logo}
                                alt=""
                              />
                            ) : (
                              <h3>{store.name}</h3>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-between">
                        <p className="text-muted text-muted">
                          Set your brand favicon.
                        </p>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => openStoreModal("store-favicon")}
                          >
                            <i className="ri-upload-cloud-2-line"></i>{" "}
                            <span className="desktop">Upload Favicon</span>
                          </button>
                        </div>
                      </div>

                      <div>
                        <div className="imagePath">
                          <div>
                            {storefrontSettings.favicon ? (
                              <img
                                className="tj-upload-img"
                                style={{ width: "200px" }}
                                src={storefrontSettings.favicon}
                                alt=""
                              />
                            ) : (
                              <h3>{store.name}</h3>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="live-preview">
                    <div>
                      <ModalBox
                        show={storeModal === "store-favicon" ? true : false}
                        handleClose={closeStoreModal}
                        title={"Upload Favicon"}
                        closeBtn={
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={closeStoreModal}
                          >
                            {" "}
                            Close
                          </button>
                        }
                        saveBtn={""}
                      >
                        <form
                          action=""
                          method="POST"
                          className="form"
                          id="storeFaviconForm"
                          encType="multipart/form-data"
                        >
                          <div className="card-body">
                            <div className="form-group mb-3">
                              <div className="col-lg-12 col-xl-12">
                                <FileUploader uploader={uploadStoreFavicon} />
                                <span className="form-text text-muted">
                                  Allowed file types: png, jpg, jpeg.
                                </span>
                              </div>
                              <div className="progress mt-2 hide">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: 0 }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  0%
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ModalBox>
                    </div>
                  </div>
                  <div className="live-preview">
                    <div>
                      <ModalBox
                        show={storeModal === "store-logo" ? true : false}
                        handleClose={closeStoreModal}
                        title={"Upload Logo"}
                        closeBtn={
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={closeStoreModal}
                          >
                            {" "}
                            Close
                          </button>
                        }
                        saveBtn={""}
                      >
                        <form
                          action=""
                          method="POST"
                          className="form"
                          id="storeLogoForm"
                          encType="multipart/form-data"
                        >
                          <div className="card-body">
                            <div className="form-group mb-3">
                              <div className="col-lg-12 col-xl-12">
                                <FileUploader uploader={uploadStoreLogo} />
                                <span className="form-text text-muted">
                                  Allowed file types: png, jpg, jpeg.
                                </span>
                              </div>
                              <div className="progress mt-2 hide">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: 0 }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  0%
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ModalBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-9 mb-4">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Store Details
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <label
                        htmlFor="default-modal"
                        className="form-label text-muted"
                      >
                        Show on website
                      </label>
                      <input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        checked={storefrontSettings.show_store_information}
                        onChange={() =>
                          updateSettings("show_store_information")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <p className="text-muted text-muted">
                      Update business details and contact infomation.
                    </p>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => openStoreModal("store-information")}
                      >
                        <i className="ri-pencil-fill fs-16"></i>
                      </button>
                    </div>
                  </div>
                  <div className="">
                    {storeInformation && (
                      <>
                        <p className="fw-bold mb-1">{storeInformation.name}</p>
                        <p className="mb-1">{storeInformation.description}</p>
                        <p className="mb-1">
                          <b>Email:</b> {storeInformation.email}
                        </p>
                        <p className="mb-1">
                          <b>Phone:</b> {storeInformation.phone}
                        </p>
                        <p className="mb-1">
                          <b>WhatsApp:</b>{" "}
                          <a href={storeInformation.whatsapp} target="_blank">
                            {storeInformation.whatsapp}
                          </a>
                        </p>
                        <div className="d-flex gap-3">
                          <p>
                            <i className="ri-facebook-circle-fill fs-16"></i>{" "}
                            <span>{storeInformation.facebook}</span>
                          </p>
                          <p>
                            <i className="ri-instagram-fill fs-16"></i>{" "}
                            <span>{storeInformation.instagram}</span>
                          </p>
                          <p>
                            <i className="ri-twitter-fill fs-16"></i>{" "}
                            <span>{storeInformation.twitter}</span>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="live-preview">
                    <div>
                      <ModalBox
                        show={storeModal === "store-information" ? true : false}
                        handleClose={closeStoreModal}
                        title={"Business Details"}
                        closeBtn={
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={closeStoreModal}
                          >
                            {" "}
                            Close
                          </button>
                        }
                        saveBtn={
                          <button
                            type="button"
                            disabled={saving}
                            className={`btn btn-primary ${saving ? "disabled" : ""
                              }`}
                            onClick={() => saveStoreInformation()}
                          >
                            {saving ? (
                              <>
                                <div
                                  className="spinner-border spinner-border-sm text-white"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>{" "}
                                Saving...
                              </>
                            ) : (
                              "Save Changes"
                            )}
                          </button>
                        }
                      >
                        <form
                          action=""
                          method="POST"
                          className="form"
                          id="storeInformationForm"
                          encType="multipart/form-data"
                        >
                          <div className="card-body">
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Business Name
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <input
                                  className="form-control form-control-solid"
                                  disabled
                                  name="store_name"
                                  type="text"
                                  value={storeInformation.name || ""}
                                  onChange={(e) => null}
                                />
                                <p className="form-text text-muted">
                                  Business name is not editable, if you're
                                  willing change your business name. Kindly{" "}
                                  <a href="#">Contact Support</a>.
                                </p>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Bio
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <textarea
                                  className="form-control form-control-solid"
                                  rows="5"
                                  name="description"
                                  value={storeInformation.description || ""}
                                  onChange={(e) => {
                                    handleFieldChange(e);
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Facebook
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <div className="input-group input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-facebook"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="facebook"
                                    value={storeInformation.facebook || ""}
                                    placeholder=""
                                    onChange={(e) => {
                                      handleFieldChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Instagram
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <div className="input-group input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-instagram"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="instagram"
                                    value={storeInformation.instagram || ""}
                                    placeholder=" e.g @john.doe"
                                    onChange={(e) => {
                                      handleFieldChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Twitter
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <div className="input-group input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-twitter"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="twitter"
                                    value={storeInformation.twitter || ""}
                                    placeholder=" e.g @john.doe"
                                    onChange={(e) => {
                                      handleFieldChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <label className="col-xl-3"></label>
                              <div className="col-lg-9 col-xl-9">
                                <h5 className="font-weight-bold mt-10 mb-6">
                                  Contact Info
                                </h5>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Email
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <div className="input-group input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-email"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="email"
                                    value={storeInformation.email || ""}
                                    placeholder="Email Address"
                                    onChange={(e) => {
                                      handleFieldChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Phone
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <div className="input-group input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-phone"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="phone"
                                    value={storeInformation.phone || ""}
                                    placeholder="Phone Number"
                                    onChange={(e) => {
                                      handleFieldChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                WhatsApp
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <div className="input-group input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-whatsapp"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="whatsapp"
                                    className="form-control form-control-solid"
                                    value={storeInformation.whatsapp || ""}
                                    placeholder="WhatsApp link"
                                    onChange={(e) => {
                                      handleFieldChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Domain
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <div className="input-group input-group-solid">
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="la la-globe"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="website"
                                    className="form-control form-control-solid"
                                    placeholder=" e.g taojaa.com"
                                    value={storeInformation.website || ""}
                                    onChange={(e) => {
                                      handleFieldChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ModalBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-9">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Address Information
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <label
                        htmlFor="default-modal"
                        className="form-label text-muted"
                      >
                        Show on website
                      </label>
                      <input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        checked={storefrontSettings.show_address_information}
                        onChange={() =>
                          updateSettings("show_address_information")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <p className="text-muted">
                      Add your business/store address to help customers locate
                      you.
                    </p>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => openStoreModal("store-address")}
                      >
                        <i className="ri-pencil-fill fs-16"></i>
                      </button>
                    </div>
                  </div>
                  <div className="">
                    {storeAddress && (
                      <>
                        <p className="m-0">
                          {storeAddress.additional_address},{" "}
                          {storeAddress.street}
                        </p>
                        <p className="m-0">
                          {storeAddress.city}, {storeAddress.state?.name}
                        </p>
                        <p className="m-0">
                          {storeAddress.country?.name}{" "}
                          {storeAddress.zip && `, ${storeAddress.zip}`}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="live-preview">
                    <div>
                      <ModalBox
                        show={storeModal === "store-address" ? true : false}
                        handleClose={closeStoreModal}
                        title={"Store Address"}
                        closeBtn={
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={closeStoreModal}
                          >
                            Close
                          </button>
                        }
                        saveBtn={
                          <button
                            type="button"
                            disabled={saving}
                            className={`btn btn-primary ${saving ? "disabled" : ""
                              }`}
                            onClick={() => saveStoreAddress()}
                          >
                            {saving ? (
                              <>
                                <div
                                  className="spinner-border spinner-border-sm text-white"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>{" "}
                                Saving...
                              </>
                            ) : (
                              "Save Changes"
                            )}
                          </button>
                        }
                      >
                        <form
                          action=""
                          method="POST"
                          className="form"
                          id="storeAddressForm"
                          encType="multipart/form-data"
                        >
                          <div className="card-body">
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Suite, Apt no.
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  name="additional_address"
                                  value={
                                    (storeAddress &&
                                      storeAddress.additional_address) ||
                                    ""
                                  }
                                  placeholder="Suite, Apt."
                                  onChange={(e) => handleAddressFieldChange(e)}
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                street
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <input
                                  className="form-control form-control-solid"
                                  name="street"
                                  type="text"
                                  value={
                                    (storeAddress && storeAddress.street) || ""
                                  }
                                  onChange={(e) => handleAddressFieldChange(e)}
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                City
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <input
                                  className="form-control form-control-solid"
                                  name="city"
                                  type="text"
                                  placeholder="City"
                                  value={
                                    (storeAddress && storeAddress.city) || ""
                                  }
                                  onChange={(e) => handleAddressFieldChange(e)}
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Country
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <select
                                  className="form-select"
                                  name="country_id"
                                  aria-label=".form-select-sm example"
                                  onChange={(e) => {
                                    handleAddressFieldChange(e);
                                    getStates(e.target.value);
                                  }}
                                >
                                  <option disabled selected>
                                    Select country
                                  </option>
                                  {countries.map((country, index) =>
                                    storeAddress &&
                                      storeAddress.country_id === country.id ? (
                                      <option
                                        key={index}
                                        selected
                                        value={country.id}
                                      >
                                        {country.name}
                                      </option>
                                    ) : (
                                      <option key={index} value={country.id}>
                                        {country.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                State
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <select
                                  className="form-select"
                                  name="state_id"
                                  aria-label=".form-select-sm example"
                                  onChange={(e) => handleAddressFieldChange(e)}
                                >
                                  <option disabled selected>
                                    Select state
                                  </option>
                                  {states.map((state, index) =>
                                    storeAddress &&
                                      storeAddress.state_id === state.id ? (
                                      <option
                                        key={index}
                                        selected
                                        value={state.id}
                                      >
                                        {state.name}
                                      </option>
                                    ) : (
                                      <option key={index} value={state.id}>
                                        {state.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                                Zip Code
                              </label>
                              <div className="col-lg-9 col-xl-9">
                                <input
                                  type="text"
                                  name="zip"
                                  className="form-control form-control-solid"
                                  placeholder="Zip Code"
                                  value={
                                    (storeAddress && storeAddress.zip) || ""
                                  }
                                  onChange={(e) => handleAddressFieldChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </ModalBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default StoreSetup;
