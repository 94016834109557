import React from "react";
import { useNavigate } from "react-router-dom";

const PageItem = ({ title, description, id, openDeleteModal }) => {
  const navigate = useNavigate();
  return (
    <div className="row border-bottom p-2 ">
      <a
        onClick={() => {
          navigate(`${id}`);
        }}
        href="#"
        className="col-2 text-decoration-underline d-flex flex-wrap fs-6 fs-md-5 fs-lg-4 break-words overflow-hidden"
        >
        {title}
      </a>
      <div className="col-8">
        {description}
      </div>
      <div
        onClick={() => openDeleteModal(id)}
        className="col-2 text-danger text-center"
      >
        <p>
          <i className="ri-delete-bin-5-fill fs-16"></i>
        </p>
      </div>
    </div>
  );
};

export default PageItem;
