import moment from 'moment';
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Outlet } from "react-router-dom";
import Select from "react-select";
import {
    errorResponse,
    responseMessage,
} from "../../libs/app";
import Flatpickr from "react-flatpickr";
import SearchResponse from '../../Components/SearchResponse/SearchResponse';
import Loader from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination";
import getSymbolFromCurrency from 'currency-symbol-map';
import { addFiltersToEndpoint, dateChange } from '../../helpers';
import { formatCurrency } from 'wearslot-dev-utils';
import ModalBox from '../../Components/ModalBox';


const Withdrawals = () => {

    document.title = 'Withdrawals | Taojaa - Store Manager App';

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [withdrawals, setWithdrawals] = useState([]);
    const [withdrawal, setWithdrawal] = useState(null);
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState([]);
    const [amount, setAmount] = useState(0.00);

    var end = moment();
    var start = moment().subtract(7, "days");
    const [initialFrom, setInitialFrom] = useState(start.format("YYYY-MM-DD"));
    const [initialTo, setInitialTo] = useState(end.format("YYYY-MM-DD"));

    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [pagination, setPagination] = useState(null);
    const [filters, setFilters] = useState({
        status: '',
        keywords: "",
        start_date: initialFrom,
        end_date: initialTo,
    });

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevState) => ({ ...prevState, [name]: value }));
    };

    const applyFilter = () => {
        getWithdrawals(filters);
    };

    const resetFilter = () => {
        setFilters({
            keywords: "",
            start_date: "",
            end_date: "",
            status: ""
        });
        getWithdrawals();
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        getWithdrawals();
    }, []);

    async function getWithdrawals(filters = null) {

        try {

            let endpoint = `/withdrawals?page=${currentPage}&perpage=${perPage}`;
            if (filters) {
                endpoint = addFiltersToEndpoint(filters, endpoint);
            }

            const response = await axios.get(endpoint);
            if (response.data.success) {
                setWithdrawals(response.data.data);
                setPagination(response.data.pagination)
            }
        } catch (error) {
            if (error.response) {
                return errorResponse(error.response);
            }

            return responseMessage("Something went wrong.", "error");
        }

        setLoading(false)
    }

    async function viewWithdrawal(id) {

        try {

            const response = await axios.get(`/withdrawal/${id}`);
            if (response.data.success) {
                setWithdrawal(response.data.data);
                return openModal("view")
            }

        } catch (error) {
            if (error.response) {
                return errorResponse(error.response);
            }
            return responseMessage("Something went wrong.", "error");
        }
    }

    async function getWallets() {

        try {

            const response = await axios.get('/wallets');
            if (response.data.success) {
                var walletOptions = response.data.data.map(wallet => {
                    return { label: wallet.currency.abbr + ' - Balance: ' + getSymbolFromCurrency(wallet.currency.abbr) + formatCurrency(wallet.balance), value: wallet.id }
                });

                setWallets(walletOptions);
            }
        } catch (error) {
            if (error.response) {
                return errorResponse(error.response);
            }

            return responseMessage("Something went wrong.", "error");
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            if (Number(amount) <= 0) {
                return responseMessage('Enter a valid withdrawal amount', 'warning')
            }

            if (selectedWallet.length === 0) {
                return responseMessage('Select a wallet to perform withrawal', 'warning');
            }

            const data = { wallet_id: selectedWallet.value, amount };
            const response = await axios.post('/withdraw', data);

            if (response.data.success) {
                responseMessage(response.data.message);
                getWithdrawals()
                closeModal();
            }

        } catch (error) {
            console.log(error)
            if (error.response) {
                return errorResponse(error.response);
            }
            return responseMessage("Something went wrong.", "error");
        }

    };

    const openModal = (type) => {
        getWallets()
        setModal(type)
    }

    const closeModal = () => {
        setModal(null);
        setAmount(0.00)
        setSelectedWallet([]);
        setWithdrawal(null)
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Withdrawals</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="#">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#">Payments</a>
                                        </li>
                                        <li className="breadcrumb-item active">Withdrawals</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card" id="customerList">
                                <div className="card-header border-bottom-dashed">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Withdrawal History</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <button
                                                type="button"
                                                className="btn btn-success me-1"
                                                onClick={() => openModal("withdraw")}
                                            >
                                                Withdraw Funds
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body border-bottom-dashed border-bottom">
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-xl-4">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        name="keywords"
                                                        className="form-control search"
                                                        placeholder="Search for transaction reference, status or something..."
                                                        onChange={(e) => handleFilterChange(e)}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>

                                            <div className="col-xl-8">
                                                <div className="row g-3">
                                                    <div className="col-sm-4">
                                                        <Flatpickr
                                                            className="form-control border-0 shadow"
                                                            id="dash-filter-picker"
                                                            placeholder="start date to end date"
                                                            options={{ mode: "range" }}
                                                            defaultValue={initialFrom + " to " + initialTo}
                                                            onChange={(selectedDates) => dateChange(selectedDates, setInitialFrom, setInitialTo)}
                                                        />
                                                    </div>

                                                    <div className="col-sm-4">
                                                        <div>
                                                            <select
                                                                className="form-control"
                                                                name="status"
                                                                id="idStatus"
                                                                onChange={(e) => handleFilterChange(e)}
                                                            >
                                                                <option value="">Status</option>
                                                                <option value="316">Pending</option>
                                                                <option value="111">Successful</option>
                                                                <option value="901">Failed</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-2 col-sm-3">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary w-100"
                                                                onClick={applyFilter}
                                                            >
                                                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                                                Filters
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-2 col-sm-3">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger w-100 filter-btn"
                                                                onClick={resetFilter}
                                                            >
                                                                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                                                Reset
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="table-responsive table-card mb-1">
                                            {withdrawals.length > 0 ? (
                                                <table
                                                    className="table align-middle"
                                                    id="customerTable"
                                                >
                                                    <thead className="table-light text-muted">
                                                        <tr className="text-uppercase">
                                                            <th scope="col" style={{ width: "50" }}>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                                </div>
                                                            </th>
                                                            <th className="sort" data-sort="reference">Reference</th>
                                                            <th className="sort" data-sort="account_name">Account Details</th>
                                                            <th className="sort" data-sort="amount">Amount</th>
                                                            <th className="sort" data-sort="balance">Balance</th>
                                                            <th className="sort" data-sort="date">Date</th>
                                                            <th className="sort" data-sort="status">Status</th>
                                                            <th className="sort" data-sort="action">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {withdrawals.map((withdrawal, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="checkAll"
                                                                            value="option1"
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <td className="reference">
                                                                    {withdrawal.transaction.reference}
                                                                </td>
                                                                <td className="account_details">
                                                                    <div>{withdrawal.account_name}</div>
                                                                    <div className='text-muted'>{withdrawal.account_number} - <small>{withdrawal.bank}</small></div>
                                                                </td>
                                                                <td className="amount text-danger fw-bold">-{getSymbolFromCurrency(withdrawal.wallet.currency.abbr)}{formatCurrency(withdrawal.amount)}</td>
                                                                <td className="balance text-success fw-bold">{getSymbolFromCurrency(withdrawal.wallet.currency.abbr)}{formatCurrency(withdrawal.transaction.balance)}</td>
                                                                <td className="date">
                                                                    {moment(withdrawal.created_date).format("Y-MM-DD H:mm:ss ")}
                                                                </td>
                                                                <td className="status">
                                                                    {withdrawal.status === 111 ? <span className="badge badge-soft-success text-uppercase">Successful</span>
                                                                        : <>
                                                                            {withdrawal.status === 316
                                                                                ? <span className="badge badge-soft-warning text-uppercase">Pending</span>
                                                                                : <span className="badge badge-soft-danger text-uppercase">Failed</span>}
                                                                        </>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li
                                                                            className="list-inline-item edit"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-trigger="hover"
                                                                            data-bs-placement="top"
                                                                            title="Edit"
                                                                        >
                                                                            <a
                                                                                className="text-primary d-inline-block edit-item-btn"
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() =>
                                                                                    viewWithdrawal(withdrawal.id)
                                                                                }
                                                                            >
                                                                                <i className="ri-eye-fill fs-16"></i> View
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <>
                                                    {loading
                                                        ? <div className="py-5"><Loader message={<p className="text-muted">Fetching withdrawals...</p>} /></div>
                                                        : <SearchResponse title={"No Withdrawal Available"}
                                                            message={<p>You don't have any withdrawals yet.</p>}
                                                        />
                                                    }
                                                </>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Pagination pagination={pagination} onPageChange={onPageChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalBox
                        show={modal === 'withdraw'}
                        handleClose={closeModal}
                        title={'Withdrawal'}
                        closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                        saveBtn={
                            (<button
                                type="button"
                                disabled={saving}
                                className={`btn btn-success ${saving ? 'disabled' : ''}`}
                                onClick={(e) => handleSubmit(e)}
                            >
                                {saving
                                    ? <>
                                        <div className="spinner-border spinner-border-sm text-white" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> Proccessing...
                                    </> : 'Withdraw'}
                            </button>)}
                    >
                        <form>
                            <div className="mb-3">
                                <label
                                    htmlFor="wallet-field"
                                    className="form-label"
                                >
                                    Wallets
                                </label>
                                <Select
                                    isMulti={false}
                                    isClearable={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    isSearchable={true}
                                    closeMenuOnSelect={true}
                                    options={wallets}
                                    value={selectedWallet}
                                    onChange={setSelectedWallet}
                                    styles={{backgroundColor: 'red'}}
                                >
                                    Select Wallet
                                </Select>
                            </div>

                            <div className="mb-3">
                                <div>
                                    <label
                                        htmlFor="amount-field"
                                        className="form-label"
                                    >
                                        Amount
                                    </label>
                                    <input
                                        type="number"
                                        id="amount-field"
                                        className="form-control"
                                        placeholder="0.00"
                                        name="amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </form>
                    </ModalBox>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default Withdrawals