import React from "react";
import { useNavigate } from "react-router-dom";

const MenuItems = ({ title, menuItem, id, openDeleteModal }) => {
  const navigate = useNavigate();
  return (
    <div className="row border-bottom p-2">
      <div className="col-2 mb-2 mb-md-0">
        <a
          onClick={() => {
            navigate(`${id}`);
          }}
          href="#"
          className="text-decoration-underline d-flex flex-wrap fs-6 fs-md-5 fs-lg-4 break-words"
      
        >
          {title}
        </a>
      </div>
      <div className="col-8 mb-2 mb-md-0">
        {menuItem.length > 0 ? (
          <div className="d-flex flex-wrap gap-2">
            {menuItem.map((item, index) => {
              return <div key={index}>{item.name}</div>;
            })}
          </div>
        ) : null}
      </div>
      <div
        onClick={() => openDeleteModal(id)}
        className="col-2 text-danger text-center"
      >
        <p>
          <i className="ri-delete-bin-5-fill fs-16"></i>
        </p>
      </div>
    </div>
  );
};

export default MenuItems;
