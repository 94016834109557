import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { addFiltersToEndpoint, dateChange } from "../../helpers";
import { errorResponse, responseMessage } from "../../libs/app";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import Pagination from "../../Components/Pagination";
import IconWithTooltip from "../../Components/IconWithTooltip";
import DeleteModal from "../../Components/DeleteModal";
import { AppContext } from "../../Contexts/AppContext";
import { AuthContext } from "../../Contexts/AuthContext";

export const CampaignStatus = ({ status }) => {
  if (status === "failed" || status === "cancelled") {
    return (
      <span className="badge badge-soft-danger text-uppercase">{status}</span>
    );
  } else if (status === "pending") {
    return (
      <span className="badge badge-soft-warning text-uppercase">Sending</span>
    );
  } else if (status === "scheduled") {
    return (
      <span className="badge badge-soft-primary text-uppercase">Scheduled</span>
    );
  } else if (status === "completed") {
    return (
      <span className="badge badge-soft-success text-uppercase">Completed</span>
    );
  } else if (status === "draft") {
    return <span className="badge badge-dark bg-dark text-uppercase">Draft</span>;
  }
};

export const CampaignAction = ({ campaign, signal }) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteCampaign = () => {
    setDeleteConfirmation(true);
  };

  const confirmDelete = () => {
    axios
      .delete(`/campaign/delete/${campaign.id}`)
      .then((response) => {
        if (response.data.success) {
          signal(true);
          closeDeleteModal();
          responseMessage(response.data.message, "success");
        }
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      });
  };

  const closeDeleteModal = () => {
    setDeleteConfirmation(false);
  };

  const removeItem = () => {
    return (
      <DeleteModal
        show={deleteConfirmation}
        title={"Delete confirmation"}
        message={"Are you sure you want to delete this campaign?"}
        handleDelete={confirmDelete}
        handleClose={closeDeleteModal}
      />
    );
  };

  return (
    <>
      {deleteConfirmation ? removeItem() : null}
      <ul className="list-inline hstack gap-2 mb-0">
        {campaign.status === "draft" && (
          <li className="list-inline-item edit" title="Edit">
            <IconWithTooltip message={"Edit Campaign"}>
              <Link
                to={`/marketing/campaign/${campaign.id}/edit`}
                className="text-primary d-inline-block edit-item-btn"
              >
                <i className="ri-pencil-fill fs-16"></i>
              </Link>
            </IconWithTooltip>
          </li>
        )}
        <li className="list-inline-item" title="Remove">
          <IconWithTooltip message={"Delete Campaign"}>
            <Link
              className="text-danger d-inline-block remove-item-btn"
              onClick={() => deleteCampaign()}
            >
              <i className="ri-delete-bin-5-fill fs-16"></i>
            </Link>
          </IconWithTooltip>
        </li>
      </ul>
    </>
  );
};

const Campaigns = () => {
  document.title = "Marketing - Campaigns | Taojaa - Store Manager App";
  const initialFilters = {
    status: "",
    channel: "",
    keywords: "",
    start_date: null,
    end_date: null,
  };

  const { setEmailTemplate } = useContext(AppContext);
  const { store, subscription } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const [perPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [pagination, setPagination] = useState(null);

  const [filters, setFilters] = useState(initialFilters);

  const [refresh, setRefresh] = useState(false);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const applyFilter = () => {
    getCampaigns(filters);
  };

  const resetFilter = () => {
    setFilters(initialFilters);
    getCampaigns();
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const getCampaigns = useCallback(async (filters = null) => {
    setLoading(true);
    let endpoint = `/campaigns?page=${currentPage}&perpage=${perPage}`;
    if (filters) {
      endpoint = addFiltersToEndpoint(filters, endpoint);
    }

    try {
      const response = await axios.get(endpoint);

      if (response.data.success) {
        setCampaigns(response.data.data);
        setPagination(response.data.pagination);
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getCampaigns();
    setEmailTemplate({ html: null, design: null });
  }, [refresh, setEmailTemplate, store, subscription]);


  return (
    <>
      <section className="page-content">
        <div className="container-fluid">
          <header className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Marketing - Campaigns</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">Marketing</li>
                    <li className="breadcrumb-item active">Campaigns</li>
                  </ol>
                </div>
              </div>
            </div>
          </header>

          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div>
                <div className="card">
                  <header className="card-header  border-0">
                    <div className="d-flex align-items-center">
                      <h5 className="card-title mb-0 flex-grow-1">
                        All Campaigns
                      </h5>
                      <div className="flex-shrink-0">
                        <Link
                          to="/marketing/campaigns/create"
                          className="btn btn-success add-btn me-1"
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Create Campaign
                        </Link>
                      </div>
                    </div>
                  </header>

                  <div className="card-body border border-dashed border-end-0 border-start-0">
                    <form>
                      <div className="row g-3">
                        <div className="col-xxl-6 col-sm-6">
                          <div className="search-box">
                            <input
                              type="text"
                              className="form-control search"
                              name="keywords"
                              placeholder="Search for title, subject, or something..."
                              onChange={handleFilterChange}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>

                        <div className="col-xxl-6 col-sm-6">
                          <Flatpickr
                            className="form-control"
                            id="dash-filter-picker"
                            placeholder="start date to end date"
                            options={{ mode: "range" }}
                            defaultValue={
                              filters.start_date && filters.end_date !== null
                                ? filters.start_date + " to " + filters.end_date
                                : ""
                            }
                            onChange={(selectedDates) =>
                              dateChange(selectedDates, filters, setFilters)
                            }
                          />
                        </div>

                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <select
                              className="form-control"
                              name="status"
                              id="idStatus"
                              onChange={handleFilterChange}
                            >
                              <option defaultValue="">Select Status</option>
                              <option value="all">All</option>
                              <option value="ongoing">Ongoing</option>
                              <option value="scheduled">Scheduled</option>
                              <option value="draft">Draft</option>
                              <option value="completed">Completed</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <select
                              className="form-control"
                              name="channel"
                              id="idChannel"
                              onChange={handleFilterChange}
                            >
                              <option defaultValue="">Select Channel</option>
                              <option value="all">All</option>
                              <option value="sms">SMS</option>
                              <option value="email">Email</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary w-100 filter-brn"
                              onClick={applyFilter}
                            >
                              <i className="ri-equalizer-fill me-1 align-bottom"></i>
                              Filter
                            </button>
                          </div>
                        </div>
                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger w-100 filter-btn"
                              onClick={resetFilter}
                            >
                              <i className="ri-equalizer-fill me-1 align-bottom"></i>
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="card-body">
                    <div>
                      <div className="table-responsive table-card mb-1">
                        {campaigns.length > 0 ? (
                          <>
                            <table
                              className="table table-nowrap align-middle"
                              id="campaignsTable"
                            >
                              <thead className="text-muted table-light">
                                <tr className="text-uppercase">
                                  <th scope="col" style={{ width: "25px" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                      />
                                    </div>
                                  </th>
                                  <th className="sort" data-sort="id">
                                    ID
                                  </th>
                                  <th
                                    className="sort"
                                    data-sort="campaign_title"
                                  >
                                    Title
                                  </th>
                                  <th className="sort" data-sort="channel">
                                    Channel
                                  </th>
                                  <th className="sort" data-sort="recipients">
                                    Recipients
                                  </th>
                                  <th className="sort" data-sort="status">
                                    Status
                                  </th>
                                  <th className="sort" data-sort="action">
                                    Actions
                                  </th>
                                </tr>
                              </thead>

                              <tbody className="list form-check-all">
                                {campaigns.map((campaign, index) => (
                                  <tr key={index}>
                                    <th scope="row">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                        />
                                      </div>
                                    </th>
                                    <td className="id">
                                      <Link
                                        to={`campaigns/${campaign.id}/details`}
                                        className="fw-semibold link-dark"
                                      >
                                        #{campaign.id || "N/A"}
                                      </Link>
                                    </td>
                                    <td className="campaign_title">
                                      {campaign.title}
                                    </td>
                                    <td className="campaign_channel text-capitalize">
                                      {campaign.channel}
                                    </td>
                                    <td className="campaign_recipients_count">
                                      {campaign.recipients_count}
                                    </td>
                                    <td className="campaign_status">
                                      <CampaignStatus
                                        status={campaign.status}
                                      />
                                    </td>
                                    <td className="campaign_actions">
                                      <CampaignAction
                                        campaign={campaign}
                                        signal={setRefresh}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <>
                            {loading ? (
                              <div className="py-5">
                                <Loader
                                  message={
                                    <p className="text-muted">
                                      Fetching campaigns...
                                    </p>
                                  }
                                />
                              </div>
                            ) : (
                              <SearchResponse
                                title={"No Campaign Available"}
                                message={"You don't have any campaign yet"}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <Pagination
                          pagination={pagination}
                          onPageChange={onPageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Outlet />
    </>
  );
};

export default Campaigns;
