import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import { errorResponse, responseMessage } from "../../libs/app";
import { AuthContext } from "../../Contexts/AuthContext";
import { useContext } from "react";
import Pagination from "../../Components/Pagination";
import Loader from "../../Components/Loader/Loader";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import Flatpickr from "react-flatpickr";
import DeleteModal from "../../Components/DeleteModal";
import { addFiltersToEndpoint, dateChange } from "../../helpers";
import { formatCurrency } from "wearslot-dev-utils";
import IconWithTooltip from "../../Components/IconWithTooltip";



const Action = ({ blog, signal }) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isDeletingBlog , setIsDeletingBlog] = useState(false)
  const [blogOption, setBlogOption] = useState([]);


  useEffect(() => {}, [deleteConfirmation]);

  const deleteblog = (blog) => {
    setDeleteConfirmation(blog);
  };
  const createOption = ({ _id, name, slug }) => {
    const id = _id;
    return {
      value: id,
      label: name,
    };
  };
 const getBlogs = () => {
    axios
      .get(`/blog/list`)
      .then((response) => {
        const category = response?.data?.data;
        const options = category?.map(createOption);
        setBlogOption(options);
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
    
      });
  };
  const confirmDelete = () => {
    setIsDeletingBlog(true)
    axios
      .delete(`/blog/post/${blog.slug}`)
      .then((response) => {
        if (response.data.success) {
          signal(blog.id);
          setDeleteConfirmation(null);
          responseMessage(response.data.message, "success");
          setIsDeletingBlog(false)
          getBlogs()
        }
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
        setIsDeletingBlog(false)

      });
  };

  const removeItem = () => {
    return (
      <DeleteModal
        show={deleteConfirmation ? true : false}
        title={"Delete confirmation"}
        message={"Are you sure you want to delete this blog?"}
        handleDelete={confirmDelete}
        handleClose={() => setDeleteConfirmation(null)}
        deleting = {isDeletingBlog}
      />
    );
  };


  return (
    <>
      {deleteConfirmation ? removeItem(deleteConfirmation) : null}
      <ul className="list-inline hstack gap-2 mb-0">
       
        <li className="list-inline-item edit" title="Edit">
          <IconWithTooltip message={"Edit blog"}>
            <Link
              to={"/blogs/" + blog?.slug + "/edit"}
              className="text-primary d-inline-block edit-item-btn"
            >
              <i className="ri-pencil-fill fs-16"></i>
            </Link>
          </IconWithTooltip>
        </li>

        <li className="list-inline-item" title="Remove">
          <IconWithTooltip message={"Delete blog"}>
            <Link
              className="text-danger d-inline-block remove-item-btn"
              data-bs-toggle="modal"
              data-bs-target="#removeItemModal"
              onClick={(e) => deleteblog(blog)}
            >
              <i className="ri-delete-bin-5-fill fs-16"></i>
            </Link>
          </IconWithTooltip>
        </li>
      </ul>
    </>
  );
};

const Blogs = () => {
  document.title = "Blogs | Taojaa - Store Manager App";

  const { currency, getSubscription } = useContext(AuthContext);

  const [reload, signal] = useState("off");
  const [loading, setLoading] = useState(true);
  const [Blogs, setBlogs] = useState([]);
  const [counts, setblogCounts] = useState({
    all: 0,
    published: 0,
    unpublished: 0,
  });

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [pagination, setPagination] = useState(null);
  const [filters, setFilters] = useState({
    published: null,
    keywords: "",
    start_date: null,
    end_date: null,
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const applyFilter = () => {
    getBlogs(filters);
  };

  const resetFilter = () => {
    setFilters({
      published: null,
      keywords: "",
      start_date: null,
      end_date: null,
    });
    getBlogs();
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getBlogs();
  }, [reload, currentPage]);

  async function getBlogs(filters = null) {
    try {
      let endpoint = "/blog/posts";
      

      const response = await axios.get(endpoint);
      if (response.data.success) {
        setBlogs(response.data.data);
      
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }
    setLoading(false);
  }

  const handleTabClick = (status) => {
    getBlogs({ ...filters, published: status });
  };
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Blogs</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Store</Link>
                    </li>
                    <li className="breadcrumb-item active">Blogs</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div>
                <div className="card">
                  <div className="card-header  border-0">
                    <div className="d-flex align-items-center">
                      <h5 className="card-title mb-0 flex-grow-1">All Blogs</h5>
                      <div className="flex-shrink-0">
                        <Link
                          to="/blogs/create"
                          className="btn btn-success add-btn me-1"
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add Blog
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="card-body border border-dashed border-end-0 border-start-0">
                    <form>
                      <div className="row g-3">
                        <div className="col-xxl-5 col-sm-6">
                          <div className="search-box">
                            <input
                              type="text"
                              className="form-control search"
                              name="keywords"
                              placeholder="Search for order ID, customer, order status or something..."
                              onChange={handleFilterChange}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>

                        <div className="col-xxl-5 col-sm-6">
                          <Flatpickr
                            className="form-control"
                            id="dash-filter-picker"
                            placeholder="start date to end date"
                            options={{ mode: "range" }}
                            defaultValue={
                              filters.start_date && filters.end_date !== null
                                ? filters.start_date + " to " + filters.end_date
                                : ""
                            }
                            onChange={(selectedDates) =>
                              dateChange(selectedDates, filters, setFilters)
                            }
                          />
                        </div>

                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <select
                              className="form-control"
                              name="published"
                              id="idStatus"
                              onChange={handleFilterChange}
                            >
                              <option defaultValue="">Select Status</option>
                              <option value="1">Published</option>
                              <option value="0">Unpublished</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary w-100 filter-brn"
                              onClick={applyFilter}
                            >
                              <i className="ri-equalizer-fill me-1 align-bottom"></i>
                              Filter
                            </button>
                          </div>
                        </div>
                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger w-100 filter-btn"
                              onClick={resetFilter}
                            >
                              <i className="ri-equalizer-fill me-1 align-bottom"></i>
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-body pt-0">
                    {
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <Link
                              className="nav-link active All py-3"
                              data-bs-toggle="tab"
                              id="All"
                              to="#all"
                              role="tab"
                              aria-selected="true"
                              onClick={() => getBlogs()}
                            >
                              All
                              <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                {counts.all}
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link py-3 Delivered"
                              data-bs-toggle="tab"
                              id="Delivered"
                              to="#published"
                              role="tab"
                              aria-selected="false"
                              onClick={() => handleTabClick("1")}
                            >
                              Published
                              <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                {counts.published}
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link py-3 Pickups"
                              data-bs-toggle="tab"
                              id="Pickups"
                              to="#draft"
                              role="tab"
                              aria-selected="false"
                              onClick={() => handleTabClick("0")}
                            >
                              Unpublished
                              <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                {counts.unpublished}
                              </span>
                            </Link>
                          </li>
                        </ul>
                        <div className="table-responsive table-card">
                          {Blogs.length > 0 ? (
                            <table
                              className="table align-middle table-nowrap"
                              id="BlogsTable"
                            >
                              <thead className="text-muted table-light">
                                <tr className="text-uppercase fs-13">
                                  <th scope="col" style={{ width: "50px" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                      />
                                    </div>
                                  </th>
                                  <th className="sort" data-sort="blog_id">
                                    ID
                                  </th>
                                  <th className="sort" data-sort="blog_name">
                                    Title
                                  </th>
                                  <th className="sort" data-sort="blog_name">
                                    Excerpt
                                  </th>
                                  <th className="sort" data-sort="stock">
                                    Slug
                                  </th>
                                  <th className="sort" data-sort="stock">
                                    Blog
                                  </th>
                                  <th className="sort" data-sort="price">
                                    Created At
                                  </th>
                                  <th className="sort" data-sort="price">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="list form-check-all">
                                {Blogs.map((blog, index) => (
                                  <tr key={index}>
                                    <td scope="row">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                        />
                                      </div>
                                    </td>
                                    <td className="id">
                                      <Link
                                        className="fw-semibold link-dark"
                                      >
                                        #{blog._id || "N/A"}
                                      </Link>
                                    </td>
                                  
                                    <td className="stock">{blog.title}</td>
                                    <td className="stock">{blog.excerpt}</td>
                                    <td className="stock">{blog.slug}</td>
                                    <td className="stock">
                                      {blog?.blog?.name}
                                    </td>

                                    <td className="stock">{blog.createdAt}</td>
                                    <td>
                                      <Action blog={blog} signal={signal} />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <>
                              {loading ? (
                                <div className="py-5">
                                  <Loader
                                    message={
                                      <p className="text-muted">
                                        Fetching Blogs...
                                      </p>
                                    }
                                  />
                                </div>
                              ) : (
                                <SearchResponse
                                  title={"No blog Available"}
                                  message={"You don't have any blog yet"}
                                />
                              )}
                            </>
                          )}
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <Pagination
                            pagination={pagination}
                            onPageChange={onPageChange}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Blogs;
