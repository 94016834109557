import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import NavMenu from "../Components/NavMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../Contexts/AppContext";
import TrialNotice from "../../Components/TrialNotice";
import { AuthContext } from "../../Contexts/AuthContext";
import ScrollToTop from "../../Components/ScrollToTop";

export default function AppLayout() {

  const { toggleSidebar, settings } = useContext(AppContext);
  const { subscription } = useContext(AuthContext)

  return (
    <div id="layout-wrapper">
      <ScrollToTop />
      <Header />
      <NavMenu />
      <div className="vertical-overlay" onClick={toggleSidebar}></div>
      <div className="main-content">
        {(subscription?.is_trial == 1 && subscription?.expired == false) && <TrialNotice subscription={subscription} settings={settings} />}
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc9hGUjKxQ_eu8qanbRBrHxIVcMorPsXDZkFxwhDUQXI8MG4Q/viewform" className="feedback-box bg-light text-dark border position-fixed p-2" target="_blank">Give Feedback</a>
        <Outlet />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Footer />
      </div>
    </div>
  );
}
