import axios from "axios";
import Flatpickr from "react-flatpickr";
import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import { AuthContext } from "../../Contexts/AuthContext";
import { responseMessage } from "../../libs/app";
import Loader from "../../Components/Loader/Loader";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import DeleteModal from "../../Components/DeleteModal";
import InvoiceTableItem from "./InvoiceTableItem";
import { addFiltersToEndpoint, dateChange } from "../../helpers";
import { AppContext } from "../../Contexts/AppContext";


const Invoices = () => {

  document.title = 'Invoices | Taojaa - Store Manager App'

  const { paymentStatuses } = useContext(AppContext)
  const { store, selectedWallet, displayCurrency, subscription } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [invoicesStats, setInvoicesStats] = useState({
    total: {
      sum: 0,
    },
    paid: {
      sum: 0,
    },
    unpaid: {
      sum: 0,
    },
    sent: {
      sum: 0,
    },
  });
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    keywords: '',
    payment_status: '',
    sent: null,
    start_date: null,
    end_date: null,
  });

  const applyFilter = () => {
    getInvoices(filters);
  };

  const resetFilter = () => {
    setFilters({
      keywords: '',
      status: '',
      sent: null,
      start_date: null,
      end_date: null,
    });
    getInvoices();
  };

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 2000);
  }, [copied])

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getInvoices();
  }, [store, subscription]);

  useEffect(() => {
    getInvoicesStats();
  }, [selectedWallet]);


  async function getInvoicesStats() {
    try {
      const response = await axios.get(`/invoice/statistics`);
      setInvoicesStats(response.data.data);
    } catch (error) { }
  }

  async function getInvoices(filters = null) {
    try {

      let endpoint = `/invoices?page=${currentPage}&perpage=${perPage}`;
      if (filters) {
        endpoint = addFiltersToEndpoint(filters, endpoint);
      }

      const response = await axios.get(endpoint);

      if (response.data.success) {
        setInvoices(response.data.invoices);
        setPagination(response.data.pagination);
      }
    } catch (error) {
      if (error.response) {
        responseMessage(error.response.data.message, "error");
      } else {
        responseMessage("Something went wrong.", "error");
      }
    }

    setLoading(false)
  }

  async function deleteInvoice() {

    try {

      setDeleting(true);

      const response = await axios.delete(`/invoices/delete/${selectedForDelete}`);
      if (response.data.success) {
        responseMessage(response.data.message, 'success');
        getInvoices()
        getInvoicesStats()

        closeDeleteModal()
      }

    } catch (error) {
      if (error.response) {
        responseMessage(error.response.data.message, "error");
      } else {
        responseMessage("Something went wrong.", "error");
      }
    }

    setDeleting(false);
  }

  const setForDelete = (id) => {
    setSelectedForDelete(id);
  }

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const closeDeleteModal = () => {
    setSelectedForDelete(null)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Invoicing & Receipts</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Invoicing & Receipts</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 col-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <p className="text-uppercase fw-semibold text-muted mb-0">
                        Total
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      {/* <h5 className="text-success fs-14 mb-0">
                        <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                        +7.55 %
                      </h5> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-bold ff-secondary mb-4">
                        {displayCurrency}
                        <span
                          className="counter-value"
                        >
                          {invoicesStats.total.sum}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 col-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <p className="text-uppercase fw-semibold text-muted mb-0">
                        Paid
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      {/* <h5 className="text-danger fs-14 mb-0">
                        <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
                        +8.09 %
                      </h5> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-bold ff-secondary mb-4">
                        {displayCurrency}
                        <span
                          className="counter-value"
                        >
                          {invoicesStats.paid.sum}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 col-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <p className="text-uppercase fw-semibold text-muted mb-0">
                        Unpaid
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      {/* <h5 className="text-danger fs-14 mb-0">
                        <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
                        +9.01 %
                      </h5> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-bold ff-secondary mb-4">
                        {displayCurrency}
                        <span
                          className="counter-value"
                        >
                          {invoicesStats.unpaid.sum}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 col-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <p className="text-uppercase fw-semibold text-muted mb-0">
                        Sent
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      {/* <h5 className="text-success fs-14 mb-0">
                        <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                        +89.24 %
                      </h5> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className="fs-22 fw-bold ff-secondary mb-4">
                        {displayCurrency}
                        <span
                          className="counter-value"
                        >
                          {invoicesStats.sent.sum}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="invoiceList">
                <div className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Invoices</h5>
                    <div className="flex-shrink-0">
                      <Link to="/invoices/create" className="btn btn-success">
                        <i className="ri-add-line align-bottom me-1"></i>
                        Create Invoice
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-6 col-sm-12">
                        <div className="search-box">
                          <input
                            type="text"
                            name="keywords"
                            value={filters.keywords}
                            className="form-control search bg-light border-light"
                            placeholder="Search invoices...."
                            onChange={(e) => setFilters({ ...filters, [e.target.name]: e.target.value })}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-sm-4">
                        <Flatpickr
                          className="form-control"
                          id="dash-filter-picker"
                          placeholder="Due date"
                          options={{ mode: "range" }}
                          defaultValue={filters.start_date && filters.end_date !== null ? filters.start_date + " to " + filters.end_date : ''}
                          onChange={(selectedDates) => dateChange(selectedDates, filters, setFilters)}
                        />
                      </div>

                      <div className="col-xxl-3 col-sm-4">
                        <div className="input-light">
                          <select
                            className="form-control"
                            name="payment_status"
                            id="idStatus"
                            onChange={(e) => setFilters({ ...filters, [e.target.name]: e.target.value })}
                          >
                            <option value="">Select Payment Status</option>
                            {paymentStatuses.map(({ id, name }, index) => (
                              <option selected={filters.payment_status === name ? true : false} key={index} value={name}>{name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-xxl-2 col-sm-4">
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={applyFilter}
                        >
                          <i className="ri-equalizer-fill me-1 align-bottom"></i>
                          Filter
                        </button>
                      </div>
                      <div className="col-xxl-2 col-sm-4">
                        <button
                          type="button"
                          className="btn btn-danger w-100"
                          onClick={resetFilter}
                        >
                          <i className="ri-equalizer-fill me-1 align-bottom"></i>
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-body">
                  <div>
                    <div className="table-responsive table-card">
                      {invoices.length > 0 ? (
                        <table
                          className="table align-middle table-nowrap"
                          id="invoiceTable"
                        >
                          <thead className="text-muted">
                            <tr className="text-uppercase fs-13">
                              <th scope="col" style={{ width: "50px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th
                                className="sort"
                                data-sort="invoice_id"
                              >
                                Invoice No.
                              </th>
                              <th
                                className="sort"
                                data-sort="customer_name"
                              >
                                Customer
                              </th>

                              <th
                                className="sort"
                                data-sort="invoice_amount"
                              >
                                Amount
                              </th>
                              <th
                                className="sort"
                                data-sort="country"
                              >
                                Payment Method
                              </th>
                              <th
                                className="sort"
                                data-sort="date"
                              >
                                Due Date
                              </th>
                              <th
                                className="sort"
                                data-sort="status"
                              >
                                Payment Status
                              </th>
                              <th
                                className="sort"
                                data-sort="status"
                              >
                                Sent/Not sent
                              </th>
                              <th
                                className="sort"
                                data-sort="action"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {invoices.map((invoice, index) =>
                              <InvoiceTableItem
                                key={index}
                                index={index}
                                store={store}
                                copied={copied}
                                invoice={invoice}
                                setCopied={setCopied}
                                setForDelete={setForDelete}
                              />)}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {loading
                            ? <div className="py-5"><Loader message={<p className="text-muted">Fetching invoices...</p>} /></div>
                            : <SearchResponse title={"No Invoice Found"}
                              message={<p>Can't find any invoice.
                                <Link to={"/invoices/create"} className='text-primary'> Create invoice</Link>
                              </p>}
                            />
                          }
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Pagination pagination={pagination} next={goToNextPage} previous={goToPreviousPage} />
                    </div>
                  </div>


                  <DeleteModal
                    show={selectedForDelete ? true : false}
                    title={'Delete Notice'}
                    message={<div>
                      <p>Deleting this invoice would make you lost some information that are vital to your business performance.</p>
                      <p>Are you sure you want to delete this in option?</p>
                    </div>}
                    deleting={deleting}
                    handleDelete={deleteInvoice}
                    handleClose={closeDeleteModal}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Invoices;
