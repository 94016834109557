import React from 'react'
import { errorResponse, responseMessage } from '../../libs/app';
import axios from 'axios';
import ModalBox from '../../Components/ModalBox';

const CustomerForm = ({ customer, setcustomer, modal, closeModal, getCustomers, saving, setSaving }) => {

    async function createCustomer() {
        setSaving(true)
        try {
            var data = { ...customer };
            const response = await axios.post("/customers/create", data);
            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getCustomers();
                closeModal()
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
        setSaving(false)
    }

    async function updateCustomer() {
        setSaving(true)
        try {
            const data = { ...customer };
            const response = await axios.put(`/customers/update`, data);
            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getCustomers();
                closeModal()
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
        setSaving(false)
    }

    function handleChange(event) {
        const value = event.target.value;
        setcustomer({
            ...customer,
            [event.target.name]: value,
        });
    }

    function handleSubmit(e) {

        e.preventDefault();

        if (customer.id !== undefined) {
            updateCustomer()
        } else {
            createCustomer()
        }
    }


    return (
        <ModalBox
            show={modal}
            handleClose={closeModal}
            title={customer && customer.id !== undefined ? "Edit Customer" : "Add Customer"}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={<button
                type="submit"
                className={`btn btn-success ${saving ? 'disabled' : ''}`}
                onClick={(e) => handleSubmit(e)}>
                {saving
                    ? <>
                        <div className="spinner-border spinner-border-sm text-white" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> Saving...
                    </> : 'Save Customer'}
            </button>
            }
        >
            <form>
                <div className="mb-3">
                    <label
                        htmlFor="customername-field"
                        className="form-label"
                    >
                        Customer Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={customer.name}
                        className="form-control"
                        placeholder="Enter name"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="email-field"
                        className="form-label"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        id="email-field"
                        name="email"
                        value={customer.email}
                        className="form-control"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="phone-field"
                        className="form-label"
                    >
                        Phone
                    </label>
                    <input
                        type="text"
                        id="phone-field"
                        name="phone"
                        value={customer.phone}
                        className="form-control"
                        placeholder="Enter Phone no."
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label
                        htmlFor="status-field"
                        className="form-label"
                    >
                        Status
                    </label>
                    <select
                        className="form-control"
                        data-choices
                        data-choices-search-false
                        name="status"
                        value={customer.status}
                        id="status-field"
                        onChange={handleChange}
                    >
                        <option value="">Status</option>
                        <option value="1">Active</option>
                        <option value="0">Block</option>
                    </select>
                </div>
            </form>
        </ModalBox>
    )
}

export default CustomerForm