import React, { useState, useEffect } from "react";

import { useNavigate, useParams, Link } from "react-router-dom";

import { errorResponse, responseMessage } from "../../libs/app";
import axios from "axios";
import TextEditor from "../../Components/TextEditor";

import { toast } from "react-toastify";
const AddPage = () => {
  const [saving, setSaving] = useState(false);

  const [page, setPage] = useState({
    name: "",
    title: "",
    description: "",

  });
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const getPageItem = () => {
    // setLoading(true);
    axios
      .get(`/settings/pages/${id}`)
      .then((response) => {
        setPage((prev) => {
          return {
            ...prev,
            name: response.data.data.name,
            title: response.data.data.title,
            description: response.data.data.description,

          };

        });
        setContent(response.data.data.content)
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getPageItem();
    }
  }, [id]);

  const handleSave = async () => {
    // e.preventDefault();
    setSaving(true);
    try {
      if (page.name === "") {
        return responseMessage("Page name is empty", "warning");
      }

      if (page?.title === "") {
        return responseMessage("Page name is empty", "warning");
      }

      if (id) {
        const data = { id: parseInt(id), ...page, content: content };
        const response = await axios.put("/settings/pages/update", data);
        if (response.data.success) {
          responseMessage(response.data.message);
        }
      } else {
        const data = { ...page, content: content };

        const response = await axios.post("/settings/pages/create", data);
        if (response.data.success) {
          responseMessage(response.data.message);
        }
      }
    } catch (error) {
      if (error.response) {
        return errorResponse(error.response);
      }
      return responseMessage("Something went wrong.", "error");
    } finally {
      setSaving(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the state of the object based on the input field
    setPage((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Pages</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="#" onClick={() => window.history.back()}>
                        <i className="ri-arrow-left-line align-bottom me-1"></i>
                        Back
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-9 mb-3">
              <div className="card card-height-100">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Add Page</h4>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <lable>Name</lable>

                    <input
                      type="text"
                      name="name"
                      value={page?.name}
                      onChange={handleInputChange}
                      className="form-control"
                      id="product-name-input"
                      placeholder="Enter page name"
                    />
                  </div>
                  <div className="mb-3">
                    <lable>Title</lable>
                    <input
                      type="text"
                      name="title"
                      value={page?.title}
                      onChange={handleInputChange}
                      className="form-control"
                      id="product-title-input"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3">
                    <label>Description</label>
                    <input
                      type="text"
                      name="description"
                      value={page?.description}
                      onChange={handleInputChange}
                      className="form-control"
                      id="product-title-input"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3">
                    <lable>Content</lable>
                    <TextEditor
                      value={content}
                      setValue={setContent}
                    />
                    {/* <textarea
                      type="text"
                      name="content"
                      value={page?.content}
                      onChange={handleInputChange}
                      className="form-control"
                      id="product-title-input"
                      placeholder=""
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-9 gap-2 mb-5 text-end">
              <button
                className="btn ms-1 btn-light w-sm "
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <button
                onClick={() => handleSave()}
                className="btn ms-1 btn-primary w-sm "
              >
                {saving ? (
                  <>
                    <div
                      className="spinner-border spinner-border-sm "
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>{" "}
                  </>
                ) : (
                  "Save Page"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPage;
