import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import TextEditor from "../../Components/TextEditor";
import $ from "jquery";
import { FiTrash2 } from "react-icons/fi";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Placeholder from "../../Components/Placeholder/Placeholder";
import { errorResponse, responseMessage } from "../../libs/app";
import MyDropzoneBlog from "../../Components/MyDropZoneBlog";
import { AppContext } from "../../../src/Contexts/AppContext";
import { AuthContext } from "../../Contexts/AuthContext";

const AddBlog = (props) => {
  if (props.mode == "new")
    document.title = "Create blog | Taojaa - Store Manager App";
  if (props.mode == "edit")
    document.title = "Edit blog | Taojaa - Store Manager App";

  const { blog_id } = useParams();
  const [blog, setblog] = useState({
    type: "physical",
  });

  const [body, setBody] = useState("");

  const [blogVariationOptions, setblogVariationOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  var initial = moment();
  const [initialDate, setInitialDate] = useState(initial.format("YYYY-MM-DD"));
  const [display, setDisplay] = useState("");
  const [excerpt, setExcerpt] = useState([]);
  const {
    setBlogFiles,
    blogFilesPreview,
    setBlogFilesPreview,
    fileSent,
    setFileSent,
  } = React.useContext(AppContext);
  const [blogOption, setBlogOption] = useState([]);
  const [selectedOptionBlog, setSelectedOptionBlog] = useState("");
  const [authorOptions, setAuthorOptions] = useState([]);
  const [selectedOptionAuthor, setSelectedOptionsAuthor] = useState("");
  const [tagOption, setTagOption] = useState([]);
  const [selectedOptionTag, setSelectedOptionTag] = useState("");
  const [categoryOption, setCategoryOption] = useState([]);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState("");
  const [allTags, setAllTags] = useState("");
  const [allCategories, setAllCategories] = useState("");
  const [showExcerpt, setShowExcerpt] = useState("");
  const [showWebsiteSeo, setShowWebsiteSeo] = useState("");
  const [isLoadingForBlog, setIsLoadingForBlog] = useState(false);
  const [isLoadingForCategory, setIsLoadingForCategory] = useState(false);
  const [isLoadingForTag, setIsLoadingForTag] = useState(false);
  const { store } = useContext(AuthContext);

  const [isDeletingBlog, setIsDeletingBlog] = useState(false);
  const navigate = useNavigate();
  const getBlogs = () => {
    axios
      .get(`/blog/list`)
      .then((response) => {
        const category = response?.data?.data;
        const options = category?.map(createOption);
        setBlogOption(options);
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      });
  };

  useEffect(() => {
    getBlogs();
    getTags();
    getCategories();
    getStaffs();
  }, []);

  const handleblogDetailsChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setblog({ ...blog, [name]: value });
  };

  const createOption = ({ _id, name }) => {
    const id = _id;
    return {
      value: id,
      label: name,
    };
  };

  const createOptionStaff = ({ id, email }) => {
    return {
      value: id,
      label: email,
    };
  };

  const getTags = () => {
    axios
      .get(`/blog/tags`)
      .then((response) => {
        const tag = response?.data?.data;
        const options = tag?.map(createOption);
        setTagOption(options);
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      });
  };
  const getCategories = () => {
    axios
      .get(`/blog/categories`)
      .then((response) => {
        const categories = response?.data?.data;
        const options = categories?.map(createOption);
        setCategoryOption(options);
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      });
  };
  const getStaffs = () => {
    axios
      .get(`/settings/staff/accounts`)
      .then((response) => {
        const staffs = response?.data?.data;
        const options = staffs?.map(createOptionStaff);
        setAuthorOptions(options);
   
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      });
  };
  const handleFormCheck = (e) => {
    setDisplay(e.target.value);
  };
  const save = (e) => handleblogSubmit(e);
  const saveAndClose = (e) => handleblogSubmit(e, true);

  const handleblogSubmit = (e, close = false) => {
    if (null !== e) {
      e.preventDefault();
    }

    setSaving(true);

    // var formdata = new FormData(document.getElementById("blogUploadForm"));
    var formdata = new FormData();
    formdata.append("title", blog.title);
    formdata.append("body", body);
    formdata.append("image", fileSent ? fileSent : "");
    formdata.append("meta_title", blog.meta_title);
    formdata.append("meta_description", blog.meta_description);
    formdata.append("visibility", display);
    formdata.append(
      "author",
      selectedOptionAuthor?.value ? selectedOptionAuthor?.value : ""
    );
    formdata.append("postedBy", store.user_id);
    formdata.append("excerpt", blog.excerpt);
    formdata.append(
      "blog",
      selectedOptionBlog?.value ? selectedOptionBlog?.value : ""
    );
    formdata.append("tags", allTags);
    formdata.append("categories", allCategories);
    var data = formdata;
    if (props?.mode == "new") {
      var _url = `/blog/post`;
      axios
        .post(_url, data)
        .then((response) => {
          if (response?.data?.success) {
            localStorage.setItem("images", []);

            // setFormatted([]);
            setSaving(false);
            getblogDetails();
            // responseMessage(response?.data?.message, "success");

            if (props?.mode == "new" || close == true) {
              navigate("/blogs");
            }
          }
        })
        .catch((error) => {
          setSaving(false);

          if (error?.response) {
            return errorResponse(error?.response);
          }

          return responseMessage(
            "Something went wrong, please try again",
            "error"
          );
        });
    } else if (props?.mode == "edit") {
      var _url = `/blog/post/${blog.slug}`;
      axios
        .put(_url, data)
        .then((response) => {
          if (response?.data?.success) {
            localStorage.setItem("images", []);

            // setFormatted([]);
            setSaving(false);
            getblogDetails();
            responseMessage(response?.data?.message, "success");

            // Call batch request endpoint for sales channel here

            if (props?.mode == "new" || close == true) {
              navigate("/blogs");
            }
          }
        })
        .catch((error) => {
          setSaving(false);

          if (error?.response) {
            return errorResponse(error?.response);
          }

          return responseMessage(
            "Something went wrong, please try again",
            "error"
          );
        });
    }
  };
  const onUploadError = (error) => {
    if (error.responseJSON) {
      $("#uploadStatus").html(
        `<p style="color:#EA4335;">${error.responseJSON.message}</p>`
      );
    } else {
      $("#uploadStatus").html(
        '<p style="color:#EA4335;">File upload failed, please try again.</p>'
      );
    }

    $(".progress").addClass("hide");
    $(".progress-bar").width("0%");
  };
  const onUploadResponse = (resp) => {
    var $images = {};
    $(".progress").addClass("hide");
    $(".progress-bar").width("0%");
  };
  const handleBlogChange = (option) => {
    setSelectedOptionBlog(option);
  };
  const handleTagChange = (option) => {
    setSelectedOptionTag(option);
    const newTag = option?.map((category) => category?.value).join(",");
    setAllTags(newTag);
  };
  const handleCategoryChange = (option) => {
    setSelectedOptionCategory(option);
    const newCategory = option?.map((category) => category?.value).join(",");
    setAllCategories(newCategory);
  };
  const handleExcerpt = () => {
    setShowExcerpt(true);
  };
  const handleSeo = () => {
    setShowWebsiteSeo(true);
  };

  const createOptionBlog = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });
  const handleCreateBlog = (inputValue) => {
    var _url = `/blog/create`;
    const data = {
      name: inputValue,
    };
    setIsLoadingForBlog(true);
    axios
      .post(_url, data)
      .then((response) => {
        if (response?.data?.success) {
          setIsLoadingForBlog(false);
        
          responseMessage(response.data.message, "success");
          const newOption = createOption(response?.data?.data);
          setBlogOption((prev) => [...prev, newOption]);
         
          setSelectedOptionBlog(newOption);
        }
      })
      .catch((error) => {
        setIsLoadingForBlog(false);
        if (error?.response) return errorResponse(error?.response);
        return responseMessage(
          "Something went wrong, please try again",
          "error"
        );
      })
      .finally(() => {
        getBlogs();
      });
  };
  const handleCreateCategory = (inputValue) => {
    var _url = `/blog/category`;
    const data = {
      name: inputValue,
    };
    setIsLoadingForCategory(true);
    axios
      .post(_url, data)
      .then((response) => {
        if (response?.data?.success) {
          setIsLoadingForCategory(false);
          responseMessage(response.data.message, "success");
          const newOption = createOption(response.data.data);

          setSelectedOptionCategory((prev) => {
            const updatedCategories = [...prev, newOption];

            const newCategory = updatedCategories
              .map((category) => category.value)
              .join(",");

            setAllCategories(newCategory);

            return updatedCategories;
          });
        }
      })
      .catch((error) => {
        setIsLoadingForCategory(false);
        if (error?.response) return errorResponse(error?.response);
        return responseMessage(
          "Something went wrong, please try again",
          "error"
        );
      })
      .finally(() => {
        getCategories();
      });
  };


  const handleCreateTag = (inputValue) => {
    var _url = `/blog/tag`;
    const data = {
      name: inputValue,
    };
    setIsLoadingForTag(true);
    axios
      .post(_url, data)
      .then((response) => {
        if (response?.data?.success) {
          setIsLoadingForTag(false);
          responseMessage(response.data.message, "success");
          const newOption = createOption(response.data.data);
          setTagOption((prev) => [...prev, newOption]);
          setSelectedOptionTag((prev) => {
            const updatedTags = [...prev, newOption];

            const newTag = updatedTags
              .map((category) => category.value)
              .join(",");

            setAllTags(newTag);

            return updatedTags;
          });
          // const newTag = newOption?.map((category) => category?.value).join(",");
          // setAllTags(newTag);
        }
      })
      .catch((error) => {
        setIsLoadingForTag(false);
        if (error?.response) return errorResponse(error?.response);
        return responseMessage(
          "Something went wrong, please try again",
          "error"
        );
      })
      .finally(() => {
        getTags();
      });
  };

  const handleDeleteBlog = (deletedOption) => {
    // Handle option deletion

    setIsDeletingBlog(true);
    axios
      .delete(`/blog/${deletedOption.label}`)
      .then((response) => {
        if (response.data.success) {
          responseMessage(response.data.message, "success");
          setIsDeletingBlog(false);
        }
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
      })
      .finally(() => {
        getBlogs();
      });
    const updatedOptions = blogOption.filter(
      (option) => option.value !== deletedOption.value
    );
    setSelectedOptionBlog(updatedOptions);
  };

  const handleAuthorChange = async (option) => {
    if (option) {
      setSelectedOptionsAuthor(option);
    } else {
      setSelectedOptionsAuthor("");
    }
  };
  const handleDeleteCategory = (deletedOption) => {
    // Handle option deletion
    setIsDeletingBlog(true);
    axios
      .delete(`/blog/category/${deletedOption.label}`)
      .then((response) => {
        if (response.data.success) {
          responseMessage(response.data.message, "success");
          setIsDeletingBlog(false);
        }
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      })
      .finally(() => {
        getCategories();
      });
  };
  const handleDeleteTag = (deletedOption) => {
    // Handle option deletion
    setIsDeletingBlog(true);
    axios
      .delete(`/blog/tag/${deletedOption.label}`)
      .then((response) => {
        if (response.data.success) {
          responseMessage(response.data.message, "success");
          setIsDeletingBlog(false);
        }
      })
      .catch((error) => {
        if (error.response) errorResponse(error.response);
        else responseMessage("Something went wrong.", "error");
      })
      .finally(() => {
        getTags();
      });
    const updatedOptions = blogOption.filter(
      (option) => option.value !== deletedOption.value
    );
    setSelectedOptionBlog(updatedOptions);
  };
  const getblogDetails = () => {
    if (blog_id && props.mode == "edit") {
      // setChangeCollectionMode(false);
      axios
        .get(`/blog/post/image/${blog_id}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          setBlogFilesPreview(url);
          setFileSent(blob);
        });
      axios
        .get(`/blog/post/${blog_id}`)
        .then((response) => {
          var data = response.data.data;
          var author = authorOptions?.find((item) => item.value === data.author);
          setSelectedOptionsAuthor(author);
          var categories = data.categories.map(createOption);
          var tags = data.tags.map(createOption);
          var allNewCategories = categories
            ?.map((category) => category?.value)
            .join(",");
          var allNewTags = categories
            ?.map((category) => category?.value)
            .join(",");
          const visibilty = data.visibility;
          setAllCategories(allNewCategories);
          setAllTags(allNewTags);
          if (visibilty === true) {
            setDisplay("true");
          } else if (visibilty === false) {
            setDisplay("false");
          }
          // setDisplay(visibilty);
          setSelectedOptionCategory(categories);
          setSelectedOptionTag(tags);
          const blogoptions = createOption(data.blog);
         
          setblog(data);
          setSelectedOptionBlog(blogoptions);
          setBody(data.body);
          setLoading(false);
        })
        .catch((error) => {
          if (error?.response) return errorResponse(error?.response);

          return responseMessage("Something went wrong.", "error");
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getblogDetails();
    if (props.mode == "new") {
      setBlogFilesPreview(null);
    }
  }, []);

  const removeImage = () => {
    setBlogFiles([]);
    setBlogFilesPreview("");
  };
  const customComponents = {
    DropdownIndicator: null, // Hides the default dropdown indicator
    Option: (props) => {
      const { data, innerRef, innerProps } = props;
      return (
        <div
          ref={innerRef}
          {...innerProps}
          className="select-item d-flex align-items-center justify-content-between p-2"
          style={{ cursor: "pointer" }}
        >
          <div>
            <span>{data.label}</span>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-link text-danger p-0 me-2"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteBlog(data);
              }}
            >
              &#10005; {/* Unicode 'X' character for delete */}
            </button>
          </div>
        </div>
      );
    },
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      marginTop: 10,
    }),
  };
  const customComponentsCategory = {
    DropdownIndicator: null, // Hides the default dropdown indicator
    Option: (props) => {
      const { data, innerRef, innerProps } = props;
      return (
        <div
          ref={innerRef}
          {...innerProps}
          className="select-item d-flex align-items-center justify-content-between p-2"
          style={{ cursor: "pointer" }}
        >
          <div>
            <span>{data.label}</span>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-link text-danger p-0 me-2"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteCategory(data);
              }}
            >
              &#10005; {/* Unicode 'X' character for delete */}
            </button>
          </div>
        </div>
      );
    },
  };

  const customStylesCategory = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      marginTop: 10,
    }),
  };
  const customComponentsTag = {
    DropdownIndicator: null, // Hides the default dropdown indicator
    Option: (props) => {
      const { data, innerRef, innerProps } = props;
      return (
        <div
          ref={innerRef}
          {...innerProps}
          className="select-item d-flex align-items-center justify-content-between p-2"
          style={{ cursor: "pointer" }}
        >
          <div>
            <span>{data.label}</span>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-link text-danger p-0 me-2"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteTag(data);
              }}
            >
              &#10005; {/* Unicode 'X' character for delete */}
            </button>
          </div>
        </div>
      );
    },
  };

  const customStylesTag = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      marginTop: 10,
    }),
  };

  return (
    <>
      {/* {removeVariantOption()} */}
      <div className="page-content">
        <div className="container-fluid">
          {!loading ? (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    {props.mode == "new" ? (
                      <h4 className="mb-sm-0">Create Blog </h4>
                    ) : (
                      <h4 className="mb-sm-0">Edit Blog</h4>
                    )}

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="#" onClick={() => window.history.back()}>
                            <i className="ri-arrow-left-line align-bottom me-1"></i>
                            Back
                          </Link>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <form id="blogUploadForm">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="blog-title-input"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            validatefield="true"
                            validationoutput="#titleError"
                            validationmessage="title cannot be empty!*"
                            value={blog.title}
                            onChange={(e) => {
                              handleblogDetailsChange(e);
                            }}
                            className="form-control"
                            id="blog-title-input"
                            placeholder="e.g. Blog about your latest blog"
                          />
                          <span id="titleError"></span>
                        </div>
                        <div>
                          <label>Content</label>
                          <TextEditor value={body} setValue={setBody} />
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-12 mb-3">
                      <div className="card card-height-100">
                        <div className="card-header align-items-center d-flex justify-content-between">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Excerpt
                          </h4>
                          <div className="themesExplorer">
                            {showExcerpt ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={handleExcerpt}
                              >
                                Add Excerpt
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          {showExcerpt ? (
                            <div>
                              <p>
                                Add a summary of the post to appear on your home
                                page or blog
                              </p>
                              <input
                                type="text"
                                name="excerpt"
                                validatefield="true"
                                value={blog.excerpt}
                                onChange={(e) => {
                                  handleblogDetailsChange(e);
                                }}
                                className="form-control"
                                id="blog-excerpt-input"
                                placeholder=""
                              />
                            </div>
                          ) : (
                            <p>
                              Add a summary of the post to appear on your home
                              page or blog
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 mb-3">
                      <div className="card card-height-100">
                        <div className="card-header align-items-center d-flex justify-content-between">
                          <h4 className="card-title mb-0  flex-grow-1 flex-col">
                            Search engine listing preview
                          </h4>

                          <div className="themesExplorer">
                            {showWebsiteSeo ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={handleSeo}
                              >
                                Edit Website SEO
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          {showWebsiteSeo ? (
                            <>
                              <div className="mb-3">
                                <div>
                                  <label
                                    className="form-label"
                                    htmlFor="blog-title-input"
                                  >
                                    Page Title
                                  </label>
                                  <input
                                    type="text"
                                    name="meta_title"
                                    validatefield="true"
                                    validationoutput="#meta_titleError"
                                    validationmessage="meta_title cannot be empty!*"
                                    value={blog.meta_title}
                                    onChange={(e) => {
                                      handleblogDetailsChange(e);
                                    }}
                                    className="form-control"
                                    id="blog-meta_title-input"
                                    placeholder=""
                                  />
                                  <span id="titleError"></span>
                                </div>
                                <div className="mt-2">
                                  <label
                                    className="form-label"
                                    htmlFor="blog-title-input"
                                  >
                                    Description
                                  </label>
                                  <textarea
                                    type="text"
                                    name="meta_description"
                                    validatefield="true"
                                    validationoutput="#meta_descriptionError"
                                    validationmessage="meta_description cannot be empty!*"
                                    value={blog.meta_description}
                                    onChange={(e) => {
                                      handleblogDetailsChange(e);
                                    }}
                                    className="form-control h-50"
                                    id="blog-meta_description-input"
                                    placeholder=""
                                  />

                                  <span id="titleError"></span>
                                </div>
                                <div className="mt-2">
                                  <label
                                    className="form-label"
                                    htmlFor="blog-title-input"
                                  >
                                    Url and handle
                                  </label>
                                  <input
                                    type="text"
                                    name="url"
                                    validatefield="true"
                                    validationoutput="#urlError"
                                    validationmessage="Url cannot be empty!*"
                                    value={blog.url}
                                    onChange={(e) => {
                                      handleblogDetailsChange(e);
                                    }}
                                    className="form-control"
                                    id="blog-url-input"
                                    placeholder=""
                                  />
                                  <span id="titleError"></span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <p>
                              Add a title description to see how this blog post
                              might appear in a search engine listing
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    {blogVariationOptions.length > 0 ? (
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-0">Variation Options</h5>
                        </div>
                        <div className="card-body">
                          <div className="VariationsBox p-2 "></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Visibility</h5>
                      </div>
                      <div className="card-body">
                        <div className="mt-3">
                          <div className="form-check">
                            <input
                              onChange={(e) => handleFormCheck(e)}
                              className="form-check-input"
                              type="radio"
                              value={true}
                              name="visibility"
                              id="Yes"
                              checked={display === "true"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="toStorefront"
                            >
                              Visible
                            </label>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              value={false}
                              name="visibility"
                              onChange={(e) => handleFormCheck(e)}
                              id="No"
                              checked={display === "false"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="toChannel"
                            >
                              Hidden
                            </label>
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="form-check"></div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Featured Image</h5>
                        <p className="text-muted m-0 p-0">
                          <br />
                          <small style={{ color: "red" }}>
                            <b>Recommended: </b> Image size should not be larger
                            than 500KB for better storefront performance and SEO
                            optimization
                          </small>
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {blogFilesPreview ? (
                            <div className="col-md-12">
                              <div
                                className="bg-light rounded img-display"
                                style={{ height: "300px", overflow: "hidden" }}
                              >
                                <div className="img-display__overlay">
                                  <span
                                    className="img-display__overlay__remove-btn"
                                    onClick={() => {
                                      removeImage();
                                    }}
                                  >
                                    <FiTrash2 size={15} />
                                  </span>
                                </div>
                                <img
                                  data-dz-thumbnail
                                  className="img-fluid rounded d-block"
                                  src={blogFilesPreview}
                                  width={"100%"}
                                  alt="blog"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-12">
                              <MyDropzoneBlog
                                message="Select images"
                                entries={
                                  blog_id !== undefined ? { blog_id } : null
                                }
                                beforeUpload={() => {
                                  $("#uploadStatus").html("");

                                  $(".progress").removeClass("hide");
                                  $(".progress-bar").width("0%");
                                }}
                                onUploadResponse={onUploadResponse}
                                onUploadError={onUploadError}
                              />
                            </div>
                          )}
                        </div>

                        <div className="progress mt-2 hide">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: 0 }}
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            0%
                          </div>
                        </div>
                        <div
                          id="uploadStatus"
                          className="mt-2 text-center"
                        ></div>
                      </div>
                    </div>

                    <div className="card d-none">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Publish Schedule</h5>
                      </div>

                      <div className="card-body">
                        <div>
                          <label
                            htmlFor="datepicker-publish-input"
                            className="form-label"
                          >
                            Publish Date & Time
                          </label>
                          <div className="row g-3 mb-0 align-items-center">
                            <div className="col-sm-auto">
                              <div className="input-group">
                                <Flatpickr
                                  type="text"
                                  className="form-control border-0 shadow"
                                  id="dash-filter-picker"
                                  defaultValue={initialDate}
                                  options={{ mode: "single", enableTime: true }}
                                />
                                <div className="input-group-text bg-primary border-primary text-white">
                                  <i className="ri-calendar-2-line"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Organization</h5>
                      </div>
                      <div className="card-body">
                        <div className="hstack gap-3 align-items-start">
                          <div className="flex-grow-1">
                            <div className="">
                              <label
                                className="form-label"
                                htmlFor="blog-title-input"
                              >
                                Author
                              </label>

                              <Select
                                value={selectedOptionAuthor}
                                onChange={handleAuthorChange}
                                options={authorOptions}
                                className=""
                                isClearable
                              />

                              <span id="titleError"></span>
                            </div>
                            <div className="mt-2">
                              <label
                                className="form-label"
                                htmlFor="blog-title-input"
                              >
                                Blog
                              </label>
                              <CreatableSelect
                                isClearable
                                options={blogOption}
                                isDisabled={isLoadingForBlog}
                                isLoading={isLoadingForBlog}
                                value={selectedOptionBlog}
                                onChange={handleBlogChange}
                                onCreateOption={handleCreateBlog}
                                onDelete={handleDeleteBlog}
                                components={customComponents}
                                styles={customStyles}
                              />

                              <span id="titleError"></span>
                            </div>

                            <div className="mt-2">
                              <label
                                className="form-label"
                                htmlFor="blog-title-input"
                              >
                                Categories
                              </label>
                              <CreatableSelect
                                isMulti
                                isClearable
                                options={categoryOption}
                                isDisabled={isLoadingForCategory}
                                isLoading={isLoadingForCategory}
                                value={selectedOptionCategory}
                                onChange={handleCategoryChange}
                                onCreateOption={handleCreateCategory}
                                onDelete={handleDeleteCategory}
                                components={customComponentsCategory}
                                styles={customStylesCategory}
                              />
                            </div>
                            <div className="mt-2">
                              <label
                                className="form-label"
                                htmlFor="blog-title-input"
                              >
                                Tags
                              </label>
                              <CreatableSelect
                                isMulti
                                isClearable
                                options={tagOption}
                                isDisabled={isLoadingForTag}
                                isLoading={isLoadingForTag}
                                value={selectedOptionTag}
                                onChange={handleTagChange}
                                onCreateOption={handleCreateTag}
                                onDelete={handleDeleteTag}
                                components={customComponentsTag}
                                styles={customStylesTag}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end mb-3">
                          {props?.mode == "new" ? (
                            <>
                              <button
                                type="button"
                                onClick={(e) => saveAndClose(e)}
                                className={`btn ms-1 btn-success w-sm ${
                                  saving ? "disabled" : ""
                                }`}
                              >
                                {saving ? (
                                  <>
                                    <div
                                      className="spinner-border spinner-border-sm text-white"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>{" "}
                                    Saving...
                                  </>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => save(e)}
                              disabled={saving}
                              className={`btn btn-primary ms-2 w-sm ${
                                saving ? "disabled" : ""
                              }`}
                            >
                              {saving ? (
                                <>
                                  <div
                                    className="spinner-border spinner-border-sm text-white"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>{" "}
                                  Saving...
                                </>
                              ) : (
                                "Save Changes"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              <div className="mb-2">
                <Placeholder column="col-9" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-12" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-7" />
              </div>
              <div className="mb-2">
                <Placeholder column="col-10" />
              </div>
            </>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default AddBlog;
