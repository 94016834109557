import React, { useState, useContext, useEffect } from 'react'
import Loader from '../../Components/Loader/Loader';
import SearchResponse from '../../Components/SearchResponse/SearchResponse';
import ModalBox from '../../Components/ModalBox';
import DeleteModal from '../../Components/DeleteModal';
import IconWithTooltip from '../../Components/IconWithTooltip';
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatCurrency } from 'wearslot-dev-utils';
import Pagination from '../../Components/Pagination';
import moment from 'moment';
import axios from 'axios';
import { AuthContext } from '../../Contexts/AuthContext';
import { errorResponse, responseMessage } from '../../libs/app';
import { Link } from 'react-router-dom';
import { addFiltersToEndpoint, dateChange } from '../../helpers';
import Flatpickr from "react-flatpickr";


const ExpenseLogs = () => {

    document.title = 'Expense Logs | Taojaa - Store Manager App'

    const { store, wallets, displayCurrency, subscription, recordCounts, handleUpgradeModalShow } = useContext(AuthContext);

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [expense, setExpense] = useState({
        name: '',
        type: null,
        currency: displayCurrency,
        amount: 0,
        date: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [expenses, setExpenses] = useState([]);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [forDelete, setForDelete] = useState(null);
    const [modal, setModal] = useState(false);

    const [filters, setFilters] = useState({
        type: '',
        keywords: '',
        start_date: null,
        end_date: null,
    });

    const applyFilter = () => {
        getExpenses(filters);
    };

    const resetFilter = () => {
        setFilters({
            keywords: "",
            start_date: null,
            end_date: null,
            type: ""
        });
        getExpenses();
    };

    useEffect(() => {
        getExpenses();
        getExpenseTypes();
    }, [])

    async function getExpenses(filters = null) {

        try {

            let endpoint = `/expenses?page=${currentPage}&perpage=${perPage}`;
            if (filters) {
                endpoint = addFiltersToEndpoint(filters, endpoint);
            }

            const response = await axios.get(endpoint);
            if (response.data.success) {
                setExpenses(response.data.data);
                setPagination(response.data.pagination)
            }
        }
        catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setLoading(false);
    }

    async function getExpenseTypes() {

        try {
            const response = await axios.get(`/expenses/types?all=true`);
            if (response.data.success) {
                setExpenseTypes(response.data.data);
            }
        }
        catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function handleSubmit(e) {

        e.preventDefault();

        if (store.store_type === 'live' && subscription.settings.maxInvoicesAllowed !== 'unlimited') {
            if ((Number(recordCounts.products) + 1) > subscription.settings.maxInvoicesAllowed) {
                return handleUpgradeModalShow(
                    'Upgrade to Professional Plan',
                    'You have reaches the maxiumun number of invoices allowed for your current plan, you can upgrade to add more invoices.'
                )
            }
        }

        try {
            setSaving(true)

            const payload = {
                name: expense.name,
                type: expense.type,
                currency: expense.currency,
                amount: expense.amount,
                date: expense.date,
                id: expense?.id
            };

            if (expense.id !== undefined) {
                var response = await axios.put('/update/expense', payload)
            } else {
                var response = await axios.post('/create/expense', payload)
            }

            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getExpenses()

                closeModal()
            }

        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function deleteExpense() {

        try {

            const response = await axios.delete(`/expense/delete/${forDelete}`);

            if (response.data.success) {
                responseMessage(response.data.message, "success");

                getExpenses();
                closeDeleteModal();
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    function editExpense(expense) {
        setExpense(expense);
        openModal()
    }

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setExpense({ ...expense, [name]: value });
    }

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
        setExpense({
            expense: '',
            type: null,
            discount: 0,
            minimum: 0,
            status: 1,
            expiry_date: ''
        })
    }

    const closeDeleteModal = () => {
        setForDelete(null)
    }

    return (
        <div className="col-lg-12">
            <div className="card" id="orderList">
                <div className="card-header border-bottom-dashed">
                    <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 flex-grow-1">
                            Expense Logs
                        </h5>
                        <div className="flex-shrink-0">
                            <button
                                type="button"
                                className="btn btn-success me-1"
                                onClick={openModal}
                            >
                                <i className="ri-add-line align-bottom me-1"></i> Add Expense
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body border-bottom-dashed border-bottom">
                    <form>
                        <div className="row g-3">
                            <div className="col-xl-6">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        className="form-control search"
                                        placeholder="Search for expense..."
                                        name="keywords"
                                        value={filters.keywords}
                                        onChange={(e) => setFilters({ ...filters, [e.target.name]: e.target.value })}
                                    />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className='row g-3'>
                                    <div className="col-xl-6 col-sm-3">
                                        <Flatpickr
                                            className="form-control"
                                            id="dash-filter-picker"
                                            placeholder="start date to end date"
                                            options={{ mode: "range" }}
                                            defaultValue={filters.start_date && filters.end_date !== null ? filters.start_date + " to " + filters.end_date : ''}
                                            onChange={(selectedDates) => dateChange(selectedDates, filters, setFilters)}
                                        />
                                    </div>

                                    <div className="col-xl-6">
                                        <div>
                                            <select
                                                className="form-control"
                                                name="type"
                                                id="idType"
                                                onChange={(e) => setFilters({ ...filters, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">All</option>
                                                {expenseTypes.map((type, index) => {
                                                    return (
                                                        <option key={index} selected={(type.id === filters.type) ? true : false} value={type.id}>{type.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="row g-3">
                                    <div className="col-xxl-3">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary w-100"
                                                onClick={applyFilter}
                                            >
                                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                                Filters
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-sm-4">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-danger w-100 filter-btn"
                                                onClick={resetFilter}
                                            >
                                                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <div>

                        <div className="table-responsive table-card mb-1">
                            {expenses.length > 0 ? (
                                <table
                                    className="table table-nowrap align-middle"
                                    id="orderTable"
                                >
                                    <thead className="text-muted table-light">
                                        <tr className='text-uppercase'>
                                            <th scope="col" style={{ width: "25px" }}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="checkAll"
                                                        value="option"
                                                    />
                                                </div>
                                            </th>
                                            <th className="sort" data-sort="code">
                                                Title
                                            </th>
                                            <th className="sort" data-sort="discount">
                                                Amount
                                            </th>
                                            <th className="sort" data-sort="type">
                                                Type
                                            </th>
                                            <th className="sort" data-sort="date">
                                                Expense Date
                                            </th>
                                            <th className="sort" data-sort="created_date">
                                                Created Date
                                            </th>
                                            <th className="sort" data-sort="actions">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {expenses.map((expense, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="checkAll"
                                                            value="option1"
                                                        />
                                                    </div>
                                                </th>
                                                <td className="expense">
                                                    {expense.name}
                                                </td>
                                                <td className='discount'>
                                                    {getSymbolFromCurrency(expense.currency) + formatCurrency(expense.amount)}
                                                </td>
                                                <td className='type'>
                                                    {expense.type?.name}
                                                </td>
                                                <td className="expiry_date">
                                                    {moment(expense.date).format('ddd, D MMM YYYY')}
                                                </td>
                                                <td className="created_date">
                                                    {moment(expense.created_date).format('ddd, D MMM YYYY')}
                                                </td>
                                                <td>
                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                        <li
                                                            className="list-inline-item edit"
                                                            title="Edit"
                                                        >
                                                            <Link
                                                                to="#"
                                                                className="text-primary d-inline-block edit-item-btn"
                                                                onClick={() => editExpense(expense)}
                                                            >
                                                                <i className="ri-pencil-fill fs-16"></i>
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item" title="Remove">
                                                            <Link
                                                                className="text-danger d-inline-block remove-item-btn"
                                                                data-bs-toggle="modal"
                                                                to="#deleteExpense"
                                                                onClick={() =>
                                                                    setForDelete(expense.id)
                                                                }
                                                            >
                                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <>
                                    {loading
                                        ? <div className="py-5"><Loader message={<p className="text-muted">Fetching data...</p>} /></div>
                                        : <SearchResponse title={"No expense record"}
                                            message={<p>You haven't recorded any expense yet. <Link to={"#"} type="button" className='text-primary' onClick={openModal}> Record expense</Link></p>}
                                        />
                                    }
                                </>
                            )}
                        </div>

                        <div className="d-flex justify-content-end">
                            <div className="pagination-wrap hstack gap-2">
                                <Pagination pagination={pagination} previous={goToPreviousPage} next={goToNextPage} />
                            </div>
                        </div>
                    </div>

                    <ModalBox
                        show={modal}
                        handleClose={closeModal}
                        title={expense && expense.id !== undefined ? 'Edit Expense' : 'Add Expense'}
                        closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                        saveBtn={
                            (<button
                                type="submit"
                                disabled={saving}
                                className={`btn btn-success ${saving ? 'disabled' : ''}`}
                                onClick={(e) => handleSubmit(e)}>
                                {saving
                                    ? <>
                                        <div className="spinner-border spinner-border-sm text-white" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> Saving...
                                    </> : 'Save Expense'}
                            </button>)}
                    >
                        <form>
                            <div className="mb-3">
                                <label
                                    htmlFor="expense-code"
                                    className="form-label"
                                >
                                    Expense Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="expense-name"
                                    className="form-control"
                                    value={expense.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='mb-3'>
                                <label
                                    htmlFor="expense-type"
                                    className="form-label"
                                >
                                    Expense Type
                                </label>
                                <select
                                    name="type"
                                    className="form-control"
                                    id="expense-type"
                                    value={expense.type}
                                    onChange={handleChange}
                                >
                                    <option value={''}>Select expense type</option>
                                    {expenseTypes.map((type, index) => {
                                        return (
                                            <option key={index} selected={(type.id == expense.type?.id) ? true : false} value={type.id}>{type.name}</option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="form-group row mb-3">
                                <div className="input-group">
                                    <div className='col-3'>
                                        <label className="col-xl-12 col-lg-12 col-form-label text-right">
                                            Currency
                                            <IconWithTooltip message={'Kindly note that currency cannot be edited once link has been created or saved.'}>
                                                <small className='text-danger'><i className='ri-information-line'></i></small>
                                            </IconWithTooltip>
                                        </label>
                                        <div className="col-lg-12 col-xl-12">
                                            <select className="form-control form-control-solid" name="currency"
                                                onChange={(e) => handleChange(e)} disabled={(expense && expense.id)}>
                                                {wallets.map((wallet, index) => <option key={index} value={wallet.currency.abbr} selected={(wallet.currency.abbr === expense.currency)}>{wallet.currency.abbr}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <label className="col-xl-12 col-lg-12 col-form-label text-right">Amount</label>
                                        <div className="col-lg-12 col-xl-12">
                                            <input className="form-control form-control-solid" name="amount" type="number" value={expense.amount}
                                                onChange={handleChange} placeholder="Enter Amount" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label
                                    htmlFor="expiry-date"
                                    className="form-label"
                                >
                                    Expense Date
                                </label>
                                <input
                                    type="date"
                                    name="date"
                                    id="date"
                                    className="form-control"
                                    value={expense.date}
                                    onChange={handleChange}
                                />
                            </div>
                        </form>
                    </ModalBox>

                    <DeleteModal
                        show={forDelete ? true : false}
                        title={'Delete confirmation'}
                        message={'Are you sure you want to delete this expense?'}
                        handleDelete={deleteExpense}
                        handleClose={closeDeleteModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExpenseLogs