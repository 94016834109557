import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, Outlet, useParams } from "react-router-dom";
import axios from "axios";
import { errorResponse, responseMessage } from "../../libs/app";
import { AppContext } from "../../Contexts/AppContext";
import { AuthContext } from "../../Contexts/AuthContext";
import InvoiceItem from "./InvoiceItem";
import { Placeholder } from "react-bootstrap";
import getSymbolFromCurrency from "currency-symbol-map";
import CustomerForm from "../Customers/CustomerForm";
import ProductFormModal from "../Products/ProductFormModal";

export async function downloadInvoice(invoice_id) {

  try {
    await axios.post(`/invoice/download/${invoice_id}`)
  } catch (error) {

  }
}

const InvoiceForm = () => {

  document.title = 'Create Invoice | Taojaa - Store Manager App'

  const { order_id, invoice_id } = useParams();
  const { store, wallets, subscription, recordCounts, handleUpgradeModalShow } = useContext(AuthContext)
  const { paymentMethods, paymentStatuses, currencies } = useContext(AppContext);


  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [order, setOrder] = useState(null);
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(null)
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [invoice, setInvoice] = useState({
    order_id: null,
    note: '',
    due_date: '',
    discount: 0,
    sub_total: 0,
    final_total: 0,
    amount_paid: 0,
    shipping_fee: 0,
    billing_name: '',
    billing_phone: '',
    billing_address: '',
    shipping_name: '',
    shipping_phone: '',
    shipping_address: '',
    coupon_code: null,
    customer_id: null,
    payment_method: null,
    minimum_payment_allowed: 0,
    payment_status: null,
    currency_id: store.wallet.currency.id
  });

  const [customer, setcustomer] = useState({
    name: "",
    email: "",
    phone: "",
    created_date: "",
    country_id: 140,
    status: 1,
  });

  const [product, setProduct] = useState({
    name: '',
    price: 0,
    cost_price: 0,
    availability: 1
  });

  const [paymentCurrency, setPaymentCurrency] = useState(store.wallet.currency);
  const [customerModal, setCustomerModal] = useState(false);
  const [productModal, setProductModal] = useState(false);


  useEffect(() => {

    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    if (order_id !== undefined) {
      getOrderDetails();
      getOrderItems();
    }

    if (invoice_id !== undefined) {
      getInvoice();
    }

    getCustomers();
    getProducts();
  }, [store, subscription])

  useEffect(() => {
    calculateSubtotalAndTotal()
  }, [order, items])

  function calculateSubtotalAndTotal() {
    let sub_total = items.length > 0 ?
      items.reduce((accummulator, item) => accummulator + (Number(item.price) * item.quantity), 0) : 0;

    let discount = invoice.discount;
    if (order) {
      discount = order.discount.amount;
    }

    let final_total = ((sub_total + invoice.shipping_fee) - discount).toFixed(2);
    let amount_paid = invoice.amount_paid;

    if (order?.payment_status === 111) {
      amount_paid = final_total;
    }

    setInvoice({ ...invoice, sub_total, discount, final_total, amount_paid })
  }

  async function getInvoice() {

    try {

      setLoading(true)

      const response = await axios.get(`/invoice/details/${invoice_id}`);
      if (response.data.success) {
        var invoiceDetails = response.data.data;
        var invoiceItems = invoiceDetails.items;

        delete invoiceDetails.items;
        delete invoiceDetails.customer;

        invoiceDetails.payment_method = invoiceDetails.payment_method.id;

        setInvoice(invoiceDetails);
        setItems(invoiceItems);
      }

    } catch (error) {
      console.log(error);
      responseMessage("Something went wrong!!", "error");
    }

    setLoading(false)
  }

  async function getOrderDetails() {
    try {
      const response = await axios.get(`/order/${order_id}`);

      if (response.data.success === true) {

        var orderDetails = response.data.data;
        var billing_name = '';
        var billing_address = '';
        var billing_phone = '';
        if (orderDetails.billing_address) {
          billing_name = `${orderDetails.billing_address.firstname} ${orderDetails.billing_address.lastname}`;
          billing_address = `${orderDetails.billing_address.street || ''} ${orderDetails.billing_address.additional_address || ''}, ${orderDetails.billing_address.city || ''} ${orderDetails.billing_address.state && orderDetails.billing_address.state || ''} ${orderDetails.billing_address.country && orderDetails.billing_address.country || ''} ${orderDetails.billing_address.zip || ''}`;
          billing_phone = orderDetails.billing_address.phone || '';
        }

        var shipping_name = '';
        var shipping_address = '';
        var shipping_phone = '';
        if (orderDetails.shipping_address) {
          shipping_name = `${orderDetails.shipping_address.firstname} ${orderDetails.shipping_address.lastname}`;
          shipping_address = `${orderDetails.shipping_address.street || ''} ${orderDetails.shipping_address.additional_address || ''}, ${orderDetails.shipping_address.city || ''} ${orderDetails.shipping_address.state && orderDetails.shipping_address.state || ''} ${orderDetails.shipping_address.country && orderDetails.shipping_address.country || ''} ${orderDetails.shipping_address.zip || ''}`;
          shipping_phone = orderDetails.shipping_address.phone || '';
        }

        let payment_status = 0;
        if (orderDetails.payment_status == 111) {
          payment_status = "Paid";
        } else {
          payment_status = "Unpaid";
        }

        var currency_id = null;
        wallets.forEach(wallet => {
          if (orderDetails.currency === wallet.currency.abbr) {
            setPaymentCurrency(wallet.currency);
            currency_id = wallet.currency.id;
          }
        });

        var invoiceDetails = {
          ...invoice,
          order_id: orderDetails.id,
          customer_id: orderDetails.customer.id,
          shipping_fee: orderDetails.shipping_fee,
          coupon_code: orderDetails.discount.coupon_code,
          payment_method: orderDetails.payment_method.id,
          payment_status,
          billing_name,
          billing_address,
          billing_phone,
          shipping_name,
          shipping_address,
          shipping_phone,
          currency_id
        }

        setInvoice(invoiceDetails);
        setOrder(orderDetails);
      }
    } catch (error) {
      responseMessage("Something went wrong!!", "error");
    }
  }

  async function getOrderItems() {
    try {
      const response = await axios.get(`/order/items/${order_id}`);

      if (response.data.success) {
        var formatedItems = response.data.items.map((item, index) => ({ name: item.name, price: item.price, quantity: item.quantity, description: item.description }));
        setItems(formatedItems);
      }
    } catch (error) {
      responseMessage("Something went wrong!", "error");
    }
  }

  async function getCustomers() {
    try {

      const response = await axios.get(`/customers`);
      if (response.data.success) {
        setCustomers(response.data.customers);
      }
    } catch (error) { }
  }

  async function getProducts() {
    try {
      const response = await axios.get(`/products`);
      if (response.data.success) {

        var options = response.data.data.map((product) => ({ id: product.id, variations: product.variations, var_options: product.variation_options, price: product.price, label: product.name, value: product.name }))
        setProducts(options);
      }
    } catch (error) { }
  }

  async function saveInvoice() {

    if (store.store_type === 'live' && subscription.settings.maxInvoicesAllowed !== 'unlimited') {
      if ((recordCounts.invoices + 1) > subscription.settings.maxInvoicesAllowed) {
        return handleUpgradeModalShow(
          'Upgrade to Professional Plan',
          'Oops! You have reached the maxiumun number of invoices allowed for your current plan, you can upgrade to create more invoices.'
        )
      }
    }

    setSaving(true)

    try {
      const data = { ...invoice, invoice_items: items };
      if (invoice_id !== undefined) {
        var response = await axios.post('/invoice/update', data);
      } else {
        var response = await axios.post('/invoice/create', data);
      }

      if (response.data.success) {
        setSaved(response.data.invoice)
        responseMessage(response.data.message, 'success')
      }

    } catch (error) {
      if (error.response) {
        errorResponse(error.response)
      } else {
        responseMessage("Something went wrong.", "error");
      }
    }

    setSaving(false)
  }

  const handleField = (e) => {

    var { name, value } = e.target;
    if (name === 'currency_id') {
      var targetCurrency = currencies.filter((curr) => curr.id === Number(value));
      if (targetCurrency.length > 0) {
        setPaymentCurrency(targetCurrency[0]);
      }
    }

    let final_total = invoice.final_total;

    if (name === "shipping_fee") {
      final_total = (Number(invoice.sub_total) + Number(value)) - Number(invoice.discount);
    } else if (name === "discount") {
      final_total = (Number(invoice.sub_total) + Number(invoice.shipping_fee)) - Number(value);
    }

    setInvoice({ ...invoice, [name]: value, final_total });
  }

  const addItem = () => {

    const newItem = {
      name: null,
      price: 0,
      quantity: 0
    }

    setItems([...items, newItem])
  }

  const removeItem = (index) => {
    var newList = [...items];
    newList.splice(index, 1)

    setItems(newList);
  }

  const onSelect = (value, index) => {

    var newList = [...items];
    var itemDetails = { name: value.label, price: value.price, options: [], quantity: 1, description: null, hasVariations: false };

    if (value.var_options.length > 0) {
      itemDetails.hasVariations = true;
      itemDetails.options = value.var_options.map((option) => ({ var_id: option.variation_id, pid: option.product_id, label: option.variant, value: option.variant }));
    }

    newList[index] = itemDetails;
    setItems(newList);
  }

  const onVariationSelect = (selected, index) => {
    var newList = [...items];
    var product = products.find((current) => current.id === selected.pid)
    var variants = selected.value.split('/');
    var description = '';

    product.variations.map(({ type, values }) => {
      variants.forEach((_item) => {
        var exist = values.find((target) => _item === target.name)
        if (exist) {
          description += `${type.name}: ${_item}, `;
        }
      })
    })

    newList[index].description = description;
    setItems(newList);
  }

  const increase = (id) => {
    var item = items[id];
    item.quantity += 1;

    var newList = [...items];
    newList[id] = item;

    setItems(newList);
  }

  const decrease = (id) => {
    var item = items[id];
    item.quantity -= 1;

    if (item.quantity < 1) {
      item.quantity = 1
    }

    var newList = [...items];
    newList[id] = item;

    setItems(newList);
  }

  const handleItemPrice = (index, e) => {
    var itemsList = [...items];
    itemsList[index].price = e.target.value;
    setItems(itemsList);
  }

  const handleProductChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  }

  const handleCreateProduct = (value) => {
    setProduct({ ...product, name: value });
    setProductModal(true);
  }

  const toggleCustomerModal = (status = true) => {
    setCustomerModal(status);
    if (status === false) {
      setcustomer({
        name: "",
        email: "",
        phone: "",
        created_date: "",
        country_id: 140,
        status: 1,
      });
    }
  }

  const toggleProductModal = (status) => {
    setProductModal(status);
    if (status === false) {
      setProduct({
        name: "",
        price: 0,
        cost_price: 0,
        availability: 1
      });
    }
  }

  const customerFormProps = {
    modal: customerModal,
    saving,
    customer,
    setSaving,
    closeModal: () => toggleCustomerModal(false),
    setcustomer,
    getCustomers,
  }

  const productModalProps = {
    product,
    productModal,
    saving,
    setSaving,
    getProducts,
    toggleProductModal,
    handleProductChange,
  };

  if (saved) {
    return <Navigate to={`/invoice/${saved.id}`} />
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Invoice & Receipts</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="#" onClick={() => window.history.back()}>
                        <i className="ri-arrow-left-line align-bottom me-1"></i>
                        Back to List
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {!loading ?
            <div className="row justify-content-center">
              <div className="col-xxl-9">
                <div className="card">
                  <form className="needs-validation" noValidate>
                    <div className="card-body border-bottom border-bottom-dashed p-4">
                      <div className="row">
                        <div className="col-lg-6" style={{ height: '80px' }}>
                          <div className="profile-user mx-auto mb-3">
                            <input
                              id="profile-img-file-input"
                              type="file"
                              className="profile-img-file-input"
                              required
                            />
                            <label
                              htmlFor="profile-img-file-input"
                              className="d-block"
                              tabIndex="0"
                            >
                              <span
                                className="overflow-hidden border border-dashed d-flex align-items-center justify-content-center rounded"
                                style={{ height: "60px", width: "256px" }}
                              >
                                {store.logo !== null ? <img
                                  src={store.logo}
                                  className="card-logo user-profile-image img-fluid"
                                /> : store.name}
                              </span>
                            </label>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-lg-4 ms-auto"></div>
                      </div>
                    </div>
                    <div className="card-body p-4">
                      <div className="row g-3">
                        <div className="col-lg-6 col-sm-6">
                          <div className="d-flex justify-content-between">
                            <label htmlFor="choices-payment-status">Customer</label>
                            <Link to={"#"} className="text-primary" onClick={() => toggleCustomerModal(true)}>+ Add New Customer</Link>
                          </div>
                          <div className="input-light">
                            <select
                              className="form-control bg-light border-0"
                              id="choices-payment-status"
                              name="customer_id"
                              onChange={(e) => handleField(e)}
                            >
                              <option value="" selected={!invoice.customer_id ? true : false} disabled={true}>Select customer</option>
                              {customers.length > 0 &&
                                customers.map(({ id, name, email }) => {
                                  return (
                                    <option selected={invoice.customer_id === id ? true : false} key={id} value={id}>
                                      {name} - {email}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                          <div>
                            <label htmlFor="date-field">Due Date</label>
                            <input
                              type="date"
                              name='due_date'
                              onChange={(e) => handleField(e)}
                              className="form-control bg-light border-0"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                          <label htmlFor="choices-payment-status">
                            Payment Status
                          </label>
                          <div className="input-light">
                            <select
                              className="form-control bg-light border-0"
                              id="choices-payment-status"
                              required={true}
                              name='payment_status'
                              onChange={(e) => handleField(e)}
                            >
                              <option value="">Select Payment Status</option>
                              {paymentStatuses.map(({ id, name }, index) => (
                                <option selected={invoice.payment_status === name ? true : false} key={index} value={name}>{name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-4 border-top border-top-dashed">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6">
                          <div>
                            <label
                              htmlFor="billingName"
                              className="text-muted text-uppercase fw-semibold"
                            >
                              Billing Address
                            </label>
                          </div>
                          <div className="mb-2">
                            <input
                              value={invoice.billing_name}
                              name='billing_name'
                              onChange={(e) => handleField(e)}
                              type="text"
                              className="form-control bg-light border-0"
                              id="billingName"
                              placeholder="Full Name"
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter a full name
                            </div>
                          </div>
                          <div className="mb-2">
                            <textarea
                              value={invoice.billing_address}
                              name='billing_address'
                              onChange={(e) => handleField(e)}
                              className="form-control bg-light border-0"
                              id="billingAddress"
                              rows="3"
                              placeholder="Address"
                              required
                            ></textarea>
                            <div className="invalid-feedback">
                              Please enter a address
                            </div>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              value={invoice.billing_phone}
                              name='billing_phone'
                              onChange={(e) => handleField(e)}
                              className="form-control bg-light border-0"
                              data-plugin="cleave-phone"
                              id="billingPhoneno"
                              placeholder="(123)456-7890"
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter a phone number
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ms-auto">
                          <div className="row">
                            <div className="col-lg-8">
                              <div>
                                <label
                                  htmlFor="shippingName"
                                  className="text-muted text-uppercase fw-semibold"
                                >
                                  Shipping Address
                                </label>
                              </div>
                              <div className="mb-2">
                                <input
                                  value={invoice.shipping_name}
                                  name='shipping_name'
                                  onChange={(e) => handleField(e)}
                                  type="text"
                                  className="form-control bg-light border-0"
                                  id="shippingName"
                                  placeholder="Full Name"
                                  required
                                />
                                <div className="invalid-feedback">
                                  Please enter a full name
                                </div>
                              </div>
                              <div className="mb-2">
                                <textarea
                                  value={invoice.shipping_address}
                                  name='shipping_address'
                                  onChange={(e) => handleField(e)}
                                  className="form-control bg-light border-0"
                                  id="shippingAddress"
                                  rows="3"
                                  placeholder="Address"
                                  required
                                ></textarea>
                                <div className="invalid-feedback">
                                  Please enter a address
                                </div>
                              </div>
                              <div className="mb-2">
                                <input
                                  type="text"
                                  value={invoice.shipping_phone}
                                  name='shipping_phone'
                                  onChange={(e) => handleField(e)}
                                  className="form-control bg-light border-0"
                                  data-plugin="cleave-phone"
                                  id="shippingPhoneno"
                                  placeholder="(123)456-7890"
                                  required
                                />
                                <div className="invalid-feedback">
                                  Please enter a phone number
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="invoice-table table table-borderless table-nowrap mb-0">
                          <thead className="align-middle">
                            <tr className="table-active">
                              <th scope="col" style={{ width: "50px" }}>#</th>
                              <th scope="col">Product Details</th>
                              <th scope="col" style={{ width: "120px" }}>
                                <div className="d-flex currency-select input-light align-items-center">
                                  Rate
                                  <select
                                    className="form-selectborder-0 bg-light"
                                    id="choices-payment-currency"
                                    name="currency_id"
                                    onChange={(e) => handleField(e)}
                                  >
                                    {wallets.map((wallet, index) =>
                                      <option key={index} value={wallet.currency.id} selected={wallet.currency.id === invoice.currency_id}>({wallet.currency.abbr})</option>
                                    )}
                                  </select>
                                </div>
                              </th>
                              <th scope="col" style={{ width: "120px" }}>Quantity</th>
                              <th
                                scope="col"
                                className="text-end"
                                style={{ width: "150px" }}
                              >
                                Amount
                              </th>
                              <th
                                scope="col"
                                className="text-end"
                                style={{ width: "105px" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody id="newlink">
                            {items.length > 0 && (
                              <>{
                                items.map((item, index) => (
                                  <InvoiceItem
                                    id={index}
                                    item={item}
                                    options={products}
                                    onSelect={onSelect}
                                    increase={increase}
                                    decrease={decrease}
                                    key={`invoice-item-${index}`}
                                    handleItemPrice={handleItemPrice}
                                    removeItem={() => removeItem(index)}
                                    onVariationSelect={onVariationSelect}
                                    enableSelect={item.name === null ? true : false}
                                    handleCreateProduct={handleCreateProduct}
                                  />
                                ))
                              }</>
                            )}
                            <tr id="newForm" style={{ display: "none" }}></tr>
                            <tr>
                              <td colSpan="9">
                                <button
                                  type="button"
                                  className="btn btn-soft-secondary fw-medium"
                                  onClick={addItem}
                                >
                                  <i className="ri-add-fill me-1 align-bottom"></i>
                                  Add Item
                                </button>
                              </td>
                            </tr>
                            <tr className="border-top border-top-dashed mt-2">
                              <td colSpan="3"></td>
                              <td colSpan="2" className="p-0">
                                <table className="table table-borderless table-sm table-nowrap align-middle mb-0">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Sub Total ({getSymbolFromCurrency(paymentCurrency.abbr)})</th>
                                      <td style={{ width: "150px" }}>
                                        <input
                                          type="text"
                                          className="form-control bg-light border-0"
                                          id="cart-subtotal"
                                          placeholder="0.00"
                                          value={invoice.sub_total}
                                          readOnly={true}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        Discount ({getSymbolFromCurrency(paymentCurrency.abbr)})
                                        <small className="text-muted">
                                          {order && <>{order.discount.coupon_code ? `(${order.discount.coupon_code})` : null}</>}
                                        </small>
                                      </th>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control bg-light border-0"
                                          id="cart-discount"
                                          name="discount"
                                          placeholder="0.00"
                                          value={invoice.discount}
                                          onChange={(e) => handleField(e)}
                                          readOnly={false}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Shipping Fee ({getSymbolFromCurrency(paymentCurrency.abbr)})</th>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control bg-light border-0"
                                          id="cart-shipping"
                                          value={invoice.shipping_fee}
                                          name="shipping_fee"
                                          placeholder="0.00"
                                          onChange={(e) => handleField(e)}
                                          readOnly={false}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-top border-top-dashed">
                                      <th scope="row">Total Amount ({getSymbolFromCurrency(paymentCurrency.abbr)})</th>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control bg-light border-0"
                                          id="invoice-total"
                                          value={invoice.final_total}
                                          placeholder="0.00"
                                          readOnly={true}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-top">
                                      <th scope="row">Amount Paid ({getSymbolFromCurrency(paymentCurrency.abbr)})</th>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control bg-light border-0"
                                          id="amount-paid"
                                          name="amount_paid"
                                          value={invoice.amount_paid}
                                          placeholder="0.00"
                                          onChange={(e) => handleField(e)}
                                          readOnly={false}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-top">
                                      <th scope="row">Balance ({getSymbolFromCurrency(paymentCurrency.abbr)})</th>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control bg-light border-0"
                                          id="balance"
                                          name="balance"
                                          value={invoice.final_total - invoice.amount_paid}
                                          placeholder="0.00"
                                          readOnly={true}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-4">
                          <div className="mb-2">
                            <label
                              htmlFor="choices-payment-type"
                              className="form-label text-muted text-uppercase fw-semibold"
                            >
                              Payment Method
                            </label>
                            <div className="input-light">
                              <select
                                className="form-control bg-light border-0"
                                id="choices-payment-type"
                                name="payment_method"
                                onChange={(e) => handleField(e)}
                              >
                                <option value="" selected={!invoice.payment_method ? true : false} disabled={true}>Select Payment Method</option>
                                {paymentMethods.length > 0 &&
                                  paymentMethods.map(({ id, name }) => {
                                    return (
                                      <option selected={invoice.payment_method === id ? true : false} key={id} value={id}>{name}</option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="mb-2 mt-2">
                              <label
                                htmlFor="choices-payment-type"
                                className="form-label text-muted text-uppercase fw-semibold"
                              >
                                Minimum Payment Allowed
                              </label>
                              <div className="input-light">
                                <input
                                  type="number"
                                  className="form-control bg-light border-0"
                                  id="minimum_payment_allowed"
                                  name="minimum_payment_allowed"
                                  value={invoice.minimum_payment_allowed}
                                  onChange={(e) => handleField(e)}
                                  placeholder="0.00"
                                  readOnly={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          NOTES
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Notes"
                          rows="2"
                          value={invoice.note}
                          name="note"
                          onChange={(e) => handleField(e)}
                          required
                        ></textarea>
                      </div>
                      <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                        <button type="button" className="btn btn-info" disabled={saving}
                          onClick={saveInvoice}>
                          <i className="ri-save-3-line align-bottom me-1"></i>
                          {saving
                            ? <>
                              <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div> Saving...
                            </> : 'Save Invoice'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            :
            <>
              <div className='mb-2'><Placeholder column="col-9" /></div>
              <div className='mb-2'><Placeholder column="col-12" /></div>
              <div className='mb-2'><Placeholder column="col-7" /></div>
              <div className='mb-2'><Placeholder column="col-10" /></div>
            </>
          }
        </div>

        <CustomerForm {...customerFormProps} />
        <ProductFormModal {...productModalProps} />
      </div>
      <Outlet />
    </>
  );
};

export default InvoiceForm;
