import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import { AuthContext } from '../../Contexts/AuthContext';
import { errorResponse, responseMessage } from '../../libs/app';
import Loader from '../../Components/Loader/Loader';
import SearchResponse from '../../Components/SearchResponse/SearchResponse';
import ModalBox from '../../Components/ModalBox';
import DeleteModal from '../../Components/DeleteModal';

const Coupons = () => {

    document.title = 'Discount & Coupons | Taojaa - Store Manager App'

    const { store, subscription, recordCounts, handleUpgradeModalShow } = useContext(AuthContext);

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({
        keyword: '',
        status: ''
    });

    const [coupon, setCoupon] = useState({
        coupon: '',
        type: null,
        discount: 0,
        minimum: 0,
        status: 1,
        expiry_date: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [coupons, setCoupons] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [forDelete, setForDelete] = useState(null);
    const [modal, setModal] = useState(false);

    const couponStatus = [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" }
    ];

    const couponTypes = [
        { label: 'Percentage', name: 'percentage' },
        { label: 'Amount', name: 'amount' }
    ];

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }

        getCoupons();
    }, [store, subscription])


    async function getCoupons() {
        try {
            const response = await axios.get(`/coupons?page=${currentPage}`);
            if (response.data.success) {
                setCoupons(response.data.data);
                setPagination(response.data.pagination)
            }
        }
        catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
        setLoading(false);
    }

    async function handleSubmit(e) {

        e.preventDefault();

        if (store.store_type === 'live' && subscription.settings.maxCouponsAllowed !== 'unlimited') {
            if ((Number(recordCounts.coupons) + 1) > subscription.settings.maxCouponsAllowed) {
                return handleUpgradeModalShow(
                    'Upgrade Your Plan',
                    'You have reaches the maxiumun number of coupons allowed for your current plan, you can upgrade to add more coupons.'
                )
            }
        }

        try {
            setSaving(true)

            if (coupon.id !== undefined) {
                var response = await axios.put('/coupon/update', { store_id: store.id, ...coupon })
            } else {
                var response = await axios.post('/coupon/create', { store_id: store.id, ...coupon })
            }

            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getCoupons()
                closeModal()
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function deleteCoupon() {

        try {

            const response = await axios.delete(`/coupon/delete/${forDelete}`);

            if (response.data.success) {
                responseMessage(response.data.message, "success");

                getCoupons();
                closeDeleteModal();
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    function editCoupon(coupon) {
        setCoupon(coupon);
        openModal()
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const CouponStatus = ({ status }) => {
        if (status === 0 || status === null) {
            return <span className="badge badge-soft-warning text-uppercase">Inactive</span>
        } else if (status === 1) {
            return <span className="badge badge-soft-success text-uppercase">Active</span>
        }
    }

    const filterTable = () => {

    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setCoupon({ ...coupon, [name]: value });
    }

    const openModal = () => {
        if (subscription?.settings.maxCouponsAllowed === 0) {
            return handleUpgradeModalShow(
                'Upgrade Your Plan',
                'Oops! Your current plan does not enable access to create coupons, kindly upgrade to create coupons.'
            )
        }

        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
        setCoupon({
            coupon: '',
            type: null,
            discount: 0,
            minimum: 0,
            status: 1,
            expiry_date: ''
        })
    }

    const closeDeleteModal = () => {
        setForDelete(null)
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Discounts & Coupons</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Coupons</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card" id="orderList">
                                <div className="card-header border-bottom-dashed">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">
                                            Coupons
                                        </h5>
                                        <div className="flex-shrink-0">
                                            <button
                                                type="button"
                                                className="btn btn-success me-1"
                                                onClick={openModal}
                                            >
                                                <i className="ri-add-line align-bottom me-1"></i> Create Coupon
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body border-bottom-dashed border-bottom">
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-xl-8">
                                                <div className="search-box">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Search for coupon..."
                                                        name="keyword"
                                                        value={filter.keyword}
                                                        onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="row g-3">
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <select
                                                                className="form-control"
                                                                data-plugin="choices"
                                                                data-choices
                                                                data-choices-search-false
                                                                name="choices-single-default"
                                                                id="idStatus"
                                                                onChange={(e) => {
                                                                    if (
                                                                        e.target.value.toLowerCase() == "active"
                                                                    ) {
                                                                        setFilter({ ...filter, status: 1 })
                                                                    } else if (
                                                                        e.target.value.toLowerCase() == "inactive"
                                                                    ) {
                                                                        setFilter({ ...filter, status: 0 })
                                                                    } else {
                                                                        setFilter({ ...filter, status: '' })
                                                                    }
                                                                }}
                                                            >
                                                                <option value="">All</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary w-100"
                                                                onClick={filterTable}
                                                            >
                                                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                                                Filters
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body">
                                    <div>

                                        <div className="table-responsive table-card mb-1">
                                            {coupons.length > 0 ? (
                                                <table
                                                    className="table table-nowrap align-middle"
                                                    id="orderTable"
                                                >
                                                    <thead className="text-muted table-light">
                                                        <tr className='text-uppercase'>
                                                            <th scope="col" style={{ width: "25px" }}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="checkAll"
                                                                        value="option"
                                                                    />
                                                                </div>
                                                            </th>
                                                            <th className="sort" data-sort="code">
                                                                Code
                                                            </th>
                                                            <th className="sort" data-sort="discount">
                                                                Discount
                                                            </th>
                                                            <th className="sort" data-sort="type">
                                                                Type
                                                            </th>
                                                            <th className="sort" data-sort="status">
                                                                Status
                                                            </th>
                                                            <th className="sort" data-sort="expiry_date">
                                                                Expiry Date
                                                            </th>
                                                            <th className="sort" data-sort="created_date">
                                                                Created Date
                                                            </th>
                                                            <th className="sort" data-sort="actions">
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {coupons.map((coupon, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="checkAll"
                                                                            value="option1"
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <td className="coupon">
                                                                    {coupon.coupon}
                                                                </td>
                                                                <td className='discount'>
                                                                    {coupon.discount}
                                                                </td>
                                                                <td className='type'>
                                                                    {coupon.type.toUpperCase()}
                                                                </td>
                                                                <td className="status">
                                                                    <CouponStatus status={coupon.status} />
                                                                </td>
                                                                <td className="expiry_date">
                                                                    {moment(coupon.expiry_date).format('ddd, D MMM YYYY')}
                                                                </td>
                                                                <td className="created_date">
                                                                    {moment(coupon.created_date).format('ddd, D MMM YYYY')}
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li
                                                                            className="list-inline-item edit"
                                                                            title="Edit"
                                                                        >
                                                                            <Link
                                                                                to="#"
                                                                                className="text-primary d-inline-block edit-item-btn"
                                                                                onClick={() => editCoupon(coupon)}
                                                                            >
                                                                                <i className="ri-pencil-fill fs-16"></i>
                                                                            </Link>
                                                                        </li>
                                                                        <li
                                                                            className="list-inline-item"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-trigger="hover"
                                                                            data-bs-placement="top"
                                                                            title="Remove"
                                                                        >
                                                                            <Link
                                                                                className="text-danger d-inline-block remove-item-btn"
                                                                                data-bs-toggle="modal"
                                                                                to="#deleteCoupon"
                                                                                onClick={() =>
                                                                                    setForDelete(coupon.id)
                                                                                }
                                                                            >
                                                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <>
                                                    {loading
                                                        ? <div className="py-5"><Loader message={<p className="text-muted">Fetching coupons...</p>} /></div>
                                                        : <SearchResponse title={"No Coupon Available"}
                                                            message={<p>You haven't create any coupon yet.
                                                                <Link to={"#"} type="button" className='text-primary' onClick={openModal}> Create coupon</Link>
                                                            </p>}
                                                        />
                                                    }
                                                </>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Pagination pagination={pagination} onPageChange={onPageChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <ModalBox
                                        show={modal}
                                        handleClose={closeModal}
                                        title={coupon && coupon.id !== undefined ? 'Edit Coupon' : 'Create Coupon'}
                                        closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                                        saveBtn={
                                            (<button
                                                type="submit"
                                                disabled={saving}
                                                className={`btn btn-success ${saving ? 'disabled' : ''}`}
                                                onClick={(e) => handleSubmit(e)}>
                                                {saving
                                                    ? <>
                                                        <div className="spinner-border spinner-border-sm text-white" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div> Saving...
                                                    </> : 'Save Coupon'}
                                            </button>)}
                                    >
                                        <form>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="coupon-code"
                                                    className="form-label"
                                                >
                                                    Coupon Code
                                                </label>
                                                <input
                                                    type="text"
                                                    name="coupon"
                                                    id="coupon-code"
                                                    className="form-control"
                                                    value={coupon.coupon}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className='mb-3'>
                                                <label
                                                    htmlFor="discount-type"
                                                    className="form-label"
                                                >
                                                    Discount Type
                                                </label>
                                                <select
                                                    name="type"
                                                    className="form-control"
                                                    id="discount-type"
                                                    value={coupon.type}
                                                    onChange={handleChange}
                                                >
                                                    <option defaultChecked>Select discount type</option>
                                                    {couponTypes.map(({ label, name }) => {
                                                        return (
                                                            <option key={label} selected={coupon.type == name ? true : false} value={name}>{label}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label
                                                    htmlFor="discount-value"
                                                    className="form-label"
                                                >
                                                    Discount Value
                                                </label>
                                                <input
                                                    type="number"
                                                    name="discount"
                                                    id="discount-value"
                                                    className="form-control"
                                                    value={coupon.discount}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label
                                                    htmlFor="expiry-date"
                                                    className="form-label"
                                                >
                                                    Expiry Date
                                                </label>
                                                <input
                                                    type="date"
                                                    name="expiry_date"
                                                    id="expiry-date"
                                                    className="form-control"
                                                    value={coupon.expiry_date}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label
                                                    htmlFor="minimum-eligible-price"
                                                    className="form-label"
                                                >
                                                    Minimum Eligible Price
                                                </label>
                                                <input
                                                    type="number"
                                                    name="minimum"
                                                    id="minimum-eligible-price"
                                                    className="form-control"
                                                    value={coupon.minimum}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className='mb-3'>
                                                <label
                                                    htmlFor="delivered-status"
                                                    className="form-label"
                                                >
                                                    Coupon Status
                                                </label>
                                                <select
                                                    name="status"
                                                    className="form-control"
                                                    id="delivered-status"
                                                    value={coupon.status}
                                                    onChange={handleChange}
                                                >
                                                    <option value={''}>Select status</option>
                                                    {couponStatus.map(({ id, name }) => {
                                                        return (
                                                            <option key={id} selected={coupon.status == id ? true : false} value={id}>{name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </form>
                                    </ModalBox>

                                    <DeleteModal
                                        show={forDelete ? true : false}
                                        title={'Delete confirmation'}
                                        message={'Are you sure you want to delete this coupon?'}
                                        handleDelete={deleteCoupon}
                                        handleClose={closeDeleteModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default Coupons