import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import { errorResponse, responseMessage } from "../../libs/app";
import { AuthContext } from "../../Contexts/AuthContext";
import { useContext } from "react";
import Pagination from "../../Components/Pagination";
import Loader from "../../Components/Loader/Loader";
import SearchResponse from "../../Components/SearchResponse/SearchResponse";
import { publishAction } from "./ProductDetails";
import Flatpickr from "react-flatpickr";
import DeleteModal from "../../Components/DeleteModal";
import { addFiltersToEndpoint, dateChange } from "../../helpers";
import { formatCurrency } from "wearslot-dev-utils";
import IconWithTooltip from "../../Components/IconWithTooltip";


const Product = ({ product }) => {
  return (
    <span>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-3">
          <div className="avatar-sm bg-light rounded" style={{ overflow: 'hidden' }}>
            <img
              alt=""
              src={product.images.main}
              className="img-fluid d-block"
            />
          </div>
        </div>
        <div className="flex-grow-1">
          <h5 className="fs-14 mb-1">
            <Link to={"/product/" + product.id + '/details'} className="text-dark">
              {product.name || 'N/A'}
            </Link>
          </h5>
          <p className="text-muted mb-0">
            <span className="fw-medium">
              {(product.collection || '') +
                (product.category != null ? `, ${product.category}` : "") +
                (product.sub_category != null
                  ? `, ${product.sub_category} `
                  : "")}
            </span>
          </p>
        </div>
      </div>
    </span>
  );
};

const Rating = ({ product }) => {
  return (
    <span>
      <span className="badge bg-light text-body fs-12 fw-medium">
        <i className="mdi mdi-star text-warning me-1"></i>
        {product.rating.rate}
      </span>
    </span>
  );
};

const Published = ({ product }) => {
  return <>{product.published.status
    ? <span className="badge badge-soft-success text-uppercase">Published</span>
    : <span className="badge badge-soft-danger text-uppercase">Unpublished</span>}</>;
};

const Action = ({ product, signal }) => {

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  useEffect(() => { }, [deleteConfirmation]);

  const deleteProduct = (product) => {
    setDeleteConfirmation(product);
  };

  const confirmDelete = () => {
    axios
      .delete(`/product/delete/${product.id}`)
      .then((response) => {
        if (response.data.success) {
          signal(product.id);
          setDeleteConfirmation(null);
          responseMessage(response.data.message, "success");
        }
      })
      .catch((error) => {
        if (error.response)
          errorResponse(error.response);
        else
          responseMessage("Something went wrong.", "error");
      });
  };

  const removeItem = () => {
    return (
      <DeleteModal
        show={deleteConfirmation ? true : false}
        title={'Delete confirmation'}
        message={'Are you sure you want to delete this product?'}
        handleDelete={confirmDelete}
        handleClose={() => setDeleteConfirmation(null)}
      />
    );
  };

  const publishButton = async (product, status) => {
    await publishAction(product, status);
    signal(product);
  }

  return (
    <>
      {deleteConfirmation ? removeItem(deleteConfirmation) : null}
      <ul className="list-inline hstack gap-2 mb-0">
        <li
          className="list-inline-item"
          title="View"
        >
          <IconWithTooltip message={'View Product'}>
            <Link
              to={"/product/" + product.id + "/details"}
              className="text-primary d-inline-block"
            >
              <i className="ri-eye-fill fs-16"></i>
            </Link>
          </IconWithTooltip>
        </li>
        <li
          className="list-inline-item edit"
          title="Edit"
        >
          <IconWithTooltip message={'Edit Product'}>
            <Link
              to={"/product/" + product.id + '/edit'}
              className="text-primary d-inline-block edit-item-btn"
            >
              <i className="ri-pencil-fill fs-16"></i>
            </Link>
          </IconWithTooltip>
        </li>
        {
          product.published.status ?
            <li
              className="list-inline-item unpublish"
              title="Unpublish"
              onClick={() => publishButton(product.id, 0)}
            >
              <IconWithTooltip message={'Unpublish Product'}>
                <Link
                  to={"#unpublish"}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-indeterminate-circle-line fs-16" style={{ color: "red" }}></i>
                </Link>
              </IconWithTooltip>
            </li>
            :
            <li
              className="list-inline-item publish"
              title="Publish"
              onClick={() => publishButton(product.id, 1)}
            >
              <IconWithTooltip message={'Publish Product'}>
                <Link
                  to={"#publish"}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-rocket-line fs-16 text-success"></i>
                </Link>
              </IconWithTooltip>
            </li>
        }
        <li
          className="list-inline-item"
          title="Remove"
        >
          <IconWithTooltip message={'Delete Product'}>
            <Link
              className="text-danger d-inline-block remove-item-btn"
              data-bs-toggle="modal"
              data-bs-target="#removeItemModal"
              onClick={(e) => deleteProduct(product)}
            >
              <i className="ri-delete-bin-5-fill fs-16"></i>
            </Link>
          </IconWithTooltip>
        </li>
      </ul>
    </>
  );
};

const Products = () => {

  document.title = 'Products | Taojaa - Store Manager App';

  const { currency, getSubscription } = useContext(AuthContext)

  const [reload, signal] = useState("off");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [counts, setProductCounts] = useState({
    all: 0,
    published: 0,
    unpublished: 0,
  });

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [pagination, setPagination] = useState(null);
  const [filters, setFilters] = useState({
    published: null,
    keywords: "",
    start_date: null,
    end_date: null,
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const applyFilter = () => {
    getProducts(filters);
  };

  const resetFilter = () => {
    setFilters({
      published: null,
      keywords: "",
      start_date: null,
      end_date: null,
    });
    getProducts();
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getProducts();
    
  }, [reload, currentPage]);

  async function getProducts(filters = null) {
    try {

      let endpoint = `/products?page=${currentPage}&perpage=${perPage}`;
      if (filters) {
        endpoint = addFiltersToEndpoint(filters, endpoint);
      }

      const response = await axios.get(endpoint);

      if (response.data.success) {
        setProducts(response.data.data);
        setPagination(response.data.pagination)
        setProductCounts(response.data.counts)
        getSubscription();
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
    setLoading(false);
  }

  const handleTabClick = (status) => {
    getProducts({ ...filters, published: status })
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Products & Inventory</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Store</Link>
                    </li>
                    <li className="breadcrumb-item active">Products</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div>
                <div className="card">
                  <div className="card-header  border-0">
                    <div className="d-flex align-items-center">
                      <h5 className="card-title mb-0 flex-grow-1">
                        All Products
                      </h5>
                      <div className="flex-shrink-0">
                        <Link to="/products/create" className="btn btn-success add-btn me-1">
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add Product
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="card-body border border-dashed border-end-0 border-start-0">
                    <form>
                      <div className="row g-3">
                        <div className="col-xxl-5 col-sm-6">
                          <div className="search-box">
                            <input
                              type="text"
                              className="form-control search"
                              name="keywords"
                              placeholder="Search for order ID, customer, order status or something..."
                              onChange={handleFilterChange}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>

                        <div className="col-xxl-5 col-sm-6">
                          <Flatpickr
                            className="form-control"
                            id="dash-filter-picker"
                            placeholder="start date to end date"
                            options={{ mode: "range" }}
                            defaultValue={filters.start_date && filters.end_date !== null ? filters.start_date + " to " + filters.end_date : ''}
                            onChange={(selectedDates) => dateChange(selectedDates, filters, setFilters)}
                          />
                        </div>

                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <select
                              className="form-control"
                              name="published"
                              id="idStatus"
                              onChange={handleFilterChange}
                            >
                              <option defaultValue="">Select Status</option>
                              <option value="1">Published</option>
                              <option value="0">Unpublished</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary w-100 filter-brn"
                              onClick={applyFilter}
                            >
                              <i className="ri-equalizer-fill me-1 align-bottom"></i>
                              Filter
                            </button>
                          </div>
                        </div>
                        <div className="col-xxl-2 col-sm-4">
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger w-100 filter-btn"
                              onClick={resetFilter}
                            >
                              <i className="ri-equalizer-fill me-1 align-bottom"></i>
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-body pt-0">
                    {
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <Link
                              className="nav-link active All py-3"
                              data-bs-toggle="tab"
                              id="All"
                              to="#all"
                              role="tab"
                              aria-selected="true"
                              onClick={() => getProducts()}
                            >
                              All
                              <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                {counts.all}
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link py-3 Delivered"
                              data-bs-toggle="tab"
                              id="Delivered"
                              to="#published"
                              role="tab"
                              aria-selected="false"
                              onClick={() => handleTabClick("1")}
                            >
                              Published
                              <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                {counts.published}
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link py-3 Pickups"
                              data-bs-toggle="tab"
                              id="Pickups"
                              to="#draft"
                              role="tab"
                              aria-selected="false"
                              onClick={() => handleTabClick("0")}
                            >
                              Unpublished
                              <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                                {counts.unpublished}
                              </span>
                            </Link>
                          </li>
                        </ul>
                        <div className="table-responsive table-card">
                          {products.length > 0 ? (
                            <table
                              className="table align-middle table-nowrap"
                              id="ProductsTable"
                            >
                              <thead className="text-muted table-light">
                                <tr className="text-uppercase fs-13">
                                  <th scope="col" style={{ width: "50px" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                      />
                                    </div>
                                  </th>
                                  <th className="sort" data-sort="product_id">ID</th>
                                  <th className="sort" data-sort="product_name">Product</th>
                                  <th className="sort" data-sort="stock">Stock</th>
                                  <th className="sort" data-sort="price">Price</th>
                                  <th className="sort" data-sort="rating">Rating</th>
                                  <th className="sort" data-sort="published">Status</th>
                                  <th className="sort" data-sort="action">Action</th>
                                </tr>
                              </thead>
                              <tbody className="list form-check-all">
                                {products.map((product, index) => (
                                  <tr key={index}>
                                    <td scope="row">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                        />
                                      </div>
                                    </td>
                                    <td className="id">
                                      <Link
                                        to={`product-details/${product.id}`}
                                        className="fw-semibold link-dark"
                                      >
                                        #{product.id || 'N/A'}
                                      </Link>
                                    </td>
                                    <td className="product_name">
                                      <Product product={product || 'N/A'} />
                                    </td>
                                    <td className="stock">{product.quantity}</td>
                                    <td className="price">{currency}{formatCurrency(product.price)}</td>
                                    <td className="rating">
                                      <Rating product={product} />
                                    </td>
                                    <td className="published">
                                      <Published product={product} />
                                    </td>
                                    <td>
                                      <Action product={product} signal={signal} />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <>
                              {loading
                                ? <div className="py-5"><Loader message={<p className="text-muted">Fetching products...</p>} /></div>
                                : <SearchResponse title={"No Product Available"} message={"You don't have any product yet"} />
                              }
                            </>
                          )}
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <Pagination pagination={pagination} onPageChange={onPageChange} />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Products;
