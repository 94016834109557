import axios from "axios";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { errorResponse, responseMessage } from "../../../../libs/app";
import { saveSettings } from "../../Storefront";

const Colors = () => {
  const [settings, setSettings] = useState({
    primary_color: "#ffffff",
    secondary_color: "#6c757d",
    header_light_background: "#ffffff",
    header_dark_background: "#000000",
    text_color: "#000000",
    secondary_text_color: "#000000",
    button_bg_color: "#000000",
    button_text_color: "#ffffff",
    secondary_button_bg_color: "#6c757d",
    secondary_button_text_color: "#ffffff",
    footer_bg_color: "#000000",
    footer_text_colo: "#ffffff",
  });

  const colorSettingsList = [
    "primary_color",
    "secondary_color",
    "header_light_background",
    "header_dark_background",
    "text_color",
    "secondary_text_color",
    "button_bg_color",
    "button_text_color",
    "secondary_button_bg_color",
    "secondary_button_text_color",
    "footer_bg_color",
    "footer_text_color",
  ];

  useEffect(() => {
    getStorefrontSettings();
  }, []);

  useEffect(() => {
    if (settings) {
      saveSettings(settings);
    }
  }, [settings]);

  async function getStorefrontSettings() {
    try {
      const response = await axios.get(`/settings/storefront`);
      if (response.data.success) {
        setSettings({ ...settings, ...response.data.data });
      }
    } catch (error) {
      if (error.response) errorResponse(error.response);
      else responseMessage("Something went wrong.", "error");
    }
  }

  const onChangeColor = (color, key) => {
    setSettings({ ...settings, [key]: color.hex });
  };

  return (
    <div className="col-xxl-9 mb-3">
      <div className="card card-height-100">
        <div className="card-header align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Customization</h4>
        </div>
        <div className="card-body">
          <div className="accordion" id="accordion">
            <div className="accordion-item mb-3">
              <div className="accordion-header" id="colors">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#colorsBody`}
                  aria-expanded="true"
                  aria-controls={`colorsBody`}
                >
                  <div className="d-flex gap-2">
                    <div>
                      <i
                        className="ri-palette-line"
                        style={{ fontSize: 30 }}
                      ></i>
                    </div>
                    <div>
                      <h5 className="mb-0">Colors</h5>
                      <p className="m-0">
                        Choose preferred colors for your storefront.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div
                id={`colorsBody`}
                className="accordion-collapse collapse"
                aria-labelledby="region"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <div className="mt-2">
                    <div className="row">
                      {colorSettingsList.map((color_name) => (
                        <div className="col-lg-12 mb-3" key={color_name}>
                          <div className="d-flex justify-content-between">
                            <h6>
                              {color_name
                                .toLocaleUpperCase()
                                .split("_")
                                .join(" ")}
                            </h6>
                            <div className="dropdown">
                              <a
                                href="#"
                                role="button"
                                id={color_name}
                                className="btn btn-light btn-sm"
                                style={{ background: settings[color_name] }}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="ri-pencil-fill"></i>
                              </a>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby={color_name}
                              >
                                <SketchPicker
                                  width="150"
                                  color={settings[color_name]}
                                  onChangeComplete={(color) =>
                                    onChangeColor(color, color_name)
                                  }
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item mb-3 d-none">
              <div className="accordion-header" id="colors">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#productThumbnail`}
                  aria-expanded="true"
                  aria-controls={`productThumbnail`}
                >
                  <div className="d-flex gap-2">
                    <div>
                      <i
                        className="ri-t-shirt-2-line"
                        style={{ fontSize: 30 }}
                      ></i>
                    </div>
                    <div>
                      <h5 className="mb-0">Product Thumbnail</h5>
                      <p className="m-0">
                        Set your product display thumbnail and size.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div
                id={`productThumbnail`}
                className="accordion-collapse collapse"
                aria-labelledby="region"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <div className="mt-2">
                    <div className="row"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Colors;
