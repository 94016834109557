import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import $ from "jquery";
import { AppContext } from "../../src/Contexts/AppContext";

const MyDropzoneBlog = ({
  endpoint,
  message,
  size = "big",
  entries = null,
  beforeUpload,
  onUploadResponse,
  onUploadError,
  max_files = 1,
  type,
}) => {
  const {
    setBlogFiles,
    blogFiles,
    setBlogFilesPreview,
    blogFilesPreview,
    setFileSent,
  } = React.useContext(AppContext);
  const onDrop = useCallback((acceptedFiles) => {
    var data = new FormData();

    if (entries != null) {
      for (const [key, value] of Object.entries(entries)) {
        data.append(key, value);
      }
    }

    setFileSent(acceptedFiles[0]);
    setBlogFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    setBlogFilesPreview(URL.createObjectURL(acceptedFiles[0]))
  });
  


  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: max_files,
    onDrop,
  });
  const BigImagesPreview = ({ files }) => {
    return (
      <>
        {blogFiles.length > 0
          ? files.map((item) => {
              <div className="d-flex" key={item.name}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ overflow: "hidden", height: 300 }}
                >
                  <img
                    src={item.preview}
                    alt={""}
                    width={"100%"}
                    className="img-fluid mx-auto"
                    onLoad={() => {
                      URL.revokeObjectURL(item?.preview);
                    }}
                  />
                </div>
              </div>;
            })
          : ""}
        {/* {files.map((file) => (
          <div className="d-flex" key={file.name}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ overflow: "hidden", height: 300 }}
            >
              <img
                src={file.preview}
                alt={""}
                width={"100%"}
                className="img-fluid mx-auto"
                onLoad={() => {
                  URL.revokeObjectURL(file.preview);
                }}
              />
            </div>
          </div>
        ))} */}
      </>
    );
  };
  const DisplayMessage = ({ size, message }) => {
    return (
      <div className="dz-message text-center needsclick">
        {size === "small" ? (
          <>
            <div className="mb-0">
              <i className="display-6 text-muted ri-upload-cloud-2-fill"></i>
            </div>
            <span className="text-muted" style={{ fontSize: 14 }}>
              {message}
            </span>
          </>
        ) : (
          <>
            <div className="">
              <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
            </div>
            <h6>{message}</h6>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className={
        "dropzone d-flex justify-content-center align-items-center " +
        (size === "small" ? "dropzone-small" : "")
      }
      {...getRootProps()}
    >
      <div className="fallback">
        <input type="file" {...getInputProps()} />
      </div>
      {blogFiles?.length > 0 && size === "big" ? (
        <BigImagesPreview files={blogFiles} />
      ) : (
        <>
          <DisplayMessage message={message} size={size} />
        </>
      )}
    </div>
  );
};

export default MyDropzoneBlog;
