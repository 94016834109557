import React, { useContext } from 'react'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { Link } from 'react-router-dom';
import { AppContext } from '../Contexts/AppContext';
import { AuthContext } from '../Contexts/AuthContext';


const TaskCard = ({ index, status, title, detail, link, settings }) => {

    return (
        <div className='task-card-wrapper px-0'>
            <div className={`task-card ${settings.theme} gap-3 px-2 py-2 d-flex align-items-center`} style={{ borderRadius: 10 }}>
                <div className='task-status'>
                    {status
                        ? <IoIosCheckmarkCircle size={40} className='text-success' />
                        : <span className='index ms-1'>{index + 1}</span>}
                </div>
                <div className='task-detail'>
                    <h4 className='card-title fw-bolder'>{title}</h4>
                    <p className='m-0'>{detail}  <a target='_blank' href={link} className='text-primary'>Learn more <i className="bx bx-link-external"></i></a></p>
                </div>
            </div>
        </div>
    )
}

const TodoTask = ({ taskBarDisplay }) => {

    const { settings } = useContext(AppContext)
    const { user, store } = useContext(AuthContext);

    const setupStatuses = [
        {
            title: "Verify Your Account",
            detail: "Confirm account to enable all access.",
            link: "https://taojaa.com/guide/how-to-create-and-verify-your-account-on-taojaa",
            status: user?.verified
        },
        {
            title: "Complete Store Setup",
            detail: "Add store info, address, contact and customize storefront.",
            link: "https://taojaa.com/guide/how-to-complete-store-setup-and-customize-storefront",
            status: store.store_setup_status
        },
        {
            title: "Upload Your Products",
            detail: "Add products, collections and manage inventories.",
            link: "https://taojaa.com/guide/how-to-add-your-products-on-taojaa",
            status: store.has_products
        },
        {
            title: "Setup Shipping & Delivery Rates",
            detail: "Add shipping rates, pickup locations and delivery regions.",
            link: "https://taojaa.com/guide/how-to-add-delivery-options",
            status: store.shipping_setup_status
        },
        {
            title: "Setup Payment Methods",
            detail: "Add business payment methods and bank details.",
            link: "https://taojaa.com/guide/how-to-setup-the-multicurrency-payment-options-on-taojaa",
            status: store.payment_setup_status
        }
    ];

    return (
        <>
            {taskBarDisplay &&
                <div className="row">
                    <div className="col-lg-12 col-xl-12">
                        <div className="todo-task-card d-flex justify-content-center gap-3">
                            <div className={`col-lg-7 my-3 task-card-box ${settings.theme} shadow p-3 rounded-3`}>
                                <div>
                                    <h5 className='mb-0'>To-do Task</h5>
                                    <p className='text-muted'>Complete all task to see your store overview</p>
                                </div>
                                {setupStatuses.map((setup, index) =>
                                    <TaskCard
                                        index={index}
                                        title={setup.title}
                                        status={setup.status}
                                        detail={setup.detail}
                                        link={setup.link}
                                        settings={settings} />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TodoTask