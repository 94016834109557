import React from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from 'wearslot-dev-utils'
import { OrderPaymentStatus, OrderStatus } from './Orders'
import IconWithTooltip from '../../Components/IconWithTooltip'
import getSymbolFromCurrency from 'currency-symbol-map';


const OrderTableItem = ({ index, order, setSelectedForDelete }) => {
    return (
        <tr key={index}>
            <th scope="row">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkAll"
                        value="option1"
                    />
                </div>
            </th>
            <td className="id">
                <Link
                    to={`/order/${order.id}/details`}
                    className="fw-medium link-primary"
                >
                    {order.order_no}
                </Link>
            </td>
            <td className="customer_name">{order.customer && order.customer.name}</td>
            <td className="items">
                {order.first_item
                    ?
                    <div>
                        <p className="m-0">{String(order.first_item).substring(0, 15) + '...'}</p>
                        {((order.additional_items_count > 0) ?
                            <small className="text-muted"> + {order.additional_items_count} other item(s)</small>
                            : '')
                        }
                    </div>
                    : <span className="text-muted">No Item</span>}
            </td>
            <td className="sub_total_amount">{getSymbolFromCurrency(order.currency) + formatCurrency(order.sub_total)}</td>
            <td className="shipping_fee">{getSymbolFromCurrency(order.currency) + (formatCurrency(order.shipping_fee))}</td>
            <td className="payment_status"><OrderPaymentStatus status={order.payment_status} /></td>
            <td className="date">{order.created_date && order.date}</td>
            <td>{order.channel}</td>
            <td className="status"><OrderStatus status={order.status} /></td>
            <td>
                <ul className="list-inline hstack gap-2 mb-0">
                    <li
                        className="list-inline-item"
                        title="View"
                    >
                        <IconWithTooltip message={'View Order'}>
                            <Link
                                to={`/order/${order.id}/details`}
                                className="text-primary d-inline-block"
                            >
                                <i className="ri-eye-fill fs-16"></i>
                            </Link>
                        </IconWithTooltip>
                    </li>
                    <li
                        className="list-inline-item edit"
                        title="Edit"
                    >
                        <IconWithTooltip message={'Edit Order'}>
                            <Link
                                to={`/order/${order.id}`}
                                className="text-primary d-inline-block edit-item-btn"
                            >
                                <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </IconWithTooltip>
                    </li>
                    <li
                        className="list-inline-item"
                        title="Remove"
                    >
                        <IconWithTooltip message={'Delete Order'}>
                            <Link
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={() =>
                                    setSelectedForDelete(order.id)
                                }
                            >
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Link>
                        </IconWithTooltip>
                    </li>
                </ul>
            </td>
        </tr>
    )
}

export default OrderTableItem