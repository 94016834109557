import React, { useContext } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import { AuthContext } from '../../Contexts/AuthContext'


const SubscriptionStatus = () => {

  document.title = 'Subscription | Taojaa - Store Manager App';

  const { status } = useParams();
  const { subscription } = useContext(AuthContext);


  return (
    <>
      <div className="page-content">
        <div className="container-fluid">

          <div className="row justify-content-center">
            <div className="col-xxl-8">
              <div className="card">
                <div className="card-body">
                    {status === "successful" ? (
                      <div className="text-center py-5">
                        <div className="mb-4">
                          <lord-icon src="https://cdn.lordicon.com/lupuorrc.json"
                            trigger="loop" colors="primary:#695eef,secondary:#73dce9"
                            style={{ width: "120px", height: "120px" }}></lord-icon>
                        </div>
                        <h5>Yayy! You have Successfully Subscribe to {subscription.plan.name} {subscription.plan.group_name} Plan </h5>
                        <p className="text-muted">
                          You will receive an email notification upon renewal of your subscription.
                        </p>
                        <Link to="/" className="btn btn-primary">Continue</Link>
                      </div>

                    ) : (
                      <div className="text-center py-5">
                        <div className="mb-4">
                          <lord-icon
                            src="https://cdn.lordicon.com/tdrtiskw.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#405189"
                            style={{ width: "130px", height: "130px" }}>
                          </lord-icon>
                        </div>
                        <h5>Oops! Something went wrong</h5>
                        <p className="text-muted">
                          Unable to complete request due to an error, kindly contact support or try again later.
                        </p>
                        <div className="hstack gap-2 justify-content-center">
                          <button onClick={() => null} className="btn btn-link link-success fw-medium" data-bs-dismiss="modal">
                            Contact Support
                          </button>
                          <Link to="/" className="btn btn-primary">Continue</Link>
                        </div>
                      </div>
                    )}
                    
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Outlet />
    </>
  )
}

export default SubscriptionStatus