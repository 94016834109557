import React from 'react';
import axios from 'axios';
import { Link, Outlet } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Fragment, useContext, useEffect, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import ModalBox from "../../Components/ModalBox";
import Pagination from "../../Components/Pagination";
import moment from "moment";
import { errorResponse, responseMessage } from "../../libs/app";
import { formatCurrency } from "wearslot-dev-utils";

const Referrals = () => {

    const { store, subscription, getAuthCredentials } = useContext(AuthContext);
    const { referral_code } = store;

    const [plans, setPlans] = useState([]);
    const [saving, setSaving] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [earnings, setEarnings] = useState({
        total_users_referred: 0,
        total_points_earned: 0
    });
    const [redeem_options, setRedeemOptions] = useState([]);
    const [referrals, setReferrals] = useState({ users: [], pagination: null });
    const [modal, setModal] = useState({
        referral: false,
        redeem: false
    });
    const [redeem, setRedeem] = useState({
        type: null,
        plan_id: null
    });

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }

        getPlans();
        getReferrals();
        getRedeemOptions();
        getReferralEarnings();
    }, [currentPage, store, subscription]);

    async function getReferralEarnings() {
        try {
            const response = await axios.get(`/referrals/earnings`);
            if (response.data.success) {
                setEarnings(response.data.data);
            }
        } catch (error) {
        }
    }

    async function getReferrals() {
        try {
            const response = await axios.get(`/referrals?page=${currentPage}`);
            if (response.data.success) {
                setReferrals({ users: response.data.data, pagination: response.data.pagination });
            }
        } catch (error) {
        }
    }

    async function getPlans() {
        try {
            const response = await axios.get(`/plans`);
            if (response.data.success) {
                setPlans(response.data.data);
            }
        } catch (error) {
        }
    }

    async function getRedeemOptions() {
        try {
            const response = await axios.get(`/referrals/redeem/options`);
            if (response.data.success) {
                setRedeemOptions(response.data.data);
            }
        } catch (error) {
        }
    }

    async function redeemPoints() {
        try {
            setSaving(true);
            const response = await axios.post(`/referrals/redeem`, redeem);
            if (response.data.success) {
                responseMessage(response.data.message, "success");

                getAuthCredentials();
                getReferralEarnings();
                setRedeem({ type: null, plan_id: null });
                setModal({ ...modal, redeem: false });
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        } finally {
            setSaving(false);
        }
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleChange = (e) => {
        setRedeem({ ...redeem, [e.target.name]: e.target.value });
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid gap-4">

                    <div className="row justify-content-center">
                        <div className={`col-xxl-12`}>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Referrals</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="#" onClick={() => window.history.back()}>
                                                <i className="ri-arrow-left-line align-bottom me-1"></i>
                                                Back
                                            </Link>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    {earnings.total_users_referred > 0 &&
                        <div className="row justify-content-center">
                            <div className="col-xl-3 col-md-6 col-6">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                    Reward Points
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                    <span className="counter-value">
                                                        {store.reward_points}
                                                    </span>
                                                </h4>
                                                <Link to="#" onClick={() => setModal({ ...modal, redeem: true })} className="text-decoration-underline">
                                                    Redeem points
                                                </Link>
                                            </div>
                                            <div className="avatar-sm flex-shrink-0 desktop">
                                                <span className="avatar-title bg-soft-primary rounded fs-3">
                                                    <i className="bx bx-medal text-primary"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-6">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                    Referrals
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                    <span className="counter-value">
                                                        {earnings.total_users_referred}
                                                    </span>
                                                </h4>
                                                <Link to="#" onClick={() => setModal({ ...modal, referral: true })} className="text-decoration-underline">
                                                    View referrals
                                                </Link>
                                            </div>
                                            <div className="avatar-sm flex-shrink-0 desktop">
                                                <span className="avatar-title bg-soft-info rounded fs-3">
                                                    <i className="bx bx-user-circle text-info"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-6">
                                <div className="card card-animate">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                    Overall Earnings
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                    <span className="counter-value">
                                                        {earnings.total_points_earned}
                                                    </span>
                                                </h4>
                                                <Link to="#" onClick={() => setModal({ ...modal, referral: true })} className="text-decoration-underline">
                                                    View referrals
                                                </Link>
                                            </div>
                                            <div className="avatar-sm flex-shrink-0 desktop">
                                                <span className="avatar-title bg-soft-success rounded fs-3">
                                                    <i className="bx bx-money-withdraw text-success"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row justify-content-center">
                        <div className="text-center col-md-9 py-3">
                            <div className="mb-4">
                                <lord-icon src="https://cdn.lordicon.com/lupuorrc.json"
                                    trigger="loop" colors="primary:#695eef,secondary:#73dce9"
                                    style={{ width: "120px", height: "120px" }}></lord-icon>
                            </div>

                            <h3 className='mb-2'>Earn up to {getSymbolFromCurrency('NGN')}10,000 daily!</h3>
                            <p className='m-0 mb-1'>Refer users and get rewarded when they buy a plan on Taojaa.</p>
                            <p className='mb-3' style={{ lineHeight: "20px" }}>You will earn 1000 reward points on every referred users' first subscription,
                                your referrals will also get 20% discount on their first subscription. <Link to={'#'} className='text-primary'>Learn more <i className="bx bx-link-external"></i> </Link>
                            </p>

                            <h3 className="text-primary">https://taojaa.com/ref/{referral_code}</h3>
                            <CopyToClipboard
                                text={`https://taojaa.com/ref/${referral_code}`}
                                onCopy={() =>
                                    toast.success("Copied to clipboard")
                                }
                            >
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                >
                                    Copy Referral Link
                                </button>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <ModalBox
                        size={'xl'}
                        show={modal.referral}
                        handleClose={() => setModal({ ...modal, referral: false })}
                        title={"My Referrals"}
                        closeBtn={''}
                        saveBtn={null}>
                        {referrals.users.length > 0
                            ? <Fragment>
                                <div className="mt-3">
                                    <div className="table-responsive table-card mb-1">
                                        <table
                                            className="table table-nowrap align-middle"
                                            id="referralTable"
                                        >
                                            <thead className="text-muted table-light">
                                                <tr className='text-uppercase'>
                                                    <th className="sort" data-sort="id">
                                                        Id
                                                    </th>
                                                    <th className="sort" data-sort="name">
                                                        Name
                                                    </th>
                                                    <th className="sort" data-sort="businessname">
                                                        Business Name
                                                    </th>
                                                    <th className="sort" data-sort="status">
                                                        Status
                                                    </th>
                                                    <th className="sort" data-sort="created_date">
                                                        Registered Date
                                                    </th>
                                                    <th className="sort" data-sort="actions">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {referrals.users.map((referral, index) => (
                                                    <tr key={index}>
                                                        <td className="name">
                                                            #{index + 1}
                                                        </td>
                                                        <td className="name">
                                                            {referral.user?.firstname} {referral.user?.lastname}
                                                        </td>
                                                        <td className='business'>
                                                            {referral.user?.store?.name}
                                                        </td>
                                                        <td className="status">
                                                            {referral.subscribed
                                                                ? <span className="badge badge-soft-success text-uppercase">Subscribed</span>
                                                                : <span className="badge badge-soft-danger text-uppercase">Inactive</span>
                                                            }
                                                        </td>
                                                        <td className="created_date">
                                                            {moment(referral.created_date).format('ddd, D MMM YYYY')}
                                                        </td>
                                                        <td>
                                                            <a
                                                                href={`https://${referral.user?.store?.connected_domain || `${referral.user?.store?.slug}.taojaa.shop`}`}
                                                                target="_blank"
                                                                className="text-primary d-inline-block"
                                                            >
                                                                View website
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <Pagination pagination={referrals.pagination} onPageChange={onPageChange} />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                            : <div className="text-center py-5"><p>You have no referral</p></div>}
                    </ModalBox>

                    <ModalBox
                        show={modal.redeem}
                        handleClose={() => setModal({ ...modal, redeem: false })}
                        title={"Redeem Rewards"}
                        closeBtn={<button type="button" className="btn btn-light" onClick={() => setModal({ ...modal, redeem: false })}> Close</button>}
                        saveBtn={
                            (<button
                                type="submit"
                                disabled={saving}
                                className={`btn btn-success ${saving ? 'disabled' : ''}`}
                                onClick={(e) => redeemPoints(e)}>
                                {saving
                                    ? <>
                                        <div className="spinner-border spinner-border-sm text-white" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> Processing...
                                    </> : 'Redeem Points'}
                            </button>)}
                    >
                        <form>
                            <div className='mb-3'>
                                <label
                                    htmlFor="reward-type"
                                    className="form-label"
                                >
                                    Reward Type
                                </label>
                                <select
                                    name="type"
                                    className="form-control"
                                    id="reward-type"
                                    value={redeem.type}
                                    onChange={handleChange}
                                >
                                    <option defaultChecked>Select reward type</option>
                                    {redeem_options.map((option) => {
                                        return (
                                            <option key={option.slug} selected={option.slug == redeem.type} value={option.slug}>{option.name}</option>
                                        );
                                    })}
                                </select>
                            </div>

                            {redeem.type === 'subscription' &&
                                <div className='mb-3'>
                                    <label
                                        htmlFor="plan"
                                        className="form-label"
                                    >
                                        Plans
                                    </label>
                                    <select
                                        name="plan_id"
                                        className="form-control"
                                        id="plan"
                                        value={redeem.plan_id}
                                        onChange={handleChange}
                                    >
                                        <option value={''}>Select a plan</option>
                                        {plans.map((plan) => {
                                            return <option key={plan.id} selected={redeem.plan_id == plan.id} value={plan.id}>
                                                {plan.name.replace('Monthly', '1 Month').replace('Anually', '1 Year')} {plan.group_name} Plan - {getSymbolFromCurrency('NGN')}{formatCurrency(plan.price)}
                                            </option>
                                        })}
                                    </select>
                                </div>
                            }
                        </form>
                    </ModalBox>
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default Referrals;
