import React from "react";
import { Link } from "react-router-dom";

const OrderItemAction = ({ item, editItem, deleteItem  }) => {


  return (
    <>
      <Link
        to="#showModal"
        className="text-primary me-2"
        onClick={() => editItem(item)}
      >
        Edit
      </Link>
      <Link to="#" className="text-danger" onClick={()=> deleteItem(item.id)}>
        Remove
      </Link>
    </>
  );
};

export default OrderItemAction;
