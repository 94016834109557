import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  errorResponse,
  responseMessage,
} from "../libs/app";
import { AuthContext, logoutUser } from "../Contexts/AuthContext";


const Profile = () => {

  document.title = 'Profile | Taojaa - Store Manager App';

  const { user, store, subscription, setUser } = React.useContext(AuthContext);
  const [profile, setprofile] = useState({ ...user });

  const [saving, setSaving] = useState(null);
  const [changed, setChanged] = useState(false);
  const [deleteAccountPassword, setDeleteAccountPassword] = useState(null);
  const [settings, setSettings] = useState({
    direct_message: false,
    email_notification: false,
    chat_notification: false,
    purchase_notification: false
  });
  const [password, setpassword] = useState({
    password: "",
    confirm_password: "",
    old_password: "",
  });

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }

    getPrivacySettings()
  }, [store, subscription]);

  useEffect(() => {
    if (changed) {
      updatePrivacyPolicy()
    }
  }, [settings])


  async function getPrivacySettings() {

    try {

      const response = await axios.get('/settings/privacy-policy');
      if (response.data.success) {
        setSettings(response.data.data);
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
    }
  }

  const handlePrivacySettingChange = (e, name) => {
    setChanged(true)
    setSettings({ ...settings, [name]: e.target.checked })
  }

  async function updatePrivacyPolicy() {

    try {

      const data = { settings: JSON.stringify(settings) };
      const response = await axios.put('/settings/update/privacy-policy', data);

      if (response.data.success) {
        responseMessage(response.data.message, "success");
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function updateProfile() {

    try {

      setSaving("profile")

      var response = await axios.put(`/settings/update/profile`, profile);

      if (response.data.success) {
        responseMessage(response.data.message, "success");
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(null)
  }

  async function updatePassword() {
    try {

      setSaving("password")

      var data = {
        password: password.password,
        confirm_password: password.confirm_password,
        old_password: password.old_password,
      };

      const response = await axios.put(`/settings/update/password`, data);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(null)
  }

  async function uploadPicture(e) {

    try {

      setSaving("picture")

      var data = new FormData();
      data.append('file', e.target.files[0])
      data.append('type', 'picture');

      const response = await axios.post(`/settings/profile/upload`, data);
      if (response.data.success) {
        setprofile({ ...profile, picture: response.data.image })
        setUser({ ...user, picture: response.data.image })

        responseMessage(response.data.message, "success");
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(null)
  }

  async function uploadCover(e) {

    try {

      setSaving("cover_picture")

      var data = new FormData();
      data.append('file', e.target.files[0])
      data.append('type', 'cover_picture');

      const response = await axios.post(`/settings/profile/upload`, data);
      if (response.data.success) {
        setprofile({ ...profile, cover_picture: response.data.image })
        responseMessage(response.data.message, "success");
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(null)
  }

  async function deleteAccount() {

    if (deleteAccountPassword == null && user.sign_up_method === 1) {
      return responseMessage("Kindly Enter password to continue", "info");
    }

    try {

      const response = await axios.post('/settings/profile/delete', { password: deleteAccountPassword });
      if (response.data.success) {
        logoutUser()
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  function handlePasswordChange(event) {
    const value = event.target.value;
    setpassword({
      ...password,
      [event.target.name]: value,
    });
  }

  function handleProfileChange(event) {
    const value = event.target.value;
    setprofile({
      ...profile,
      [event.target.name]: value,
    });
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img
                src={profile.cover_picture}
                className="profile-wid-img"
                alt=""
              />

              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                    <input
                      id="profile-foreground-img-file-input"
                      type="file"
                      className="profile-foreground-img-file-input"
                      onChange={(e) => uploadCover(e)}
                    />
                    <label
                      htmlFor="profile-foreground-img-file-input"
                      className="profile-photo-edit btn btn-light"
                    >
                      {saving === 'cover_picture'
                        ? <div className="spinner-border spinner-border-sm text-primary" role="status">
                          <span className="visually-hidden">Loading...</span> Uploading...
                        </div>
                        : <><i className="ri-image-edit-line align-bottom me-1"></i>Change Cover</>
                      }

                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-3">
              <div className="card mt-n5">
                <div className="card-body p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      {profile.picture
                        ? <img src={profile.picture}
                          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile-image" />
                        : <img src="/assets/images/users/default.png"
                          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile-image" />}
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <input id="profile-img-file-input" type="file"
                          className="profile-img-file-input"
                          onChange={(e) => uploadPicture(e)} />
                        <label for="profile-img-file-input"
                          className="profile-photo-edit avatar-xs">
                          <span className="avatar-title rounded-circle bg-light text-body">
                            {saving === 'picture'
                              ? <div className="spinner-border spinner-border-sm text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                              : <i className="ri-camera-fill"></i>}
                          </span>
                        </label>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">{profile.firstname} {profile.lastname}</h5>
                    <p className="text-muted mb-0">{profile.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-9">
              <div className="card mt-xxl-n5">
                <div className="card-header">
                  <ul
                    className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        data-bs-toggle="tab"
                        to="#personalDetails"
                        role="tab"
                      >
                        Personal Details
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#changePassword"
                        role="tab"
                      >
                        Change Password
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#privacy"
                        role="tab"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body p-4">
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="personalDetails"
                      role="tabpanel"
                    >
                      <form action="#">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="firstname"
                                name="firstname"
                                placeholder="Enter your firstname"
                                value={profile.firstname}
                                onChange={handleProfileChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label
                                htmlFor="lastnameInput"
                                className="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="lastname"
                                name="lastname"
                                placeholder="Enter your lastname"
                                value={profile.lastname}
                                onChange={handleProfileChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="phone"
                                name="phone"
                                value={profile.phone}
                                onChange={handleProfileChange}
                                placeholder="Enter your phone number"
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label
                                htmlFor="emailInput"
                                className="form-label"
                              >
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={profile.email}
                                readOnly={true}
                                onChange={handleProfileChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="button"
                                onClick={updateProfile}
                                className={`btn btn-primary ${saving === "profile" ? 'disabled' : ''}`}
                                disabled={saving === "profile" ? true : false}
                              >
                                {saving === "profile"
                                  ? <>
                                    <div className="spinner-border spinner-border-sm text-white" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                    </div> Updating...
                                  </> : 'Update'}
                              </button>
                              <button
                                type="button"
                                className="btn btn-soft-info"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div
                      className="tab-pane"
                      id="changePassword"
                      role="tabpanel"
                    >
                      <form action="#">
                        <div className="row g-2">
                          <div className="col-lg-4">
                            <div>
                              <label
                                htmlFor="oldpasswordInput"
                                className="form-label"
                              >
                                Old Password*
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="oldpassword"
                                name="old_password"
                                value={password.old_password}
                                onChange={handlePasswordChange}

                              />
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div>
                              <label
                                htmlFor="newpasswordInput"
                                className="form-label"
                              >
                                New Password*
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="newpassword"
                                name="password"
                                value={password.password}
                                onChange={handlePasswordChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div>
                              <label
                                htmlFor="confirmpasswordInput"
                                className="form-label"
                              >
                                Confirm Password*
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="confirmpassword"
                                name="confirm_password"
                                value={password.confirm_password}
                                onChange={handlePasswordChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="text-end">
                              <button type="button"
                                onClick={updatePassword}
                                className={`btn btn-info ${saving === "password" ? 'disabled' : ''}`}
                                disabled={saving === "password" ? true : false}
                              >
                                {saving === "password"
                                  ? <>
                                    <div className="spinner-border spinner-border-sm text-white" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                    </div> Reseting Password...
                                  </> : 'Change Password'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="tab-pane" id="privacy" role="tabpanel">
                      {/* <div className="mb-4 pb-2">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Security:
                        </h5>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                          <div className="flex-grow-1">
                            <h6 className="fs-14 mb-1">
                              Two-factor Authentication
                            </h6>
                            <p className="text-muted">
                              Two-factor authentication is an enhanced security
                              meansur. Once enabled, you'll be required to give
                              two types of identification when you log into
                              Google Authentication and SMS are Supported.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Enable Two-factor Authentication
                            </Link>
                          </div>
                        </div>
                      </div> */}
                      <div className="mb-3">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Application Notifications:
                        </h5>
                        <ul className="list-unstyled mb-0">
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <label
                                className="form-check-label fs-14"
                                htmlFor="emailNotification"
                              >
                                Send email notifications
                              </label>
                              <p className="text-muted">
                                Get prompt update on your store activities, new updates and reports.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="emailNotification"
                                  checked={settings.email_notification}
                                  onChange={(e) => handlePrivacySettingChange(e, 'email_notification')}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <label
                                className="form-check-label fs-14"
                                htmlFor="purchaesNotification"
                              >
                                Send purchase notifications
                              </label>
                              <p className="text-muted">
                                Get real-time purchase alerts for your online store activities.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="purchaseNotification"
                                  checked={settings.purchase_notification}
                                  onChange={(e) => handlePrivacySettingChange(e, 'purchase_notification')}
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h5 className="card-title text-decoration-underline mb-3">
                          Deactivate My Account:
                        </h5>
                        <p className="text-muted">
                          Deactivate your Account. Follow the instructions below
                          to deactivate your account:
                        </p>
                        <div>
                          <input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Enter your password"
                            style={{ maxWidth: "265px" }}
                            onChange={(e) => setDeleteAccountPassword(e.target.value)}
                          />
                        </div>
                        <div className="hstack gap-2 mt-3">
                          <button type="button" onClick={deleteAccount} className="btn btn-soft-info">
                            Deactivate Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};
export default Profile;
