import React from 'react'

const SearchResponse = ({title, message}) => {
    return (
        <div className="noresult">
            <div className="text-center">
                <lord-icon
                    src="https://cdn.lordicon.com/msoeawqm.json"
                    colors="primary:#405189,secondary:#0ab39c"
                    style={{ width: "75px", height: "75px" }}
                ></lord-icon>
                <h5 className="mt-2">{title}</h5>
                <div className="text-muted">{message}</div>
            </div>
        </div>
    )
}

export default SearchResponse