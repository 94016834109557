import React from "react";
import CreatableSelect from "react-select/creatable";

const CreatableSingle = ({ id, options, selected, handleSelectOptions, handleCreate = undefined }) => {


  const handleChange = (newValue, actionMeta) => {
    if (newValue !== "") {
      handleSelectOptions(id, newValue);
    }
  };

  const handleInputChange = (inputValue, actionMeta) => {
    if (inputValue !== "") {
      handleSelectOptions(id, inputValue);
    }
  };

  return (
    <CreatableSelect
      isClearable
      value={selected}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      onCreateOption={handleCreate}
    />
  );
};

export default CreatableSingle;
