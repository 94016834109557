import React, { useState, useEffect } from 'react'
import Loader from '../../Components/Loader/Loader';
import SearchResponse from '../../Components/SearchResponse/SearchResponse';
import ModalBox from '../../Components/ModalBox';
import DeleteModal from '../../Components/DeleteModal';
import Pagination from '../../Components/Pagination';
import moment from 'moment';
import axios from 'axios';
import { errorResponse, responseMessage } from '../../libs/app';
import { Link } from 'react-router-dom';


const ExpenseTypes = () => {

    document.title = 'Expense Types | Taojaa - Store Manager App'

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({
        keyword: '',
        status: ''
    });

    const [expenseType, setExpenseType] = useState({
        name: '',
        description: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [forDelete, setForDelete] = useState(null);
    const [modal, setModal] = useState(false);


    useEffect(() => {
        getExpenseTypes();
    }, [])

    async function getExpenseTypes() {

        try {
            const response = await axios.get(`/expenses/types?page=${currentPage}`);
            if (response.data.success) {
                setExpenseTypes(response.data.data);
                setPagination(response.data.pagination);
            }
        }
        catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setLoading(false)
    }

    async function handleSubmit(e) {

        e.preventDefault();

        try {
            setSaving(true)

            if (expenseType.id !== undefined) {
                var response = await axios.put('/expenses/types/update', expenseType)
            } else {
                var response = await axios.post('/expenses/types/create', expenseType)
            }

            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getExpenseTypes()

                closeModal()
            }

        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function deleteExpenseType() {

        try {

            const response = await axios.delete(`/expenses/types/delete/${forDelete}`);

            if (response.data.success) {
                responseMessage(response.data.message, "success");

                getExpenseTypes();
                closeDeleteModal();
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    function editExpenseType(type) {
        setExpenseType(type);
        openModal()
    }

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const filterTable = () => {

    }

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setExpenseType({ ...expenseType, [name]: value });
    }

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
        setExpenseType({
            name: '',
            description: ''
        })
    }

    const closeDeleteModal = () => {
        setForDelete(null)
    }

    return (
        <div className="col-lg-12">
            <div className="card" id="orderList">
                <div className="card-header border-bottom-dashed">
                    <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 flex-grow-1">
                            Expense Types
                        </h5>
                        <div className="flex-shrink-0">
                            <button
                                type="button"
                                className="btn btn-success me-1"
                                onClick={openModal}
                            >
                                <i className="ri-add-line align-bottom me-1"></i> Add Expense Type
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body border-bottom-dashed border-bottom">
                    <form>
                        <div className="row g-3">
                            <div className="col-xl-8">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        className="form-control search"
                                        placeholder="Search for expense types..."
                                        name="keyword"
                                        value={filter.keyword}
                                        onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                                    />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary w-100"
                                                onClick={filterTable}
                                            >
                                                <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                                Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <div>

                        <div className="table-responsive table-card mb-1">
                            {expenseTypes.length > 0 ? (
                                <table
                                    className="table table-nowrap align-middle"
                                    id="orderTable"
                                >
                                    <thead className="text-muted table-light">
                                        <tr className='text-uppercase'>
                                            <th scope="col" style={{ width: "25px" }}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="checkAll"
                                                        value="option"
                                                    />
                                                </div>
                                            </th>
                                            <th className="sort" data-sort="code">
                                                Name
                                            </th>
                                            <th className="sort" data-sort="discount">
                                                Description
                                            </th>
                                            <th className="sort" data-sort="created_date">
                                                Created Date
                                            </th>
                                            <th className="sort" data-sort="actions">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {expenseTypes.map((type, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="checkAll"
                                                            value="option1"
                                                        />
                                                    </div>
                                                </th>
                                                <td className="name">
                                                    {type.name}
                                                </td>
                                                <td className="description">
                                                    {type.description}
                                                </td>
                                                <td className="created_date">
                                                    {moment(type.created_date).format('ddd, D MMM YYYY')}
                                                </td>
                                                <td>
                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                        <li
                                                            className="list-inline-item edit"
                                                            title="Edit"
                                                        >
                                                            <Link
                                                                to="#"
                                                                className="text-primary d-inline-block edit-item-btn"
                                                                onClick={() => editExpenseType(type)}
                                                            >
                                                                <i className="ri-pencil-fill fs-16"></i>
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item" title="Remove">
                                                            <Link
                                                                className="text-danger d-inline-block remove-item-btn"
                                                                data-bs-toggle="modal"
                                                                to="#deleteExpenseType"
                                                                onClick={() =>
                                                                    setForDelete(type.id)
                                                                }
                                                            >
                                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <>
                                    {loading
                                        ? <div className="py-5"><Loader message={<p className="text-muted">Fetching data...</p>} /></div>
                                        : <SearchResponse title={"No expense type"}
                                            message={<p>You haven't created any expense type yet. <Link to={"#"} type="button" className='text-primary' onClick={openModal}> Create expense type</Link></p>}
                                        />
                                    }
                                </>
                            )}
                        </div>

                        <div className="d-flex justify-content-end">
                            <div className="pagination-wrap hstack gap-2">
                                <Pagination pagination={pagination} previous={goToPreviousPage} next={goToNextPage} />
                            </div>
                        </div>
                    </div>

                    <ModalBox
                        show={modal}
                        handleClose={closeModal}
                        title={expenseType && expenseType.id !== undefined ? 'Edit Expense Type' : 'Add Expense Type'}
                        closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                        saveBtn={
                            (<button
                                type="submit"
                                disabled={saving}
                                className={`btn btn-success ${saving ? 'disabled' : ''}`}
                                onClick={(e) => handleSubmit(e)}>
                                {saving
                                    ? <>
                                        <div className="spinner-border spinner-border-sm text-white" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> Saving...
                                    </> : 'Save'}
                            </button>)}
                    >
                        <form>
                            <div className="mb-3">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="form-control"
                                    value={expenseType.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-3">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    Description
                                </label>
                                <input
                                    type="text"
                                    name="description"
                                    id="description"
                                    className="form-control"
                                    value={expenseType.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </form>
                    </ModalBox>

                    <DeleteModal
                        show={forDelete ? true : false}
                        title={'Delete confirmation'}
                        message={'Are you sure you want to delete this expense type?'}
                        handleDelete={deleteExpenseType}
                        handleClose={closeDeleteModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExpenseTypes