import React, { useRef, useContext, useState, useEffect } from 'react';
import EmailEditor from "react-email-editor";
import { AppContext } from '../../Contexts/AppContext';
import { Button } from "react-bootstrap";
import { CloseConfirmation } from '../../Components/DeleteModal';
import { responseMessage } from '../../libs/app';
import { AuthContext } from '../../Contexts/AuthContext';

const EmailBuilder = () => {

    const emailEditorRef = useRef(null);
    const { emailTemplate, setEmailTemplate } = useContext(AppContext);
    const { store, subscription } = useContext(AuthContext);

    const [saving, setSaving] = useState(false);
    const [closeConfirmation, setCloseConfirmation] = useState(false);

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }
    }, [store, subscription]);

    const exportHtml = async () => {
        await emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            setEmailTemplate({ html, design })
        }, {
            minify: true,
        });
    };

    const onReady = (unlayer) => {
        // editor is ready
        // you can load your template here;
        // the design json can be obtained by calling
        // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
        // const templateJson = { DESIGN JSON GOES HERE };
        // unlayer.loadDesign(templateJson);
        // unlayer.loadTemplate(1);
        if (emailTemplate) {
            unlayer.loadDesign(emailTemplate.design);
        }
    };

    const saveAndContinue = async () => {
        try {
            setSaving(true)
            await exportHtml()
            responseMessage("Saved", "success");
        } catch (error) {
            responseMessage("Something went wrong", "error");
        } finally {
            setSaving(false)
        }
    }

    const closeEditor = () => {
        return window.history.back();
    }


    return (
        <div className="page-content">
            <div className="">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Campaign Content</h4>
                            <div className="page-title-right">
                                <Button className="btn btn-sm btn-primary me-2" onClick={saveAndContinue}> {saving ? 'Saving..' : 'Save & Continue'}</Button>
                                <Button className="btn btn-sm btn-danger" onClick={() => setCloseConfirmation(true)}>Close</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <EmailEditor
                    editorId="email_content"
                    ref={emailEditorRef}
                    onReady={onReady}
                    minHeight={600}
                />
            </div>

            <CloseConfirmation
                show={closeConfirmation}
                title={"Confirmation"}
                message={"Are you sure you want to close editor? Kindly note that unsaved changes will no be saved."}
                handleConfirm={closeEditor}
                handleClose={() => setCloseConfirmation(false)}
            />
        </div>
    );
};

export default EmailBuilder;