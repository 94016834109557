import moment from "moment";
import React from 'react';
import { Tooltip } from "react-bootstrap";


export function dateChange(selectedDates, filters, setFilters) {
    if (selectedDates.length === 2) {
        let start = moment(selectedDates[0]);
        let end = moment(selectedDates[1]);

        setFilters({ ...filters, start_date: start.format("YYYY-MM-DD"), end_date: end.format("YYYY-MM-DD") });
    }
}

export function addFiltersToEndpoint(filters, endpoint) {

    if (filters) {
        Object.keys(filters).map(key => {
            if (filters[key]) {
                return endpoint += `&${key}=${filters[key]}`;
            }
        })
    }

    return endpoint
}

export const RenderTooltip = (message) => (
    <Tooltip id="button-tooltip">
        {message}
    </Tooltip>
);

export const copyLink = (link, setState) => {
    navigator.clipboard.writeText(link)
    setState(true)
}

export const generateNewRefCode = (setReference) => {
    setReference(`TAC${Date.now()}tYrt2y`)
}