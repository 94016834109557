import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';



export default function FileUploader({ uploader }) {

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: acceptedFiles => {
            uploader(acceptedFiles);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const imagePath = files.map(file => (
        <div className='imagePath' key={file.name}>
            <div>
                <img
                    className='tj-upload-img'
                    alt='Preview'
                    src={file.preview}
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />

            {(imagePath.length == 0) ?
                <div className='imagePath'>
                    <div><p className='py-3 mb-0'>Click to upload logo</p></div>
                </div>
                : <div className='imageContainer'>{imagePath}</div>
            }
        </div>
    );
}