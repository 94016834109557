import React from 'react'
import ModalBox from '../ModalBox'

const UpgradeModal = (props) => {

    return (
        <ModalBox {...props} centered
            className={"zoomIn text-center"}
            footerClass={'justify-content-center'}
            closeBtn={props.allowClose && <button
                className="btn btn-link link-success fw-medium text-decoration-none"
                onClick={props.handleClose}
            >
                <i className="ri-close-line me-1 align-middle"></i>
                Close
            </button>}
            saveBtn={
                <a href='/settings/billing-and-subscription' target='_blank' className={`btn btn-success`}>
                    Upgrade Now
                </a>}
        >
            <lord-icon src="https://cdn.lordicon.com/pithnlch.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}></lord-icon>
            <div className="mt-4 text-center">
                <h4>{props.upgradeRequirements}</h4>
                <p className="text-muted fs-15 mb-4">
                    {props.upgradeMessage}
                </p>
            </div>
        </ModalBox>
    )
}

export default UpgradeModal