import React, { Fragment } from 'react'
import { Link, Outlet } from 'react-router-dom';

const BlankPage = ({ children, title = 'Blank Page', titleColumSize = 12 }) => {

    return (
        <Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row justify-content-center">
                        <div className={`col-xxl-${titleColumSize}`}>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{title}</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <Link to="#" onClick={() => window.history.back()}>
                                                <i className="ri-arrow-left-line align-bottom me-1"></i>
                                                Back
                                            </Link>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        {children}
                    </div>
                </div>
            </div>
            <Outlet />
        </Fragment>
    )
}

export default BlankPage