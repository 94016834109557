import React from 'react'
import { Link } from 'react-router-dom'

const OrderTabs = ({ handleTabClick, recordsCount }) => {

    const tabs = {
        tab1: {
            status: null,
            defaultString: "All orders",
        },
        tab2: {
            status: 1,
            defaultString: "Pending",
        },
        tab3: {
            status: 2,
            defaultString: "Confirmed",
        },
        tab4: {
            status: 3,
            defaultString: "Packed",
        },
        tab5: {
            status: 4,
            defaultString: "Shipped",
        },
        tab6: {
            status: 5,
            defaultString: "Delivered",
        },
        tab7: {
            status: 6,
            defaultString: "Returns",
        },
        tab8: {
            status: 0,
            defaultString: "Cancelled",
        }
    };

    return (
        <ul
            className="nav nav-tabs nav-tabs-custom nav-success mb-3"
            role="tablist"
        >
            <li className="nav-item">
                <Link
                    className="nav-link active All py-3"
                    data-bs-toggle="tab"
                    id="All"
                    to="#home1"
                    role="tab"
                    aria-selected="true"
                    onClick={() => handleTabClick(tabs.tab1.status)}
                >
                    <i className="ri-store-2-fill me-1 align-bottom"></i>
                    {tabs.tab1.defaultString}
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link py-3 Pending"
                    data-bs-toggle="tab"
                    id="Pending"
                    to="#pending"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handleTabClick(tabs.tab2.status)}
                >
                    <i className="ri-loader-2-fill me-1 align-bottom"></i>
                    {tabs.tab2.defaultString}
                    <span className="badge bg-light text-dark align-middle ms-1">
                        {recordsCount.pendingCounts}
                    </span>
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link py-3 Confirmed"
                    data-bs-toggle="tab"
                    id="Confirmed"
                    to="#confirmed"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handleTabClick(tabs.tab3.status)}
                >
                    <i className="ri-check-double-line me-1 align-bottom"></i>
                    {tabs.tab3.defaultString}
                    <span className="badge bg-light text-dark align-middle ms-1">
                        {recordsCount.confirmedCounts}
                    </span>
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link py-3 Packed"
                    data-bs-toggle="tab"
                    id="Packed"
                    to="#packed"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handleTabClick(tabs.tab4.status)}
                >
                    <i className="ri-briefcase-2-line me-1 align-bottom"></i>
                    {tabs.tab4.defaultString}
                    <span className="badge bg-light text-dark align-middle ms-1">
                        {recordsCount.packedCounts}
                    </span>
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link py-3 Shipping"
                    data-bs-toggle="tab"
                    id="Shipping"
                    to="#shipping"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handleTabClick(tabs.tab5.status)}
                >
                    <i className="ri-truck-line me-1 align-bottom"></i>
                    {tabs.tab5.defaultString}
                    <span className="badge bg-primary align-middle ms-1">
                        {recordsCount.shippedCounts}
                    </span>
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link py-3 Delivered"
                    data-bs-toggle="tab"
                    id="Delivered"
                    to="#delivered"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handleTabClick(tabs.tab6.status)}
                >
                    <i className="ri-checkbox-circle-line me-1 align-bottom"></i>
                    {tabs.tab6.defaultString}
                    <span className="badge bg-success align-middle ms-1">
                        {recordsCount.deliveredCounts}
                    </span>
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link py-3 Returns"
                    data-bs-toggle="tab"
                    id="Returns"
                    to="#returns"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handleTabClick(tabs.tab7.status)}
                >
                    <i className="ri-arrow-left-right-fill me-1 align-bottom"></i>
                    {tabs.tab7.defaultString}
                    <span className="badge bg-dark align-middle ms-1">
                        {recordsCount.returnedCounts}
                    </span>
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className="nav-link py-3 Cancelled"
                    data-bs-toggle="tab"
                    id="Cancelled"
                    to="#cancelled"
                    role="tab"
                    aria-selected="false"
                    onClick={() => handleTabClick(tabs.tab8.status)}
                >
                    <i className="ri-close-circle-line me-1 align-bottom"></i>
                    {tabs.tab8.defaultString}
                    <span className="badge bg-danger align-middle ms-1">
                        {recordsCount.cancelledCounts}
                    </span>
                </Link>
            </li>
        </ul>
    )
}

export default OrderTabs