import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Chart from "react-apexcharts";
import Flatpickr from "react-flatpickr";
import { errorResponse, responseMessage } from "../../libs/app";
import { formatCurrency } from "wearslot-dev-utils";
import { AuthContext } from "../../Contexts/AuthContext";

const Sales = () => {

  document.title = 'Sales | Taojaa - Business Management App';

  const { store, subscription, selectedWallet, displayCurrency } = React.useContext(AuthContext);

  var linechartcustomerColors = ["#73dce9"];
  var productChartColors = ["#73dce9", "#695eef", "#ff7f41", "#11d1b7"];
  const [productcAnalyticsChart, setProductAnalyticsChart] = useState({
    series: [
      {
        name: "Units Sold",
        type: "area",
        data: [0, 0, 0],
      },
      {
        name: "Units Costs",
        type: "bar",
        data: [0, 0, 0],
      },
      {
        name: "Units Total",
        type: "line",
        data: [0, 0, 0],
      },
      {
        name: "Profit",
        type: "area",
        data: [0, 0, 0],
      }
    ],
    options: {
      chart: { height: 250, type: "line", toolbar: { show: !1 } },
      stroke: { curve: "straight", dashArray: [0, 0, 8], width: [2, 0, 2.2] },
      fill: { opacity: [0.1, 0.9, 1] },
      markers: { size: [0, 0, 0], strokeWidth: 2, hover: { size: 4 } },
      xaxis: {
        categories: [
          moment().subtract(2, 'days').format('MMM DD, YY'),
          moment().subtract(1, 'days').format('MMM DD, YY'),
          moment().format('MMMM DD'),
        ],
        axisTicks: { show: !1 },
        axisBorder: { show: !1 },
        labels: {
          formatter: function (value) {
            return value;
          }
        }
      },
      grid: {
        show: !0,
        xaxis: { lines: { show: !0 } },
        yaxis: { lines: { show: !1 } },
        padding: { top: 0, right: -2, bottom: 15, left: 10 },
      },
      legend: {
        show: !0,
        horizontalAlign: "center",
        offsetX: 0,
        offsetY: -5,
        markers: { width: 9, height: 9, radius: 6 },
        itemMargin: { horizontal: 10, vertical: 0 },
      },
      plotOptions: { bar: { columnWidth: "30%", barHeight: "70%" } },
      colors: productChartColors,
      tooltip: {
        shared: !0,
        y: [
          {
            formatter: function (e) {
              return e;
            },
          },
          {
            formatter: function (e) {
              var formated = displayCurrency + formatCurrency(e);
              return formated;
            },
          },
          {
            formatter: function (e) {
              var formated = displayCurrency + formatCurrency(e);
              return formated;
            },
          },
          {
            formatter: function (e) {
              var formated = displayCurrency + formatCurrency(e);
              return formated;
            },
          },
        ],
      },
    },
  });

  const [totalSalesChart, setTotalSalesChart] = useState({
    series: [
      {
        name: "Total Sales",
        type: "area",
        data: [0, 0, 0],
      },
    ],
    options: {
      chart: { height: 377, type: "line", toolbar: { show: !1 } },
      stroke: { curve: "straight", dashArray: [0, 0, 8], width: [2, 0, 2.2] },
      fill: { opacity: [0.1, 0.9, 1] },
      markers: { size: [0, 0, 0], strokeWidth: 2, hover: { size: 4 } },
      xaxis: {
        categories: [
          moment().subtract(2, 'days').format('MMM DD, YY'),
          moment().subtract(1, 'days').format('MMM DD, YY'),
          moment().format('MMMM DD'),
        ],
        axisTicks: { show: !1 },
        axisBorder: { show: !1 },
        labels: {
          formatter: function (value) {
            return value;
          }
        }
      },
      grid: {
        show: !0,
        xaxis: { lines: { show: !0 } },
        yaxis: { lines: { show: !1 } },
        padding: { top: 0, right: -2, bottom: 15, left: 10 },
      },
      legend: {
        show: !0,
        horizontalAlign: "center",
        offsetX: 0,
        offsetY: -5,
        markers: { width: 9, height: 9, radius: 6 },
        itemMargin: { horizontal: 10, vertical: 0 },
      },
      plotOptions: { bar: { columnWidth: "30%", barHeight: "70%" } },
      colors: linechartcustomerColors,
      tooltip: {
        shared: !0,
        y: [
          {
            formatter: function (e) {
              return e;
            },
          },
        ],
      },
    },
  });

  const [estimatedSalesChart, setEstimatedSalesChart] = useState({
    ...totalSalesChart, series: [{
      name: "Estimated Sales",
      type: "area",
      data: [0, 0, 0],
    }]
  });

  const [ordersCountChart, setOrdersCountChart] = useState({
    ...totalSalesChart, series: [{
      name: "Order",
      type: "area",
      data: [0, 0, 0],
    }]
  })

  const [salesByLocationGroup, setSalesByLocationGroup] = useState('country');
  const [salesRevenueByLocation, setSalesRevenueByLocation] = useState([]);

  var start = moment().subtract(2, "days");
  var end = moment();
  const [initialFrom, setInitialFrom] = useState(start.format("YYYY-MM-DD"));
  const [initialTo, setInitialTo] = useState(end.format("YYYY-MM-DD"));

  useEffect(() => {
    if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
      window.location = '/dashboard/access/choose/plan';
    }
  }, [store, subscription]);

  useEffect(() => {
    getOrdersSalesAnalyticsChart(initialFrom, initialTo);
    getProductsSalesAnalyticsChart(initialFrom, initialTo);

  }, [initialFrom, initialTo, selectedWallet]);

  useEffect(() => {
    getLocationsSalesAnalyticsChart(initialFrom, initialTo);
  }, [salesByLocationGroup, initialFrom, initialTo])


  async function getOrdersSalesAnalyticsChart(from, to) {

    try {

      const response = await axios.get(`/sales/analytics/orders?start_date=${from}&end_date=${to}`);

      if (response.data) {
        var _charts = response.data;

        setTotalSalesChart((prevState) => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: _charts.total_sales }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories: _charts.ticks } }
        }));

        setEstimatedSalesChart((prevState) => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: _charts.estimated_sales }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories: _charts.ticks } }
        }));

        setOrdersCountChart((prevState) => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: _charts.orders_count }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories: _charts.ticks } }
        }))

      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function getLocationsSalesAnalyticsChart(from, to) {

    try {
      const response = await axios.get(`/sales/analytics/locations?by=${salesByLocationGroup}&start_date=${from}&end_date=${to}`);
      if (response.data.success) {
        setSalesRevenueByLocation(response.data.data)
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function getProductsSalesAnalyticsChart(from, to) {

    try {
      const response = await axios.get(`/sales/analytics/products?start_date=${from}&end_date=${to}`);
      if (response.data) {
        setProductAnalyticsChart((prevState) => ({
          ...prevState,
          series: [
            { ...prevState.series[0], data: response.data.total_units },
            { ...prevState.series[1], data: response.data.cost_of_items },
            { ...prevState.series[2], data: response.data.amount_of_items },
            { ...prevState.series[3], data: response.data.profits },
          ],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories: response.data.ticks } }
        }))
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  const onDateSelected = (selectedDates) => {

    if (selectedDates.length === 2) {
      var start = moment(selectedDates[0]);
      var end = moment(selectedDates[1]);

      var days = moment.duration(start.diff(end)).asDays();

      setInitialFrom(start.format("YYYY-MM-DD"));
      setInitialTo(end.format("YYYY-MM-DD"));
    }
  }

  const Percantage = ({ value }) => {

    return (
      <div className="flex-shrink-0">
        {value < 0 ? (
          <h5 className="text-danger fs-14 mb-0" style={{ whiteSpace: "nowrap" }}>
            <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
            {value}%
          </h5>
        ) : (
          <>
            {
              value > 0 ?
                (
                  <h5 className="text-success fs-14 mb-0" style={{ whiteSpace: "nowrap" }}>
                    <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                    {value}%
                  </h5>
                ) : (
                  <h5 className="text-muted fs-14 mb-0" style={{ whiteSpace: "nowrap" }}>+0.00%</h5>
                )
            }
          </>
        )}
      </div>
    )
  }

  const handleSalesByLocationGroup = (option) => {
    setSalesByLocationGroup(option);
  }




  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="h-100">

                <div className="row mb-3 pb-1">
                  <div className="col-12">
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                      <div className="flex-grow-1">
                        <h4 className="fs-16 mb-1 fw-bolder">
                          Sales Analytics
                        </h4>
                      </div>
                      <div className="mt-3 mt-lg-0">
                        <form action="">
                          <div className="row g-3 mb-0 align-items-center">

                            <div className="col-sm-auto">
                              <div className="input-group">
                                <Flatpickr
                                  type="text"
                                  className="form-control border-0 shadow"
                                  id="dash-filter-picker"
                                  defaultValue={initialFrom + " to " + initialTo}
                                  options={{ mode: "range" }}
                                  onChange={onDateSelected}
                                />
                                <div className="input-group-text bg-primary border-primary text-white">
                                  <i className="ri-calendar-2-line"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-4">
                    <div className="card">
                      <div className="card-header border-0 d-flex flex-sm-column">
                        <h4 className="card-title flex-grow-1">Total Sales</h4>
                        <div>
                          <h5 className="mb-1">
                            <span
                              className="counter-value"
                            >
                              {displayCurrency}
                              {formatCurrency(totalSalesChart.series[0].data.reduce((a, b) => a + b, 0))}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="w-100">
                          <div
                            id="customer_impression_charts"
                            className="apex-charts"
                            dir="ltr"
                          >
                            {totalSalesChart ? (
                              <Chart
                                options={totalSalesChart.options}
                                series={totalSalesChart.series}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4">
                    <div className="card">
                      <div className="card-header border-0 d-flex flex-sm-column">
                        <h4 className="card-title flex-grow-1">Estimated Sales</h4>
                        <div>
                          <h5 className="mb-1">
                            <span
                              className="counter-value"
                            >
                              {displayCurrency}
                              {formatCurrency(estimatedSalesChart.series[0].data.reduce((a, b) => a + b, 0))}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="w-100">
                          <div
                            id="customer_impression_charts"
                            className="apex-charts"
                            dir="ltr"
                          >
                            {estimatedSalesChart ? (
                              <Chart
                                options={estimatedSalesChart.options}
                                series={estimatedSalesChart.series}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4">
                    <div className="card">
                      <div className="card-header border-0 d-flex flex-sm-column">
                        <h4 className="card-title flex-grow-1">Orders</h4>
                        <div>
                          <h5 className="mb-1">
                            <span
                              className="counter-value"
                            >
                              {ordersCountChart.series[0].data.reduce((a, b) => a + b, 0)}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="w-100">
                          <div
                            id="customer_impression_charts"
                            className="apex-charts"
                            dir="ltr"
                          >
                            {ordersCountChart ? (
                              <Chart
                                options={ordersCountChart.options}
                                series={ordersCountChart.series}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-xl-6">
                    <div className="card">
                      <div className="card-header border-0 d-flex flex-sm-column">
                        <h4 className="card-title flex-grow-1">Sales by Products</h4>
                      </div>

                      <div className="card-header p-0 border-0 bg-soft-light">
                        <div className="row g-0 text-center">
                          <div className="col-6 col-sm-3">
                            <div className="p-3 border border-dashed border-start-0">
                              <h5 className="mb-1">
                                <span
                                  className="counter-value"
                                >
                                  {productcAnalyticsChart.series[0].data.reduce((a, b) => a + b, 0)}
                                </span>
                              </h5>
                              <p className="text-muted mb-0">Units Sold</p>
                            </div>
                          </div>

                          <div className="col-6 col-sm-3">
                            <div className="p-3 border border-dashed border-start-0">
                              <h5 className="mb-1">
                                {displayCurrency}
                                <span
                                  className="counter-value" >
                                  {formatCurrency(productcAnalyticsChart.series[1].data.reduce((a, b) => a + b, 0))}
                                </span>
                              </h5>
                              <p className="text-muted mb-0">Tocal Cost</p>
                            </div>
                          </div>

                          <div className="col-6 col-sm-3">
                            <div className="p-3 border border-dashed border-start-0">
                              <h5 className="mb-1">
                                {displayCurrency}
                                <span
                                  className="counter-value">
                                  {formatCurrency(productcAnalyticsChart.series[2].data.reduce((a, b) => a + b, 0))}
                                </span>
                              </h5>
                              <p className="text-muted mb-0">Revenue</p>
                            </div>
                          </div>

                          <div className="col-6 col-sm-3">
                            <div className="p-3 border border-dashed border-start-0 border-end-0">
                              <h5 className="mb-1 text-success">
                                {displayCurrency}
                                <span
                                  className="counter-value"
                                >
                                  {formatCurrency(productcAnalyticsChart.series[3].data.reduce((a, b) => a + b, 0))}
                                </span>
                              </h5>
                              <p className="text-muted mb-0">
                                Profit
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body p-0 pb-2">
                        <div className="w-100">
                          <div
                            id="customer_impression_charts"
                            className="apex-charts"
                            dir="ltr"
                            style={{ minHeight: 300 }}
                          >
                            {productcAnalyticsChart ? (
                              <Chart
                                options={productcAnalyticsChart.options}
                                series={productcAnalyticsChart.series}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="card card-height-100">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Sales by Locations
                        </h4>
                        <div className="flex-shrink-0 dropdown">
                          <button
                            type="button"
                            id="salesByLocationDropdown"
                            className="btn btn-soft-primary btn-sm"
                            data-bs-toggle="dropdown" aria-expanded="false"
                          >
                            View by {salesByLocationGroup}
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="salesByLocationDropdown">
                            <li><a className="dropdown-item" onClick={() => handleSalesByLocationGroup("country")} href="#">By Country</a></li>
                            <li><a className="dropdown-item" onClick={() => handleSalesByLocationGroup("state")} href="#">By States</a></li>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="px-2 py-2 mt-1">
                          {
                            salesRevenueByLocation.length > 0 ? (
                              <>{
                                salesRevenueByLocation.map((location, index) => (
                                  <div key={index}>
                                    <p className="mb-1">
                                      {location.name} <span className="float-end">{`${location.percentage}%`}</span>
                                    </p>
                                    <div className="progress mt-2 mb-2" style={{ height: 6 }}>
                                      <div
                                        className="progress-bar progress-bar-striped bg-primary"
                                        role="progressbar"
                                        style={{ width: `${location.percentage}%` }}
                                        aria-valuenow={`${location.percentage}`}
                                        aria-valuemin="0"
                                        aria-valuemax={`${location.percentage}`}
                                      ></div>
                                    </div>
                                  </div>
                                ))
                              }</>
                            ) : <p className="text-center"><span>No record available</span></p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Sales;
